<section class="tw-py-4" *ngIf="products?.length" [ngClass]="{ 'justify-center': products?.length === 1, 'ordersBanner': banner || userOrder , 'userOrderBanner': userOrder, 'onlyOneProd': homePage && products?.length === 1, 'tw-bg-white': !ordersBanner, 'homeBanner': ordersBanner }">
  <h3 class="tw-px-5 tw-font-body tw-text-400 tw-text-gray-900 tw-mb-3" i18n="@@whatsInsideYourKit" *ngIf="!ordersBanner">
  What's inside your kit
  </h3>
  <ul class="tw-flex tw-flex-row tw-items-start tw-overflow-x-auto tw-mb-1 tw-pb-2 products-ul" [ngClass]="{ 'products-ul-max': products?.length > 4 }">
  <ng-container *ngFor="let product of products; let l = last; let i = index;">
    <li id="productImage" class="tw-flex-1 tw-flex tw-flex-col tw-items-center" (click)="clickOnProductImage()">
    <div class="tw-w-20 tw-h-40 tw-flex tw-px-2" [ngClass]="{ 'height-new-ui': ordersBanner }">
      <img class="tw-object-contain tw-items-center tw-justify-center" [src]="getProductImage(product)" />
    </div>
    <p class="tw-font-body tw-font-bold tw-text-200 tw-text-gray-900 tw-text-center tw-mb-1" *ngIf="!(banner || userOrder)">
      {{product?.title || product?.hiddenTitle}}
    </p>
    <p class="tw-font-body tw-text-100 tw-text-gray-900 tw-text-center" *ngIf="!ordersBanner">
      {{product?.quantity}} {{product?.quantityUnit}}
    </p>
    </li>
    <li *ngIf="!l" class="tw-flex-none tw-pt-20" [ngClass]="{ 'padding-new-ui': ordersBanner }">
    <cs-icon class="tw-h-4 tw-w-4 tw-text-gray-900" [iconName]="'add'"></cs-icon>
    </li>
  </ng-container>
  </ul>
  <div class="tw-px-5 tw-flex tw-flex-row tw-items-center tw-justify-between tw-flex-wrap tw-gap-3" *ngIf="!hideFooter && products?.length" [hidden]="hideButtons">
  <div id="getInstructions" class="tw-flex-auto tw-flex tw-items-center tw-justify-center tw-gap-3 tw-pl-4 tw-pr-3 tw-h-10 tw-border tw-border-gray-900 tw-font-body tw-font-bold tw-text-300 tw-text-green-500 tw-rounded-full" (click)="seeInstructions()">
    <span i18n="@@getInstructions">Get Instructions</span><cs-icon class="tw-h-4 tw-w-4 tw-text-green-400" [iconName]='"chevron_right"'></cs-icon>
  </div>
  <div id="readAboutProducts" class="tw-flex-auto tw-flex tw-items-center tw-justify-center tw-gap-3 tw-pl-4 tw-pr-3 tw-h-10 tw-border tw-border-gray-900 tw-font-body tw-font-bold tw-text-300 tw-text-green-500 tw-rounded-full" (click)="readMoreAboutProducts()">
    <span i18n="@@readMore">Read More</span><cs-icon class="tw-h-4 tw-w-4 tw-text-green-400" [iconName]='"chevron_right"'></cs-icon>
  </div>
  </div>
</section>