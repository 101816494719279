import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/index';
import { map, tap } from 'rxjs/operators';
import { ConnectionService } from '@services/connection-service';
import { BroadcastService } from '@services/broadcast-service';
import { AddressBookI } from '@store/modals';
import { fromActions } from '@store/actions';
import { fromSelectors } from '@store/selectors';
import { EventLoggerService } from '@services/event-logger-service';

@Component({
  selector: 'user-address-list',
  templateUrl: './user-address-list.html',
})

export class UserAddressListComponent implements OnInit {
  addressBookList$: Observable<AddressBookI[]>;
  isLoading$: Observable<boolean>;
  defaultAddress$: Observable<AddressBookI>;
  otherAddressList$: Observable<AddressBookI[]>;
  deliverTo: AddressBookI;
  loading: boolean = true;
  inProcess: boolean = false;
  totalSP: number = 0;
  totalMRP: number = 0;
  discount: number = 0;
  productsInCart: { [key: string]: number } = {};
  regimenId: any;
  products: any;
  regimen: any;

  constructor(private conn: ConnectionService,
    private broadcast: BroadcastService,
    private route: ActivatedRoute,
    private eventLogger: EventLoggerService,
    private store: Store,
    private router: Router) {
  }

  async ngOnInit(): Promise<void> {
    const params = this.route.snapshot.queryParams;
    if (params.products) {
      this.products = params.products.split(',');
    }
    if (params.regimenId) {
      this.regimenId = params.regimenId;
      const regimenList = await this.conn.fetchRegimens();
      regimenList.forEach((element: any): void => {
        if (element.regimenId === this.regimenId) {
          this.regimen = element;
        }
      });
    }
    this.isLoading$ = this.store.select(fromSelectors.selectIsFetchingAddress);
    this.addressBookList$ = this.store.select(fromSelectors.selectAddressBookList);
    this.defaultAddress$ = this.addressBookList$
      .pipe(
        map((data: AddressBookI[]): AddressBookI => data.find((each: AddressBookI): boolean => each.default)),
        tap((defaultAddress: AddressBookI): AddressBookI => this.deliverTo = defaultAddress));
    this.otherAddressList$ = this.addressBookList$
      .pipe(map((data: AddressBookI[]): AddressBookI[] => data.filter((each: AddressBookI): boolean => !each.default)));
    if (this.products) {
      const products: any[] = await this.conn.findAllProductsForUser(['RE_ORDER', 'ADD_ON'], true);
      products.forEach((product: any): void => {
        this.products.forEach((element: any): void => {
          if (product.id.toString() === element.toString()) {
            this.totalSP += product.get('price');
            this.totalMRP += product.get('mrp');
          }
        });
      });
    }
    if (this.regimen) {
      this.totalSP = this.regimen.fixedPrice;
    }
    await this.store.dispatch(fromActions.AddressBookListFetchBegin({}));
  }

  navigateTo(url: string): void {
    this.conn.navigateToURL(url);
  }

  editAddress(address: any): void {
    this.navigateTo(`/user/address/${address.objectId}/edit`);
  }

  getDeliveryAddress(defaultAddress: AddressBookI): string {
    const address = [];
    address.push(defaultAddress.buildingDetails);
    address.push(defaultAddress.city);
    address.push(defaultAddress.state);
    address.push(defaultAddress.zipCode);
    return address.filter((each: string): boolean => !!each).join(', ');
  }

  async deleteAddress(address: AddressBookI): Promise<void> {
    this.store.dispatch(fromActions.AddressBookDeleteBegin({ address }));
  }

  saveAsDeliveryAddress(): void {
    if (!this.deliverTo) return;
    this.inProcess = true;
    this.store.dispatch(fromActions.AddressBookSetDeliveryAddress({ deliveryAddress: this.deliverTo }));
    this.eventLogger.cleverTapEvent('click-selected-old-address', JSON.stringify({ addressId: this.deliverTo?.objectId }));
  }

  addNewAddressClick(): void {
    this.eventLogger.cleverTapEvent('click-add-new-address', JSON.stringify({ addressId: this.deliverTo?.objectId }));
    this.navigateTo('/user/address/new');
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  viewDetails(): void {
    if (this.regimen) {
      this.navigateTo(`/user?tab=regimen&class=${this.regimen.class}`);
    }
    if (this.products) {
      const params = this.route.snapshot.queryParams;
      const queryParams = { products: params.products, showAll: true };
      this.router.navigate(['user/order/reorder'], { queryParams });
    }
  }
}
