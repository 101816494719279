import { Component, ElementRef, HostListener, Inject, Input, ViewChild, AfterViewInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ConnectionService } from '@services/connection-service';
import { BroadcastService } from '@services/broadcast-service';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { WindowRefService } from '@services/window-ref-service';
import { TimeService } from '@services/time-service';
import { EventLoggerService } from '@services/event-logger-service';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'regimen-instruction',
  templateUrl: './regimen-instruction.html',
  styleUrls: ['../../user/user-view/user-regimen/user-view-regimen.scss'],
})
export class RegimenInstructionComponent implements AfterViewInit {
  @ViewChild('videoPlayer', { static: false }) videoPlayer: ElementRef<HTMLVideoElement>;
  @ViewChild('morningAndNightTabs', { static: false }) morningAndNightTabs: ElementRef<HTMLElement>;
  @ViewChild('nightElementRelative') set nightBlockMain(block: ElementRef<HTMLElement>) {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };
    /**
 * This is used to check is the element is in view port or not, intersection ratio greater than 0 && isIntersecting true means,
 * screen is scrolled to the specified block
 */
    const observer = new IntersectionObserver(async ([entry]: any, observe: any): Promise<any> => {
      if (entry?.intersectionRatio > 0 && entry?.isIntersecting) {
        this.isDayTime = false;
      } else {
        this.isDayTime = true;
      }
    }, options);
    if (block?.nativeElement) {
      observer?.observe(block?.nativeElement);
    }
  }

  @ViewChild('nightElement') set nightBlock(block: ElementRef<HTMLElement>) {
    if (!block) return;
    if (this.onNightBlockClick || this.onMorningBlockClick) return;
    this.todayDay = new Date().getDate();
    this.todayDayVal = new Date().getDay();
    const hours = new Date().getHours();
    this.isDayTime = hours > 6 && hours < 20;

    if (!this.isDayTime) {
      this.onNightClick(block.nativeElement);
    }
  }
  regimen: any = {};
  dayAfterDelivery: number = -1;
  changeInstructionForRepairProduct: boolean = true;
  morningProducts: any[] = [];
  nightProducts: any[] = [];
  weekArray: any[] = [];
  currentDate: any;
  todayDay: number;
  todayDayVal: any;
  supportTicketId: any;
  onNightBlockClick: boolean = false;
  isCustomInstruction: boolean = false;
  isLoading: boolean = false;
  onMorningBlockClick: boolean = false;
  thumbsUpDown: boolean = false;
  isDayTime: boolean;
  days: any[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thr', 'Fri', 'Sat'];
  monthNames: any[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  isPlaying: boolean = false;
  @Input('regimen')
  set regimenObject(r: any) {
    if (this.regimen?.concern === r?.concern) return;
    this.regimen = JSON.parse(JSON.stringify(r));
    this.processRegimen();
    this.checkForInstructionUpdate();
  }
  user: any;
  @Input('supportTicketId')
  set supportTicket(id: any) {
    this.supportTicketId = id;
  }
  @Input('regimenContentElement') regimenContentElement: HTMLElement;
  @Input('experiments')
  set experiments(data: any) { }
  @Input('newInstructionUIExperiment') newInstructionUIExperiment: boolean;
  @Input('scrollingDown') scrollingDown: boolean;
  thumbnailImageUrl: string;
  thumbnailImageUrlRebranding: string;
  videoURL: string;
  productIds: Array<any> = [];
  videoPlayed: boolean = false;
  daysData: any = [];
  userLanguage: string;
  isTabsVisible: boolean;

  constructor(private conn: ConnectionService,
    private broadcast: BroadcastService,
    public appConfig: AppConfig,
    private appBridge: AppWebBridgeService,
    private window: WindowRefService,
    private timerService: TimeService,
    private eventLoggerService: EventLoggerService,
    // eslint-disable-next-line new-cap
    @Inject(DOCUMENT) private document: Document) { }

  @HostListener('document:fullscreenchange')
  onFullscreenChange(): void {
    if (!this.document.fullscreenElement) {
      this.closeVideo();
    }
  }

  ngOnInit(): void {
    this.onMorningBlockClick = false;
    this.onNightBlockClick = false;
    this.getWeeklyCalendar();
    this.user = this.conn.getActingUser();
    if (!this.user?.get('instructionsFeedbackResponse') && this.supportTicketId) {
      this.thumbsUpDown = true;
    }
  }

  ngAfterViewInit(): void {
    this.setupIntersectionObserver();
  }

  setupIntersectionObserver(): void {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.7,
    };

    const observer = new IntersectionObserver(([entry]: IntersectionObserverEntry[]): void => {
      this.onIntersection(entry.isIntersecting);
    }, options);
    if (this.morningAndNightTabs?.nativeElement) {
      observer.observe(this.morningAndNightTabs.nativeElement);
    }
  }

  onIntersection(isIntersecting: boolean): void {
    if (this.isTabsVisible !== isIntersecting) {
      this.isTabsVisible = isIntersecting;
    }
  }

  // For variants, we need to manually update the instruction
  // as it doesn't give the translated strings
  async checkForInstructionUpdate(): Promise<void> {
    const hasVariants = this.regimen?.variants && this.regimen?.variants?.length;
    const hasTranslatedInstructionSet = this.regimen.morning[0]?.instructionSet?.areaOfApplication;
    if (hasVariants && !hasTranslatedInstructionSet) {
      await this.updateInstruction();
    }
  }

  async updateInstruction(): Promise<void> {
    this.isLoading = true;
    const regimens = await this.conn.fetchRegimens(null, true, true);
    const regimenInfo = regimens.find((each: any): boolean => each.class === this.regimen.class);
    this.regimen.morning = this.regimen?.morning?.map((product: any, index: number): any => {
      const updatedProduct = { ...product };
      delete updatedProduct?.instructionSet;
      updatedProduct.instructionSet = JSON.parse(JSON.stringify(regimenInfo?.morning[index]?.instructionSet));
      return updatedProduct;
    });

    this.regimen.night = this.regimen?.night?.map((product: any, index: number): any => {
      const updatedProduct = { ...product };
      delete updatedProduct?.instructionSet;
      updatedProduct.instructionSet = JSON.parse(JSON.stringify(regimenInfo?.night[index]?.instructionSet));
      return updatedProduct;
    });

    await this.processRegimen();
  }

  async processRegimen(): Promise<any> {
    this.isLoading = true;
    this.userLanguage = this.user?.get('languagePreference') || this.appConfig.Shared.Languages.EN;
    delete this.videoURL;
    delete this.thumbnailImageUrl;
    delete this.thumbnailImageUrlRebranding;
    this.morningProducts = [];
    this.nightProducts = [];
    this.productIds = [];
    await this.regimen?.morning?.forEach((e: any): void => {
      const each = e;
      if (!this.productIds.includes(e.product.objectId)) this.productIds.push(e.product.objectId);
      if (each.instructionSet) {
        const set = each.instructionSet;
        const prod: any = each.product?.instructions?.find((element: any): any => element.title === set.title);
        set.paidInstructionVideo = prod?.paidInstructionVideo;
        set.unpaidInstructionVideo = prod?.unpaidInstructionVideo;
        if (set.quantityUnit) set.quantityUnit = this.formatString(set.quantityUnit);
        if (set.areaOfApplication) set.areaOfApplication = this.formatString(set.areaOfApplication);
        if (set.frequencyOfApplication) set.frequencyOfApplication = this.formatString(set.frequencyOfApplication);
        if (set.frequencyOfApplicationEnglish) set.frequencyOfApplicationEnglish = this.formatString(set.frequencyOfApplicationEnglish);
        if (set.frequencyOfApplicationEnglish) set.newFrequencyOfApplication = this.formatFrequency(set);
        if (set.newFrequencyOfApplication) set.displayDays = this.formatStringDays(set.newFrequencyOfApplication);
        if (set.durationOfApplication) set.durationOfApplication = this.formatString(set.durationOfApplication);
        this.morningProducts.push(each);
      }
    });
    await this.regimen?.night?.forEach((e: any): void => {
      const each = e;
      if (!this.productIds.includes(e.product.objectId)) this.productIds.push(e.product.objectId);
      if (each.instructionSet) {
        const set = each.instructionSet;
        const prod: any = each.product?.instructions?.find((element: any): any => element.title === set.title);
        set.paidInstructionVideo = prod?.paidInstructionVideo;
        set.unpaidInstructionVideo = prod?.unpaidInstructionVideo;
        if (set.quantityUnit) set.quantityUnit = this.formatString(set.quantityUnit);
        if (set.areaOfApplication) set.areaOfApplication = this.formatString(set.areaOfApplication);
        if (set.frequencyOfApplication) set.frequencyOfApplication = this.formatString(set.frequencyOfApplication);
        if (set.frequencyOfApplicationEnglish) set.frequencyOfApplicationEnglish = this.formatString(set.frequencyOfApplicationEnglish);
        if (set.frequencyOfApplicationEnglish) set.newFrequencyOfApplication = this.formatFrequency(set);
        if (set.newFrequencyOfApplication) set.displayDays = this.formatStringDays(set.newFrequencyOfApplication);
        if (set.durationOfApplication) set.durationOfApplication = this.formatString(set.durationOfApplication);
        this.nightProducts.push(each);
      }
    });
    await this.checkForInstructionVideo(this.userLanguage);
    this.checkForRepairProductInstructionsAlert();
    this.eventLoggerService.cleverTapEvent('pageOpen', JSON.stringify({ regimenId: this.regimen.regimenId, pageName: 'instruction' }));
    this.isLoading = false;
  }
  /**
 * This method is used to get the current week starting from monday
 */
  getWeeklyCalendar(): void {
    this.weekArray = [];
    this.currentDate = new Date();
    if (!this.newInstructionUIExperiment) {
      for (let i = 1; i <= 7; i += 1) {
        const first = this.currentDate.getDate() - this.currentDate.getDay() + i;
        const day = new Date(this.currentDate.setDate(first)).toISOString().slice(0, 10);
        const dayValue = this.days[new Date(day).getDay()];
        const month = this.monthNames[new Date(day).getMonth()];
        const date = new Date(day).getDate();
        const obj = { dayValue, month, date };
        this.weekArray.push(obj);
      }
    } else {
      // New logic to place today in the middle of the week array
      for (let i = -3; i <= 3; i += 1) {
        const day = new Date(this.currentDate);
        day.setDate(this.currentDate.getDate() + i);
        const dayValue = this.days[day.getDay()];
        const month = this.monthNames[day.getMonth()];
        const date = day.getDate();

        const obj = { dayValue, month, date };
        this.weekArray.push(obj);
      }
    }
  }

  checkForRepairProductInstructionsAlert(): void {
    if (this.regimen.deliveredDate) {
      const todayDate: any = new Date();
      const regimenDeliveredDate = new Date(this.regimen.deliveredDate);
      const afterDeliveryDate1: any = this.timerService.addDays(regimenDeliveredDate, 1);
      if (todayDate > afterDeliveryDate1) {
        this.changeInstructionForRepairProduct = false;
        return;
      }
      if (todayDate.getDate() === regimenDeliveredDate.getDate()) this.dayAfterDelivery = 0;
      else if (todayDate.getDate() === afterDeliveryDate1.getDate()) this.dayAfterDelivery = 1;
      else this.changeInstructionForRepairProduct = false;
    }
  }

  formatString(text: string): string {
    return text.replace('N/A', '').trim();
  }

  /**
   * This method is used to format the instructions frequency of usage to days array.
   */
  formatFrequency(data: any, isOldUi: boolean = false): any {
    let text: string;
    if (isOldUi) {
      text = data;
    } else {
      text = data.frequencyOfApplicationEnglish;
    }
    if (!text) return '';
    const daysArray = text.split('/');
    const dayObjList = Object.keys(this.appConfig.Shared.FrequencyOfUsage.Days);
    const hasAllElems = daysArray.every((elem: any): any => dayObjList.includes(elem));
    if (isOldUi && !hasAllElems) {
      return text;
    }
    if (!isOldUi && !hasAllElems) {
      if (text.toLowerCase() !== 'all days') {
        // eslint-disable-next-line no-param-reassign
        data.isCustomInstruction = true;
      }
      return '';
    }
    this.daysData = [];
    daysArray.forEach((element: any): any => {
      if (this.appConfig.Shared.FrequencyOfUsage.Days[element]) {
        this.daysData.push(this.appConfig.Shared.FrequencyOfUsage.Days[element]);
      }
    });
    return this.daysData;
  }

  /**
   * This is used to format the array to a string with commas and & opeartor at last
   */
  formatStringDays(list: any): any {
    if (!list?.length) {
      return '';
    }
    if (list.length === 1) {
      return list.toString();
    }
    if (list.length === 2) {
      return list.join(' & ');
    }
    return `${list.slice(0, -1).join(', ')}, & ${list.slice(-1)}`;
  }

  private async checkForInstructionVideo(language: string): Promise<any> {
    if (this.appBridge.requestOSInformation() !== 'iOS' && !this.window.isSafariBrowser()) {
      const filenameArray = this.productIds;
      filenameArray.push(language);
      const fileName = filenameArray.join('-');
      const videoURL = `https://cdn.cureskin.com/regimen-products-video/${fileName}.webm`;
      const videoFileExists = await this.findIfProductVideoExists(videoURL);
      if (videoFileExists) {
        this.videoURL = videoURL;
        this.thumbnailImageUrl = `https://cdn.cureskin.com/regimen-products-video/regimen-instruction-thumbnail-${language}.jpg`;
        this.thumbnailImageUrlRebranding = `https://cdn.cureskin.com/regimen-products-video/regimen-instruction-thumbnail-${language}.jpg`;
        this.eventLoggerService.trackEvent('REGIMEN_PRODUCT_VIDEO_FOUND', { url: videoURL });
      } else {
        this.eventLoggerService.trackEvent('REGIMEN_PRODUCT_VIDEO_NOT_FOUND',
          { url: videoURL, class: this.regimen.class, regimenId: this.regimen.regimenId });
        if (language !== this.appConfig.Shared.Languages.EN) {
          await this.checkForInstructionVideo(this.appConfig.Shared.Languages.EN);
        }
      }
      this.scrollToTopOfPage();
    }
  }

  logVideoPlay(): void {
    if (this.videoPlayed) return;
    this.videoPlayed = true;
    this.eventLoggerService.trackInFirebaseAndBranch('REGIMEN_PRODUCT_VIDEO_PLAYED');
    this.eventLoggerService.cleverTapEvent('click', JSON.stringify({ name: 'play-regimen-instruction-video', class: this.regimen.class }));
    this.eventLoggerService.trackEvent('REGIMEN_PRODUCT_VIDEO_PLAYED',
      { url: this.videoURL, class: this.regimen.class, regimenId: this.regimen.regimenId });
  }

  closeVideo(): any {
    if (!this.isPlaying) return;
    this.videoPlayer.nativeElement.pause();
    this.videoPlayer.nativeElement.currentTime = 0;
    this.videoPlayer.nativeElement.parentElement.classList.add('tw-hidden');
    this.isPlaying = false;
  }

  async findIfProductVideoExists(url: any): Promise<boolean> {
    try {
      const http = new XMLHttpRequest();
      http.open('HEAD', url, false);
      http.send();
      return Promise.resolve(http.status !== 404);
    } catch (err) {
      return Promise.resolve(false);
    }
  }

  myDiet(): void {
    let articleId = '3PMLGVSMfZ';
    if (this.regimen.orderPlaced || this.regimen.delivered) {
      const { params = {} }: any = this.regimen.dietAndLifestyleQuestion || {};
      if (params.url) {
        if (params.url.includes('addonProduct/')) articleId = params.url.split('addonProduct/')[1];
        if (params.url.includes('article/')) articleId = params.url.split('article/')[1];
      }
      this.conn.navigateToURL(`/article/${articleId}`);
    } else this.broadcast.broadcast('NOTIFY', { message: 'Diet plan will be available once treatment is started' });
  }

  myDietNew(): void {
    this.conn.navigateToURL('/notice');
  }

  onNightClick(element?: HTMLElement): void {
    this.isLoading = true;
    const windowRef = this.window.nativeWindow;
    const doc = windowRef.document;
    const nightBlockScroll = doc.getElementById('nightBlockScroll');
    if (element) {
      element.scrollIntoView(true);
    } else {
      this.isDayTime = false;
      this.onNightBlockClick = true;
      nightBlockScroll.scrollIntoView(true);
    }
    this.isLoading = false;
  }
  onMorningClick(): void {
    this.isLoading = true;
    const windowRef = this.window.nativeWindow;
    const doc = windowRef.document;
    const morningBlock = doc.getElementById('morningBlock');
    if (morningBlock) {
      this.onNightBlockClick = false;
      this.onMorningBlockClick = true;
      this.isDayTime = true;
      morningBlock.scrollIntoView(true);
    }
    this.isLoading = false;
  }

  onPlayButtonClick(instructionItem: any): void {
    const activeUser = this.user || this.conn.getActingUser();
    this.userLanguage = activeUser?.get('languagePreference') || this.appConfig.Shared.Languages.EN;
    if (this.videoPlayer && this.videoPlayer.nativeElement) {
      this.eventLoggerService.cleverTapEvent('click', JSON.stringify({ name: 'play-product-instruction-video' }));
      const videoPlayer = this.videoPlayer.nativeElement;
      videoPlayer.parentElement.classList.remove('tw-hidden');
      if (activeUser?.isPaid()) {
        videoPlayer.src = instructionItem.instructionSet.paidInstructionVideo[this.userLanguage]
        || instructionItem.instructionSet.paidInstructionVideo.en;
      } else {
        videoPlayer.src = instructionItem.instructionSet.unpaidInstructionVideo[this.userLanguage]
        || instructionItem.instructionSet.unpaidInstructionVideo.en;
      }
      const playPromise = videoPlayer.play();
      if (playPromise && !this.isPlaying) {
        playPromise.then(():void => {
          this.isPlaying = true;
        });
      }
    }
  }

  scrollToTopOfPage(): void {
    if (this.regimenContentElement) this.regimenContentElement.scrollTop = 0;
  }

  async thumpsUpDownRes(response: boolean): Promise<void> {
    if (response) {
      this.eventLoggerService.cleverTapEvent('click', JSON.stringify({ name: 'instructionsHelpful' }));
    } else {
      this.eventLoggerService.cleverTapEvent('click', JSON.stringify({ name: 'instructionsNotHelpful' }));
    }
    await this.conn.updateSupportTicketFeedback(this.supportTicketId, response);
    this.user.set('instructionsFeedbackResponse', response ? 'yes' : 'no');
    await this.user.save();
    this.thumbsUpDown = false;
  }
}
