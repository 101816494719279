/* eslint-disable no-underscore-dangle */
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { AppConfig } from 'client/app/app.config';
import SwiperCore, { Scrollbar, Autoplay, SwiperOptions } from 'swiper';

SwiperCore.use([Autoplay, Scrollbar]);

@Component({
  selector: 'feed',
  templateUrl: './feed.html',
  styleUrls: ['./feed.scss', '../../user-explore.scss'],
})
export class FeedComponent {
  private _feed: any;
  @Input() set feed(feed: any) {
    if (feed && feed?.media) {
      this._feed = feed;
    } else if (feed && !feed?.media) {
      this._feed = {
        ...feed,
        media: [{
          url: feed?.url,
          aspectRatio: feed?.aspectRatio,
          type: feed?.type,
          thumbnailUrl: feed?.thumbnailUrl,
        }],
      };
    }
  }

  get feed(): any { return this._feed; }

  @Input() index: number;
  @Input() visible: boolean;
  @Input() isLiked: boolean;
  @Output() onVideoPlay: EventEmitter<any> = new EventEmitter();
  @Output() onCTAClick: EventEmitter<any> = new EventEmitter();
  @Output() likeToggle: EventEmitter<any> = new EventEmitter();

  carouselConfig: SwiperOptions = {
    slidesPerView: 1,
    navigation: { prevEl: 'nonext', nextEl: 'nonext' },
    pagination: true,
    loop: false,
  };
  playLikeAnimation: boolean = false;
  timeoutInterval: any;
  user: any;
  isPaidUser: boolean = false;
  isCtaDisabled: boolean = false;

  constructor(
    private eventLogger: EventLoggerService,
    public appConfig: AppConfig,
    public conn: ConnectionService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.user = await this.conn.getActingUser();
    if (this.user.get('orderState') === this.appConfig.Shared.User.OrderState.DELIVERED) this.isPaidUser = true;
    if (!this.isPaidUser && this.feed.ctaText === 'Buy') {
      this.isCtaDisabled = true;
    }
  }

  updateIsLikedFromFeedImageComponent(isLiked:boolean): void {
    this.isLiked = isLiked;
  }

  onLikeClick(): void {
    this.isLiked = !this.isLiked;
    if (this.isLiked) {
      this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'explore-feed-liked' }));
      this.playHeartAnimation();
    }
    this.likeToggle.emit(this.isLiked);
  }

  playHeartAnimation(): void {
    clearTimeout(this.timeoutInterval);
    this.playLikeAnimation = true;
    this.timeoutInterval = setTimeout((): boolean => this.playLikeAnimation = false, 500);
  }
}
