import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CsIconModule } from '@components/icon';
import { NgClass, NgIf, NgStyle } from '@angular/common';
import { AppConfig } from '../../../app.config';

@Component({
  selector: 'app-instruction-card',
  templateUrl: './instruction-card.component.html',
  standalone: true,
  imports: [
    CsIconModule,
    NgClass,
    NgStyle,
    NgIf,
  ],
})
export class InstructionCardComponent {
  @Input() item: any;
  @Input() isDayTime: boolean;
  @Input() todayDayVal: number;
  @Input() days: any[];
  @Input() user: any;
  @Input() userLanguage: string;
  @Input() index: number;
  @Input() dayAfterDelivery: number;
  @Input() changeInstructionForRepairProduct: boolean;

  @Output() playButtonClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(public readonly appConfig: AppConfig) { }

  onPlayButtonClick(): void {
    this.playButtonClick.emit(this.item);
  }
}
