import { Component, OnDestroy, AfterViewChecked, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/index';
import { Models } from 'api-client';
import { ConnectionService } from '@services/connection-service';
import { BroadcastService } from '@services/broadcast-service';
import { EventLoggerService } from '@services/event-logger-service';
import { ImageService } from '@services/image-service';
import { TimeService } from '@services/time-service';
import { CurrentComponentService } from '@services/current-component';
import SwiperCore, { Scrollbar, Autoplay, SwiperOptions } from 'swiper';
import { WindowRefService } from '@services/window-ref-service';
import { Location } from '@angular/common';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CureskinCashSheetComponent } from '@shared/bottom-sheet-layouts/cureskin-cash-sheet/cureskin-cash-sheet.component';
import { AnimationOptions } from 'ngx-lottie';
import { CacheService } from '@services/cache-service';
import { AppConfig } from '../../../app.config';

SwiperCore.use([Autoplay, Scrollbar]);
@Component({ selector: 'user-shop', templateUrl: './user-shop.html', styleUrls: ['./user-shop.scss'] })
export class UserShopComponent implements OnDestroy, AfterViewChecked {
  @ViewChild('bogoSection', { static: false }) bogoSection: ElementRef;
  @ViewChild('reorderProductsSection', { static: false }) reorderProductsSection: ElementRef;
  @ViewChild('recommendedProductsSection', { static: false }) recommendedProductsSection: ElementRef;
  @ViewChild('drRecommendedProductsSection', { static: false }) drRecommendedProductsSection: ElementRef;
  @ViewChild('personalisedRecommendationsSection', { static: false }) personalisedRecommendationsSection: ElementRef;
  @ViewChild('superSaverKitsSection', { static: false }) superSaverKitsSection: ElementRef;
  @ViewChild('holiSaleSection', { static: false }) holiSaleSection: ElementRef;
  @ViewChild('forYourBodySection', { static: false }) forYourBodySection: ElementRef;
  @ViewChild('forYourScalpSection', { static: false }) forYourScalpSection: ElementRef;
  @ViewChild('forYourLipsSection', { static: false }) forYourLipsSection: ElementRef;
  searchValue: string = '';
  showSearch: boolean = false;
  isSearchOn: boolean = false;
  user: any;
  userLanguageKnOrTa: boolean;
  cart: any;
  isCartPending: boolean;
  cartItems: any = [];
  productsInCart: any = {};
  productsInCartArray: any = [];
  cartDiscount: number = 0;
  showOrderStatus: any;
  totalCashBalance: any;
  totalMRP: number = 0;
  totalSP: number = 0;
  numberOfProductsInCart: number;
  regimens: any = [];
  products: any = [];
  allShopProducts: any = [];
  appLanguageCode: string;
  timeout: any = null;
  experiments: Array<any> = [];
  minimumCash: any;
  cashDiscount: any;
  tempCashBalance: any;
  showCashBalance: boolean = false;
  loading: boolean = true;
  isDiscontinued: boolean = false;
  subscriptions: Array<Subscription> = [];
  isBOGOSaleLive: boolean = false;
  isHoliSaleLive: boolean = false;
  saleVariants: any;
  holiSaleVariants: any;
  shopTagsSection: boolean = false;
  userDrImage: string;
  rebrandedLevelFourBanners: boolean = false;
  carouselConfigBoost: SwiperOptions = {
    slidesPerView: 1,
    navigation: { prevEl: 'nonext', nextEl: 'nonext' },
    loop: false,
    autoHeight: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      renderBullet: (index: number, className: string): string => `<span class="${className}"></span>`,
    },
  };
  newProduct: any;
  oldProduct: any;
  options: AnimationOptions = {
    path: '/assets/animation.json',
  };
  styles: Partial<CSSStyleDeclaration> = {
    marginTop: '-200px',
    position: 'sticky',
    bottom: '0',
  };
  animationProduct: any;
  showConfettiAnimation: boolean = false;

  isCheckoutStickyVisible: boolean = true;
  // Boost your regimen banners
  isBoostYourRegimenExperiment: boolean = false;
  showDrRecommendedSection: boolean = false;
  userProductsInCart: any[] = [];
  doctorProductsInCart: any[] = [];

  isProgressiveProductBanner: boolean = false;
  progressiveProduct: any;

  isBoostProductBanner: boolean = false;
  boostProductTagName: string = '';
  isBoostProductLoading: boolean = false;
  boostProductList: any[] = [];

  // Personalized Bogo
  isPersonalizedBogoExperiment: boolean = false;
  personalizedBogoTitle: any;

  // Products sections -
  saleProducts: any = [];
  personalizedBogoProducts: any[];
  isBogoProductsPending: boolean = true;

  reorderProducts: any = [];
  addOnProducts: any = [];
  isAddOnAndReorderPending: boolean = true;

  drRecommendedProducts: Array<any> = [];
  kitsProducts: any = [];
  holiSaleProducts: any = [];

  bodyProducts: any = [];
  scalpProducts: any = [];
  lipProducts: any = [];
  isShopTagSectionPending: boolean = true;
  aovTask: boolean = false;
  prodImage: any;
  barStyle: any = {};
  freeProduct: any;
  aovData: any;
  maxPrice: number;
  percentageDone: number = 0;
  amountRemaining: any;
  isAddingToCart: boolean = false;
  allSectionWiseProducts: Record<string, Array<Models.Catalog>> = {};
  searchResultTexts: Array<any> = [];
  searchResultProducts: Array<any> = [];
  advanceSearchExperiment: boolean = false;
  centerSearchExperiment: boolean = false;
  showCenterSearchResults: boolean = false;
  private debounceTimer: any = null;
  repairProducts: any[] = [];
  latestActiveRegimens: any;
  showRepairProductAutoComplete: boolean;
  newCartUI: boolean = false;

  constructor(private router: Router,
    public conn: ConnectionService,
    private broadcast: BroadcastService,
    public eventLogger: EventLoggerService,
    public appConfig: AppConfig,
    public imageService: ImageService,
    private currentComponentService: CurrentComponentService,
    public windowRef: WindowRefService,
    private route: ActivatedRoute,
    private location: Location,
    private bottomSheet: MatBottomSheet,
    public timeService: TimeService,
    private changeDetectionRef: ChangeDetectorRef,
    private cacheService: CacheService) {
    this.currentComponentService.set(this);
  }

  async ngOnInit(): Promise<any> {
    this.user = await this.conn.getActingUser();
    await this.fetchUserDrImage();
    this.experiments = await this.conn.findUserActiveExperiments();
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'shop-ui-page' }));
    this.getExperiments();
    await this.processOnInit();
    this.loading = false;
  }

  async processOnInit(): Promise<void> {
    this.minimumCash = this.appConfig.Shared.experimentVariants.SHOP_PAGE_CASH_VISIBILITY.minCash;
    this.totalCashBalance = await this.conn.fetchCashBalance();
    if (this.totalCashBalance >= this.minimumCash) {
      this.showCashBalance = true;
    }
    if (!this.user?.get('boostProductSelectedDate')) {
      this.user.set('boostProductSelectedDate', new Date());
      await this.user.save();
    }
    const boostProductSelectedDate = this.user?.get('boostProductSelectedDate');
    if (boostProductSelectedDate) {
      const differenceInDays = this.timeService.differenceInDays(new Date(), new Date(boostProductSelectedDate));
      if (this.user.get('boostProductTag') && differenceInDays <= 15) {
        this.boostProductTagName = this.user.get('boostProductTag');
      }
    }
    this.appLanguageCode = this.conn.currentWebSiteLanguage;
    await this.loadData();
    (<Window> this.windowRef.nativeWindow).performance.mark('shop-page-end');
    const measure: any = (<Window> this.windowRef.nativeWindow).performance
      .measure('shop_page_loading_time', 'shop-page-start', 'shop-page-end');
    this.eventLogger.trackEvent('shop_page_loading_time', {
      durationInMilliSecs: measure.duration,
    });
  }

  public getExperiments(): void {
    this.experiments.forEach(async (experiment: any): Promise<any> => {
      if (experiment.key === 'shop_section_personalized') {
        this.shopTagsSection = true;
      }
      if (experiment.key === 'perosonlized_bogo') {
        this.isPersonalizedBogoExperiment = true;
        this.personalizedBogoTitle = experiment.variant;
      }
      if (experiment.key === 'buy1_get1_sale') {
        this.saleVariants = experiment.variant;
        this.isBOGOSaleLive = true;
      }
      if (experiment.key === 'holi_sale') {
        this.holiSaleVariants = experiment.variant;
        this.isHoliSaleLive = true;
      }
      if (experiment.key === 'shop_search') {
        this.isSearchOn = true;
      }
      if (experiment.key === 'show_dr_recommended_section') {
        this.showDrRecommendedSection = true;
      }
      if (experiment.key === 'advanced_search') {
        this.advanceSearchExperiment = true;
      }
      if (experiment.key === 'boost_your_regimen') {
        this.isBoostYourRegimenExperiment = true;
      }
      if (experiment.key === 'level_four_banners') {
        this.rebrandedLevelFourBanners = true;
      }
      if (experiment.key === 'aov_gamification' && this.user?.get('orderState') === this.appConfig.Shared.User.OrderState.DELIVERED) {
        this.aovData = experiment.variant;
        this.maxPrice = this.aovData.totalValue;
        const product = await this.conn.findCatalogWithKey([this.aovData.productID]);
        [this.freeProduct] = JSON.parse(JSON.stringify(product));
        if (this.freeProduct?.rebrandedImageWithoutBackground?.length) {
          this.prodImage = this.freeProduct.rebrandedImageWithoutBackground[0];
        } else if (this.aovData?.productImage) {
          this.prodImage = this.aovData.productImage;
        } else if (this.freeProduct.rebrandedImageWithBackground?.length) {
          this.prodImage = this.freeProduct.rebrandedImageWithBackground[0];
        } else if (this.freeProduct?.productUnboxedImage?.length) {
          this.prodImage = this.freeProduct.productUnboxedImage[0];
        }
        this.aovTask = true;
        this.getCart();
      }
      if (experiment.key === 'center_search') {
        this.centerSearchExperiment = true;
      }
      if (experiment.key === 'new_cart_redesign') {
        this.newCartUI = true;
      }
    });
  }

  ngAfterViewInit(): void {
    // Perform any initialization that requires the view to be fully loaded
    const params = this.route.snapshot.queryParams;
    if (params.section) {
      this.checkLoadingAndScroll(params.section);
    }
  }

  checkLoadingAndScroll(sectionId: string): void {
    const interval = setInterval((): void => {
      if (!this.loading) { // Check if loading is complete
        this.scrollToSection(sectionId);
        clearInterval(interval);
      }
    }, 1);
  }

  scrollToSection(section: string): void {
    let element: ElementRef;
    let block: ScrollLogicalPosition;

    switch (section) {
      case 'bogoSection':
        element = this.bogoSection;
        block = 'center';
        break;
      case 'reorderProductsSection':
        element = this.reorderProductsSection;
        block = 'center';
        break;
      case 'recommendedProductsSection':
        element = this.recommendedProductsSection;
        block = 'center';
        break;
      case 'drRecommendedProductsSection':
        element = this.drRecommendedProductsSection;
        block = 'center';
        break;
      case 'personalisedRecommendationsSection':
        element = this.personalisedRecommendationsSection;
        block = 'center';
        break;
      case 'superSaverKitsSection':
        element = this.superSaverKitsSection;
        block = 'start';
        break;
      case 'holiSaleSection':
        element = this.holiSaleSection;
        block = 'start';
        break;
      case 'forYourBodySection':
        element = this.forYourBodySection;
        block = 'start';
        break;
      case 'forYourScalpSection':
        element = this.forYourScalpSection;
        block = 'start';
        break;
      case 'forYourLipsSection':
        element = this.forYourLipsSection;
        block = 'start';
        break;
      default:
        console.warn('Invalid section:', section);
        return;
    }
    // Check if element exists, and scroll to the element
    if (element?.nativeElement) {
      element.nativeElement.scrollIntoView({ behavior: 'smooth', block });
    }
  }

  async loadData(): Promise<void> {
    this.getUserLanguage();
    await this.getCart(true);
    // fetch bogo & personalized bogo
    this.isBogoProductsPending = true;
    Promise.all([
      this.isBOGOSaleLive ? this.fetchSaleProducts() : null,
      this.isPersonalizedBogoExperiment ? this.fetchPersonalizedBogoProducts() : null,
    ]).then((_: any): any => {
      this.isBogoProductsPending = false;
    });

    try {
      this.isShopTagSectionPending = true;
      this.isAddOnAndReorderPending = true;
      await this.fetchAllProducts();
      if (this.shopTagsSection) {
        await this.fetchShopProducts();
      }
      this.isShopTagSectionPending = false;
      this.isAddOnAndReorderPending = false;
    } catch (_) {
      this.isShopTagSectionPending = false;
      this.isAddOnAndReorderPending = false;
    }

    // this.loading = false;
    await Promise.all([
      this.fetchKitsProducts(),
      this.fetchRegimens(),
      this.showDrRecommendedSection ? this.fetchDrAddedProducts() : null,
      this.boostProductTagName ? this.fetchBoostProductByType(this.boostProductTagName) : null,
      this.isHoliSaleLive ? this.fetchHoliSaleProducts() : null,
    ]);
  }
  openBottomSheet(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'shop-cash-banner-click' }));
    this.bottomSheet.open(CureskinCashSheetComponent, {
      data: { data: this.totalCashBalance },
    });
  }

  async fetchUserDrImage(): Promise<void> {
    if (this.user.get('allocatedDoctor')?.id) {
      const allocatedDoctor = await this.conn.findUserByObjectId(this.user.get('allocatedDoctor').id);
      this.userDrImage = allocatedDoctor?.get('doctorDisplayImage') || allocatedDoctor?.get('DoctorImageThumbnail')
      || 'https://cdn.cureskin.com/app/img/dr-charu-main-concern.png';
    }
  }

  async fetchRepairProducts(): Promise<void> {
    // Map over the latestActiveRegimens array to create an array of promises
    const regimenPromises = this.latestActiveRegimens.map((regimen:any): any => this.conn.findRegimenById(regimen.objectId));
    try {
      // Wait for all promises to resolve
      const regimensWithProducts = await Promise.all(regimenPromises);
      // Extract products from each regimen response and filter for repair products
      this.repairProducts = regimensWithProducts?.flatMap((regimenWithProducts:any): any => regimenWithProducts.get('products').filter(
        (each:any): any => each?.get('isRepair')));
      this.repairProducts = JSON.parse(JSON.stringify(this.repairProducts));
    } catch (error) {
      this.broadcast.broadcast('NOTIFY', { message: error.toString() });
    }
  }

  async fetchRegimens(force?: boolean): Promise<any> {
    this.latestActiveRegimens = (await this.conn.fetchRegimens(null, force))
      .filter((regimen: any): boolean => (regimen.delivered && regimen.active));
    this.fetchRepairProducts();
    const latestFaceRegimen = this.latestActiveRegimens.find((regimen: any): boolean => (regimen.class === 'FACE'));
    if (!latestFaceRegimen) return;
    const differenceInDays = this.timeService.differenceInDays(new Date(), new Date(latestFaceRegimen.deliveredDate));
    if (differenceInDays >= 5 && differenceInDays <= 14) {
      this.isProgressiveProductBanner = true;
      const progressiveTreatment = await this.conn.fetchProgressiveProduct();
      // Check if progressive product is already ordered by the user
      if (progressiveTreatment) {
        const orders = await this.conn.fetchOrders({
          user: this.user,
          type: this.appConfig.Shared.Order.Type.PRODUCT,
          progressiveProductInfo: { $exists: true },
          stage: { $nin: [this.appConfig.Shared.Order.Stage.DELIVERED, this.appConfig.Shared.Order.Stage.CANCELED] },
        });
        if (!orders?.length) this.progressiveProduct = null;
      }

      this.progressiveProduct = progressiveTreatment && progressiveTreatment.get('product');

      // Check if the progressive product is already in the cart
      this.cartItems.forEach((item: any): void => {
        if (item.productId === this.progressiveProduct?.id) {
          this.progressiveProduct = null;
        }
      });
    }
    if (differenceInDays >= 20) this.isBoostProductBanner = true;

    if (this.isBoostProductBanner && this.isBoostYourRegimenExperiment && !this.doctorProductsInCart?.length) {
      this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'boost-your-regimen-banner' }));
    }

    if (this.isProgressiveProductBanner
      && this.progressiveProduct
      && this.isBoostYourRegimenExperiment
      && !this.doctorProductsInCart?.length) {
      this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'progressive-treatment-banner' }));
    }
  }

  async filterAvailableProducts(products:any[]):Promise<any> {
    return products.filter((product:any):any => product.get('inventoryStatus') === 'AVAILABLE');
  }

  filterAllShopProducts(): void {
    const shopSection = this.appConfig.Shared.ShopSections.TYPE;
    this.allShopProducts = Object.entries(this.allSectionWiseProducts)
      .filter(
        ([key, _]: [string, Models.Catalog[]])
        : boolean => ![shopSection.ReOrder, shopSection.Recommended, shopSection.Doctor]
          .includes(key),
      )
      .map(
        ([key, value]: [string, Models.Catalog[]]): Record<string, Array<Models.Catalog>> => ({ [key]: value }),
      );
  }

  async fetchShopProducts(): Promise<any> {
    this.filterAllShopProducts();
    let scalpProds: any;
    let bodyProds: any;
    let hairFallProds: any = [];
    let dandruffProds: any = [];
    let lipProds: any;
    if (this.allShopProducts && this.allShopProducts.length) {
      const shopMarketingTags = this.appConfig.Shared.ShopSections.MARKETING_TAGS;
      this.allShopProducts.forEach((element: any): any => {
        if (element[shopMarketingTags.MT_BODY] && element[shopMarketingTags.MT_BODY].length) {
          bodyProds = element[shopMarketingTags.MT_BODY];
          this.bodyProducts = JSON.parse(JSON.stringify(bodyProds));
        }
        if (element[shopMarketingTags.MT_SMOKER] && element[shopMarketingTags.MT_SMOKER].length) {
          lipProds = element[shopMarketingTags.MT_SMOKER];
          this.lipProducts = JSON.parse(JSON.stringify(lipProds));
        }
        if ((element[shopMarketingTags.MT_HAIR_FALL] && element[shopMarketingTags.MT_HAIR_FALL].length)) {
          hairFallProds = element[shopMarketingTags.MT_HAIR_FALL];
        }
        if ((element[shopMarketingTags.MT_DANDRUFF] && element[shopMarketingTags.MT_DANDRUFF].length)) {
          dandruffProds = element[shopMarketingTags.MT_DANDRUFF];
        }
      });
      scalpProds = hairFallProds.concat(dandruffProds);
      if (scalpProds && scalpProds?.length) {
        this.scalpProducts = JSON.parse(JSON.stringify(scalpProds));
      }
    }
  }

  async fetchSaleProducts(): Promise<any> {
    const saleProds = await this.conn.findBogoSaleProds();
    const tempSaleProducts = [];
    saleProds?.forEach((element: any): any => {
      tempSaleProducts.push(element.get('product'));
    });
    const availableSaleProducts = await this.filterAvailableProducts(tempSaleProducts);
    availableSaleProducts?.sort((element1: any, element2: any): number => {
      if (element1.get('ranking') === element2.get('ranking')) {
        return 0;
      }
      return element1.get('ranking') > element2.get('ranking') ? 1 : -1;
    });
    this.saleProducts = JSON.parse(JSON.stringify(availableSaleProducts.slice(0, 5)));
  }

  async fetchKitsProducts(): Promise<any> {
    const kitProds = await this.conn.findKitsProducts();
    const availableKitProds = await this.filterAvailableProducts(kitProds);
    availableKitProds.sort((element1: any, element2: any): number => {
      if (element1.get('ranking') === element2.get('ranking')) {
        return 0;
      }
      return element1.get('ranking') > element2.get('ranking') ? 1 : -1;
    });
    this.kitsProducts = JSON.parse(JSON.stringify(availableKitProds.slice(0, 5)));
  }

  async fetchHoliSaleProducts(): Promise<any> {
    const limit = 5;
    const saleProds = await this.conn.findHoliSaleProds(limit);
    const availableSaleProds = await this.filterAvailableProducts(saleProds);
    this.holiSaleProducts = JSON.parse(JSON.stringify(availableSaleProds));
  }

  async fetchPersonalizedBogoProducts(): Promise<any> {
    const payload = {
      username: this.user.get('username'),
      tag: ['BOGO_PRODUCT'],
      responseArray: true,
      limit: 5,
    };
    const personalizedBogoProducts = await this.conn.findProductsForUser(payload);
    const availablepersonalizedBogoProducts = await this.filterAvailableProducts(personalizedBogoProducts);
    this.personalizedBogoProducts = JSON.parse(JSON.stringify(availablepersonalizedBogoProducts));
  }

  async fetchDrAddedProducts(): Promise<any> {
    const payload = {
      username: this.user.get('username'),
      tag: ['DOCTOR_ADDED'],
      responseArray: true,
      limit: 5,
    };
    const products = await this.conn.findProductsForUser(payload);
    this.drRecommendedProducts = JSON.parse(JSON.stringify(products));
  }

  ngAfterViewChecked(): void {
    const params = this.route.snapshot.queryParams;
    if (params.bogo && this.isBOGOSaleLive) {
      const windowRef = this.windowRef.nativeWindow;
      const doc = windowRef.document;
      const bogoSection = doc.getElementById('bogoSection');
      if (bogoSection) {
        bogoSection.scrollIntoView(true);
      }
    }
  }

  goToCart(): void {
    this.eventLogger.cleverTapEvent('cartClicked', JSON.stringify({ name: 'shop' }));
    this.router.navigate(['cart']);
  }

  /**
   * @param firstCall boolean Evaluate DoctorProudcts/UserProducts only once when shop page is loaded.
   * Otherwize every add to product will lead updation/show/hide of the banners
   */
  async getCart(firstCall?: boolean): Promise<void> {
    this.isCartPending = true;
    this.cart = await this.conn.getCart();
    this.cartItems = this.cart.get('lineItems');
    this.fetchDiscount();
    if (firstCall) {
      this.doctorProductsInCart = [];
      this.userProductsInCart = [];
    }
    if (!this.cartItems.length) {
      if (this.aovTask) {
        this.barStyle.width = '1%';
        this.barStyle.backgroundColor = '#D66736';
        const found = this.cartItems.some((el: any): any => el.productId === this.aovData.productID);
        if (found) {
          const params1 = {
            productId: this.aovData.productID,
            quantity: 1,
          };
          await this.conn.removeProductFromCart(params1);
          this.getCart();
        }
      }
      this.amountRemaining = this.maxPrice - this.totalSP;
      this.numberOfProductsInCart = null;
      this.productsInCart = {};
      this.percentageDone = Math.round((this.totalSP / this.maxPrice) * 100);
      return;
    }

    // this is executed when AOV exp is on to add a free product above certain value in cart
    if (this.aovTask) {
      this.percentageDone = Math.round((this.totalSP / this.maxPrice) * 100);
      const foundTwo = this.cartItems.some((el: any): any => el.productId === this.aovData.productID && el.quantity > 1);
      if (this.totalSP < this.maxPrice || foundTwo) {
        const found = this.cartItems.some((el: any): any => el.productId === this.aovData.productID);
        if (found) {
          const params1 = {
            productId: this.aovData.productID,
            quantity: 1,
          };
          await this.conn.removeProductFromCart(params1);
          this.getCart();
        }
        this.amountRemaining = this.maxPrice - this.totalSP;
        this.barStyle.width = `${this.percentageDone}%`;
        this.barStyle.backgroundColor = '#D66736';
      } else {
        this.barStyle.width = '100%';
        this.barStyle.backgroundColor = '#5EAC73';
        this.amountRemaining = 0;
        const found = this.cartItems.some((el: any): any => el.productId === this.aovData.productID);
        if (!found) {
          const params1 = {
            productId: this.aovData.productID,
            quantity: 1,
          };
          await this.conn.addProductToCart(params1);
          this.getCart();
          this.showConfettiAnimation = true;
          setTimeout((): any => {
            this.showConfettiAnimation = false;
          }, 2000);
        }
      }
    }
    this.numberOfProductsInCart = 0;
    this.productsInCart = {};
    this.cartItems.forEach((element: any): void => {
      this.numberOfProductsInCart += element.quantity;
      // Divide user and doctor products in cart
      if (element?.isDoctorAdded && firstCall) {
        this.doctorProductsInCart.push(element);
      }
      if (firstCall) {
        this.productsInCartArray.push(element);
      }
      this.productsInCart[element.productId] = element;
    });

    if (this.doctorProductsInCart?.length) {
      const drAddedProducts = this.doctorProductsInCart.map((each: any): any => each.productId);
      this.drRecommendedProducts = await this.conn.findProductsById(drAddedProducts);
      this.drRecommendedProducts = JSON.parse(JSON.stringify(this.drRecommendedProducts));
    }

    if (firstCall) {
      this.fetchDiscount();
    }
    this.isCartPending = false;
  }

  fetchDiscount(): any {
    this.totalMRP = 0;
    this.totalSP = 0;
    this.cartItems.forEach((element: any): any => {
      this.totalSP += element.quantity * element.price;
      this.totalMRP += element.quantity * element.mrp;
    });
    this.totalMRP = Math.round(this.totalMRP);
    this.totalSP = Math.round(this.totalSP);
    if (this.showCashBalance) {
      this.cashDiscount = Math.min(this.totalCashBalance, this.totalSP);
      this.totalSP -= this.cashDiscount;
    }
    let discount = 0;
    this.totalMRP = Math.floor(this.totalMRP);
    if (this.totalMRP > this.totalSP) {
      discount = Math.floor(((this.totalMRP - this.totalSP) * 100) / this.totalMRP);
    }
    this.cartDiscount = discount;
    this.changeDetectionRef.detectChanges();
  }

  async fetchAllProducts(): Promise<any> {
    this.allSectionWiseProducts = await this.conn.findAllSectionWiseProductsServerAPI(true);
    const shopSection = this.appConfig.Shared.ShopSections.TYPE;
    this.addOnProducts = this.allSectionWiseProducts[shopSection.Recommended];
    this.reorderProducts = this.allSectionWiseProducts[shopSection.ReOrder];
  }

  back(): void {
    if (this.showSearch) {
      this.showSearch = false;
      return;
    }
    if (this.showCenterSearchResults) {
      this.showCenterSearchResults = false;
      this.searchValue = '';
      return;
    }
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  closeSearch(): void {
    this.searchValue = '';
    this.showSearch = false;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
    this.currentComponentService.remove(this);
  }

  exploreAll(): void {
    this.router.navigate(['/user/order/reorder'], { queryParams: { showAll: true } });
  }

  async addToCart(event: any, sectionName: string): Promise<void> {
    if (this.isAddingToCart) {
      return;
    }
    this.isAddingToCart = true;
    const product = event?.product;
    const image = event?.image;
    // If product is discountinued then open popup for alternative product
    if (product?.inventoryStatus === this.appConfig.Shared.Inventory.Type.DISCONTINUED) {
      const alternateProduct = await this.conn.findCatalogWithAlternateProduct(product.objectId);
      if (alternateProduct.get('alternateProduct')) {
        this.oldProduct = product;
        this.newProduct = JSON.parse(JSON.stringify(alternateProduct.get('alternateProduct')));
        this.isDiscontinued = true;
        this.isAddingToCart = false;
        return;
      }
    }

    if (!product) {
      this.isAddingToCart = false;
      return;
    }

    const productObjectInCart = this.cart?.get('lineItems')?.find((each: any): any => each.productId === product.objectId);

    if (productObjectInCart && product?.prescriptionRequired && productObjectInCart.quantity >= 3) {
      this.broadcast.broadcast('NOTIFY', { message: 'Maximum quantity is limited to 3 per product' });
      this.isAddingToCart = false;
      return;
    }

    if (productObjectInCart && !product?.prescriptionRequired && productObjectInCart.quantity >= 5) {
      this.broadcast.broadcast('NOTIFY', { message: 'Maximum quantity is limited to 5 per product' });
      this.isAddingToCart = false;
      return;
    }

    this.animateAddToCart(image);
    await this.conn.addProductToCart({ productId: product.objectId, quantity: 1, section: sectionName });
    if (this.searchValue.length > 2) {
      this.eventLogger.cleverTapEvent('Click', JSON.stringify({ name: 'advance-search-add-to-cart', value: product.objectId }));
    }
    await this.getCart();
    this.isAddingToCart = false;
  }

  animateAddToCart(img?: HTMLImageElement): void {
    const image = new Image();
    image.src = img.src;
    image.style.position = 'absolute';
    image.style.top = `${img.getBoundingClientRect().top}px`;
    image.style.right = `${this.windowRef.nativeWindow.innerWidth - img.getBoundingClientRect().right}px`;
    image.style.height = `${img.offsetHeight}px`;
    image.style.width = 'auto';
    image.style.zIndex = '1000';

    image.classList.add('fly-animation');
    image.onanimationend = ((): void => {
      image.remove();
    });
    this.windowRef.nativeWindow.document.body.appendChild(image);
  }

  async reomveFromCart(product: any): Promise<any> {
    const quantity = this.cart.get('lineItems').find((p: any): any => p.productId === product.objectId)?.quantity;
    if (!quantity || quantity <= 0) return;

    const params = {
      productId: product.objectId,
      quantity,
    };
    await this.conn.removeProductFromCart(params);
    this.getCart();
  }

  async fetchSearchData(): Promise<any> {
    if (this.advanceSearchExperiment) {
      this.fetchAdvanceSearch();
    } else {
      this.fetchSearch();
    }
  }

  async fetchAdvanceSearch(): Promise<any> {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
    if (this.searchValue.length <= 2) {
      this.showCenterSearchResults = false;
    }
    if (this.searchValue.length > 2) {
      this.loading = true;
      this.debounceTimer = setTimeout(async ():Promise<any> => {
        await this.performAdvanceSearch();
      }, 1000);
    } else {
      this.loading = false;
    }
  }

  async fetchSearch(): Promise<any> {
    clearTimeout(this.timeout);
    if (this.searchValue.length) {
      this.loading = true;
      this.timeout = setTimeout((): any => {
        const pageName = 'search';
        this.eventLogger.cleverTapEvent('search', JSON.stringify({ name: 'search-with-term', value: this.searchValue }));
        this.conn.navigateToURL(`/user/order/reorder?searchKey=${this.searchValue}&from=${pageName}`);
        this.loading = false;
      }, 2000);
    }
  }

  async performAdvanceSearch():Promise<void> {
    this.eventLogger.cleverTapEvent('search', JSON.stringify({ name: 'advance-search-with-term', value: this.searchValue }));
    const responseAutocomplete = await this.conn.serverApi.findAutocomplete(
      this.searchValue,
      this.user.get('sessionToken'),
    );
    this.searchResultTexts = responseAutocomplete.data.catalogs.slice(0, 3);
    this.loading = false;
    const responseSearchProducts = await this.conn.serverApi.findCatalogsByKeyword(
      this.searchValue,
      this.user.get('sessionToken'),
    );
    this.searchResultProducts = responseSearchProducts.data.catalogs;

    if (this.centerSearchExperiment) {
      this.showCenterSearchResults = true;
      this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'searched-using-center-search' }));
    } else {
      this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ name: 'advance-search-page-open' }));
    }
  }

  async selectAutocomplete(autocompleteWord:{title:string}):Promise<void> {
    this.eventLogger.cleverTapEvent('search', JSON.stringify(
      { name: 'advance-search-autocomplete-select', value: autocompleteWord.title }));
    this.searchValue = autocompleteWord.title;
    this.loading = true;
    const responseSearchProducts = await this.conn.serverApi.findCatalogsByKeyword(
      this.searchValue,
      this.user.get('sessionToken'),
    );
    this.searchResultTexts = [];
    this.searchResultProducts = responseSearchProducts.data.catalogs;
    if (this.centerSearchExperiment) {
      this.showCenterSearchResults = true;
    }
    this.loading = false;
  }

  onSearchOpen(): any {
    this.eventLogger.cleverTapEvent('click', JSON.stringify(
      { name: this.advanceSearchExperiment ? 'advance-shop-search-open' : 'shop-search-open' }));
    this.showSearch = true;
    this.searchResultTexts = this.repairProducts;
  }

  centerSearchClickedHandler(): void {
    this.showRepairProductAutoComplete = true;
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'center-search-click' }));
  }

  searchClickedHandler(): void {
    this.showRepairProductAutoComplete = true;
  }

  async closePopup(isReplace: boolean = false): Promise<void> {
    this.isDiscontinued = false;
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  updateCache(): void {
    const existingReorderCache = this.cacheService.retrieve('CureSkin/reorderProducts');
    const existingShopCache = this.cacheService.retrieve('CureSkin/shopSection');
    if (existingReorderCache) {
      const cachedReorder = existingReorderCache.data.result;
      for (let i = 0; i < cachedReorder.length; i += 1) {
        const currentReorderCache = cachedReorder[i];
        if (currentReorderCache.objectId === this.oldProduct.objectId) {
          cachedReorder[i] = this.newProduct;
          break;
        }
      }
      const updatedReorderCache = JSON.parse(JSON.stringify(existingReorderCache));
      updatedReorderCache.data.result = cachedReorder;
      this.cacheService.store('CureSkin/reorderProducts', updatedReorderCache);
    }

    if (existingShopCache) {
      const cachedShop = existingShopCache.data.result.ReOrder;
      for (let i = 0; i < cachedShop.length; i += 1) {
        const currentShopCache = cachedShop[i];
        if (currentShopCache.objectId === this.oldProduct.objectId) {
          cachedShop[i] = this.newProduct;
          break;
        }
      }
      const updatedShopCache = JSON.parse(JSON.stringify(existingShopCache));
      updatedShopCache.data.result.ReOrder = cachedShop;
      this.cacheService.store('CureSkin/shopSection', updatedShopCache);
    }
  }

  async changeProduct(): Promise<void> {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'discontinued-product-changed' }));
    this.loading = true;
    const res = await this.conn.replaceDiscontinuedProduct(this.oldProduct.objectId,
      this.newProduct.id || this.newProduct.objectId);
    this.updateCache();
    this.isDiscontinued = false;
    if (res) {
      const params = {
        productId: this.newProduct.id || this.newProduct.objectId,
        quantity: 1,
      };
      const cart = await this.conn.addProductToCart(params);
      if (cart) {
        await this.getCart();
        this.closePopup(true);
        await this.loadData();
      }
    }
    this.loading = false;
  }

  async openArticle(): Promise<any> {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'discontinued-product-changed' }));
    await this.conn.replaceDiscontinuedProduct(this.oldProduct.objectId || this.oldProduct.id,
      this.newProduct.id || this.newProduct.objectId);
    this.updateCache();
    this.isDiscontinued = false;
    this.location.replaceState('/user', 'tab=shop');
    this.gotoProduct(JSON.parse(JSON.stringify(this.newProduct)), '');
  }

  async showPopup(data?: any): Promise<void> {
    if (!data) return;
    this.isDiscontinued = true;
    this.oldProduct = data.oldProduct;
    this.newProduct = data.newProduct;
  }

  viewProduct(product: any): void {
    if (product.get('article')?.id) {
      const productData = JSON.parse(JSON.stringify(product));
      this.conn.navigateToURL(`/article/${product.get('article').id}?product=${productData.objectId}`);
    } else {
      const productData = JSON.parse(JSON.stringify(product));
      this.conn.navigateToURL(`/product/${productData.objectId}`);
    }
  }

  async fetchBoostProductByType(type: string, isUserSelect: boolean = false): Promise<void> {
    this.boostProductTagName = type;
    this.isBoostProductLoading = true;
    this.boostProductList = JSON.parse(JSON.stringify(await this.conn.findProducts({ boostYourRegimenTags: type })));
    this.user.set('boostProductTag', type);
    if (isUserSelect) this.user.set('boostProductSelectedDate', new Date());
    await this.user.save();
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: `boost-your-regimen-for-${type}` }));
    this.isBoostProductLoading = false;
  }

  async proceedToBuy(event: any, productId: string): Promise<void> {
    event?.stopPropagation();

    const params = {
      productId,
      quantity: 1,
    };
    await this.conn.addProductToCart(params);
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'add-to-cart-progressive-product' }));
    this.router.navigate(['cart']);
  }

  async gotoProduct(product: any, sectionName:any): Promise<void> {
    // If product is discountinued then open popup for alternative product
    if (product?.inventoryStatus === this.appConfig.Shared.Inventory.Type.DISCONTINUED) {
      const alternateProduct = await this.conn.findCatalogWithAlternateProduct(product.objectId);
      if (alternateProduct.get('alternateProduct')) {
        this.oldProduct = product;
        this.newProduct = alternateProduct.get('alternateProduct');
        this.newProduct = JSON.parse(JSON.stringify(this.newProduct));
        this.isDiscontinued = true;
        return;
      }
    }
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'advance-search-product', value: product.objectId }));
    await this.router.navigate([`/product/${product.objectId}`],
      { queryParams: { section: sectionName } });
  }

  checkout(): void {
    if (this.numberOfProductsInCart) {
      this.router.navigate(['cart']);
    }
  }
  getUserLanguage(): void {
    this.userLanguageKnOrTa = this.user?.get('languagePreference') === 'ta' || this.user?.get('languagePreference') === 'kn';
  }
}
