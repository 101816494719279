<div class="tw-flex tw-gap-2">
  <cs-icon class="tw-h-4 tw-w-4" [ngClass]="{'tw-text-yellow-400': isDayTime, 'tw-text-purple-400': !isDayTime}" [iconName]="isDayTime ? 'light_full' : 'night_full'"></cs-icon>
  <div class="tw-font-body tw-text-200 tw-mb-[8px]" [ngClass]="{'tw-text-gray-900': isDayTime, 'tw-text-white': !isDayTime, 'tw-line-through': item?.instructionSet?.newFrequencyOfApplication && !item?.instructionSet?.newFrequencyOfApplication.includes(days[todayDayVal])}">
    <span *ngIf="isDayTime" class="tw-font-body tw-font-bold" i18n="@@morning"> Morning </span>
    <span *ngIf="!isDayTime" class="tw-font-bold tw-capitalize" i18n="@@night"> Night </span>
    <span class="tw-font-bold tw-capitalize" i18n="@@step">step {{ index + 1 }} &nbsp;</span>
    <span class="tw-font-bold">-&nbsp;</span>
    <span>{{item.product.purpose ? item.product.purpose : ''}}</span>
  </div>
</div>
<div class="tw-border tw-border-gray-200 tw-relative tw-bg-white tw-rounded-md tw-mb-4 tw-h-[194px]">
  <div class="tw-flex tw-h-[194px]">
    <div class="tw-flex-none tw-w-[85.7px] tw-h-[194px] tw-object-contain tw-object-center">
      <img class="tw-w-full tw-h-[194px] tw-object-contain tw-object-center tw-p-4"
           [src]="
        (item.product?.rebrandedImageWithoutBackground?.length ?
        item.product.rebrandedImageWithoutBackground[item.product.rebrandedImageWithoutBackground.length - 1] :
        null)
        ||
        (item?.product?.productUnboxedImage?.length ?
        item.product.productUnboxedImage[item.product.productUnboxedImage.length - 1] :
        null)
      "
           *ngIf="item.product">
    </div>
    <div class="tw-flex tw-flex-col tw-justify-between tw-px-4 tw-pr-3 tw-pt-[21px] tw-pb-4 tw-w-full tw-h-[193px] tw-border-l tw-border-gray-200">
<!--      card title , list of instructions and image container-->
      <div>
        <img class="tw-h-[86px] tw-aspect-square tw-float-right" *ngIf="item.instructionSet.quantityImage?.length" [src]="item.instructionSet.quantityImage[0]" />
        <h2 class="tw-font-body tw-font-bold tw-text-300 tw-mb-1 tw-w-28">
          {{item.product.title}}
        </h2>
        <p class="tw-font-body tw-text-100 tw-text-gray-500" *ngIf="item.instructionSet?.frequencyOfApplication?.includes(',')" i18n="@@alternateDays"> (Alternate Days) </p>
        <ul class="tw-text-gray-900">
          <ng-container *ngIf="changeInstructionForRepairProduct && item.product?.reorderType === appConfig.Shared.Product.ReOrderType.LIMITED">
            <ng-container *ngIf="dayAfterDelivery === -1">
              <li>
                <span class="tw-text-gray-900 tw-font-body tw-text-200" i18n="@@repairProductHint1">Instructions will be visible after product delivery.</span>
              </li>
              <li>
                <span class="tw-text-gray-900 tw-font-body tw-text-200" i18n="@@repairProductHint2">Please start using this 2 days after starting other products.</span>
              </li>
            </ng-container>
            <ng-container *ngIf="dayAfterDelivery === 0">
              <li>
                <span class="tw-text-gray-900 tw-font-body tw-text-200" i18n="@@repairProductHint1-D0">Instructions will be visible after 2 days.</span>
              </li>
              <li>
                <span class="tw-text-gray-900 tw-font-body tw-text-200" i18n="@@repairProductHint2-D0">Please continue using other products.</span>
              </li>
            </ng-container>
            <ng-container *ngIf="dayAfterDelivery === 1">
              <li>
                <span class="tw-text-gray-900 tw-font-body tw-text-200" i18n="@@repairProductHint1-D1">Instructions will be visible tomorrow.</span>
              </li>
              <li>
                <span class="tw-text-gray-900 tw-font-body tw-text-200" i18n="@@repairProductHint2-D0">Please continue using other products.</span>
              </li>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!changeInstructionForRepairProduct || (item.product?.reorderType !== appConfig.Shared.Product.ReOrderType.LIMITED)">
            <li *ngIf="item?.instructionSet?.isCustomInstruction" class="tw-leading-[18px] tw-list-none tw-flex tw-items-start tw-gap-1">
                <span class="tw-inline-block tw-w-[3px] tw-aspect-square tw-bg-black tw-rounded-full tw-flex-shrink-0 tw-translate-y-[6.5px]"></span>
              <span class="tw-break-all tw-font-body tw-text-100 tw-text-gray-900 tw-leading-[14px]">{{item.instructionSet?.frequencyOfApplication}}</span>
            </li>
            <li *ngIf="item?.instructionSet && item.instructionSet.quantityUnit?.length" class="tw-leading-[18px] tw-list-none tw-flex tw-items-start tw-gap-1">
              <span class="tw-inline-block tw-w-[3px] tw-aspect-square tw-bg-black tw-rounded-full tw-flex-shrink-0 tw-translate-y-[6.5px]"></span>
                  <span class="tw-font-body tw-text-100 tw-text-gray-900 tw-leading-[14px]">{{item.instructionSet.quantity}}
                    {{item.instructionSet?.quantityUnit}}</span>
            </li>
            <li *ngIf="item.instructionSet?.areaOfApplication" class="tw-leading-[18px] tw-list-none tw-flex tw-items-start tw-gap-1">
              <span class="tw-inline-block tw-w-[3px] tw-aspect-square tw-bg-black tw-rounded-full tw-flex-shrink-0 tw-translate-y-[6.5px]"></span>
              <span class="tw-font-body tw-text-100 tw-text-gray-900 tw-leading-[14px]">{{item.instructionSet?.areaOfApplication}}</span>
            </li>
            <li *ngIf="item.instructionSet?.durationOfApplication" class="tw-leading-[18px] tw-list-none tw-flex tw-items-start tw-gap-1">
              <span class="tw-inline-block tw-w-[3px] tw-aspect-square tw-bg-black tw-rounded-full tw-flex-shrink-0 tw-translate-y-[6.5px]"></span>
              <span class="tw-font-body tw-text-100 tw-text-gray-900 tw-leading-[14px]">{{item.instructionSet?.durationOfApplication}}</span>
            </li>
          </ng-container>
        </ul>
      </div>
<!--      play video container-->
      <div>
        <ng-container *ngIf="user?.isPaid() && item.instructionSet?.paidInstructionVideo && (item.instructionSet?.paidInstructionVideo[userLanguage] || item.instructionSet?.paidInstructionVideo.en)">
          <div class="tw-flex tw-items-center tw-mt-1">
            <div class="tw-font-body tw-text-300 tw-text-teal-600 tw-capitalize tw-font-bold" i18n="@@playVideo" (click)="onPlayButtonClick()"> Play video </div>
            <img class="tw-ml-2" src="/assets/icons/play-icon-teal.svg" (click)="onPlayButtonClick()" />
          </div>
        </ng-container>
        <ng-container *ngIf="!user?.isPaid() && item.instructionSet?.unpaidInstructionVideo && (item.instructionSet?.unpaidInstructionVideo[userLanguage] || item.instructionSet?.unpaidInstructionVideo.en)">
          <div class="tw-flex tw-items-center tw-mt-1">
            <div class="tw-font-body tw-text-300 tw-text-teal-600 tw-capitalize tw-font-bold" i18n="@@playVideo" (click)="onPlayButtonClick()"> Play video </div>
            <img class="tw-ml-2" src="/assets/icons/play-icon-teal.svg" (click)="onPlayButtonClick()" />
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-items-center" id="overlay" [ngClass]="{'tw-bg-[#ffffff99] tw-flex': item?.instructionSet?.newFrequencyOfApplication && !item?.instructionSet?.newFrequencyOfApplication.includes(days[todayDayVal])}" [ngStyle]="{ 'display': item?.instructionSet?.newFrequencyOfApplication && !item?.instructionSet?.newFrequencyOfApplication.includes(days[todayDayVal]) ? 'block' : 'none' }">
    <div class="tw-bg-orange-100 tw-flex-1 tw-backdrop-blur-sm tw-absolute tw-w-full tw-m-auto tw-text-center tw-p-3 tw-top-[50%] tw-left-[50%] tw-translate-x-[-50%] tw-translate-y-[-50%]" *ngIf="item?.instructionSet?.newFrequencyOfApplication && !item?.instructionSet?.newFrequencyOfApplication.includes(days[todayDayVal])">
      <p class="tw-text-200 tw-text-orange-400 tw-font-bold" i18n="@@notToUseToday"> Not to be used today! </p>
      <p class="tw-text-200 tw-text-gray-500" i18n="@@checkTomorrow"> Please check back tomorrow. </p>
    </div>
  </div>
</div>
