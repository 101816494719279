import { Injectable } from '@angular/core';
import { LocalStorageService } from '@services/local-storage-service';
import { Router } from '@angular/router';
import { ConnectionService } from '@services/connection-service';
import { BroadcastService } from '@services/broadcast-service';
import { WindowRefService } from '@services/window-ref-service';
import { FilePickerComponent } from '@components/file-picker/file-picker.component';
import { CommonUtilityHelper } from '@services/common-utility-helper/common-utility-helper';
import { EventLoggerService } from '@services/event-logger-service';
import { Table } from 'api-client';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { OrderListSheetComponent } from '@shared/bottom-sheet-layouts/order-list/order-list-sheet.component';
import { TimeService } from '@services/time-service';
import { AppConfig } from '../../app.config';

export declare interface InputType {
  params: {
    functionName: string;
    actionName: string;
    queryParams: string;
    url: string;
    payload: Record<string, string>;
    track: { event: string; data: Record<string, unknown>; };
  };
}

@Injectable()
export class SingleSelectorService {
  isInternalUser: boolean;

  constructor(
    private window: WindowRefService,
    private eventLoggerService: EventLoggerService,
    private appConfig: AppConfig,
    private localStorageService: LocalStorageService,
    private router: Router,
    private connectionService: ConnectionService,
    private commonUtilityHelper: CommonUtilityHelper,
    private broadcastService: BroadcastService,
    private timeService: TimeService,
    private bottomSheet: MatBottomSheet) {
    this.isInternalUser = this.connectionService.isInternalUser();
  }
  async openInAppAction(
    input: InputType,
    context: Record<string, unknown> = {},
    filePickerComponent: FilePickerComponent = undefined,
    messageId: string = '',
    className: string = '',
    callback: (inputItem: InputType, data: Record<string, unknown>) => void = (): void => undefined,
  ): Promise<any> {
    try {
      if (input.params.track) {
        this.eventLoggerService.trackEvent(input.params.track.event, input.params.track.data);
      }
      switch (input.params.actionName) {
        case 'InstantCheckup': {
          this.eventLoggerService.cleverTapEvent('click',
            JSON.stringify({ name: 'chat-instant-checkup-cta',
              photoType: JSON.parse(input.params.queryParams)?.tag || this.appConfig.Shared.InstantCheckup.Type.FULL_FACE }));
          await this.openInAppActionInstantCheckup(input, context, messageId, className);
          break;
        }
        case 'Regimen': {
          await this.router.navigate(['/user'], { queryParams: { tab: 'regimen' } });
          break;
        }
        case 'reOpenTicket': {
          const { supportTicketId }: { supportTicketId: string } = JSON.parse(input.params.queryParams);
          const supportTicket = new Table.SupportTicket();
          supportTicket.id = supportTicketId;
          await supportTicket.save({ status: 'Pending', disableChat: false });
          await this.router.navigate([`/support/ticket/${supportTicketId}`]);
          break;
        }
        case 'closeTicket': {
          const { supportTicketId }: { supportTicketId: string } = JSON.parse(input.params.queryParams);
          const { message }: { message: string } = await this.connectionService.updateSupportTicketFeedback(supportTicketId, true);
          this.broadcastService.broadcast('NOTIFY', { message });
          break;
        }
        case 'feedbackTicket': {
          const { feedback, supportTicketId }: { feedback: boolean, supportTicketId: string } = JSON.parse(input.params.queryParams);
          const { message }: { message: string } = await this.connectionService.updateSupportTicketFeedback(supportTicketId, feedback);
          this.broadcastService.broadcast('NOTIFY', { message });
          break;
        }
        case 'OpenInCustomTab': {
          const { url }: { url: string } = input.params;
          this.broadcastService.broadcast('OPEN_IN_NEW_TAB', { url });
          break;
        }
        case 'UnCancelOrder': {
          const { orderId }: { orderId: string; } = JSON.parse(input.params.queryParams);
          this.connectionService.unCancelUserOrder(orderId)
            .then((): void => this.broadcastService.broadcast('NOTIFY', { message: 'Order uncancelled successfully.' }))
            .catch((): void => this.showErrorMessage('Unable to UnCancel Order. Contact CureSkin Team.'));
          break;
        }
        case 'OrderSelection': {
          const orders = await this.connectionService.fetchOrders(
            { user: this.connectionService.getActingUser,
              createdAt: { $gte: this.timeService.removeDays(new Date(), 30) } },
            ['orderNumber',
              'stage',
              'productInfo',
              'products.titleLanguageString' as 'products',
              'products.rebrandedImageWithBackground' as 'products',
              'products.rebrandedImageWithoutBackground' as 'products'],
            ['products', 'productInfo']);

          const options = orders
            .filter((each: any): boolean => {
              const products = each.get('products');
              const productInfo = each.get('productInfo');
              return (products?.length > 0) || (productInfo?.length > 0);
            })
            .map((each: any): Record<string, unknown> => {
              const products = each.get('products')?.length > 0
                ? JSON.parse(JSON.stringify(each.get('products'))) // Use products if available
                : each.get('productInfo') || []; // Fallback to productInfo if products is empty

              return {
                orderId: each.id,
                orderNumber: each.get('orderNumber'),
                stage: each.get('stage'),
                createdAt: each.get('createdAt'),
                text: each.get('orderNumber'),
                images: products
                  .map((product: any): string | undefined => product.rebrandedImageWithBackground
                      || product.rebrandedImageWithoutBackground
                      || product.image,
                  ),
                subText: products.map((product: any): string => product.title || product.name,
                ).join(', '),
              };
            });
          this.bottomSheet.open(OrderListSheetComponent, {
            data: {
              content: options,
              callback: (result: Record<string, any>): void => {
                callback(input, { option: result?.options[0] });
              },
            },
          });
          break;
        }
        case 'ProductSelection': {
          let products: any = [];
          if (context?.orderId) {
            const order = await this.connectionService.findOrderWithSignedURL(context.orderId);
            let productIds = order.get('productInfo').map((product: any): any => product.id);
            productIds = Array.from(new Set(productIds));
            products = await this.connectionService.findProducts({ objectId: { $in: productIds } });
          } else {
            const where: any = {
              stage: this.appConfig.Shared.Order.Stage.DELIVERED,
            };
            const orders = await this.connectionService.fetchOrders(where, ['productInfo']);
            let productIds = orders.map((order: any): any => order.get('productInfo').map((product: any): any => product.id)).flat();
            productIds = Array.from(new Set(productIds));
            products = await this.connectionService.findProducts({ objectId: { $in: productIds } });
          }
          const options = products.map((each: any): Record<string, unknown> => ({
            productId: each.id,
            title: each.get('title'),
            text: each.get('title'),
            subText: '',
          }));
          this.broadcastService.broadcast('OPEN_POPUP', {
            open: true,
            type: this.appConfig.Dialog.MULTI_OPTION_SINGLE_SELECT,
            message: { type: this.appConfig.Shared.String.PRODUCT_SELECTION },
            options,
            okText: this.appConfig.Shared.String.OK,
            cancelText: this.appConfig.Shared.String.CANCEL,
            callback: (result: Record<string, any>): void => {
              this.broadcastService.broadcast('NAVIGATION_BACK');
              this.broadcastService.broadcast('OPEN_POPUP', { open: false });
              if (!result?.options?.length) {
                return;
              }
              callback(input, { option: result?.options[0] });
            },
          });
          break;
        }
        case 'OpenURL':
        case 'Checkout': {
          await this.openInAppActionCheckout(input);
          break;
        }
        case 'MainConcern': {
          await this.connectionService.navigateToURL('/mainConcern?force=true');
          break;
        }
        case 'UploadReport': {
          if (filePickerComponent) {
            filePickerComponent.openFileIntent();
          }
          break;
        }
        case 'CloudFunction': {
          await this.openInAppActionCloudFunction(input);
          break;
        }
        default:
      }
    } catch (error) {
      this.broadcastService.broadcast('NOTIFY', { message: error.message });
    }
  }

  showErrorMessage(message: any): void {
    this.broadcastService.broadcast('NOTIFY', { message: message.error || message.toString() });
  }

  private async openInAppActionInstantCheckup(
    input: InputType,
    context: Record<string, unknown>,
    messageId: string = '',
    className: string = '',
  ): Promise<void> {
    const queryParams = {
      tag: this.appConfig.Shared.InstantCheckup.Type.FULL_FACE,
      ...JSON.parse(input.params.queryParams || '{}'),
      ...context,
      messageId,
      className,
    };
    let redirectUrl = this.router.url;
    redirectUrl += `${redirectUrl.includes('?') ? '&' : '?'}back=home`;
    this.localStorageService.setValue('CureSkin/redirectUrl', redirectUrl);
    await this.router.navigate(['/user/instantCheckup/preview'], { queryParams });
  }

  private async openInAppActionCheckout(input: InputType): Promise<void> {
    const { url }: { url: string } = input.params;
    if (url[0] === '/' || url.startsWith(this.connectionService.getBaseUrl())) {
      const [relativePath, queryParams]: [string, object] = this.connectionService
        .findRelativePathAndQueryString(url[0] === '/' ? url : url.split(this.connectionService.getBaseUrl())[1]);
      await this.router.navigate([relativePath], { queryParams });
    } else {
      this.window.nativeWindow.location.href = url;
    }
  }

  private async openInAppActionCloudFunction(input: InputType): Promise<void> {
    try {
      await this.connectionService.executeCloudFunction(input.params.functionName, input.params.payload);
    } catch (error) {
      this.showErrorMessage(error.message || error.toString());
    }
  }

  /** Reads the file from event and upload to UserFile table
   * 1. Rejects if file size is greater than 5MB.
   */
  async onUploadReport(event: any): Promise<void> {
    const user = this.connectionService.getActingUser();
    const file = event.target.files[0];
    if (!file) return;
    if (!this.commonUtilityHelper.isFileSizeLesserThanMaxSize(file.size, 5)) {
      this.broadcastService.broadcast('NOTIFY', { message: 'File size should be less than 5MB. Kindly retry.' });
      return;
    }
    const response: { publicURL: string; signedGetURL: string } = await this.connectionService.uploadFile({
      file,
      bucket: 'OTHER',
      username: user.get('username'),
      source: 'app_upload_report',
    });
    const userFile = new Table.UserFiles();
    userFile.set('fileUrl', response.publicURL);
    userFile.set('user', user);
    userFile.set('type', this.commonUtilityHelper.findFileType(file.name));
    try {
      await userFile.save({}, { context: { displayInChat: true } });
      this.broadcastService.broadcast('NOTIFY', { message: 'Success' });
    } catch (error) {
      this.broadcastService.broadcast('NOTIFY', { message: error.toString() });
    }
  }
}
