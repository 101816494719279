import {
  Component, ElementRef, EventEmitter, Input, Output, ViewChild, ChangeDetectionStrategy,
  ChangeDetectorRef, SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { EventLoggerService } from '@services/event-logger-service';
import { ConnectionService } from '@services/connection-service';
import { BroadcastService } from '@services/broadcast-service';
import { LocalStorageService } from '@services/local-storage-service';
import { HairVariantServices, VariantServices } from 'e2e/src/shared/variant-services';
import { WindowRefService } from '@services/window-ref-service';
import { RegimenService } from '@services/regimen-service';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { AppConfig } from '../../../../app.config';

type LinearGradient = {
  color1: string;
  color2: string;
};
@Component({
  selector: 'user-view-regimen-unpaid',
  templateUrl: './user-view-regimen-unpaid.html',
  styleUrls: ['./user-view-regimen-unpaid.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class UserViewRegimenUnpaidComponent {
  showOptForDoctorCall: boolean;
  @ViewChild('serviceDetail', { static: false }) serviceDetailCard: ElementRef;
  @ViewChild('regimenContainer', { static: false }) regimenContainer: ElementRef;
  @ViewChild('swiper', { static: false }) swiper: ElementRef;
  @Input('fromPreparation') isRedirectFromPreparation: boolean = false;
  @Output('updateRegimen') updateRegimenInfo: EventEmitter<any> = new EventEmitter<any>();
  user: any;
  popUp: any = { open: false };
  callConfirmed: boolean = false;
  isCallSuccessful: boolean = false;
  isCallUnsuccessful: boolean = false;
  variantServices: any = VariantServices;
  hairServices: any = HairVariantServices;
  userLanguage: string = 'en';
  showRefundPolicyOption: boolean = false;
  newRegimenIconsExperiment: boolean = false;

  @Input('regimen')
  set regimenObject(regimen: any) {
    if (!regimen) return;
    this.setRegimen(regimen);
  }
  @Input('experiments') experiments: any[];
  @Output('showSummaryView') showSummaryView: EventEmitter<any> = new EventEmitter();
  @Output('buyNow') buyNow: EventEmitter<any> = new EventEmitter();
  @Output('cartChange') cartChange: EventEmitter<any> = new EventEmitter();
  // show loading icon when fetching new regimen in 2499 experiment.
  @Output() parentLoading: EventEmitter<any> = new EventEmitter<boolean>();
  @Output() selectedPlanEmit: EventEmitter<any> = new EventEmitter<string>();
  regimen: any;
  patientName: string;
  allocatedDoctor: any;
  products: any[];
  productIds: string[];
  productString: string = '';
  isPaidUser: boolean = false;
  // 2499 feat variables
  upsellPlans: Array<string> = ['introductory', 'advance', 'pro', 'assist'];
  upsellPlanWithoutIntroductory: Array<string> = ['advance', 'pro', 'assist'];
  upsellStandAlonePlans: Array<string> = ['introductory', 'advance'];
  selectedPlan: string = this.upsellPlanWithoutIntroductory[0];
  is2499Upsell: boolean = false;
  newRegimenPricingExperiment: any;
  @Input('isRebrandingV1') isRebrandingV1: boolean = false;
  @Input('isRegimenNewPropositionUI') isRegimenNewPropositionUI: boolean = false;
  backgroundColor: string = 'tw-bg-teal-100';
  regimenTagGradientColors: LinearGradient = {
    color1: '#A2DBD0',
    color2: '#14796D',
  };
  doesAllTagsContainRebrandedImages: boolean = false;

  constructor(
    private eventLogger: EventLoggerService,
    public appConfig: AppConfig,
    public conn: ConnectionService,
    private router: Router,
    private broadcast: BroadcastService,
    private localStorage: LocalStorageService,
    private changeDetector: ChangeDetectorRef,
    private windowRef: WindowRefService,
    private appWebBridge: AppWebBridgeService,
    private readonly regimenService: RegimenService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.user = await this.conn.getActingUser();
    this.patientName = this.getUserFirstName(this.user);
    this.userLanguage = this.user.get('languagePreference') || this.appConfig.Shared.Languages.EN;
    this.updateBackgroundColor();
    await this.setAllocatedDoctorAndLogIfNotFound();
    this.trackRegimenViewUnpaidEvents();
    this.isPaidUser = this.checkIsPaidUser();
    this.changeDetector.detectChanges();
    (<Window> this.windowRef.nativeWindow).performance.mark('regimen-page-end');
    const measure: any = (<Window> this.windowRef.nativeWindow).performance
      .measure('regimen_page_loading_time', 'regimen-page-start', 'regimen-page-end');
    this.eventLogger.trackEvent('regimen_page_loading_time', {
      duration: measure.duration,
    });
    this.doesAllTagsContainRebrandedImages = this.regimen?.regimenTag.every((tag: any): boolean => !!tag.rebrandedImageV2);
    this.updateRegimenTagsBorderColor();
  }

  getFromColor(): string {
    return this.regimenTagGradientColors.color1;
  }

  getToColor(): string {
    return this.regimenTagGradientColors.color2;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.experiments.forEach((each: any): void => {
      if (each.key === 'privacy_policy') {
        this.showRefundPolicyOption = true;
      }
      if (each.key === 'add_extra_regimen_products') {
        this.newRegimenPricingExperiment = each;
      }
      if (each.key === 'regimen_new_icons') {
        this.newRegimenIconsExperiment = true;
      }
    });
    this.changeDetector.detectChanges();
  }

  updateBackgroundColor(): void {
    const variantId = this.regimenService.getRegimenVariantId();
    this.backgroundColor = this.regimenService.getBackgroungColourBasedOnVariantId(variantId);
  }

  // Set the allocated doctor property if available and log an event if not found
  private async setAllocatedDoctorAndLogIfNotFound(): Promise<void> {
    if (this.user.get('allocatedDoctor')) {
      this.allocatedDoctor = await this.conn.findUserByObjectId(this.user.get('allocatedDoctor')?.id);
    } else {
      this.eventLogger.trackEvent('DOCTOR_NOT_FOUND_IN_REGIMEN_UNPAID_VIEW', {
        username: this.user.get('username'),
        regimenId: this.regimen.regimenId,
      });
    }
    this.changeDetector.detectChanges();
  }

  async changeRegimenKitInfo(plan: string): Promise<void> {
    this.selectedPlan = plan;
    let indexOfVariant = 0;
    switch (this.regimen?.variants.length) {
      case 2:
        indexOfVariant = this.upsellStandAlonePlans.indexOf(this.selectedPlan);
        break;
      case 3:
        indexOfVariant = this.upsellPlanWithoutIntroductory.indexOf(this.selectedPlan);
        break;
      case 4:
        indexOfVariant = this.upsellPlans.indexOf(this.selectedPlan);
        break;
      default:
        break;
    }
    const selectedVariant = this.regimen.variants[indexOfVariant];
    const selectedVariantId = selectedVariant?.variantId;
    this.copyVariantData(selectedVariant, indexOfVariant);
    this.regimen.variantId = selectedVariantId;
    this.regimenService.setRegimenVariantId(selectedVariantId);
    this.updateBackgroundColor();
    this.updateRegimenTagsBorderColor();
    await this.setRegimenAndEmitUpdate(this.regimen, this.regimen.variantId);
    if (this.regimen?.variantId) {
      await this.conn.updateRegimenInfo(this.regimen.regimenId, this.regimen.variantId);
      if (this.regimen?.extraProducts?.length) {
        const extraProductIds: string[] = this.regimen?.extraProducts?.map((product: any): string => product?.objectId) || [];
        await this.conn.updateRegimenPrice({ regimenId: this.regimen.regimenId, extraProductIds });
      }
    }
    await this.handleMandatoryVariantCall(indexOfVariant);
    this.changeDetector.detectChanges();
  }

  copyVariantData(selectedVariant: any, indexOfVariant: number): void {
    Object.keys(this.regimen?.variants[indexOfVariant]).forEach((key: any): void => {
      this.regimen[key] = selectedVariant[key];
    });
  }

  async setRegimenAndEmitUpdate(regimen: any, variantId: string): Promise<void> {
    await this.setRegimen(regimen);
    // Informs the parent component to update the price in Buy Now CTA
    this.updateRegimenInfo.emit(this.regimen);
  }

  /*
    Note: This block is for 2999 mandatory call. Please remove this once backend solution is implemented.
    If the user has opted for doctor call, no change needed
  */
  async handleMandatoryVariantCall(indexOfVariant: number): Promise<void> {
    const hasIntroductoryKit = this.regimen?.variants.length === 4;
    const isMandatoryVariantCallWithIntroKit = hasIntroductoryKit && indexOfVariant === 3;
    const isMandatoryVariantCallWithoutIntroKit = !hasIntroductoryKit && indexOfVariant === 2;
    const mandatoryVariantCall = isMandatoryVariantCallWithIntroKit || isMandatoryVariantCallWithoutIntroKit;
    if (!this.regimen?.optedForDoctorCall) {
      await this.conn.optForRegimenDoctorCall(this.regimen.regimenId, mandatoryVariantCall);
      this.regimen.optedForDoctorCall = mandatoryVariantCall;
      if (mandatoryVariantCall) {
        this.user.set('hasUserOptedForCall', false);
        await this.user.save();
      }
    } else {
      const hasUserOptedForCall = this.user?.get('hasUserOptedForCall') || false;
      const is999Variant = this.regimen?.variantId === 'variant_999';
      if ((!hasUserOptedForCall && !mandatoryVariantCall) || is999Variant) {
        await this.conn.optForRegimenDoctorCall(this.regimen.regimenId, false);
        this.regimen.optedForDoctorCall = false;
      }
    }
  }

  readMoreAboutPlans(): void {
    if (this.conn.isInternalUser() || this.regimen?.orderPlaced) {
      return;
    }
    const params = {
      class: this.regimen.class,
      plan: this.selectedPlan,
      isStandAlone: this.regimen.variants.length === 2,
      isRegimenNewPropositionUI: this.isRegimenNewPropositionUI,
      regimenId: this.regimen?.regimenId,
      doctorImageUrl: this.allocatedDoctor?.get('DoctorImageThumbnail'),
      optedForDoctorCall: this.regimen?.optedForDoctorCall,
    };
    this.router.navigate(['/user/regimen/plan-info'],
      { queryParams: params });
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'read-more-plan' }));
  }

  async buyRegimen(): Promise<void> {
    this.buyNow.emit();
  }

  async startAgain(): Promise<any> {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ pageName: 'unpaid-reanswer-tree' }));
    await this.conn.disablePreviousConsultationSessions(this.regimen.class);
    return this.conn.navigateToURL(`/mainConcern?force=true&class=${this.regimen.class}`);
  }

  async getUniqueProducts(regimen: any): Promise<{ productIds: string[], products: any[] }> {
    const uniqueProducts: { [key: string]: any } = {};

    if (regimen.morning && regimen.night) {
      [...regimen.morning, ...regimen.night].forEach((item: any): void => {
        uniqueProducts[item.product.objectId] = item;
      });
    }
    return {
      productIds: Object.keys(uniqueProducts),
      products: Object.values(uniqueProducts),
    };
  }

  async getProductString(products: any[]): Promise<string> {
    const productLabels: string[] = products.map((item: any): string => {
      if (item.purpose && item.purpose !== 'na') return item.purpose;
      if (item.product.hiddenTitle) return item.product.hiddenTitle;
      return item.product.title;
    });

    return productLabels.join(' + ');
  }

  async setRegimen(regimen: any): Promise<void> {
    this.regimen = regimen;
    this.regimenService.setRegimenVariantId(this.regimen.variantId);
    this.is2499Upsell = this.regimen?.variants && this.regimen?.variants.length;
    const { productIds, products }: { productIds: string[]; products: any[] } = await this.getUniqueProducts(regimen);
    this.productIds = productIds;
    this.products = products;
    this.productString = await this.getProductString(products);
    // query the mediaLink for coverImage
    if (this.is2499Upsell) {
      const { combinedProductIds }: any = this.regimen;
      const mediaLinkObj = await this.conn.fetchImageFromMediaLink(combinedProductIds);
      this.regimen.regimenCoverImage = mediaLinkObj[0]?.get('link');
    }
    this.changeDetector.detectChanges();
  }

  getUserFirstName(user: any): string {
    const name = user?.get('PatientName') || '';
    return name
      .split(' ')
      .map((word: string): string => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())[0];
  }

  trackEventAndLogCleverTap(event: string, eventName: string): void {
    this.eventLogger.trackEvent(event, { username: this.user.get('username') });
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: eventName }));
    this.eventLogger.trackInFirebaseAndBranch(event);
  }

  seeInstructions(): void {
    this.trackEventAndLogCleverTap('REGIMEN_VIEW_SEE_INSTRUCTION', 'regimen-see-instructions');
    this.showSummaryView.emit();
  }

  navigateAndTrackEvent(event: string, route: string): void {
    this.eventLogger.trackEvent(event, { username: this.user.get('username') });
    this.eventLogger.trackInFirebaseAndBranch(event);
    this.router.navigate([`/user/regimen/${route}`], {
      queryParams: {
        class: this.regimen.class,
        username: this.user.get('username'),
        regimenId: this.regimen.regimenId,
      },
    });
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: `read-more-${route}` }));
  }

  readMoreAboutDoctor(): void {
    if (!this.allocatedDoctor) return;
    this.navigateAndTrackEvent('REGIMEN_VIEW_READ_ABOUT_DOCTOR', 'doctor');
  }

  readMoreAboutServices(): void {
    if (this.is2499Upsell && this.regimen.class === this.appConfig.Shared.Regimen.Class.FACE) {
      this.readMoreAboutPlans();
      return;
    }
    this.navigateAndTrackEvent('REGIMEN_VIEW_READ_ABOUT_SERVICES', 'services');
  }

  readMoreAboutProducts(): void {
    this.navigateAndTrackEvent('REGIMEN_VIEW_READ_ABOUT_PRODUCTS', 'products');
  }

  async optForRegimenDoctorCall(): Promise<void> {
    try {
      await this.conn.optForRegimenDoctorCall(this.regimen.regimenId, this.regimen.optedForDoctorCall);
      this.user.set('hasUserOptedForCall', this.regimen.optedForDoctorCall);
      await this.user.save();
    } catch (error) {
      this.regimen.optedForDoctorCall = !this.regimen.optedForDoctorCall;
      this.broadcast.broadcast('NOTIFY', { message: error.toString() });
    }
  }

  async onOptForDoctorCall(): Promise<void> {
    if (this.regimen.orderPlaced || this.regimen.active) return;
    setTimeout((): Promise<void> => this.optForRegimenDoctorCall(), 0);
  }

  clickOnRegimenImage(): void {
    this.trackEventAndLogCleverTap('REGIMEN_VIEW_REGIMEN_IMAGE_CLICK', 'regimen-cover-image');
    this.showSummaryView.emit();
  }

  clickOnProductImage(): void {
    this.trackEventAndLogCleverTap('REGIMEN_VIEW_PRODUCT_IMAGE_CLICK', 'regimen-product-image');
    this.showSummaryView.emit();
  }

  // Track various events for analytics purposes
  private trackRegimenViewUnpaidEvents(): void {
    this.eventLogger.trackEvent('REGIMEN_VIEW_UNPAID', { username: this.user.get('username'), regimenId: this.regimen.regimenId });
    this.eventLogger.trackInFirebaseAndBranch('REGIMEN_VIEW_UNPAID');
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'regimen-view-unpaid',
      regimenClass: this.regimen.class,
      regimenId: this.regimen.regimenId,
      regimenConcern: this.regimen.concern,
      skinType: this.regimen.skinType }));
    this.appWebBridge.logEventInBranchAndFirebaseFromiOS({
      branch: { name: 'REGIMEN_VIEW_UNPAID' },
      firebase: { name: 'REGIMEN_VIEW_UNPAID' },
    });
  }

  // Check if the user is a paid user based on their order state
  private checkIsPaidUser(): boolean {
    return [
      this.appConfig.Shared.User.OrderState.PROCESSING,
      this.appConfig.Shared.User.OrderState.DELIVERED,
    ].includes(this.user.get('orderState'));
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  regimenOfferProductEvent(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'free_travel_pouch_clicked_regimenpage' }));
  }

  updateRegimenTagsBorderColor(): void {
    this.regimenTagGradientColors = this.regimenService.getRegimenTagBorderColorBasedOnBgColor(this.backgroundColor);
  }
}
