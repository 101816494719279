<div class="tw-flex tw-flex-col tw-h-full tw-pt-24 tw-overflow-y-auto" id="checkoutPage">
  <loading class="tw-flex-1 tw-justify-self-center tw-self-center" *ngIf="ui.loading || !(defaultAddress$ | async)"></loading>
  <div id="back" class="tw-fixed tw-left-6 tw-top-6 tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
    <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'arrow_back'"></cs-icon>
  </div>
  <div class="tw-mx-6" *ngIf="defaultAddress$ | async as defaultAddress">
    <div id="address" class="tw-flex tw-flex-col tw-gap-4 tw-p-6 tw-border tw-border-green-400 tw-rounded-xl" *ngIf="!ui.loading" (click)='navigateTo("/user/addresses")'>
      <div class="tw-flex tw-flex-col addressCard p0">
        <div class="tw-flex tw-justify-between tw-gap-2">
          <div class="tw-font-body tw-text-100 tw-text-black tw-uppercase tw-tracking-[1px] tw-break-words" i18n="@@name">Name</div>
          <cs-icon class="tw-h-6 tw-w-6 tw-text-green-400 tw-shrink-0" [iconName]="'done'"></cs-icon>
        </div>
        <div id="contact-name" class="tw-font-body tw-font-bold tw-text-black tw-text-300 value tw-break-words">
          {{defaultAddress?.contactName}}
        </div>
      </div>
      <div class="tw-flex tw-flex-col">
        <div class="tw-font-body tw-text-100 tw-text-black tw-uppercase tw-tracking-[1px] tw-break-words" i18n="@@phoneNumber"> Phone Number </div>
        <div id="mobile-number" class="tw-font-body tw-font-bold tw-text-black tw-text-300 tw-break-words">
          {{defaultAddress?.mobileNumber || user?.get('MobileNumber')}}
        </div>
      </div>
      <div class="tw-flex tw-flex-col">
        <div class="tw-font-body tw-text-100 tw-text-black tw-uppercase tw-tracking-[1px] tw-break-words" i18n="@@address"> Address </div>
        <div id="delivery-address" class="tw-font-body tw-font-bold tw-text-black tw-text-300 tw-break-words">
          {{getDeliveryAddress(defaultAddress)}}
        </div>
      </div>
      <div class="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-min-h-8 tw-px-4 tw-border tw-border-black tw-rounded-full tw-w-fit">
        <div class="change-add-address tw-font-body tw-font-bold tw-text-200 tw-text-green-400 tw-tracking-normal" i18n="@@changeAddAddress"> Change or Add address </div>
        <cs-icon class="tw-h-4 tw-w-4 tw-text-green-400 tw-shrink-0" [iconName]='"chevron_right"'></cs-icon>
      </div>
    </div>
  </div>
  <div class="tw-flex tw-flex-row tw-items-center tw-gap-3 tw-px-4 tw-py-3 tw-bg-gray-100 tw-rounded-xl tw-mx-6 tw-mt-4" *ngIf="!ui.loading && defaultAddress$ | async">
    <div class="tw-block tw-h-28 tw-w-28 tw-bg-transparent" src="" alt="">
      <img src="https://cdn.cureskin.com/app/img/order.gif" />
    </div>
    <div class="tw-flex-1">
      <p class="tw-font-body tw-text-300 tw-text-black" i18n="@@youWillReceiveOn"> Your order is expected to be delivered on </p>
      <ng-container *ngIf="!expectedDeliveryDate.to && !expectedDeliveryDate.from">
        <div class="tw-flex tw-justify-center tw-items-center tw-pr-3">
          <loading class="tw-scale-75"></loading>
        </div>
      </ng-container>
      <ng-container *ngIf="expectedDeliveryDate.to && expectedDeliveryDate.from">
        <p id="expected-delivery-date" class="tw-font-body tw-font-bold tw-text-600 tw-text-black">
          {{expectedDeliveryDate.from | date: 'd MMM' }} - {{expectedDeliveryDate.to | date: 'd MMM'}}
        </p>
      </ng-container>
    </div>
  </div>
  <div class="tw-flex-1"></div>
  <div class="flex-footer" *ngIf="!ui.loading && defaultAddress$ | async">
    <div class="w100" *ngIf="offerTimer.endTime > 0 && typeOfOrder === appConfig.Shared.Order.Type.REGIMEN">
      <discount-timer id="discount-timer" [endTime]="offerTimer.endTime" [text]="offerTimer.title"></discount-timer>
    </div>
    <div class="p20 w100">
      <cs-button id="selectPayment" (callback)="checkout()" [class]="'w100  primary new-bg'" [loading]="(checkoutInProgress$ | async)">
        <cs-button-text i18n="@@useThisAddress">Use this address</cs-button-text>
      </cs-button>
    </div>
  </div>
</div>
