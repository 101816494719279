<div class="tw-flex tw-flex-col tw-font-body" *ngIf="addressForm" #form="" [formGroup]="addressForm">
    <div class="tw-relative">
      <div class="tw-flex tw-mb-1 tw-relative tw-overflow-hidden">
        <input class="tw-h-12 tw-w-full tw-rounded-xl tw-bg-gray-100 tw-font-body tw-text-300 tw-text-black tw-pl-4 tw-pr-11 tw-border-0 placeholder:tw-opacity-100" #nameInput [autocomplete]="true" formControlName="contactName" id="contactName" i18n-placeholder="@@yourName" placeholder="Your Name" [ngClass]='{ "redError": (isSaveForm && !addressForm?.get("contactName")?.valid) }' required="required" />
        <div class="tw-absolute tw-right-4 tw-mt-2.5" *ngIf="addressForm?.get('contactName')?.valid">
          <cs-icon class="tw-h-6 tw-w-6 tw-text-green-400" iconName="done"></cs-icon>
        </div>
      </div>
      <div class="tw-mb-1 tw-flex tw-items-center tw-pl-3 tw-gap-1.5" *ngIf="addressFieldError.contactName.contactNameError">
        <img src="/assets/images/warn-err.png" class="tw-h-3" />
        <p i18n="@@errorNonEnglishName" class="tw-text-red-400 tw-text-200 tw-leading-none" *ngIf="addressFieldError.contactName.errorType === 'nonEnglish'">Please enter name in English language only</p>
        <p i18n="@@errorNumbers" class="tw-text-red-400 tw-text-200 tw-leading-none" *ngIf="addressFieldError.contactName.errorType === 'numbers'">Please avoid using numbers</p>
        <p i18n="@@errorSpecialCharacters" class="tw-text-red-400 tw-text-200 tw-leading-none" *ngIf="addressFieldError.contactName.errorType === 'specialCharacters'">Please avoid using special characters and emojis</p>
        <p i18n="@@errorEmptyName" class="tw-text-red-400 tw-text-200 tw-leading-none" *ngIf="addressFieldError.contactName.errorType === 'emptyName'">Please enter name here</p>
        <p i18n="@@errorWordLimit" class="tw-text-red-400 tw-text-200 tw-leading-none" *ngIf="addressFieldError.contactName.errorType === 'wordLimit'">Only three words are allowed in name</p>
      </div>
    </div>
    <div class="tw-flex tw-items-center tw-px-4 tw-h-12 tw-w-full tw-rounded-xl tw-bg-gray-100 tw-overflow-hidden" *ngIf="isCheckout">
        <span class="tw-font-body tw-text-300 tw-text-black">+91</span>
        <div class="tw-border tw-border-t-0 tw-border-r-0 tw-border-b-0 tw-border-black tw-h-8 tw-mx-1"></div>
        <input class="tw-w-full tw-h-full tw-font-body tw-text-300 tw-bg-transparent tw-text-black tw-border-0 placeholder:tw-opacity-100" required="required" #numberInput (keydown)="commonUtilService.limitInputByMaxDigit($event, 10)" type="number" pattern="[0-9]*" [autocomplete]="true" formControlName="mobileNumber" id="mobileNumber" i18n-placeholder="@@enterNumber" placeholder="Mobile Number" [ngClass]='{ "orangeError": (isSaveForm && !addressForm?.get("mobileNumber")?.valid) }'/>
        <cs-icon *ngIf="addressForm?.get('mobileNumber')?.valid" class="tw-h-6 tw-w-6 tw-text-green-400 tw-shrink-0 tw-ml-1" iconName="done"></cs-icon>
    </div>
    <div class="tw-text-green-400 tw-text-200 tw-font-bold tw-mb-4" *ngIf="!showAlternateNo" (click)="showAlternateNo = true" i18n="@@altNumber">
        + Add alternate phone no.
    </div>
    <div class="tw-flex tw-mb-4 tw-relative tw-mt-4" *ngIf="showAlternateNo">
        <input class="tw-h-12 tw-w-full tw-rounded-xl tw-bg-gray-100 tw-font-body tw-text-300 tw-text-black tw-pl-4 tw-pr-11 tw-border-0 placeholder:tw-opacity-100" #alternateNumber="" id="alternateNumber" (keydown)="commonUtilService.limitInputByMaxDigit($event, 10)" type="number" pattern="[0-9]*" [autocomplete]="true" formControlName="alternateNumber" i18n-placeholder="@@enterAltNumber" placeholder="Alternate contact number" [ngClass]='{ "invalid": !alternateNumber.value?.length, "orangeError": (isSaveForm && !addressForm?.get("alternateNumber")?.valid) }' />
        <div class="tw-absolute tw-right-4 tw-mt-2.5" *ngIf="addressForm?.get('alternateNumber')?.value && addressForm?.get('alternateNumber')?.valid">
            <cs-icon class="tw-h-6 tw-w-6 tw-text-green-400" iconName="done"></cs-icon>
        </div>
    </div>
    <div class="tw-flex tw-flex-col tw-mb-4">
        <div class="tw-flex tw-justify-between tw-items-center tw-gap-2 tw-overflow-hidden">
            <div class="tw-flex tw-justify-between tw-items-center tw-gap-1 tw-px-4 tw-h-12 tw-w-full tw-rounded-xl tw-bg-gray-100">
                <input class="tw-w-full tw-h-full tw-bg-transparent tw-font-body tw-text-300 tw-text-black tw-border-0 placeholder:tw-opacity-100" #zipCodeInput required="required" type="number" pattern="[0-9]*" (keypress)="restrictSpecialChars($event)" (keydown)="commonUtilService.limitInputByMaxDigit($event, 6)" [autocomplete]="true" formControlName="zipCode" i18n-placeholder="@@pincodeRequired" placeholder="Pincode(Required)*" id="zipCode" [ngClass]='{ "orangeError": (isSaveForm && (addressForm?.get("zipCode")?.value?.toString()?.length !== 6 || pinCodeError))}' />
                <div class="tw-flex tw-items-center tw-shrink-0">
                    <!-- if the length of state.value is > 10, just show the first word. for ex. Himachal pradesh should be Himachal-->
                  <ng-container *ngIf="!pinCodeError && addressForm?.get('zipCode')?.value?.toString()?.length === 6 && addressForm?.get('city')?.value && addressForm?.get('state')?.value">
                    <div class="tw-text-100 tw-bg-gradient-to-b tw-from-white tw-to-gray-100">({{ addressForm?.get('state')?.value }})</div>
                  </ng-container>
                  <cs-icon *ngIf="addressForm?.get('zipCode')?.value?.toString()?.length === 6 && !pinCodeError" class="tw-h-6 tw-w-6 tw-text-green-400 tw-shrink-0" iconName="done"></cs-icon>
                </div>
            </div>
            <cs-button [class]="'tw-bg-green-400 btn-xxs tw-px-4 tw-tracking-[1px]'" [loading]="locationLoading" (callback)="getLocationDetails()"><cs-button-text class="flex justify-center"><span class="tw-font-bold tw-text-white" i18n="@@getPincode">find my pincode</span></cs-button-text></cs-button>
        </div>
        <div class="tw-text-red-400 tw-border-b-red-400 tw-flex tw-items-center tw-pl-3 tw-mt-1" *ngIf='isSaveForm && (addressForm?.get("zipCode")?.value?.toString()?.length !== 6 || pinCodeError)'>
            <img src="/assets/images/warn-err.png" class="tw-h-3" />
            <div class="tw-text-red-400 tw-text-200 tw-ml-1" i18n="@@errorPincode">
                Please enter a valid pincode
            </div>
        </div>
    </div>
    <div class="tw-mb-3">
      <div class="tw-flex tw-relative tw-overflow-hidden tw-flex-col tw-gap-1 tw-mb-1">
        <input class="tw-h-12 tw-w-full tw-rounded-xl tw-bg-gray-100 tw-font-body tw-text-300 tw-text-black tw-pl-4 tw-pr-11 tw-border-0 placeholder:tw-opacity-100" #cityInput required="required" type="text" [autocomplete]="true" i18n-placeholder="@@cityOrTown" formControlName="city" id="city" placeholder="City/Town/District(Required)*" [ngClass]='{ "orangeError": (isSaveForm && !addressForm?.get("city")?.value?.length) }' />
        <div class="tw-absolute tw-right-4 tw-mt-2.5" *ngIf="addressForm?.get('city')?.value?.length && !addressFieldError.city.cityError">
          <cs-icon class="tw-h-6 tw-w-6 tw-text-green-400" iconName="done"></cs-icon>
        </div>
        <div class="tw-text-red-400 tw-border-b-red-400 tw-flex tw-items-center tw-pl-3" *ngIf='isSaveForm && !addressForm?.get("city")?.value'>
          <img src="/assets/images/warn-err.png" class="tw-h-3" />
          <div class="tw-text-red-400 tw-text-200 tw-ml-1" i18n="@@errorCity">
            Please enter City/Town/District
          </div>
        </div>
      </div>
      <div class="tw-mb-1 tw-pl-3 tw-flex tw-items-center tw-gap-1.5" *ngIf="addressFieldError.city.cityError">
        <img src="/assets/images/warn-err.png" class="tw-h-3" />
        <p i18n="@@errorNonEnglishAddress" class="tw-text-red-400 tw-text-200 tw-leading-none" *ngIf="addressFieldError.city.errorType === 'nonEnglish'">Please enter address in English language only</p>
        <p i18n="@@errorSpecialCharacters" class="tw-text-red-400 tw-text-200 tw-leading-none" *ngIf="addressFieldError.city.errorType === 'specialCharacters'">Please avoid using special characters and emojis</p>
      </div>
    </div>
    <div class="tw-mb-3">
      <div class="tw-flex tw-mb-1 tw-relative tw-overflow-hidden tw-flex-col tw-gap-1">
        <input class="tw-h-12 tw-w-full tw-rounded-xl tw-bg-gray-100 tw-font-body tw-text-300 tw-text-black tw-pl-4 tw-pr-11 tw-border-0 placeholder:tw-opacity-100" #buildingDetails required="required" formControlName="buildingDetails" id="buildingDetails" type="text" i18n-placeholder="@@flatOrHouseRequired" placeholder="House No./Building(Required)*" [ngClass]='{ "orangeError": (isSaveForm && !addressForm?.get("buildingDetails")?.value) }' [autocomplete]="true" />
        <div class="tw-absolute tw-right-4 tw-mt-2.5" *ngIf="addressForm?.get('buildingDetails')?.value && !addressFieldError.buildingDetails.buildingDetailsError">
          <cs-icon class="tw-h-6 tw-w-6 tw-text-green-400" iconName="done"></cs-icon>
        </div>
        <div class="tw-text-red-400 tw-border-b-red-400 tw-flex tw-items-center tw-pl-3 mt-1" *ngIf='isSaveForm && !addressForm?.get("buildingDetails")?.value'>
          <img src="/assets/images/warn-err.png" class="tw-h-3" />
          <div class="tw-text-red-400 tw-text-200 tw-ml-1" i18n="@@errorHouse">
            Please enter House No. or Building No.
          </div>
        </div>
      </div>
      <div class="tw-mb-1 tw-pl-3 tw-flex tw-items-center tw-gap-1.5" *ngIf="addressFieldError.buildingDetails.buildingDetailsError">
        <img src="/assets/images/warn-err.png" class="tw-h-3" />
        <p i18n="@@errorNonEnglishAddress" class="tw-text-red-400 tw-text-200 tw-leading-none" *ngIf="addressFieldError.buildingDetails.errorType === 'nonEnglish'">Please enter address in English language only</p>
        <p i18n="@@errorSpecialCharacters" class="tw-text-red-400 tw-text-200 tw-leading-none" *ngIf="addressFieldError.buildingDetails.errorType === 'specialCharacters'">Please avoid using special characters and emojis</p>
      </div>
    </div>
    <div class="tw-mb-3">
      <div class="tw-flex tw-mb-1 tw-relative tw-overflow-hidden tw-flex-col tw-gap-1">
          <input class="tw-h-12 tw-w-full tw-rounded-xl tw-bg-gray-100 tw-font-body tw-text-300 tw-text-black tw-pl-4 tw-pr-11 tw-border-0 placeholder:tw-opacity-100" #buildingDetails2 required="required" formControlName="buildingDetails2" id="buildingDetails2" type="text" i18n-placeholder="@@areaOrRoadRequired" placeholder="Area/Colony/Road name(Required)*" [ngClass]='{ "orangeError": (isSaveForm && !addressForm?.get("buildingDetails2")?.value) }' [autocomplete]="true" />
          <div class="tw-text-red-400 tw-border-b-red-400 tw-flex tw-items-center tw-pl-3 mt-1" *ngIf='isSaveForm && !addressForm?.get("buildingDetails2")?.value'>
              <img src="/assets/images/warn-err.png" class="tw-h-3" />
              <div class="tw-text-red-400 tw-text-200 tw-ml-1" i18n="@@errorArea">
                  Please enter area or colony name
              </div>
          </div>
          <div class="tw-absolute tw-right-4 tw-mt-2.5" *ngIf="addressForm?.get('buildingDetails2')?.value && !addressFieldError.buildingDetails2.buildingDetails2Error">
              <cs-icon class="tw-h-6 tw-w-6 tw-text-green-400" iconName="done"></cs-icon>
          </div>
      </div>
      <div class="tw-mb-1 tw-pl-3 tw-flex tw-items-center tw-gap-1.5" *ngIf="addressFieldError.buildingDetails2.buildingDetails2Error">
        <img src="/assets/images/warn-err.png" class="tw-h-3" />
        <p i18n="@@errorNonEnglishAddress" class="tw-text-red-400 tw-text-200 tw-leading-none" *ngIf="addressFieldError.buildingDetails2.errorType === 'nonEnglish'">Please enter address in English language only</p>
        <p i18n="@@errorSpecialCharacters" class="tw-text-red-400 tw-text-200 tw-leading-none" *ngIf="addressFieldError.buildingDetails2.errorType === 'specialCharacters'">Please avoid using special characters and emojis</p>
      </div>
    </div>
    <div class="tw-mb-3">
      <div class="tw-flex tw-mb-1 tw-relative tw-overflow-hidden tw-flex-col tw-gap-1">
        <input class="tw-h-12 tw-w-full tw-rounded-xl tw-bg-gray-100 tw-font-body tw-text-300 tw-text-black tw-pl-4 tw-pr-11 tw-border-0 placeholder:tw-opacity-100" #landmark="" type="text" [autocomplete]="true" i18n-placeholder="@@shopOrLandmark" placeholder="add nearby famous shop/landmark/mall" formControlName="landmark" id="landmark" [ngClass]='{ "invalid": !landmark.value?.length }' />
        <div class="tw-absolute tw-right-4 tw-mt-2.5" *ngIf="addressForm?.get('landmark')?.value?.length && !addressFieldError.landmark.landmarkError">
          <cs-icon class="tw-h-6 tw-w-6 tw-text-green-400" iconName="done"></cs-icon>
        </div>
      </div>
      <div class="tw-mb-1 tw-pl-3 tw-gap-1.5 tw-flex tw-items-center" *ngIf="addressFieldError.landmark.landmarkError">
        <img src="/assets/images/warn-err.png" class="tw-h-3" />
        <p i18n="@@errorNonEnglishAddress" class="tw-text-red-400 tw-text-200 tw-leading-none" *ngIf="addressFieldError.landmark.errorType === 'nonEnglish'">Please enter address in English language only</p>
        <p i18n="@@errorSpecialCharacters" class="tw-text-red-400 tw-text-200 tw-leading-none" *ngIf="addressFieldError.landmark.errorType === 'specialCharacters'">Please avoid using special characters and emojis</p>
      </div>
    </div>
    <div class="tw-flex tw-mb-4 tw-items-center tw-h-12" *ngIf="!hideDefaultAddressSwitch">
        <span class="tw-mr-5 tw-font-body tw-font-bold tw-text-300" i18n="@@makeAddressDefault">Set as default address</span>
        <input-switch [(checked)]="formDataFromParent.default" [colorClass]='"tw-bg-green-100"'></input-switch>
    </div>
</div>
