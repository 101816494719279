<div class="tw-flex tw-flex-col tw-h-full tw-overflow-y-auto tw-bg-gray-100" *ngIf="!isPrepaidDiscountEnabled">
  <loading class="tw-flex-1 tw-self-center tw-justify-self-center" *ngIf="!paymentMethods.length"></loading>
  <ng-container *ngIf="paymentMethods.length">
    <div class="tw-fixed tw-left-6 tw-top-6 tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
      <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'arrow_back'"></cs-icon>
    </div>
    <section class="tw-px-6 tw-pt-16 tw-pb-5 tw-bg-green-100">
      <p class="fontR" *ngIf='offerTimer.endTime > 0 && order.get("type") === appConfig.Shared.Order.Type.REGIMEN'>
        <discount-timer [endTime]="offerTimer.endTime" [text]="offerTimer.title"></discount-timer>
      </p>
    </section>
    <section class="tw-px-6 tw-bg-green-100 tw-sticky tw-top-0 tw-relative tw-pt-4 tw-z-[30]">
      <div class="tw-sticky tw-top-0">
        <p class="tw-font-body tw-text-100 tw-text-black tw-uppercase tw-tracking-[1px]" *ngIf="order" i18n="@@totalPayment"> Total Payment </p>
        <h1 class="tw-font-body tw-text-900 tw-text-black tw-mb-4">
          <del *ngIf="discount">₹ <span>{{orderMRP}}</span>
          </del>
          <del class="tw-font-bold" *ngIf="orderBeforeOfferPrice">&nbsp;₹{{orderBeforeOfferPrice}}</del>
          <span class="tw-font-bold tw-text-green-400 tw-whitespace-nowrap">&nbsp;₹{{orderSP}} &nbsp;</span>
        </h1>
      </div>
    </section>
    <section class="tw-px-6 tw-pb-5 tw-bg-green-100">
      <div class="tw-flex tw-items-center tw-gap-4 tw-mb-2" *ngIf="appliedCoupon || discount">
        <div class="tw-flex tw-items-center">
          <div class="svg-container tw-relative">
            <svg class="anim-image" xmlns="http://www.w3.org/2000/svg" width="64.443" height="67.447" viewBox="0 0 64.443 67.447">
              <path id="Path_23051" data-name="Path 23051" d="M87.025,208.059,79.5,203.146l-2.347-8.676a3.039,3.039,0,0,0-3.088-2.243l-8.976.449-7-5.639a3.042,3.042,0,0,0-3.817,0l-7,5.639-8.975-.449a3.031,3.031,0,0,0-3.088,2.243l-2.347,8.676-7.525,4.912a3.042,3.042,0,0,0-1.179,3.63l3.2,8.4-3.2,8.4a3.042,3.042,0,0,0,1.179,3.63l7.525,4.913,2.347,8.675a3.034,3.034,0,0,0,3.088,2.243l8.975-.449,7,5.639a3.041,3.041,0,0,0,3.817,0l7-5.639,8.976.449a3.036,3.036,0,0,0,3.088-2.243l2.347-8.675,7.525-4.913a3.042,3.042,0,0,0,1.179-3.63l-3.2-8.4,3.2-8.4A3.042,3.042,0,0,0,87.025,208.059Z" transform="translate(-23.961 -186.363)" fill="#00AB5E"></path>
            </svg>
            <span class="off">%</span>
          </div>
        </div>
        <div class="tw-flex tw-flex-col">
          <p class="tw-font-body tw-font-bold tw-text-100 tw-text-black" *ngIf="discount && +discount >= 5" i18n="@@discountApplied">
            {{discount}}% instant discount applied
          </p>
          <p class="tw-font-body tw-text-200 tw-text-black" *ngIf="appliedWalletBalance">
            <span class="fw700 clr-dark" i18n="@@cashApplied">{{appliedWalletBalance.amount%1 != 0 ?
        appliedWalletBalance.amount.toFixed(2) :
        appliedWalletBalance.amount}} CureSkin wallet balance used =</span>
            <span>- ₹{{appliedWalletBalance.amount%1 != 0 ?
        appliedWalletBalance.amount.toFixed(2) :
        appliedWalletBalance.amount}}</span>
          </p>
          <p class="tw-font-body tw-text-200 tw-text-black" *ngIf="appliedCoupon">
            <span i18n="@@couponApplied">Coupon "{{appliedCoupon.code}}" has been applied =</span>
            <span>- ₹{{appliedCoupon.amount}}.</span>
          </p>
          <p class="tw-font-body tw-text-200 tw-text-black" i18n="@@youHaveSavedAmountNew" *ngIf="(orderMRP - orderSP) > 0"> You are saving ₹{{orderMRP - orderSP}} on this order </p>
        </div>
      </div>
    </section>
    <section class="cashStrip card tw-items-center tw-gap-4 tw-rounded-2xl tw-px-5 tw-py-2 tw-mx-4 tw-bg-white tw-relative tw-top-[-15px]" *ngIf="appliedWalletBalance">
      <div class="tw-flex tw-items-center tw-justify-between">
        <div class="tw-flex tw-flex-row">
          <div class="tw-flex tw-items-center tw-justify-center tw-rounded-xl" (click)="back()">
            <cs-icon class="tw-h-6 tw-w-6" [iconName]="'cureskin_cash_coin'"></cs-icon>
          </div>
          <span class="tw-ml-1 tw-flex tw-flex-row tw-items-center">
            <p class="tw-font-bold tw-font-body tw-text-600">
              {{appliedWalletBalance.amount%1 != 0 ?
        appliedWalletBalance.amount.toFixed(2) :
        appliedWalletBalance.amount}}
            </p>
            <p class="tw-ml-1 tw-font-body tw-text-300 tw-text-gray-500" i18n="@@cureskinCashApplied"> Cureskin Cash applied </p>
            <img class="tw-w-5 tw-h-5 tw-ml-2" src="/assets/images/correct_green.png" />
          </span>
        </div>
      </div>
    </section>
  </ng-container>
  <section class="tw-flex-1 tw-pb-20 tw-bg-white" id="paymentPage" *ngIf="order" #paymentPage="">
    <div class="partialAmount" *ngIf='order.get("paidAmount")' i18n="@@partialPaymentReceived"> We have received Rs {{order.get("paidAmount")}}. You will be paying only Rs {{orderSP - order.get("paidAmount")}} for this order. </div>
    <img class="banner" *ngFor="let banner of banners" [src]="banner.image" />
    <div class="payments tw-p-5" *ngFor="let paymentMethod of paymentMethods; let i = index" [id]="paymentMethod.mode" (click)="selectPaymentMethod(paymentMethod.mode)" [ngClass]='{ "no-bdr": i === 0 }'>
      <div class="image">
        <img *ngIf="paymentMethod.img" [src]="paymentMethod.img" alt="service_image" [class]="paymentMethod.title" [ngStyle]="{ 'opacity': (disableCod && paymentMethod.mode === appConfig.Shared.Payment.paymentModes.COD) ? '0.2' : '1' }" />
      </div>
      <div class="title">
        <div id="paymentMethodTitle" class="tw-font-body" [ngStyle]="{ 'color': (disableCod && paymentMethod.mode === appConfig.Shared.Payment.paymentModes.COD) ? 'var(--grey-400, #9E9E9E)' : 'inherit' }">
          {{getTranslatedString(paymentMethod.title)}}
        </div>
        <div class="sub tw-font-body" *ngIf="paymentMethod.mode === appConfig.Shared.Payment.paymentModes.UPI" i18n="@@gPayPhonePe"> Google Pay, PhonePe, BHIM and more </div>
        <div class="sub tw-font-body" *ngIf="paymentMethod.mode === appConfig.Shared.Payment.paymentModes.WALLET" i18n="@@amazonOla"> Amazon Pay, Ola Money and more </div>
        <div class="sub tw-font-body" *ngIf="disableCod && paymentMethod.mode === appConfig.Shared.Payment.paymentModes.COD" i18n="@@codUnavailable"> (Not available for this order) </div>
        <ng-container *ngIf="showCCODCharge && !disableCod">
          <ng-container *ngIf="paymentMethod.mode === appConfig.Shared.Payment.paymentModes.COD && CCODInfo?.amount">
            <div class="sub fontS2 tw-text-orange-400 bolder tw-font-body" i18n="@@processingFeeOnline"> ₹{{CCODInfo?.amount}} processing fee to be paid online </div>
            <div class="sub fontS3 tw-text-gray-400 bolder tw-font-body" i18n="@@nonRefundable"> This is non refundable </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="paymentMethod.mode === appConfig.Shared.Payment.paymentModes.COD && isCodHandlingFeeEnabled">
          <p class="tw-w-full tw-mt-1 tw-text-200 tw-text-gray-400 tw-font-bold tw-font-body">₹{{codHandlingFee}} <span i18n="@@handlingChargesApply">handling charges apply</span> </p>
        </ng-container>
      </div>
      <div *ngIf="paymentMethod.mode !== appConfig.Shared.Payment.paymentModes.COD" class="selection">
        <em class="selected clr-green" *ngIf="paymentMethod.mode === selectedPaymentMode"></em>
        <div class="unselected unselectedNew" *ngIf="paymentMethod.mode !== selectedPaymentMode"></div>
      </div>
      <div *ngIf="disableCod && paymentMethod.mode === appConfig.Shared.Payment.paymentModes.COD" class="selection">
        <div class="unselected" [ngStyle]="{'background': 'var(--grey-100, #F3F3F3)'}"></div>
      </div>
      <div *ngIf="!disableCod && paymentMethod.mode === appConfig.Shared.Payment.paymentModes.COD" class="selection">
        <em class="selected clr-green" *ngIf="paymentMethod.mode === selectedPaymentMode"></em>
        <div class="unselected unselectedNew" *ngIf="paymentMethod.mode !== selectedPaymentMode"></div>
      </div>
    </div>
    <div class="otherPayments" id="otherPayments" *ngIf="enableOtherPayments" (click)="openRazorpayHome()">
      <div class="dots">
        <em></em>
        <em></em>
        <em></em>
      </div>
      <div class="flex-grow tw-font-body" i18n="@@otherPayment"> Other Payment Methods </div>
      <em class="cs-icon-arrow fontL2 tw-text-green-400"></em>
    </div>
    <form method="post" action="{{paytmServerUrl}}" name="formPaytm">
      <table border="1">
        <tbody *ngIf="paytmConfig">
          <input type="hidden" name="MID" value="{{paytmConfig.MID}}" />
          <input type="hidden" name="ORDER_ID" value="{{paytmConfig.ORDER_ID}}" />
          <input type="hidden" name="CUST_ID" value="{{paytmConfig.CUST_ID}}" />
          <input type="hidden" name="WEBSITE" value="{{paytmConfig.WEBSITE}}" />
          <input type="hidden" name="CHANNEL_ID" value="{{paytmConfig.CHANNEL_ID}}" />
          <input type="hidden" name="EMAIL" value="{{paytmConfig.EMAIL}}" />
          <input type="hidden" name="MOBILE_NO" value="{{paytmConfig.MOBILE_NO}}" />
          <input type="hidden" name="CALLBACK_URL" value="{{paytmConfig.CALLBACK_URL}}" />
          <input type="hidden" name="INDUSTRY_TYPE_ID" value="{{paytmConfig.INDUSTRY_TYPE_ID}}" />
          <input type="hidden" name="TXN_AMOUNT" value="{{paytmConfig.TXN_AMOUNT}}" />
          <input type="hidden" name="CHECKSUMHASH" value="{{paytmConfig.CHECKSUMHASH}}" />
        </tbody>
      </table>
      <div class="tw-bg-gray-100 tw-flex tw-flex-col">
        <div *ngIf="orderSP > 0 && enableChooseCouponExperiment && coupons?.length" class="bg-f4 pB10 tw-mb-6">
          <ng-container>
            <div class="or-choose-coupon" i18n="@@ChooseACouponCode"> Choose a coupon </div>
            <div class="choose-coupon">
              <div class="coupon-item" *ngFor="let coupon of coupons" (click)="applyCouponCode(coupon.code)">
                <radio-btn cssClass="mT7" [checked]="couponCode === coupon.code" (checkedChange)="couponCode = coupon.code">
                  <div class="coupon-code" [ngClass]='{ "selected": couponCode == coupon.code }'>
                    {{coupon.code}}
                  </div>
                  <div class="coupon-desc" *ngIf="coupon.description">
                    {{coupon.description}}
                  </div>
                </radio-btn>
              </div>
            </div>
          </ng-container>
        </div>
        <div id="disabledCod" *ngIf="disableCod" class="tw-mt-4 tw-mx-5 tw-py-3 tw-px-4 tw-border tw-border-gray-200 tw-bg-[#f9f9f9] tw-rounded-2">
          <p class="tw-text-gray-500 tw-text-200 tw-font-medium">(<span i18n="@@codNotAvailable">COD Not available for this order</span>)</p>
        </div>
        <section class="tw-flex tw-items-center tw-bg-yellow-100 tw-rounded-xl tw-px-4 tw-py-2 tw-justify-between tw-mx-5 tw-mt-5 tw-mb-6 tw-bg-white tw-shadow-z4" *ngIf="!appliedCoupon && !showCouponsSection" style="background: #f7f3d0" (click)="openCouponsSection()">
          <p class="tw-font-body tw-font-bold tw-text-black tw-text-300" i18n="@@haveACouponCode"> have a coupon code? </p>
          <div class="tw-flex tw-items-center tw-gap-2 tw-text-200 tw-text-green-400 tw-h-8 tw-border tw-border-black tw-rounded-full tw-px-4">
            <span class="tw-font-body tw-font-bold" i18n="@@apply">Apply</span>
            <cs-icon class="tw-h-4 tw-w-4" [iconName]='"chevron_right"'></cs-icon>
          </div>
        </section>
        <div class="coupon-container" *ngIf="showCouponsSection">
          <div class="coupon-textbox tw-rounded-2" id="coupon-box">
            <input type="text" placeholder="Enter coupon code" [(ngModel)]="couponCode" [disabled]="couponLoading" autofocus="autofocus"/>
            <div class="apply" [ngClass]=" { 'clr-dark': couponCode } ">
              <span i18n="@@apply" *ngIf="!couponLoading" (click)="applyCoupon(selectedPaymentMode)">Apply</span>
              <loading class="pos-abs vh-center" *ngIf="couponLoading" [scale]="0.7"></loading>
            </div>
          </div>
        </div>
      </div>
    </form>
    <footer class="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-flex tw-px-6 tw-py-4 tw-bg-white" *ngIf="order && selectedPaymentMode">
      <div class="underline fontS2 mB15" *ngIf="showOrderDetail" (click)="showOrderDetailsPopup()" i18n="@@clickHereForOrderDetails"> Click here for order details </div>
      <div class="w100" *ngIf="!codConfirmationFee">
        <cs-button id="continueButton" [disabled]="!selectedPaymentMode" (callback)="placeOrder()" [class]="'primary w100 shine'" [loading]="loading">
          <cs-button-text class="tw-font-body" i18n="@@pay" *ngIf="orderSP !== 0 && selectedPaymentMode !== appConfig.Shared.Payment.paymentModes.COD">Pay</cs-button-text>
          <cs-button-text class="tw-font-body" i18n="@@placeYourOrder" *ngIf="(orderSP === 0 || selectedPaymentMode === appConfig.Shared.Payment.paymentModes.COD)">place your order</cs-button-text>
        </cs-button>
      </div>
      <div class="flex justify-between" *ngIf="codConfirmationFee">
        <div class="w100 fontS2 pB15">
          <span *ngIf="showCCODCharge" i18n="@@kindlyPayToConfirmOrderNew">Pay ₹{{(payment?.get('TransactionAmount') / 100)}} processing fees and confirm your COD order. To enjoy free delivery, you can also pay ₹{{orderSP}} online now.</span>
          <ng-container *ngIf="!showCCODCharge">
            <span i18n="@@kindlyPayToConfirmOrder">Kindly make ₹{{(payment?.get('TransactionAmount') / 100)}} payment to confirm your COD order.</span>
            <span class="pT5" i18n="@@youCanPayFullAmount"> You can also pay ₹{{orderSP}} to complete your order online.</span>
          </ng-container>
        </div>
        <div class="w55">
          <cs-button id="payOnlineButton" [disabled]="!selectedPaymentMode" (callback)="resetCodFeeAndPayOnline()" [class]="'secondary w100 shine btn-xs'">
            <cs-button-text *ngIf="codConfirmationFee" i18n="@@paySpOnline">Pay ₹{{orderSP}} ONLINE</cs-button-text>
          </cs-button>
        </div>
        <div class="w40">
          <cs-button id="continueButton" [disabled]="!selectedPaymentMode" (callback)="openPayment()" [class]="'primary w100 shine btn-xs'">
            <cs-button-text *ngIf="codConfirmationFee">
              <span class="pR5" i18n="@@pay">Pay</span>
              <span>₹{{(payment?.get('TransactionAmount') / 100)}}</span>
            </cs-button-text>
          </cs-button>
        </div>
      </div>
    </footer>
  </section>
</div>

<div class="tw-flex tw-flex-col tw-h-full tw-overflow-y-auto tw-bg-gray-100" *ngIf="isPrepaidDiscountEnabled">
  <loading class="tw-flex-1 tw-self-center tw-justify-self-center" *ngIf="!paymentMethods.length || paymentProcessing"></loading>
  <ng-container *ngIf="paymentMethods.length && !paymentProcessing">
    <div class="tw-fixed tw-left-6 tw-top-6 tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
      <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'arrow_back'"></cs-icon>
    </div>
    <section class="tw-px-6 tw-pt-16 tw-pb-5 tw-bg-green-100">
      <p class="fontR" *ngIf='offerTimer.endTime > 0 && order.get("type") === appConfig.Shared.Order.Type.REGIMEN'>
        <discount-timer [endTime]="offerTimer.endTime" [text]="offerTimer.title"></discount-timer>
      </p>
    </section>
    <section class="tw-px-6 tw-bg-green-100 tw-sticky tw-top-0 tw-relative tw-pt-4 tw-z-[30]">
      <div class="tw-sticky tw-top-0">
        <p class="tw-font-body tw-text-100 tw-text-black tw-uppercase tw-tracking-[1px]" *ngIf="order" i18n="@@totalPayment"> Total Payment </p>
        <h1 class="tw-font-body tw-text-900 tw-text-black tw-mb-4">
          <del *ngIf="discount">₹ <span>{{orderMRP}}</span>
          </del>
          <del class="tw-font-bold" *ngIf="orderBeforeOfferPrice">&nbsp;₹{{orderBeforeOfferPrice}}</del>
          <span class="tw-font-bold tw-text-green-400">&nbsp;₹{{orderSP}} &nbsp;</span>
        </h1>
      </div>
    </section>
    <section class="tw-px-6 tw-pb-5 tw-bg-green-100">
      <div class="tw-flex tw-items-center tw-gap-4 tw-mb-2" *ngIf="appliedCoupon || discount">
        <div class="tw-flex tw-items-center">
          <div class="svg-container tw-relative">
            <svg class="anim-image" xmlns="http://www.w3.org/2000/svg" width="64.443" height="67.447" viewBox="0 0 64.443 67.447">
              <path id="Path_23051" data-name="Path 23051" d="M87.025,208.059,79.5,203.146l-2.347-8.676a3.039,3.039,0,0,0-3.088-2.243l-8.976.449-7-5.639a3.042,3.042,0,0,0-3.817,0l-7,5.639-8.975-.449a3.031,3.031,0,0,0-3.088,2.243l-2.347,8.676-7.525,4.912a3.042,3.042,0,0,0-1.179,3.63l3.2,8.4-3.2,8.4a3.042,3.042,0,0,0,1.179,3.63l7.525,4.913,2.347,8.675a3.034,3.034,0,0,0,3.088,2.243l8.975-.449,7,5.639a3.041,3.041,0,0,0,3.817,0l7-5.639,8.976.449a3.036,3.036,0,0,0,3.088-2.243l2.347-8.675,7.525-4.913a3.042,3.042,0,0,0,1.179-3.63l-3.2-8.4,3.2-8.4A3.042,3.042,0,0,0,87.025,208.059Z" transform="translate(-23.961 -186.363)" fill="#00AB5E"></path>
            </svg>
            <span class="off">%</span>
          </div>
        </div>
        <div class="tw-flex tw-flex-col">
          <p class="tw-font-body tw-font-bold tw-text-100 tw-text-black" *ngIf="discount && +discount >= 5" i18n="@@discountApplied">
            {{discount}}% instant discount applied
          </p>
          <p class="tw-font-body tw-text-200 tw-text-black" *ngIf="appliedWalletBalance">
            <span class="fw700 clr-dark" i18n="@@cashApplied">{{appliedWalletBalance.amount%1 != 0 ?
        appliedWalletBalance.amount.toFixed(2) :
        appliedWalletBalance.amount}} CureSkin wallet balance used =</span>
            <span>- ₹{{appliedWalletBalance.amount%1 != 0 ?
        appliedWalletBalance.amount.toFixed(2) :
        appliedWalletBalance.amount}}</span>
          </p>
          <p class="tw-font-body tw-text-200 tw-text-black" *ngIf="appliedCoupon && appliedCoupon?.code !== prepaidDiscountCoupon ">
            <span i18n="@@couponApplied">Coupon "{{appliedCoupon.code}}" has been applied =</span>
            <span>- ₹{{appliedCoupon.amount}}.</span>
          </p>
          <p class="tw-font-body tw-text-200 tw-text-black" i18n="@@youHaveSavedAmountNew" *ngIf="(orderMRP - orderSP) > 0"> You are saving ₹{{orderMRP - orderSP}} on this order </p>
        </div>
      </div>
    </section>
    <section class="cashStrip card tw-items-center tw-gap-4 tw-rounded-2xl tw-px-5 tw-py-2 tw-mx-4 tw-bg-white tw-relative tw-top-[-15px]" *ngIf="appliedWalletBalance">
      <div class="tw-flex tw-items-center tw-justify-between">
        <div class="tw-flex tw-flex-row">
          <div class="tw-flex tw-items-center tw-justify-center tw-rounded-xl" (click)="back()">
            <cs-icon class="tw-h-6 tw-w-6" [iconName]="'cureskin_cash_coin'"></cs-icon>
          </div>
          <span class="tw-ml-1 tw-flex tw-flex-row tw-items-center">
            <p class="tw-font-bold tw-font-body tw-text-600">
              {{appliedWalletBalance.amount%1 != 0 ?
        appliedWalletBalance.amount.toFixed(2) :
        appliedWalletBalance.amount}}
            </p>
            <p class="tw-ml-1 tw-font-body tw-text-300 tw-text-gray-500" i18n="@@cureskinCashApplied"> Cureskin Cash applied </p>
            <img class="tw-w-5 tw-h-5 tw-ml-2" src="/assets/images/correct_green.png" />
          </span>
        </div>
      </div>
    </section>
  </ng-container>
  <section class="tw-flex-1 tw-pb-20 tw-bg-white" id="paymentPage" *ngIf="order && !paymentProcessing" #paymentPage="">
    <div class="partialAmount" *ngIf='order.get("paidAmount")' i18n="@@partialPaymentReceived"> We have received Rs {{order.get("paidAmount")}}. You will be paying only Rs {{orderSP - order.get("paidAmount")}} for this order. </div>
    <img class="banner" *ngFor="let banner of banners" [src]="banner.image" />
    <div class="payments tw-p-5" *ngFor="let paymentMethod of paymentMethods; let i = index" [id]="paymentMethod.mode" (click)="selectPaymentMethod(paymentMethod.mode)" [ngClass]='{ "no-bdr": i === 0 }'>
      <div class="image">
        <img *ngIf="paymentMethod.img" [src]="paymentMethod.img" alt="service_image" [ngStyle]="{ 'opacity': (disableCod && paymentMethod.mode === appConfig.Shared.Payment.paymentModes.COD) ? '0.2' : '1' }" [class]="paymentMethod.title" />
      </div>
      <div class="title">
        <div id="paymentMethodTitle" class="tw-font-body" [ngStyle]="{ 'color': (disableCod && paymentMethod.mode === appConfig.Shared.Payment.paymentModes.COD) ? 'var(--grey-400, #9E9E9E)' : 'inherit' }">
          {{getTranslatedString(paymentMethod.title)}}
        </div>
        <div class="sub tw-font-body" *ngIf="paymentMethod.mode === appConfig.Shared.Payment.paymentModes.UPI" i18n="@@gPayPhonePe"> Google Pay, PhonePe, BHIM and more </div>
        <div class="sub tw-font-body" *ngIf="paymentMethod.mode === appConfig.Shared.Payment.paymentModes.WALLET" i18n="@@amazonOla"> Amazon Pay, Ola Money and more </div>
        <div class="sub tw-font-body" *ngIf="disableCod && paymentMethod.mode === appConfig.Shared.Payment.paymentModes.COD" i18n="@@codUnavailable"> (Not available for this order) </div>
        <div class="sub tw-font-body tw-text-green-400 tw-font-bold tw-text-200" *ngIf="showPrepaidDiscountInfo && paymentMethod.mode === appConfig.Shared.Payment.paymentModes.UPI" i18n="@@prepaidDiscount"> ₹100 off with online coupon </div>
        <ng-container *ngIf="showCCODCharge && !disableCod">
          <ng-container *ngIf="paymentMethod.mode === appConfig.Shared.Payment.paymentModes.COD && CCODInfo?.amount">
            <div class="sub fontS2 tw-text-orange-400 bolder tw-font-body" i18n="@@processingFeeOnline"> ₹{{CCODInfo?.amount}} processing fee to be paid online </div>
            <div class="sub fontS3 tw-text-gray-400 bolder tw-font-body" i18n="@@nonRefundable"> This is non refundable </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="paymentMethod.mode === appConfig.Shared.Payment.paymentModes.COD && isCodHandlingFeeEnabled">
          <p class="tw-w-full tw-mt-1 tw-text-200 tw-text-gray-400 tw-font-bold tw-font-body">₹{{codHandlingFee}} <span i18n="@@handlingChargesApply">handling charges apply</span> </p>
        </ng-container>
      </div>
      <div *ngIf="paymentMethod.mode !== appConfig.Shared.Payment.paymentModes.COD" class="selection">
        <em class="selected clr-green" *ngIf="paymentMethod.mode === selectedPaymentMode"></em>
        <div class="unselected unselectedNew" *ngIf="paymentMethod.mode !== selectedPaymentMode"></div>
      </div>
      <div *ngIf="disableCod && paymentMethod.mode === appConfig.Shared.Payment.paymentModes.COD" class="selection">
        <div class="unselected" [ngStyle]="{'background': 'var(--grey-100, #F3F3F3)'}"></div>
      </div>
      <div *ngIf="!disableCod && paymentMethod.mode === appConfig.Shared.Payment.paymentModes.COD" class="selection">
        <em class="selected clr-green" *ngIf="paymentMethod.mode === selectedPaymentMode"></em>
        <div class="unselected unselectedNew" *ngIf="paymentMethod.mode !== selectedPaymentMode"></div>
      </div>
    </div>
    <div class="otherPayments" id="otherPayments" *ngIf="enableOtherPayments" (click)="openRazorpayHome()">
      <div class="dots">
        <em></em>
        <em></em>
        <em></em>
      </div>
      <div class="flex-grow tw-font-body" i18n="@@otherPayment"> Other Payment Methods </div>
      <em class="cs-icon-arrow fontL2 tw-text-green-400"></em>
    </div>
    <form method="post" action="{{paytmServerUrl}}" name="formPaytm">
      <table border="1">
        <tbody *ngIf="paytmConfig">
          <input type="hidden" name="MID" value="{{paytmConfig.MID}}" />
          <input type="hidden" name="ORDER_ID" value="{{paytmConfig.ORDER_ID}}" />
          <input type="hidden" name="CUST_ID" value="{{paytmConfig.CUST_ID}}" />
          <input type="hidden" name="WEBSITE" value="{{paytmConfig.WEBSITE}}" />
          <input type="hidden" name="CHANNEL_ID" value="{{paytmConfig.CHANNEL_ID}}" />
          <input type="hidden" name="EMAIL" value="{{paytmConfig.EMAIL}}" />
          <input type="hidden" name="MOBILE_NO" value="{{paytmConfig.MOBILE_NO}}" />
          <input type="hidden" name="CALLBACK_URL" value="{{paytmConfig.CALLBACK_URL}}" />
          <input type="hidden" name="INDUSTRY_TYPE_ID" value="{{paytmConfig.INDUSTRY_TYPE_ID}}" />
          <input type="hidden" name="TXN_AMOUNT" value="{{paytmConfig.TXN_AMOUNT}}" />
          <input type="hidden" name="CHECKSUMHASH" value="{{paytmConfig.CHECKSUMHASH}}" />
        </tbody>
      </table>
      <div class="tw-bg-gray-100 tw-flex tw-flex-col">
        <div *ngIf="orderSP > 0 && enableChooseCouponExperiment && coupons?.length" class="bg-f4 pB10 tw-mb-6">
          <ng-container>
            <div class="or-choose-coupon" i18n="@@ChooseACouponCode"> Choose a coupon </div>
            <div class="choose-coupon">
              <div class="coupon-item" *ngFor="let coupon of coupons" (click)="applyCouponCode(coupon.code)">
                <radio-btn cssClass="mT7" [checked]="couponCode === coupon.code" (checkedChange)="couponCode = coupon.code">
                  <div class="coupon-code" [ngClass]='{ "selected": couponCode == coupon.code }'>
                    {{coupon.code}}
                  </div>
                  <div class="coupon-desc" *ngIf="coupon.description">
                    {{coupon.description}}
                  </div>
                </radio-btn>
              </div>
            </div>
          </ng-container>
        </div>
        <div id="disabledCod" *ngIf="disableCod" class="tw-mt-4 tw-mx-5 tw-py-3 tw-px-4 tw-border tw-border-gray-200 tw-bg-[#f9f9f9] tw-rounded-2">
          <p class="tw-text-gray-500 tw-text-200 tw-font-medium">(<span i18n="@@codNotAvailable">COD Not available for this order</span>)</p>
        </div>
        <section class="tw-flex tw-items-center tw-bg-yellow-100 tw-rounded-xl tw-px-4 tw-py-2 tw-justify-between tw-mx-5 tw-mt-5 tw-mb-6 tw-bg-white tw-shadow-z4" *ngIf="!appliedCoupon && !showCouponsSection" style="background: #f7f3d0" (click)="openCouponsSection()">
          <p class="tw-font-body tw-font-bold tw-text-black tw-text-300" i18n="@@haveACouponCode"> have a coupon code? </p>
          <div class="tw-flex tw-items-center tw-gap-2 tw-text-200 tw-text-green-400 tw-h-8 tw-border tw-border-black tw-rounded-full tw-px-4">
            <span class="tw-font-body tw-font-bold" i18n="@@apply">Apply</span>
            <cs-icon class="tw-h-4 tw-w-4" [iconName]='"chevron_right"'></cs-icon>
          </div>
        </section>
        <div class="coupon-container" *ngIf="showCouponsSection">
          <div class="coupon-textbox tw-rounded-2" id="coupon-box">
            <input type="text" placeholder="Enter coupon code" [(ngModel)]="couponCode" [disabled]="couponLoading" autofocus="autofocus"/>
            <div class="apply" [ngClass]=" { 'clr-dark': couponCode } ">
              <span i18n="@@apply" *ngIf="!couponLoading" (click)="applyCoupon(selectedPaymentMode)">Apply</span>
              <loading class="pos-abs vh-center" *ngIf="couponLoading" [scale]="0.7"></loading>
            </div>
          </div>
        </div>
      </div>
    </form>
    <footer class="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-flex tw-px-6 tw-py-4 tw-bg-white" *ngIf="order && selectedPaymentMode">
      <div class="underline fontS2 mB15" *ngIf="showOrderDetail" (click)="showOrderDetailsPopup()" i18n="@@clickHereForOrderDetails"> Click here for order details </div>
      <div class="w100" *ngIf="!codConfirmationFee">
        <cs-button id="continueButton" [disabled]="!selectedPaymentMode" (callback)="placeOrder()" [class]="'primary w100 shine'" [loading]="loading">
          <cs-button-text class="tw-font-body" i18n="@@pay" *ngIf="orderSP !== 0 && selectedPaymentMode !== appConfig.Shared.Payment.paymentModes.COD">Pay</cs-button-text>
          <cs-button-text class="tw-font-body" i18n="@@placeYourOrder" *ngIf="(orderSP === 0 || selectedPaymentMode === appConfig.Shared.Payment.paymentModes.COD)">place your order</cs-button-text>
        </cs-button>
      </div>
      <div class="flex justify-between" *ngIf="codConfirmationFee">
        <div class="w100 fontS2 pB15">
          <span *ngIf="showCCODCharge" i18n="@@kindlyPayToConfirmOrderNew">Pay ₹{{(payment?.get('TransactionAmount') / 100)}} processing fees and confirm your COD order. To enjoy free delivery, you can also pay ₹{{orderSP}} online now.</span>
          <ng-container *ngIf="!showCCODCharge">
            <span i18n="@@kindlyPayToConfirmOrder">Kindly make ₹{{(payment?.get('TransactionAmount') / 100)}} payment to confirm your COD order.</span>
            <span class="pT5" i18n="@@youCanPayFullAmount"> You can also pay ₹{{orderSP}} to complete your order online.</span>
          </ng-container>
        </div>
        <div class="w55">
          <cs-button id="payOnlineButton" [disabled]="!selectedPaymentMode" (callback)="resetCodFeeAndPayOnline()" [class]="'secondary w100 shine btn-xs'">
            <cs-button-text *ngIf="codConfirmationFee" i18n="@@paySpOnline">Pay ₹{{orderSP}} ONLINE</cs-button-text>
          </cs-button>
        </div>
        <div class="w40">
          <cs-button id="continueButton" [disabled]="!selectedPaymentMode" (callback)="openPayment()" [class]="'primary w100 shine btn-xs'">
            <cs-button-text *ngIf="codConfirmationFee">
              <span class="pR5" i18n="@@pay">Pay</span>
              <span>₹{{(payment?.get('TransactionAmount') / 100)}}</span>
            </cs-button-text>
          </cs-button>
        </div>
      </div>
    </footer>
  </section>
</div>
<pop-up *ngIf='popUp.open && popUp.type === "ORDER_SUMMARY"' [show]="true">
  <order-summary-popup [data]="orderSummary" [experiments]="experiments" (close)="back()"></order-summary-popup>
</pop-up>