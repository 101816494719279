<ng-container *ngIf="orderPageRegimenProposition && !(isRegimenOrder && !isRegimenPresent); then rebrandedTemplate else originalTemplate"></ng-container>
<loading class="pos-fix vh-center" *ngIf="loading"></loading>
<ng-template #originalTemplate>
  <div class="tw-h-full tw-overflow-auto" *ngIf="!loading">
    <div class="tw-fixed tw-left-6 tw-top-6 tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
      <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'arrow_back'"></cs-icon>
    </div>
    <div class="tw-flex tw-flex-col tw-max-h-full tw-h-full tw-bg-gray-100">
      <div class="tw-flex-1 tw-overflow-y-scroll" *ngIf="order">
        <div class="tw-flex tw-items-end tw-justify-between tw-px-4 tw-pt-24 tw-pb-4 tw-bg-white tw-mb-2">
          <div class="tw-flex-1 tw-flex tw-flex-col">
            <div class="tw-font-body tw-font-bold tw-text-200 tw-text-black">
              <span i18n="@@orderNumber">Order number</span><span>: {{"#"+ (order ? order.get('orderNumber') : '')}}</span>
            </div>
            <div class="tw-font-body tw-text-200 tw-text-gray-400">
              <span i18n="@@orderDate">Order date</span><span>: {{order.get('createdAt') | date: 'd MMM, yyyy'}}</span>
              <div *ngIf="expectedDeliveryDate.from && expectedDeliveryDate.to">
                <div class="bold" i18n="@@expectedDeliveryDate">
                  Expected Delivery Date
                </div>
                <p id="expected-delivery-date" class="">
                  {{expectedDeliveryDate.from | date: 'd MMM' }} - {{expectedDeliveryDate.to | date: 'd MMM'}}
                </p>
              </div>
            </div>
          </div>
          <div class="tw-flex-none">
            <p class="tw-font-body tw-text-300">
              <span class="tw-text-black" i18n="@@total">Total</span><span class="tw-font-bold tw-text-green-500">: ₹{{order.get('amount')}}</span>
            </p>
          </div>
        </div>
        <ng-container [ngSwitch]="true">
          <div class="tw-h-12 tw-flex tw-items-center tw-justify-between tw-pl-4 tw-pr-7 tw-bg-yellow-100 tw-mb-2" *ngSwitchCase="orderPaymentPendingStages.includes(order.get('stage'))" (click)="makePaymentForOrder()">
            <p class="tw-font-body tw-text-300" i18n="@@yourPaymentIsPending">
              Your payment is pending
            </p>
            <div class="tw-h-8 tw-px-5 tw-bg-green-400 tw-rounded-full tw-flex tw-items-center">
              <span class="tw-font-body tw-text-200 tw-text-white" i18n="@@makePayment"> Make payment </span>
            </div>
          </div>
          <div class="tw-h-12 tw-flex tw-items-center tw-justify-between tw-pl-4 tw-pr-7 tw-bg-green-100 tw-mb-2" *ngSwitchCase="orderPlacedStages.includes(order.get('stage')) && order.get('trackingId')">
            <p class="tw-font-body tw-text-300" i18n="@@yourOrderIsPlaced">
              Your order is Placed
            </p>
            <div class="tw-h-8 tw-px-5 tw-rounded-full tw-flex tw-items-center tw-border tw-border-black" *ngIf="order.get('trackingId')" (click)='trackOrder(order.get("trackingURL"))'>
              <span class="tw-font-body tw-text-200 tw-text-green-500" i18n="@@trackOrder">Track Order</span>
            </div>
          </div>
          <div class="tw-h-12 tw-flex tw-items-center tw-justify-between tw-pl-4 tw-pr-7 tw-bg-green-100 tw-mb-2" *ngSwitchCase="orderPackedStages.includes(order.get('stage'))">
            <p class="tw-font-body tw-text-300" i18n="@@yourOrderIsPacked">
              Your order is Packed
            </p>
            <div class="tw-h-8 tw-px-5 tw-rounded-full tw-flex tw-items-center tw-border tw-border-black" *ngIf="order.get('trackingId')" (click)='trackOrder(order.get("trackingURL"))'>
              <span class="tw-font-body tw-text-200 tw-text-green-500" i18n="@@trackOrder">Track Order</span>
            </div>
          </div>
          <div class="tw-h-12 tw-flex tw-items-center tw-justify-between tw-pl-4 tw-pr-7 tw-bg-green-100 tw-mb-2" *ngSwitchCase="orderDispatchedStages.includes(order.get('stage'))">
            <p class="tw-font-body tw-text-300" i18n="@@yourOrderIsDispatched">
              Your order is Dispatched
            </p>
            <div class="tw-h-8 tw-px-5 tw-rounded-full tw-flex tw-items-center tw-border tw-border-black" *ngIf="order.get('trackingId')" (click)='trackOrder(order.get("trackingURL"))'>
              <span class="tw-font-body tw-text-200 tw-text-green-500" i18n="@@trackOrder">Track Order</span>
            </div>
          </div>
          <div class="tw-h-12 tw-flex tw-items-center tw-justify-between tw-pl-4 tw-pr-7 tw-bg-yellow-100 tw-mb-2" *ngSwitchCase="orderDeliveryFailedStages.includes(order.get('stage'))">
            <p class="tw-font-body tw-text-300" i18n="@@deliveryAttemptFailed">
              Delivery attempt failed
            </p>
            <div class="tw-h-8 tw-px-5 tw-rounded-full tw-flex tw-items-center tw-border tw-border-black" *ngIf="order.get('trackingId')" (click)='trackOrder(order.get("trackingURL"))'>
              <span class="tw-font-body tw-text-200 tw-text-green-500" i18n="@@trackOrder">Track Order</span>
            </div>
          </div>
          <div class="tw-h-12 tw-flex tw-items-center tw-justify-between tw-pl-4 tw-pr-7 tw-bg-green-100 tw-mb-2" *ngSwitchCase="orderOutForDeliveryStages.includes(order.get('stage'))">
            <p class="tw-font-body tw-text-300" i18n="@@orderOutForDelivery">
              Your order is out for delivery
            </p>
            <div class="tw-h-8 tw-px-5 tw-bg-green-400 tw-rounded-full tw-flex tw-items-center" *ngIf="order.get('trackingId')" (click)='trackOrder(order.get("trackingURL"))'>
              <span class="tw-font-body tw-text-200 tw-text-white" i18n="@@trackOrder">Track Order</span>
            </div>
          </div>
          <div class="tw-h-12 tw-flex tw-items-center tw-justify-between tw-pl-4 tw-pr-7 tw-bg-blue-100 tw-mb-2" *ngSwitchCase="orderDeliveredStages.includes(order.get('stage'))">
            <p class="tw-font-body tw-text-300" i18n="@@yourOrderIsDelivered">
              Your order is Delivered
            </p>
            <div class="tw-h-8 tw-px-5 tw-bg-green-400 tw-rounded-full tw-flex tw-items-center" *ngIf="order.get('type') === appConfig.Shared.Order.Type.REGIMEN" (click)="seeInstructions()">
              <span class="tw-font-body tw-text-200 tw-text-white" i18n="@@seeInstructions">See instructions</span>
            </div>
          </div>
          <div class="tw-h-12 tw-flex tw-items-center tw-justify-between tw-pl-4 tw-pr-7 tw-bg-orange-100 tw-mb-2" *ngSwitchCase="orderCanceledStages.includes(order.get('stage'))">
            <div class="tw-font-body tw-text-300 tw-flex tw-items-center tw-gap-1">
              <span i18n="@@yourOrderIsCancelled">Your order is Cancelled</span>
              <div class="tw-rounded-full tw-bg-red-400 tw-h-4">
                <cs-icon class="tw-h-4 tw-w-4 tw-text-white" [iconName]="'close'"></cs-icon>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="tw-flex tw-flex-col tw-gap-2 tw-bg-white tw-p-4 tw-mb-2">
          <h2 class="tw-font-body tw-font-bold tw-text-300 tw-text-black" i18n="@@deliveryAddress">Delivery Address</h2>
          <div>
            <div class="tw-font-body tw-text-300 tw-text-gray-500">
              {{order.get("contactDetail").contactName }}
            </div>
            <div class="tw-font-body tw-text-300 tw-text-gray-500">
              {{order.get("contactDetail").buildingDetails }},
              {{order.get("contactDetail").localityDetails }},
              {{order.get("contactDetail").landmark }}
            </div>
            <div>
              <span class="tw-font-body tw-text-300 tw-text-gray-500">{{order.get("contactDetail").city }},
                {{order.get("contactDetail").state }}&nbsp;</span><span class="tw-font-body tw-text-300 tw-text-gray-500">- {{order.get("contactDetail").zipCode }}</span>
            </div>
            <div class="tw-font-body tw-text-300 tw-text-gray-500">
              {{order.get("contactDetail").contactNumber }}
            </div>
          </div>
        </div>
        <div class="tw-flex tw-flex-col tw-bg-white tw-py-4 tw-mb-5">
          <h2 class="tw-font-body tw-text-300 tw-text-black tw-px-5">
            <span>{{order.get('productInfo').length ? totalCumulativeQuantityOfProducts : 0}}&nbsp;</span><span i18n="@@productsInYourOrder">Products in your order</span>
          </h2>
          <div class="tw-flex tw-flex-col tw-gap-4 tw-mb-gray-300 tw-pt-6 tw-pb-5 tw-border-b-2 tw-border-gray-100 tw-pl-5 tw-pr-7 last:tw-border-none" *ngFor="let product of order.get('productInfo')">
            <div class="tw-flex tw-items-end tw-pr-3">
              <img class="tw-h-20 tw-w-auto tw-rounded-xl tw-mr-5" [src]="products[product.id]?.get('rebrandedImageWithBackground')?.[0]" />
              <div class="tw-flex-1 tw-flex tw-flex-col tw-self-stretch tw-justify-between tw-py-2">
                <h3 class="tw-font-body tw-font-bold tw-text-400 tw-text-black">
                  {{product?.name}}{{!product?.SP && order?.get('type') === appConfig.Shared.Order.Type.REGIMEN ? ': Free' : ''}}
                </h3>
                <div class="tw-font-body tw-text-300 tw-text-gray-500">
                  Qty {{product?.quantity}}
                </div>
              </div>
              <div class="tw-font-body tw-text-300 tw-pb-2" *ngIf="order?.get('type') !== appConfig.Shared.Order.Type.REGIMEN">
                <span class="tw-line-through tw-gray-500">₹{{product?.MRP}}</span><span class="tw-font-bold tw-text-black">&nbsp;₹{{product?.SP}}</span>
              </div>
            </div>
            <div class="tw-flex tw-gap-3">
              <div class="tw-flex-1 tw-h-8 tw-flex tw-gap-2 tw-items-center tw-justify-center tw-border tw-border-black tw-rounded-full"
                *ngIf="!orderCanceledStages.includes(order.get('stage')) && product?.SP" (click)="handleNavigation(product)">
                <span class="tw-font-body tw-font-bold tw-text-200 tw-text-green-500 tw-lowercase" i18n="@@seeInstructions">See instructions</span><cs-icon class="tw-h-4 tw-w-4 tw-text-black" [iconName]='"chevron_right"'></cs-icon>
              </div>
              <div class="tw-flex-1 tw-h-8 tw-flex tw-gap-2 tw-items-center tw-justify-center tw-border tw-border-black tw-rounded-full"
                *ngIf="!orderCanceledStages.includes(order.get('stage'))&& !product?.SP && order?.get('type') === appConfig.Shared.Order.Type.REGIMEN" (click)="handleNavigation(product)">
                <span class="tw-font-body tw-font-bold tw-text-200 tw-text-green-500 tw-lowercase" i18n="@@view">View</span><cs-icon class="tw-h-4 tw-w-4 tw-text-black" [iconName]='"chevron_right"'></cs-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="tw-mx-4 tw-mb-5 tw-flex tw-flex-col tw-rounded-3xl tw-overflow-hidden tw-bg-white tw-shadow-z1 tw-py-3 tw-px-4">
          <div class="tw-flex tw-items-center tw-justify-between tw-py-3 tw-border-b tw-border-gray-200 last:tw-border-none" *ngIf='order.get("signedUrlForPdfInvoice")' (click)='openInvoiceUrl(order.get("signedUrlForPdfInvoice"))'>
            <p class="tw-font-body tw-text-200" i18n="@@downloadInvoice">
              Download Invoice
            </p>
            <cs-icon class="tw-h-6 tw-w-6" [iconName]="'chevron_right'"></cs-icon>
          </div>
          <div class="tw-flex tw-items-center tw-justify-between tw-py-3 tw-border-b tw-border-gray-200 last:tw-border-none" *ngIf="regimenApproved" routerLink="/user/prescription">
            <p class="tw-font-body tw-text-200" i18n="@@seePrescription">
              See Prescription
            </p>
            <cs-icon class="tw-h-6 tw-w-6" [iconName]="'chevron_right'"></cs-icon>
          </div>
          <div class="tw-flex tw-items-center tw-justify-between tw-py-3 tw-border-b tw-border-gray-200 last:tw-border-none" routerLink="/support">
            <p class="tw-font-body tw-text-200 tw-text-orange-500">
              <span i18n="@@needHelp">Need help</span><span>?</span>
            </p>
            <cs-icon class="tw-h-6 tw-w-6 tw-text-orange-500" [iconName]="'chevron_right'"></cs-icon>
          </div>
          <div class="tw-flex tw-items-center tw-justify-between tw-py-3 tw-border-b tw-border-gray-200 last:tw-border-none" *ngIf="cancelOrderAllowed" (click)="cancelOrderDialog()">
            <p class="tw-font-body tw-text-200 tw-text-orange-500" i18n="@@cancelOrder">Cancel Order</p>
            <cs-icon class="tw-h-6 tw-w-6 tw-text-orange-500" [iconName]="'chevron_right'"></cs-icon>
          </div>
        </div>
        <div class="tw-flex tw-flex-col tw-bg-white tw-pt-4 tw-gap-2">
          <h2 class="tw-font-body tw-text-400 tw-mb-3 tw-px-4" i18n="@@paymentDetails">
            Payment details
          </h2>
          <div class="tw-flex tw-items-center tw-justify-between tw-px-4">
            <span class="tw-font-body tw-text-200 tw-text-gray-800" i18n="@@paymentMethod">Payment method</span><span class="tw-font-body tw-text-200">{{order.get('paymentType').replace('_', ' ') | titlecase}}</span>
          </div>
          <div class="tw-flex tw-items-center tw-justify-between tw-px-4">
            <span class="tw-font-body tw-text-200 tw-text-gray-800" i18n="@@subtotalInclusiveTax">Subtotal (inclusive tax)</span><span class="tw-font-body tw-text-200">₹{{orderMRP}}</span>
          </div>
          <div class="tw-flex tw-items-center tw-justify-between tw-px-4">
            <span class="tw-font-body tw-text-200 tw-text-gray-800" i18n="@@discount">Discount</span><span class="tw-font-body tw-text-200 tw-text-orange-400">(-) ₹{{+orderMRP - +orderSp}}</span>
          </div>
          <div class="tw-flex tw-items-center tw-justify-between tw-px-4">
            <span class="tw-font-body tw-text-200 tw-text-gray-800" i18n="@@shippingCharges">Shipping charges</span><span class="tw-font-body tw-text-200">₹0</span>
          </div>
          <div class="tw-flex tw-items-center tw-justify-between tw-px-4">
            <span class="tw-font-body tw-font-bold tw-text-300 tw-text-black" i18n="@@total">Total</span><span class="tw-font-body tw-font-bold tw-text-300">₹{{orderSp}}</span>
          </div>
          <div class="tw-flex tw-items-center tw-justify-between tw-p-4 tw-bg-blue-100">
            <span class="tw-font-body tw-text-300 tw-text-green-500" i18n="@@totalSavings">Total Savings:</span><span class="tw-font-body tw-text-300 tw-text-green-500">₹{{+orderMRP - +orderSp}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <pop-up-modal *ngIf="popUpModal.open" [config]="popUpModal" (close)="onPopUpClose($event)"></pop-up-modal>
</ng-template>

<ng-template #rebrandedTemplate>
  <ng-content *ngIf="isRegimenOrder; then regimenOrderTemplate else productOrderTemplate"></ng-content>
  <ng-template #regimenOrderTemplate>
    <div class="tw-h-full tw-bg-gray-100 tw-font-body tw-overflow-auto" *ngIf="!loading">
      <div class="tw-flex tw-flex-col tw-gap-11 tw-px-4 tw-pt-[18px] tw-pb-4" [ngClass]="bgColor">
        <div class="tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-bg-gray-800 tw-rounded-xl" (click)="back()">
          <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'arrow_back'"></cs-icon>
        </div>
        <div class="tw-flex tw-justify-between">
          <div class="tw-flex tw-flex-col tw-gap-1">
            <ng-container [ngSwitch]="true">
              <p class="tw-text-400 tw-font-bold tw-font-body tw-text-black" i18n="@@yourPaymentIsPending" *ngSwitchCase="orderPaymentPendingStages.includes(order.get('stage'))"> Your payment is pending </p>
              <p class="tw-text-400 tw-font-bold tw-font-body tw-text-black" i18n="@@yourOrderIsPlaced" *ngSwitchCase="orderPlacedStages.includes(order.get('stage')) || order.get('stage') === appConfig.Shared.Order.Stage.INVOICE_REQUESTED"> Your order is Placed </p>
              <p class="tw-text-400 tw-font-bold tw-font-body tw-text-black" i18n="@@yourOrderIsPacked" *ngSwitchCase="orderPackedStages.includes(order.get('stage'))"> Your order is Packed </p>
              <p class="tw-text-400 tw-font-bold tw-font-body tw-text-black" i18n="@@yourOrderIsDispatched" *ngSwitchCase="orderDispatchedStages.includes(order.get('stage'))"> Your order is Dispatched </p>
              <p class="tw-text-400 tw-font-bold tw-font-body tw-text-black" i18n="@@deliveryAttemptFailed" *ngSwitchCase="orderDeliveryFailedStages.includes(order.get('stage'))"> Delivery attempt failed </p>
              <p class="tw-text-400 tw-font-bold tw-font-body tw-text-black" i18n="@@orderOutForDelivery" *ngSwitchCase="orderOutForDeliveryStages.includes(order.get('stage'))"> Your order is out for delivery </p>
              <p class="tw-text-400 tw-font-bold tw-font-body tw-text-black" i18n="@@yourOrderIsDelivered" *ngSwitchCase="orderDeliveredStages.includes(order.get('stage'))"> Your order is Delivered </p>
              <p class="tw-text-400 tw-font-bold tw-font-body tw-text-black" i18n="@@yourOrderIsCancelled" *ngSwitchCase="orderCanceledStages.includes(order.get('stage'))">Your order is Cancelled</p>
            </ng-container>
            <p *ngIf="orderNumber" class="tw-text-200 tw-font-medium tw-font-body tw-text-black tw-capitalize"> <span i18n="@@orderNo">Order no:</span> #{{orderNumber}}</p>
            <div class="tw-text-200 tw-font-medium tw-font-body tw-text-black" *ngIf="expectedDeliveryDate.from && expectedDeliveryDate.to">
              <div i18n="@@expectedDeliveryDate">
                Expected Delivery Date
              </div>
              <p id="expected-delivery-date">
                {{expectedDeliveryDate.from | date: 'd MMM' }} - {{expectedDeliveryDate.to | date: 'd MMM'}}
              </p>
            </div>
          </div>
          <ng-container [ngSwitch]="true">
            <div class="tw-self-center tw-shrink-0">
              <span (click)="makePaymentForOrder()"
                  class="tw-min-w-fit tw-h-max tw-flex tw-justify-center tw-items-center tw-shrink-0 tw-self-end tw-font-bold tw-font-body tw-text-white tw-rounded-xl tw-text-300 tw-overflow-hidden tw-capitalize tw-py-2 tw-px-5 tw-bg-teal-600"
                  i18n="@@makePayment"
                  *ngSwitchCase="orderPaymentPendingStages.includes(order.get('stage'))">
                  Make payment
              </span>

              <div class="tw-self-center"
              *ngSwitchCase="orderPlacedStages.includes(order?.get('stage')) ||
              orderPackedStages.includes(order?.get('stage')) ||
              orderDispatchedStages.includes(order?.get('stage')) ||
              orderDeliveryFailedStages.includes(order?.get('stage')) ||
              orderOutForDeliveryStages.includes(order?.get('stage'))">
                  <span
                    *ngIf="order?.get('trackingId')"
                    (click)='trackOrder(order.get("trackingURL"))'
                    class="tw-min-w-fit tw-h-max tw-flex tw-justify-center tw-items-center tw-shrink-0 tw-self-end tw-font-bold tw-font-body tw-text-white tw-rounded-xl tw-text-300 tw-overflow-hidden tw-capitalize tw-py-2 tw-px-5 tw-bg-teal-600"
                    i18n="@@trackOrder">Track Order</span>
              </div>

              <span (click)="seeInstructions()"
                  class="tw-min-w-fit tw-h-max tw-flex tw-justify-center tw-items-center tw-shrink-0 tw-self-end tw-font-bold tw-font-body tw-text-white tw-rounded-xl tw-text-300 tw-overflow-hidden tw-capitalize tw-py-2 tw-px-5 tw-bg-teal-600"
                  *ngSwitchCase="orderDeliveredStages.includes(order.get('stage'))"
                  i18n="@@seeInstructions">See instructions</span>

              <span (click)="navigateToRegimenPage()"
                  class="tw-min-w-fit tw-h-max tw-flex tw-justify-center tw-items-center tw-shrink-0 tw-self-end tw-font-bold tw-font-body tw-text-white tw-rounded-xl tw-text-300 tw-overflow-hidden tw-capitalize tw-py-2 tw-px-5 tw-bg-teal-600"
                  i18n="@@reorder"
                  *ngSwitchCase="orderCanceledStages.includes(order.get('stage'))">Reorder</span>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="tw-p-4 tw-mt-2 tw-bg-white">
        <p class="tw-text-300 tw-text-black tw-font-bold tw-font-body"> Delivering to {{order.get("contactDetail").contactName }} </p>
        <p class="tw-text-gray-500 tw-text-200 tw-font-normal tw-font-body tw-mt-2">{{order.get("contactDetail").buildingDetails }}, {{order.get("contactDetail")?.localityDetails }} </p>
        <p class="tw-text-gray-500 tw-text-200 tw-font-normal tw-font-body">
          <span *ngIf="order.get('contactDetail')?.landmark"> {{order.get("contactDetail").landmark }}, </span>
          <span *ngIf="order.get('contactDetail')?.city"> {{order.get("contactDetail").city }}, </span>
          <span *ngIf="order.get('contactDetail')?.state"> {{order.get("contactDetail").state }}, </span>
          <span *ngIf="order.get('contactDetail')?.zipCode"> {{order.get("contactDetail").zipCode }} </span>
        </p>
        <p class="tw-text-gray-500 tw-text-200 tw-font-normal tw-mt-2 tw-font-body"> +91 {{order.get("contactDetail").contactNumber }} </p>
      </div>
      <!-- REGIMEN CARD -->
      <div class="tw-px-4" [ngClass]="freeProducts.length > 0 ? 'tw-pt-6' : 'tw-py-6'">
        <div class="tw-p-4 tw-rounded-3 tw-bg-white tw-shadow-z1">
          <div class="tw-flex tw-items-center tw-gap-[5%]" (click)="navigateToRegimenPage()">
            <div class="tw-w-32 tw-min-w-28 tw-h-[120px] tw-bg-teal-100  tw-rounded-3">
              <img [src]="regimen.get('regimenCoverImage')" class="tw-w-32 tw-h-[120px] tw-object-cover tw-rounded-3" alt="regimen_img"/>
            </div>
            <div *ngIf="regimen?.get('displayConcern')?.name" class="tw-flex tw-flex-col tw-gap-2">
              <p class="tw-text-400 tw-font-body tw-font-bold tw-text-black">Treatment <br/> kit for <br/> {{regimen?.get('displayConcern')?.name}} </p>
            </div>
            <div *ngIf="!regimen?.get('displayConcern')?.name">
              <p *ngIf="regimenClassText === appConfig.Shared.RegimenFor.SKIN.toLowerCase()" class="tw-text-400 tw-font-body tw-font-bold tw-text-black"> Treatment <br/> kit for <span class="tw-capitalize"> skin </span> </p>
              <p *ngIf="regimenClassText === appConfig.Shared.RegimenFor.HAIR.toLowerCase()" class="tw-text-400 tw-font-body tw-font-bold tw-text-black"> Treatment <br/> kit for <span class="tw-capitalize"> hair </span> </p>
            </div>
          </div>
          <div class="tw-flex tw-justify-center tw-items-center tw-my-4 tw-py-1.5 tw-border tw-border-teal-600 tw-border-solid tw-rounded-2" (click)="navigateToRegimenPage()">
            <p class="tw-basis-[75%] tw-text-end tw-text-200 tw-font-bold tw-text-teal-600" i18n="@@KnowHowToUseYourProducts"> Know how to use products </p>
            <div class="tw-basis-[25%] tw-text-center"><cs-icon class="tw-h-2.5 tw-w-2.5 -tw-rotate-90" [iconName]="'arrow_forward'"></cs-icon></div>
          </div>
          <div class="tw-mb-4">
            <p class="tw-font-bold tw-text-200 tw-text-black"> Your Plan includes: </p>
             <div class="tw-flex tw-items-center tw-gap-2 tw-mt-1">
               <cs-icon class="tw-w-4 tw-h-4" [iconName]="'rebranding/v1/check'" ></cs-icon>
               <span class="tw-text-200 tw-text-gray-500 tw-font-normal"> {{this.order?.get('regimen')?.get('products')?.length || 4 }} <span class="tw-lowercase" i18n="@@products">Products</span> </span>
             </div>
             <div class="tw-flex tw-items-center tw-gap-2 tw-mt-1.5">
               <cs-icon class="tw-w-4 tw-h-4" [iconName]="'rebranding/v1/check'" ></cs-icon>
               <span class="tw-text-200 tw-text-gray-500 tw-font-normal tw-capitalize" i18n="@@monthlyDoctorCheckup"> Monthly Doctor Checkup </span>
             </div>
             <div class="tw-flex tw-items-center tw-gap-2 tw-mt-1.5">
               <cs-icon class="tw-w-4 tw-h-4" [iconName]="'rebranding/v1/check'" ></cs-icon>
               <span class="tw-text-200 tw-text-gray-500 tw-font-normal" i18n="@@dietPlan"> Diet Plan </span>
             </div>
             <div class="tw-flex tw-items-center tw-gap-2 tw-mt-1.5">
               <cs-icon class="tw-w-4 tw-h-4" [iconName]="'rebranding/v1/check'"></cs-icon>
               <span class="tw-text-200 tw-text-gray-500 tw-font-normal" i18n="@@dedicatedDoctorForAnyTimeSupport"> Dedicated doctor for any time support </span>
             </div>
          </div>
          <!-- Mini Doctor card -->
          <div *ngIf="!orderCanceledStages.includes(order?.get('stage')) && doctor" class="tw-py-2 tw-px-4 tw-rounded-full" style="background: linear-gradient(118deg, #FFF6EC 30.34%, #FFF 95.83%);">
            <div class="tw-flex tw-items-center tw-gap-2">
              <img class="tw-w-9 tw-h-9 tw-rounded-full" [src]="doctor.get('doctorDisplayImage')" alt="doctorImage"/>
              <div>
                <p class="tw-text-orange-400 tw-text-200 tw-font-bold tw-capitalize" i18n="@@yourSkinSpecialist"> Your skin specialist </p>
                <p class="tw-text-black tw-text-200 tw-font-normal"> {{doctor.get('DoctorDisplayName')}} </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Free Products section -->
      <div class="tw-my-2 tw-mx-4">
        <p class="tw-w-max tw-m-auto tw-text-200 tw-text-black tw-font-body tw-font-medium"> + </p>
      </div>

      <div class="tw-flex tw-items-center tw-gap-3 tw-mx-4 tw-p-3 tw-border-2 tw-border-dashed tw-border-teal-400 tw-rounded-2 tw-overflow-hidden tw-bg-gradient-white" *ngFor="let freeProduct of freeProducts">
        <div class="tw-relative tw-flex tw-items-center tw-w-20 tw-h-20 tw-px-2.5 tw-rounded-1 tw-bg-[#ebebeb] tw-overflow-hidden">
          <img [src]="freeProduct.imgUrl" alt="freeProductImg"/>
          <div class="tw-w-[60px] tw-absolute -tw-top-[10px] -tw-left-[25px] tw-aspect-video tw-bg-teal-500 -tw-rotate-45"></div>
          <div class="tw-absolute tw-top-1 tw-left-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 10 9" fill="#FEFEFE">
              <path d="M7.11266 0.320901C7.37189 0.179956 7.69269 0.213418 7.85308 0.326807C7.92286 0.37602 7.9907 0.45988 7.91898 0.580355C7.76829 0.834296 7.4475 0.99808 7.10201 0.99808H6.113C6.15951 1.09572 6.1852 1.20163 6.1852 1.31266C6.1852 1.41148 6.16485 1.50636 6.12753 1.59495H8.45297C8.60803 1.35557 8.61917 1.08076 8.48107 0.828782L8.19759 0.311839C8.16464 0.251995 8.11376 0.196092 8.04447 0.147269C7.80412 -0.0224215 7.33893 -0.08856 6.94983 0.123253L5.88184 0.704369C5.94968 0.761457 6.0083 0.82563 6.05531 0.895709L7.11266 0.320901Z" fill="#FEFEFE"/>
              <path d="M4.08957 1.59494C4.05226 1.50636 4.03191 1.41147 4.03191 1.31265C4.03191 1.20162 4.05759 1.09572 4.10411 0.998073H3.11559C2.77008 0.998073 2.4493 0.833896 2.29812 0.579955C2.22641 0.459874 2.29473 0.376016 2.36402 0.3268C2.45028 0.265775 2.58305 0.227978 2.72794 0.227978C2.85199 0.227978 2.98476 0.255932 3.10445 0.320895L4.16178 0.895712C4.20878 0.825631 4.26741 0.761066 4.33525 0.704372L3.26725 0.123255C2.87766 -0.0885654 2.41248 -0.0224168 2.17262 0.147272C2.09557 0.201604 2.04081 0.264602 2.00883 0.332312L1.73602 0.828392C1.59792 1.07997 1.60907 1.35479 1.76413 1.59456H4.08956L4.08957 1.59494Z" fill="#FEFEFE"/>
              <path d="M4.40459 1.59433H5.8128C5.86465 1.50928 5.89421 1.41322 5.89421 1.31203C5.89421 0.960445 5.54143 0.673828 5.10872 0.673828C4.67601 0.673828 4.32324 0.960456 4.32324 1.31203C4.32324 1.41322 4.35274 1.50928 4.40459 1.59433Z" fill="#FEFEFE"/>
              <path d="M0.459961 2.33128V3.11161C0.459961 3.34587 0.66009 3.54312 0.928533 3.59667H4.56616V1.83203H1.07442C0.735698 1.83203 0.459961 2.05608 0.459961 2.33128Z" fill="#FEFEFE"/>
              <path d="M0.928711 7.39115C0.928711 7.75652 1.29456 8.05377 1.74424 8.05377H4.56636V3.83398H0.928736L0.928711 7.39115Z" fill="#FEFEFE"/>
              <path d="M9.14079 1.83203H5.64941V3.59667H9.28655C9.555 3.54312 9.75512 3.34626 9.75512 3.11161V2.33128C9.75512 2.05607 9.47949 1.83203 9.14079 1.83203Z" fill="#FEFEFE"/>
              <path d="M5.64941 8.05339H8.47104C8.92072 8.05339 9.28657 7.75614 9.28657 7.39077V3.83398H5.64944V8.05377L5.64941 8.05339Z" fill="#FEFEFE"/>
            </svg>
          </div>
        </div>
        <div class="tw-h-full">
          <p>{{freeProduct.name}}</p>
          <p class="tw-text-gray-400 tw-text-100 tw-font-medium tw-mt-2">{{freeProduct.quantity}} unit</p>
          <p class="tw-flex tw-items-center"> <span class="tw-text-gray-500 tw-text-200 tw-font-normal tw-line-through"> ₹{{freeProduct.MRP}} </span> <span class="tw-text-500 tw-text-teal-500 tw-font-bold tw-uppercase tw-ml-1" i18n="@@free">free</span> </p>
        </div>
      </div>

      <!-- Order detail  CTA's  -->
      <div class="tw-px-4 tw-py-6">
        <div class="tw-flex tw-flex-col tw-rounded-2.5 tw-overflow-hidden tw-bg-white tw-shadow-z1 tw-py-1 tw-px-4">
          <div class="tw-flex tw-items-center tw-justify-between tw-py-3 tw-border-b tw-border-gray-200 last:tw-border-none" *ngIf='order.get("signedUrlForPdfInvoice")' (click)='openInvoiceUrl(order.get("signedUrlForPdfInvoice"))'>
            <p class="tw-font-body tw-font-bold tw-text-200" i18n="@@downloadInvoice">
              Download Invoice
            </p>
            <cs-icon class="tw-h-6 tw-w-6" [iconName]="'chevron_right_green'"></cs-icon>
          </div>
          <div class="tw-flex tw-items-center tw-justify-between tw-py-3 tw-border-b tw-border-gray-200 last:tw-border-none" *ngIf="regimenApproved" routerLink="/user/prescription">
            <p class="tw-font-body tw-font-bold tw-text-200" i18n="@@seePrescription">
              See Prescription
            </p>
            <cs-icon class="tw-h-6 tw-w-6" [iconName]="'chevron_right_green'"></cs-icon>
          </div>
          <div class="tw-flex tw-items-center tw-justify-between tw-py-3 tw-border-b tw-border-gray-200 last:tw-border-none" routerLink="/support">
            <p class="tw-font-body tw-font-bold tw-text-200">
              <span i18n="@@needHelp">Need help</span><span>?</span>&nbsp;<span>Chat with us</span>
            </p>
            <cs-icon class="tw-h-6 tw-w-6 tw-text-orange-400" [iconName]="'chevron_right_green'"></cs-icon>
          </div>
          <div class="tw-flex tw-items-center tw-justify-between tw-py-3 tw-border-b tw-border-gray-200 last:tw-border-none" *ngIf="cancelOrderAllowed" (click)="cancelOrderDialog()">
            <p class="tw-font-body tw-font-bold tw-text-200 tw-text-orange-500" i18n="@@cancelOrder">Cancel Order</p>
            <cs-icon class="tw-h-6 tw-w-6 tw-text-orange-500" [iconName]="'chevron_right'"></cs-icon>
          </div>
        </div>
      </div>

      <!-- Payment Details -->
      <div class="tw-flex tw-flex-col tw-bg-white tw-pt-2 tw-gap-1.5">
        <h2 class="tw-font-body tw-font-bold tw-text-300 tw-mb-2 tw-px-4" i18n="@@paymentDetails">
          Payment details
        </h2>
        <div class="tw-flex tw-items-center tw-justify-between tw-px-4">
          <span class="tw-font-body tw-text-200 tw-text-gray-800" i18n="@@paymentMethod">Payment method</span><span class="tw-font-body tw-text-200">{{order.get('paymentType').replace('_', ' ') | titlecase}}</span>
        </div>
        <div class="tw-flex tw-items-center tw-justify-between tw-px-4">
          <span class="tw-font-body tw-text-200 tw-text-gray-800" i18n="@@subtotalInclusiveTax">Subtotal (inclusive tax)</span><span class="tw-font-body tw-text-200">₹{{orderMRP}}</span>
        </div>
        <div class="tw-flex tw-items-center tw-justify-between tw-px-4">
          <span class="tw-font-body tw-text-200 tw-text-gray-800" i18n="@@discount">Discount</span><span class="tw-font-body tw-text-200 tw-text-orange-400">(-) ₹{{+orderMRP - +orderSp}}</span>
        </div>
        <div class="tw-flex tw-items-center tw-justify-between tw-px-4">
          <span class="tw-font-body tw-text-200 tw-text-gray-800" i18n="@@shippingCharges">Shipping charges</span><span class="tw-font-body tw-text-200">₹0</span>
        </div>
        <div class="tw-flex tw-items-center tw-justify-between tw-px-4">
          <span class="tw-font-body tw-font-bold tw-text-300 tw-text-black" i18n="@@total">Total</span><span class="tw-font-body tw-font-bold tw-text-300">₹{{orderSp}}</span>
        </div>
        <div class="tw-flex tw-items-center tw-justify-between tw-p-4 tw-bg-blue-100">
          <span class="tw-font-body tw-text-300 tw-text-green-500" i18n="@@totalSavings">Total Savings:</span><span class="tw-font-body tw-text-300 tw-text-green-500">₹{{+orderMRP - +orderSp}}</span>
        </div>
      </div>
    </div>
    <pop-up-modal *ngIf="popUpModal.open" [config]="popUpModal" (close)="onPopUpClose($event)"></pop-up-modal>
  </ng-template>

  <ng-template #productOrderTemplate>
    <div class="tw-h-full tw-bg-gray-100 tw-overflow-auto" *ngIf="!loading">
      <div class="tw-flex tw-flex-col tw-max-h-full tw-h-full">
        <div class="tw-flex-1 tw-overflow-y-scroll" *ngIf="order">
          <div class="tw-flex tw-flex-col tw-gap-11 tw-px-4 tw-pt-[18px] tw-pb-4" [ngClass]="bgColor">
            <div class="tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-bg-gray-800 tw-rounded-xl" (click)="back()">
              <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'arrow_back'"></cs-icon>
            </div>
            <div class="tw-flex tw-justify-between">
              <div class="tw-flex tw-flex-col tw-gap-1">
                <ng-container [ngSwitch]="true">
                  <p class="tw-text-400 tw-font-bold tw-font-body tw-text-black" i18n="@@yourPaymentIsPending" *ngSwitchCase="orderPaymentPendingStages.includes(order.get('stage'))"> Your payment is pending </p>
                  <p class="tw-text-400 tw-font-bold tw-font-body tw-text-black" i18n="@@yourOrderIsPlaced" *ngSwitchCase="orderPlacedStages.includes(order.get('stage')) || order.get('stage') === appConfig.Shared.Order.Stage.INVOICE_REQUESTED"> Your order is Placed </p>
                  <p class="tw-text-400 tw-font-bold tw-font-body tw-text-black" i18n="@@yourOrderIsPacked" *ngSwitchCase="orderPackedStages.includes(order.get('stage'))"> Your order is Packed </p>
                  <p class="tw-text-400 tw-font-bold tw-font-body tw-text-black" i18n="@@yourOrderIsDispatched" *ngSwitchCase="orderDispatchedStages.includes(order.get('stage'))"> Your order is Dispatched </p>
                  <p class="tw-text-400 tw-font-bold tw-font-body tw-text-black" i18n="@@deliveryAttemptFailed" *ngSwitchCase="orderDeliveryFailedStages.includes(order.get('stage'))"> Delivery attempt failed </p>
                  <p class="tw-text-400 tw-font-bold tw-font-body tw-text-black" i18n="@@orderOutForDelivery" *ngSwitchCase="orderOutForDeliveryStages.includes(order.get('stage'))"> Your order is out for delivery </p>
                  <p class="tw-text-400 tw-font-bold tw-font-body tw-text-black" i18n="@@yourOrderIsDelivered" *ngSwitchCase="orderDeliveredStages.includes(order.get('stage'))"> Your order is Delivered </p>
                  <p class="tw-text-400 tw-font-bold tw-font-body tw-text-black" i18n="@@yourOrderIsCancelled" *ngSwitchCase="orderCanceledStages.includes(order.get('stage'))">Your order is Cancelled</p>
                </ng-container>
                <p *ngIf="orderNumber" class="tw-text-200 tw-font-medium tw-font-body tw-text-black"> Order No: #{{orderNumber}}</p>
                <div class="tw-text-200 tw-font-medium tw-font-body tw-text-black" *ngIf="expectedDeliveryDate.from && expectedDeliveryDate.to">
                  <div i18n="@@expectedDeliveryDate">
                    Expected Delivery Date
                  </div>
                  <p id="expected-delivery-date">
                    {{expectedDeliveryDate.from | date: 'd MMM' }} - {{expectedDeliveryDate.to | date: 'd MMM'}}
                  </p>
                </div>
              </div>
              <ng-container [ngSwitch]="true">
                <div class="tw-self-center tw-shrink-0">
                  <span (click)="makePaymentForOrder()"
                      class="tw-min-w-fit tw-h-max tw-flex tw-justify-center tw-items-center tw-shrink-0 tw-self-end tw-font-bold tw-font-body tw-text-white tw-rounded-xl tw-text-300 tw-overflow-hidden tw-capitalize tw-py-2 tw-px-5 tw-bg-teal-600"
                      i18n="@@makePayment"
                      *ngSwitchCase="orderPaymentPendingStages.includes(order.get('stage'))">
                      Make payment
                  </span>

                  <div class="tw-self-center"
                  *ngSwitchCase="orderPlacedStages.includes(order?.get('stage')) ||
                  orderPackedStages.includes(order?.get('stage')) ||
                  orderDispatchedStages.includes(order?.get('stage')) ||
                  orderDeliveryFailedStages.includes(order?.get('stage')) ||
                  orderOutForDeliveryStages.includes(order?.get('stage'))">
                      <span
                        *ngIf="order?.get('trackingId')"
                        (click)='trackOrder(order.get("trackingURL"))'
                        class="tw-min-w-fit tw-h-max tw-flex tw-justify-center tw-items-center tw-shrink-0 tw-self-end tw-font-bold tw-font-body tw-text-white tw-rounded-xl tw-text-300 tw-overflow-hidden tw-capitalize tw-py-2 tw-px-5 tw-bg-teal-600"
                        i18n="@@trackOrder">Track Order</span>
                  </div>

                  <span (click)="navigateToShoPage()"
                      class="tw-min-w-fit tw-h-max tw-flex tw-justify-center tw-items-center tw-shrink-0 tw-self-end tw-font-bold tw-font-body tw-text-white tw-rounded-xl tw-text-300 tw-overflow-hidden tw-capitalize tw-py-2 tw-px-5 tw-bg-teal-600"
                      i18n="@@reorder"
                      *ngSwitchCase="orderCanceledStages.includes(order.get('stage'))">Reorder</span>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="tw-p-4 tw-mt-2 tw-bg-white">
            <p class="tw-text-300 tw-text-black tw-font-bold tw-font-body"> Delivering to {{order.get("contactDetail").contactName }} </p>
            <p class="tw-text-gray-500 tw-text-200 tw-font-normal tw-font-body tw-mt-2">{{order.get("contactDetail").buildingDetails }}, {{order.get("contactDetail")?.localityDetails }} </p>
            <p class="tw-text-gray-500 tw-text-200 tw-font-normal tw-font-body">
              <span *ngIf="order.get('contactDetail')?.landmark"> {{order.get("contactDetail").landmark }}, </span>
              <span *ngIf="order.get('contactDetail')?.city"> {{order.get("contactDetail").city }}, </span>
              <span *ngIf="order.get('contactDetail')?.state"> {{order.get("contactDetail").state }}, </span>
              <span *ngIf="order.get('contactDetail')?.zipCode"> {{order.get("contactDetail").zipCode }} </span>
            </p>
            <p class="tw-text-gray-500 tw-text-200 tw-font-normal tw-mt-2 tw-font-body"> +91 {{order.get("contactDetail").contactNumber }} </p>
          </div>
          <div class="tw-flex tw-flex-col tw-bg-white tw-mt-4">
            <h2 class="tw-font-body tw-text-300 tw-text-black tw-px-5 tw-pt-5">
              <span>{{order.get('productInfo').length ? totalCumulativeQuantityOfProducts : 0}}&nbsp;</span><span i18n="@@productsInYourOrder">Products in your order</span>
            </h2>
            <div class="tw-flex tw-flex-col tw-gap-4 tw-mb-gray-300 tw-pt-6 tw-pl-5 tw-pr-7 last:tw-border-none last:tw-pb-5" *ngFor="let product of order.get('productInfo')">
              <div class="tw-flex tw-items-end tw-pr-3">
                <img class="tw-h-20 tw-w-auto tw-rounded-xl tw-mr-5" [src]="products[product.id]?.get('rebrandedImageWithBackground')?.[0]" />
                <div class="tw-flex-1 tw-flex tw-flex-col tw-self-stretch tw-justify-between tw-py-2">
                  <h3 class="tw-font-body tw-font-bold tw-text-400 tw-text-black">
                    {{product?.name}}{{!product?.SP && order?.get('type') === appConfig.Shared.Order.Type.REGIMEN ? ': Free' : ''}}
                  </h3>
                  <div class="tw-font-body tw-text-300 tw-text-gray-500">
                    Qty {{product?.quantity}}
                  </div>
                </div>
                <div class="tw-font-body tw-text-300 tw-pb-2" *ngIf="order?.get('type') !== appConfig.Shared.Order.Type.REGIMEN">
                  <span class="tw-line-through tw-gray-500">₹{{product?.MRP}}</span><span class="tw-font-bold tw-text-black">&nbsp;₹{{product?.SP}}</span>
                </div>
              </div>
              <div class="tw-flex tw-gap-3">
                <div class="tw-w-full tw-flex tw-justify-center tw-items-center tw-my-4 tw-py-1.5 tw-border tw-border-teal-600 tw-border-solid tw-rounded-2"
                  *ngIf="!orderCanceledStages.includes(order.get('stage')) && product?.SP" (click)="handleNavigation(product)">
                  <p class="tw-basis-[50%] tw-text-end tw-text-200 tw-font-bold tw-text-teal-600" i18n="@@seeInstructions">See instructions</p>
                  <div class="tw-basis-[25%] tw-text-center"><cs-icon class="tw-h-2.5 tw-w-2.5 -tw-rotate-90" [iconName]="'arrow_forward'"></cs-icon></div>
                </div>

                <div class="tw-flex-1 tw-h-8 tw-flex tw-gap-2 tw-items-center tw-justify-center tw-border tw-border-black tw-rounded-full"
                  *ngIf="!orderCanceledStages.includes(order.get('stage'))&& !product?.SP && order?.get('type') === appConfig.Shared.Order.Type.REGIMEN" (click)="handleNavigation(product)">
                  <span class="tw-font-body tw-font-bold tw-text-200 tw-text-green-500 tw-lowercase" i18n="@@view">View</span><cs-icon class="tw-h-4 tw-w-4 tw-text-black" [iconName]='"chevron_right"'></cs-icon>
                </div>
              </div>
            </div>
          </div>

          <!-- Order detail  CTA's      -->
          <div class="tw-px-4 tw-py-6">
            <div class="tw-flex tw-flex-col tw-rounded-2.5 tw-overflow-hidden tw-bg-white tw-shadow-z1 tw-py-1 tw-px-4">
              <div class="tw-flex tw-items-center tw-justify-between tw-py-3 tw-border-b tw-border-gray-200 last:tw-border-none" *ngIf='order.get("signedUrlForPdfInvoice")' (click)='openInvoiceUrl(order.get("signedUrlForPdfInvoice"))'>
                <p class="tw-font-body tw-font-bold tw-text-200" i18n="@@downloadInvoice">
                  Download Invoice
                </p>
                <cs-icon class="tw-h-6 tw-w-6" [iconName]="'chevron_right_green'"></cs-icon>
              </div>
              <div class="tw-flex tw-items-center tw-justify-between tw-py-3 tw-border-b tw-border-gray-200 last:tw-border-none" *ngIf="regimenApproved" routerLink="/user/prescription">
                <p class="tw-font-body tw-font-bold tw-text-200" i18n="@@seePrescription">
                  See Prescription
                </p>
                <cs-icon class="tw-h-6 tw-w-6" [iconName]="'chevron_right_green'"></cs-icon>
              </div>
              <div class="tw-flex tw-items-center tw-justify-between tw-py-3 tw-border-b tw-border-gray-200 last:tw-border-none" routerLink="/support">
                <p class="tw-font-body tw-font-bold tw-text-200">
                  <span i18n="@@needHelp">Need help</span><span>?</span>&nbsp;<span>Chat with us</span>
                </p>
                <cs-icon class="tw-h-6 tw-w-6 tw-text-orange-400" [iconName]="'chevron_right_green'"></cs-icon>
              </div>
              <div class="tw-flex tw-items-center tw-justify-between tw-py-3 tw-border-b tw-border-gray-200 last:tw-border-none" *ngIf="cancelOrderAllowed" (click)="cancelOrderDialog()">
                <p class="tw-font-body tw-font-bold tw-text-200 tw-text-orange-500" i18n="@@cancelOrder">Cancel Order</p>
                <cs-icon class="tw-h-6 tw-w-6 tw-text-orange-500" [iconName]="'chevron_right'"></cs-icon>
              </div>
            </div>
          </div>

          <!-- Payment Details -->
          <div class="tw-flex tw-flex-col tw-bg-white tw-pt-2 tw-gap-1.5">
            <h2 class="tw-font-body tw-font-bold tw-text-300 tw-mb-2 tw-px-4" i18n="@@paymentDetails">
              Payment details
            </h2>
            <div class="tw-flex tw-items-center tw-justify-between tw-px-4">
              <span class="tw-font-body tw-text-200 tw-text-gray-800" i18n="@@paymentMethod">Payment method</span><span class="tw-font-body tw-text-200">{{order.get('paymentType').replace('_', ' ') | titlecase}}</span>
            </div>
            <div class="tw-flex tw-items-center tw-justify-between tw-px-4">
              <span class="tw-font-body tw-text-200 tw-text-gray-800" i18n="@@subtotalInclusiveTax">Subtotal (inclusive tax)</span><span class="tw-font-body tw-text-200">₹{{orderMRP}}</span>
            </div>
            <div class="tw-flex tw-items-center tw-justify-between tw-px-4">
              <span class="tw-font-body tw-text-200 tw-text-gray-800" i18n="@@discount">Discount</span><span class="tw-font-body tw-text-200 tw-text-orange-400">(-) ₹{{+orderMRP - +orderSp}}</span>
            </div>
            <div class="tw-flex tw-items-center tw-justify-between tw-px-4">
              <span class="tw-font-body tw-text-200 tw-text-gray-800" i18n="@@shippingCharges">Shipping charges</span><span class="tw-font-body tw-text-200">₹0</span>
            </div>
            <div class="tw-flex tw-items-center tw-justify-between tw-px-4">
              <span class="tw-font-body tw-font-bold tw-text-300 tw-text-black" i18n="@@total">Total</span><span class="tw-font-body tw-font-bold tw-text-300">₹{{orderSp}}</span>
            </div>
            <div class="tw-flex tw-items-center tw-justify-between tw-p-4 tw-bg-blue-100">
              <span class="tw-font-body tw-text-300 tw-text-green-500" i18n="@@totalSavings">Total Savings:</span><span class="tw-font-body tw-text-300 tw-text-green-500">₹{{+orderMRP - +orderSp}}</span>
            </div>
          </div>

        </div>
      </div>
    </div>
    <pop-up-modal *ngIf="popUpModal.open" [config]="popUpModal" (close)="onPopUpClose($event)"></pop-up-modal>
  </ng-template>
</ng-template>
