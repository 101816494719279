import {
  AfterViewChecked, ChangeDetectorRef, Component,
  ElementRef, Input, NgZone, OnDestroy, ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs/index';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { ConnectionService } from '@services/connection-service';
import { DataStoreService } from '@services/data-store-service';
import { BroadcastService } from '@services/broadcast-service';
import { WindowRefService } from '@services/window-ref-service';
import { TimeService } from '@services/time-service';
import { EventLoggerService } from '@services/event-logger-service';
import { CommonUtilityHelper } from '@services/common-utility-helper/common-utility-helper';
import { ImageService } from '@services/image-service';
import { CurrentComponentService } from '@services/current-component';
import { AppStateI } from '@store/reducers';
import { fromActions } from '@store/actions';
import { fromSelectors } from '@store/selectors';
import { ApiClientConstant, ParseKeys, RequestQueryPayload, Table } from 'api-client';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import SwiperCore, { Scrollbar, Autoplay, SwiperOptions, Pagination, Swiper } from 'swiper';
import { SwiperComponent } from 'swiper_angular';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { IncompleteChatSheetComponent } from '@shared/bottom-sheet-layouts/incomplete-chat/incomplete-chat-sheet.component';
import { FollowUpChatSheetComponent } from '@shared/bottom-sheet-layouts/followup-chat/followup-chat-sheet.component';
import { PrivacyPolicySheetComponent } from '@shared/bottom-sheet-layouts/privacy-policy/privacy-policy-sheet.component';
import { HomePageSheetComponent } from '@shared/bottom-sheet-layouts/homepage-bottom-sheet/home-page-bottom-sheet.component';
import { CureskinCashSheetComponent } from '@shared/bottom-sheet-layouts/cureskin-cash-sheet/cureskin-cash-sheet.component';
import { AnytimeFollowupComponent } from '@shared/bottom-sheet-layouts/anytime-followup/anytime-followup.component';
import { LocalStorageService } from '@services/local-storage-service';
import { TakePhotoBannerService } from '@services/banner-services/take-photo-banner-service';
import { InstantCheckupHelper } from 'client/helper/instant-checkup-helper';
import { AppConfig } from '../../../app.config';

SwiperCore.use([Autoplay, Scrollbar, Pagination]);

@Component({
  selector: 'user-home',
  templateUrl: './user-home.html',
  styleUrls: ['./user-home.scss'],
})
export class UserHomeComponent implements OnDestroy, AfterViewChecked {
  @ViewChild('swiperWrapper', { static: false }) swiper: SwiperComponent;
  @ViewChild('swiperWrapperOne', { static: false }) swiperOne: SwiperComponent;
  @ViewChild('swiperWrapperTwo', { static: false }) swiperTwo: SwiperComponent;
  static BannerOrder: Record<string, number> = {
    doctorOrder: 1,
    followUpReport: 2,
    cartItems: 3,
  };
  isTabActive: boolean;
  @ViewChild('homePageContainer', { static: true }) homePageContainer: ElementRef;
  isAovSaleLive: boolean = false;
  @Input('refresh')
  set refresh(isActive: boolean) {
    this.isTabActive = isActive;
    this.refreshComponent();
  }
  @Input('animation') animation: any;
  @Input('tabUpdates') tabUpdates: any;
  user: any;
  userAge: string;
  showFollowUp: boolean = false;
  saleBannerImage: any;
  aovBannerImage: any;
  cashDiscount: any;
  cart: any;
  cartItems: any = [];
  userStatus: any = { PAID: false, GOT_REGIMEN: false, NO_PHOTO: false };
  skinBannerIndex: number = -1;
  hairBannerIndex: number = -1;
  resetKitReadyBannerHeight: boolean = false;
  userName: string;
  userFirstName: string;
  toolbarIcons: Array<any> = [{
    clickId: 'bell_notification',
    name: this.appConfig.Toolbar.ICONS.NOTIFICATION,
    callback: (): void => this.notificationClickAction(),
  }, {
    clickId: 'cart_page',
    name: this.appConfig.Toolbar.ICONS.CART,
    callback: (): void => this.goToCart(),
    cartValue: null,
  }];
  isUnReadFollowUpReport: boolean;
  tipOfDay: any;
  isMenuOpen: boolean;
  tempCashBalance: any;
  regimens: Array<any> = [];
  order: any;
  faqs: any = {};
  experiments: any = [];
  notification: any = { message: '', type: '' };
  bannersTop: Array<any> = [];
  remainingFollowUpDays: number;
  showGameBanner: boolean = false;
  bannersBottom: Array<any> = [];
  loading: boolean = true;
  latestUnBoughtRegimen: any = {};
  subscriptions: Array<Subscription> = [];
  isRegimenBeingPrepared: boolean;
  offerBanner: any = {};
  showUnBoughtRegimen: boolean = false;
  blogs$: Observable<object[]>;
  supportTicket: any;
  orderChat: any;
  notListenedVoiceMessage: any;
  userDoctor: any;
  reorderProducts$: Observable<object[]> = this.store.select(fromSelectors.selectHomePageReorderProducts);
  addOnProducts$: Observable<object[]> = this.store.select(fromSelectors.selectHomePageAddons);
  showClaimCashBanner: boolean;
  showAMKitBanner: boolean = false;
  redirectArticleId: string = '';
  articleBannerImage: string = '';
  showFullBanner: boolean = false;
  showDietBanner: boolean = false;
  isDoctorOrder: boolean = false;
  doctorOrder: any;
  showDoctorBanner: boolean = false;
  showOrderDeliveredBanner: boolean = false;
  allActiveOrders: any;
  isBOGOSaleLive: boolean = false;
  isSaleLive: boolean = false;
  bannerImage: any;
  faceIconUrl: string = 'https://cdn.cureskin.com/app/img/female-face.png';
  bodyIconUrl: string = 'https://cdn.cureskin.com/app/img/female-body.png';
  hairIconUrl: string = 'https://cdn.cureskin.com/app/img/female-hair.png';
  showAlternativeColorForPouchImage: boolean = false;
  activeBannerIndex: number = 0;
  activeBannerIndexOne: number = 0;
  bannerContainer: Array<any> = [];
  bannerContainerOne: Array<any> = [];
  bannerContainerTwo: Array<any> = [];
  requestDataInTicket: boolean = false;
  replyInTicket: boolean = false;
  is2499Upsell: boolean = false;
  bottomSheetPriority: Array<String> = [];
  carouselConfig: SwiperOptions = {
    slidesPerView: 1,
    autoHeight: true,
    navigation: { prevEl: 'nonext', nextEl: 'nonext' },
    pagination: true,
    autoplay: { delay: 6000, disableOnInteraction: false },
    on: {
      slideChangeTransitionStart: (event:Swiper):any => {
        this.onSlideChange2(event);
      },
    },
  };
  isMainSwiperMounted: boolean = false;
  bottomCarouselConfig: SwiperOptions = {
    slidesPerView: 1,
    pagination: true,
    autoplay: { delay: 6000, disableOnInteraction: false },
  };
  carouselConfigOrderStatus: SwiperOptions = {
    slidesPerView: 1,
    navigation: { prevEl: 'nonext', nextEl: 'nonext' },
    pagination: {
      el: '#swiper-pagination',
      clickable: true,
      renderBullet: (index: number, className: string): string => `<span class="${className}"></span>`,
    },
    autoplay: { delay: 6000, disableOnInteraction: false },
  };
  carouselConfigTwo: SwiperOptions = {
    slidesPerView: 1,
    autoHeight: true,
    navigation: { prevEl: 'nonext', nextEl: 'nonext' },
    pagination: true,
    autoplay: { delay: 1000, disableOnInteraction: false },
  };
  followUp: any;
  isWeeklyJourneyExperiment: boolean = false;
  isJourneyCardOne: boolean = false;
  isWeekOne: boolean = false;
  isJourneyCardTwo: boolean = false;
  expectedDeliveryDate: { from?: Date, to?: Date } = {};
  deliveryIntervalInDays: number = 2;
  latestFaceRegimenDelivered: any;
  faceDisplayConcern: string;
  hairDisplayConcern: string;
  primaryConcern: string = '';
  otherConcerns: string = '';
  faceRegimenOrderPlaced: any = {};
  faceRegimenOrderDelivered: any = {};
  hairRegimenOrderDelivered: any = {};
  pendingCheckInCall: any;
  isJourneyCardThree: boolean = false;
  isJourneyCardFour: boolean = false;
  isWeekThreeJourneyCard: boolean = false;
  isWeekFourJourneyCard: boolean = false;
  trackingURL: any;
  latestPlacedRegimenOrder: any;
  userDrImage: any;
  unreadNotifications: boolean = false;
  rebrandedLevelFourBanners: boolean = false;
  carouselConfigOne: SwiperOptions = {
    slidesPerView: 1,
    autoHeight: true,
    navigation: { prevEl: 'nonext', nextEl: 'nonext' },
    pagination: false,
    loop: false,
    autoplay: { delay: 6000, disableOnInteraction: false },
  };

  carouselConfigWeeklyJourney: SwiperOptions = {
    slidesPerView: 'auto',
    navigation: { prevEl: 'nonext', nextEl: 'nonext' },
  };
  defaultDoctorImage: string;
  drOrderBannerHome: boolean = false;
  productAddedByScript: any;
  allocatedDoctor: Table.User;
  showStickyBars$: boolean = false;
  showFollowupBanner$: boolean = false;
  showIncompleteChatPopup: boolean = false;
  showFollowupPopup: boolean = false;
  showOrderStatusBanner: boolean = false;
  showDeliveredBanner: boolean = false;
  consultationSession: any;
  followUpReport: any;
  latestOrder: any;
  regimensClass: string[];
  indexOfHairRegimen: any;
  indexOfFaceRegimen: any;
  totalCashBalance: any;
  showCashBalance: boolean;
  minimumCash: any;
  reportWaitingTime: string;
  regimenKit: string;
  selectedFollowUpBanner: 'followUpScheduled' | 'followUpScheduledInTwoDays' | 'followUpReadyWaitingForReport' | 'followUpWaitingForImage'
    | 'anyTimeFollowUpEnabled' | 'lastFollowUpSkipped' | 'followUpReportIsReady';
  feedback: any;
  concernClass: string;
  // Boost your regimen
  isBoostYourRegimenExperiment: boolean = false;
  voiceNoteByScript: boolean = false;
  productsInCart: any[] = [];
  recommendedItems: any[] = [];
  voiceMessageOrder: any;
  cartDiscount: number = 0;
  showOrderStatus: any;
  totalMRP: number = 0;
  totalSP: number = 0;
  isMultipleQuestionQuiz: boolean = false;
  reAcquisitionBannerType: 'reAcquisitionBannerReady' | 'waitingForReAcquisitionCall'
    | 'reAcquisitionWaitingForPhoto' | 'missedReAcquisitionCall';
  showUpdatedPrivacyPopup: boolean = false;
  festivalDelayBannerEnabled: boolean = false;
  startTimer:number;
  isRegimenPreparing: boolean = false;
  isiOSDevice: boolean = false;
  voiceNoteBannerDetails: { voiceUrl?: string, doctorDisplayImageURL?: string, trackEventAudio?: {[key: string]: unknown} } = {};
  isRebrandingHomeIcon: boolean = false;
  isHairThinningCrossSellBanner: boolean = false;
  isHairFallCrossSellBanner: boolean = false;
  isHairFallCrossSellMaleBanner: boolean = false;
  isRebrandingBanner: boolean = false;
  isHairChatPending: boolean = false;
  hairChatId: string;
  faceChatId: string;
  isFaceChatPending: boolean = false;
  newChatBannerExperiment: boolean = false;
  newChatBannerWithImageExperiment: boolean = false;
  showSuggestedForYouBanner: boolean = false;
  takePhotoBannerPositioningExperiment: boolean = false;
  takePhotoBannerPositionBottom: boolean = true;
  newRegimenPricingExperiment: any;
  isRegimenExtraProductBannerVisible: boolean = false;
  latestUnDelivereRegimen: any;
  levelTwoBannerExperiment: boolean = false;
  shouldShowTakePhotoBanner: boolean = false;
  instantCheckups: Array<Object> = [];
  takePhotoBannerTrigerringStates:Record<string, boolean> = {
    orderWaitingForImage: false,
    orderPlacedWithoutCheckup: false,
    firstRegimenWithoutCheckup: false,
  };
  userLanguage: string = this.appConfig.Shared.Languages.EN;
  acquisitonWfiBanner:boolean = false;
  activeTakePhotoBannerState: any;

  constructor(private router: Router,
              private conn: ConnectionService,
              private dataStore: DataStoreService,
              private broadcast: BroadcastService,
              private window: WindowRefService,
              private route: ActivatedRoute,
              private dom: DomSanitizer,
              private timeService: TimeService,
              private eventLogger: EventLoggerService,
              public appConfig: AppConfig,
              private commonUtil: CommonUtilityHelper,
              public imageService: ImageService,
              private currentComponentService: CurrentComponentService,
              private changeDetectorRef: ChangeDetectorRef,
              private zone: NgZone,
              private appWebBridgeService: AppWebBridgeService,
              private windowRef: WindowRefService,
              private store: Store<AppStateI>,
              private readonly bottomSheet: MatBottomSheet,
              private readonly localStorage: LocalStorageService,
              private readonly takePhotoBannerService: TakePhotoBannerService,
              private readonly instantCheckupHelper: InstantCheckupHelper,
              private readonly localStorageService: LocalStorageService,
  ) {
    this.currentComponentService.set(this);
  }

  async ngOnInit(): Promise<void> {
    this.startTimer = new Date().getTime();
    this.user = this.conn.getActingUser();
    this.userLanguage = this.user.get('languagePreference');
    // this.userLanguage = 'ta';
    this.userAge = this.user?.get('Age');
    this.userName = this.user?.get('PatientName');
    this.userFirstName = this.userName?.split(' ')[0];
    try {
      if (!this.user?.get('allocatedDoctor')) this.conn.getActingUser().fetch();
    } catch (error: any) {
      if (error.code === 209 || error.code === 206) {
        this.eventLogger.trackEvent('iosWhiteScreenIssueHomeInit');
        await this.router.navigate(['/logout'], { replaceUrl: true });
      }
    }
    await this.getCart();
    await this.fetchUserActiveExperiments();
    await this.getInitialOrderDetails();
    await this.fetchRegimens();
    if (this.isWeeklyJourneyExperiment) {
      await this.showWeeklyJourneyCard();
    }
    await this.setRegimenPreprationBannerState();

    if (this.newRegimenPricingExperiment) {
      this.isRegimenExtraProductBannerVisible = this.regimens
        ?.some((regimen: any): boolean => !regimen.isPreparationTimePending
        && !regimen.active && !regimen.orderPlaced && regimen.class !== this.appConfig.Shared.Regimen.Class.BODY);
    }

    await this.fetchAllocatedDoctorForUser();
    this.userDrImage = this.allocatedDoctor?.get('doctorDisplayImage') || this.allocatedDoctor?.get('DoctorImageThumbnail')
      || 'https://cdn.cureskin.com/app/img/dr-charu-main-concern.png';
    this.findUserStatus();
    await this.fetchCashBalance();
    await this.fetchDiscount();
    this.setGenderIcons();
    this.toolbarIcons[1].homePage = true;
    this.toolbarIcons[1].showCartUI = true;
    if (this.takePhotoBannerPositioningExperiment) {
      this.takePhotoBannerPositionBottom = this.user.get('InstantCheckupTaken') || false;
    }
    await this.loadIncompleteChatBannerData();
    await this.handleUserPaidStatus();
    this.regimenKit = this.user?.get('PrivateMainConcernClass') || 'FACE';
    if (!this.userStatus.PAID) {
      await this.fetchInstantCheckups();
    }
    if (this.acquisitonWfiBanner) {
      await this.renderTheTakePhotoBanner();
    }
    try {
      await this.fetchDataInParallel();
      this.loading = false;
    } catch (error) {
      this.loading = false;
      this.eventLogger.logError(error);
    }
    this.appWebBridgeService.clearHistory();
    this.defaultDoctorImage = 'https://cdn.cureskin.com/doctor/thumbnail/Dr.charu.jpg';
    this.checkForPolicyUpdate();
    this.newRegimenBannerRenderLogic();
    setTimeout((): void => this.openBottomSheet(), 1000);
    const time = new Date().getTime() - this.startTimer;
    this.eventLogger.trackEvent('home_page_loading_time', { userId: this.user.id, timeInMilliSec: time });
    this.isiOSDevice = this.appWebBridgeService.requestOSInformation() === 'iOS' || this.window.isSafariBrowser();
    this.changeDetectorRef.detectChanges();
  }

  async fetchInstantCheckups():Promise<void> {
    const type = [this.appConfig.Shared.InstantCheckup.Type.FRONT_FACE];
    this.instantCheckups = await this.conn.fetchInstantCheckup({ userId: this.user.get('username'), type, limit: 5 });
  }

  async renderTheTakePhotoBanner(): Promise<void> {
    if (this.userStatus.PAID) {
      this.shouldShowTakePhotoBanner = false;
      return;
    }

    const frontFaceInstantCheckups = this.instantCheckupHelper.getCheckupsByType(this.instantCheckups,
      this.appConfig.Shared.InstantCheckup.Type.FRONT_FACE);

    this.shouldShowTakePhotoBanner = this.takePhotoBannerService.shouldRenderBanner(frontFaceInstantCheckups,
      this.regimens,
      this.allActiveOrders);

    if (this.shouldShowTakePhotoBanner) {
      this.takePhotoBannerTrigerringStates = this.takePhotoBannerService.getBannerTriggeringStates();
      this.activeTakePhotoBannerState = Object.entries(this.takePhotoBannerTrigerringStates).find(
        ([key, value]: [string, boolean]):any => value === true)?.[0];
      this.eventLogger.trackEvent('upload_photo_banner_displayed',
        { username: this.user.get('username'), banner_type: this.activeTakePhotoBannerState },
      );
    } else {
      this.eventLogger.trackEvent('upload_photo_banner_not_displayed', { username: this.user.get('username') });
    }
  }

  async handleTakePhotoBannerClick(): Promise<void> {
    this.eventLogger.trackEvent('upload_photo_banner_clicked', { username: this.user.get('username'),
      banner_type: this.activeTakePhotoBannerState });

    const redirectUrl = this.router.url;
    this.localStorageService.setValue('CureSkin/redirectUrl', redirectUrl);
    const tag = this.appConfig.Shared.InstantCheckup.Type.FRONT_FACE;
    this.router.navigate(['user/instantCheckup/preview'], { queryParams: { tag, fromTakePhotoBanner: 'true' } });
  }

  onAfterInitOfMainSwiper(): void {
    setTimeout((): void => {
      this.isMainSwiperMounted = true;
    }, 3000);
  }

  async fetchAllocatedDoctorForUser(): Promise<void> {
    if (this.user.get('allocatedDoctor')?.id) {
      this.allocatedDoctor = await this.conn.findUserByObjectId(this.user.get('allocatedDoctor').id);
    }
  }

  async fetchCashBalance(): Promise<void> {
    this.minimumCash = this.appConfig.Shared.experimentVariants.SHOP_PAGE_CASH_VISIBILITY.minCash;
    this.totalCashBalance = await this.conn.fetchCashBalance();
    if (this.totalCashBalance >= this.minimumCash && this.userStatus.PAID) {
      this.showCashBalance = true;
    }
  }

  checkForPolicyUpdate(): void {
    if (this.user) {
      const userPrivacyLogDates = this.user?.get('termsOfServiceAcceptedDates');
      if (userPrivacyLogDates && userPrivacyLogDates.length) {
        const lastLog = [...userPrivacyLogDates].pop();
        const date1 = lastLog;
        const date2 = new Date();
        const daysDifference = this.timeService.getDaysDifference(date1, date2);
        if (daysDifference >= 365) this.openPrivacyPolicySheet();
      } else this.openPrivacyPolicySheet();
    }
  }

  setRegimenPreprationBannerState(): any {
    this.regimens = this.regimens.map((regimen: any): any => {
      if (!regimen.activeFrom) return { ...regimen };
      const activeTime: any = new Date(regimen.activeFrom?.iso);
      const curTime: any = new Date();
      if (activeTime - curTime > 0) {
        setTimeout(():void => {
          this.setRegimenPreprationBannerState();
        }, activeTime - curTime);
      }
      return {
        ...regimen,
        isPreparationTimePending: activeTime - curTime > 0,
      };
    });
  }

  async openPrivacyPolicySheet(): Promise<void> {
    if (this.conn.isInternalUser()) return;
    this.showUpdatedPrivacyPopup = true;
    setTimeout((): void => {
      this.bottomSheet.open(PrivacyPolicySheetComponent, {
        data: {
          callback: async (): Promise<void> => {
            await this.conn.logPrivacyPolicyDate();
          },
        },
        disableClose: true,
      });
    }, 1000);
  }

  // eslint-disable-next-line complexity
  async checkForReAcquisitionBannerAndResponse(): Promise<void> {
    if (this.regimens?.length) return;

    // if user total amount spent is lesser than minimumSpentAmount of experiment then we should not show re-acq banner
    if ((this.user?.get('totalAmountSpent') || 0) < this.appConfig.Shared.User.MinimumAmountSpentFromReAcq) {
      return;
    }
    const callPending = await this.conn.getCountOfUserPendingCall({
      where: {
        user: this.user,
        status: this.appConfig.Shared.PendingCall.Status.CallCompleted,
        createdAt: { $gte: this.timeService.removeMonths(new Date(), 4) },
      },
    });
    let reAcquisitionResponse = this.user?.get('reAcquisitionResponse');
    if (!reAcquisitionResponse) {
      const res = { totalReAcquisitionResponse: 0, reAcquisitionResponseYes: false };
      reAcquisitionResponse = res;
    }
    if (reAcquisitionResponse.lastReAcquisititionResponseTime
      && (this.timeService.differenceInDays(new Date(), new Date(reAcquisitionResponse.lastReAcquisititionResponseTime)) >= 120)) {
      reAcquisitionResponse.totalReAcquisitionResponse = 0;
      reAcquisitionResponse.reAcquisitionResponseYes = false;
      delete reAcquisitionResponse.lastReAcquisititionResponseTime;
      this.user.set('reAcquisitionResponse', reAcquisitionResponse);
      await this.user.save();
    }
    if (!reAcquisitionResponse?.totalReAcquisitionResponse) reAcquisitionResponse.totalReAcquisitionResponse = 0;
    if (this.user?.get('marketingTags')?.includes(this.appConfig.Shared.MarketingTags.Type.ON_REGIMEN)
      || this.user?.get('orderState') !== this.appConfig.Shared.User.OrderState.DELIVERED
      || callPending) {
      return;
    }
    if (reAcquisitionResponse?.totalReAcquisitionResponse < 2 && !reAcquisitionResponse.reAcquisitionResponseYes) {
      let showFollowUpBanner$;
      this.store.select(fromSelectors.selectHomePageState).subscribe((data: any): void => {
        showFollowUpBanner$ = data.showFollowUpBanner;
      });
      this.showFollowupPopup = showFollowUpBanner$;
      if (this.showFollowupPopup) {
        this.bottomSheetPriority.push('FollowUpChatSheetComponent');
      }
    }
    const lastReAcquisitionCall = await this.conn.findOnePendingCall({
      where: {
        user: this.user,
        type: this.appConfig.Shared.PendingCall.Type.RE_ACQUISITION_CALL,
        createdAt: { $gte: this.timeService.removeMonths(new Date(), 1) },
      },
      descending: 'requestTime',
      project: ['status', 'requestTime'],
    });
    if (!lastReAcquisitionCall) {
      this.reAcquisitionBannerType = 'reAcquisitionBannerReady';
      return;
    }
    if (lastReAcquisitionCall?.get('status') === this.appConfig.Shared.PendingCall.Status.CallCompleted) {
      return;
    }
    switch (lastReAcquisitionCall?.get('status')) {
      case this.appConfig.Shared.PendingCall.Status.Requested: {
        this.reAcquisitionBannerType = 'waitingForReAcquisitionCall';
        break;
      }
      default: {
        if (lastReAcquisitionCall?.get('status') !== this.appConfig.Shared.PendingCall.Status.CallCompleted
          && (this.timeService.differenceInDays(new Date(), lastReAcquisitionCall?.get('requestTime')) <= 2)) {
          this.reAcquisitionBannerType = 'missedReAcquisitionCall';
        }
      }
    }
  }

  async openReAcquisitionPage(): Promise<void> {
    if (this.reAcquisitionBannerType === 'reAcquisitionBannerReady') {
      const oldReAcquisitionResponse = this.user?.get('reAcquisitionResponse') || { totalReAcquisitionResponse: 0 };
      const updatedReAcquisitionResponse = {
        totalReAcquisitionResponse: oldReAcquisitionResponse.totalReAcquisitionResponse += 1,
        reAcquisitionResponseYes: true,
        lastReAcquisititionResponseTime: new Date().toISOString(),
      };
      this.user.set('reAcquisitionResponse', updatedReAcquisitionResponse);
      await this.user.save();
    }
    if (this.reAcquisitionBannerType === 'missedReAcquisitionCall') {
      this.router.navigate(['/support']);
      return;
    }
    this.router.navigate(['/re-acquisition/user-query']);
  }

  setGenderIcons(): void {
    const gender = this.user?.get('Gender');
    if (gender === this.appConfig.Shared.Gender.MALE) {
      this.faceIconUrl = 'https://cdn.cureskin.com/app/img/rebranded-male-face.svg';
      this.hairIconUrl = 'https://cdn.cureskin.com/app/img/rebranded-male-hair.svg';
      this.bodyIconUrl = 'https://cdn.cureskin.com/app/img/Male%20Body_1.png';
    } else if (gender === this.appConfig.Shared.Gender.FEMALE) {
      this.faceIconUrl = 'https://cdn.cureskin.com/app/img/rebranded-female-face.svg';
      this.hairIconUrl = 'https://cdn.cureskin.com/app/img/rebranded-female-hair.svg';
    }
  }

  private async handleGameNewExperiment(): Promise<void> {
    const question = await this.conn.fetchQuizQuestions();
    if (question.length) {
      this.showGameBanner = true;
    }
  }

  private async fetchUserActiveExperiments(): Promise<void> {
    this.experiments = await this.conn.findUserActiveExperiments();
    // eslint-disable-next-line complexity
    this.experiments?.forEach(async (experiment: any): Promise<void> => {
      switch (experiment.key) {
        case 'show_diet_banner':
          this.showDietBanner = true;
          break;
        case '2499_upsell':
          this.is2499Upsell = true;
          break;
        case 'game_new':
          await this.handleGameNewExperiment();
          break;
        case 'game_quiz_multiple_questions':
          this.isMultipleQuestionQuiz = true;
          break;
        case 'buy1_get1_sale': {
          this.saleBannerImage = experiment.variant.bannerImage;
          const saleProds = await this.conn.findBogoSaleProds();
          if (saleProds?.length) {
            this.isBOGOSaleLive = true;
          }
          break;
        }
        case 'aov_gamification':
          this.aovBannerImage = experiment.variant.bannerImagesMultiple[this.user?.get('languagePreference')]
            || experiment.variant.bannerImagesMultiple?.en;
          if (this.aovBannerImage) this.isAovSaleLive = true;
          break;
        case 'sale_offers_retention':
          this.bannerImage = experiment.variant.bannerImage;
          this.isSaleLive = true;
          break;
        case 'doctor_order_banner':
          this.drOrderBannerHome = true;
          break;
        case 'boost_your_regimen':
          this.isBoostYourRegimenExperiment = true;
          break;
        case 'order_status_banners':
          await this.loadOrders();
          break;
        case 'festival_delay_banner_enabled':
          this.festivalDelayBannerEnabled = true;
          break;
        case 'weekly_journey':
          this.isWeeklyJourneyExperiment = true;
          break;
        case 'rebranding_home_top_icon':
          this.isRebrandingHomeIcon = true;
          break;
        case 'hair_thinning_cross_sell_banner':
          this.isHairThinningCrossSellBanner = true;
          break;
        case 'hair_fall_cross_sell_banner':
          this.isHairFallCrossSellBanner = true;
          break;
        case 'hair_fall_cross_sell_male_banner':
          this.isHairFallCrossSellMaleBanner = true;
          break;
        case 'rebranding_v1_banner':
          this.isRebrandingBanner = true;
          break;
        case 'voice_note_by_script':
          this.fetchCartProductAddedByScript();
          break;
        case 'level_four_banners':
          this.rebrandedLevelFourBanners = true;
          break;
        case 'new_chat_progress_banner':
          this.newChatBannerExperiment = true;
          break;
        case 'new_chat_progress_banner_image':
          this.newChatBannerWithImageExperiment = true;
          break;
        case 'level_two_banner':
          this.levelTwoBannerExperiment = true;
          break;
        case 'add_extra_regimen_products':
          this.newRegimenPricingExperiment = experiment;
          break;
        case 'photo_banner_user_bassed_positioning':
          this.takePhotoBannerPositioningExperiment = true;
          break;
        case 'acquisition_waiting_for_image_banner':
          this.acquisitonWfiBanner = true;
          break;
        default:
          break;
      }
    });
  }

  private async handleUserPaidStatus(): Promise<void> {
    if (this.user.isPaid()) {
      this.showDietBanner = true;
      this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'home-open-paid' }));
    } else {
      this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'home-open-unpaid' }));
    }
  }

  private async fetchDataInParallel(): Promise<void> {
    await Promise.all([
      this.checkForReAcquisitionBannerAndResponse(),
      this.fetchUserRegimenAndConsultation(),
      this.fetchBlog(),
      this.getOrderDetails(),
      this.fetchUserFollowUp(),
      this.fetchOtherDataBasedOnUserState(),
      this.fetchSupportTickets(),
      this.fetchFeedback(),
      this.fetchOrderChat(),
    ]);
  }

  openBottomSheetForCash(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'home-cash-banner-click' }));
    this.bottomSheet.open(CureskinCashSheetComponent, {
      data: { data: this.totalCashBalance, url: '/user?tab=shop&back=home' },
    });
  }

  openBottomSheet(): void {
    if (this.conn.isInternalUser() || this.showUpdatedPrivacyPopup) return;

    // Get the last opened date
    const lastOpenedDate = this.localStorage.getValue('anytimeFollowupLastOpenedDate');
    const currentDate = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format

    if (this.bottomSheetPriority.includes('AnytimeFollowupComponent')
      && lastOpenedDate !== currentDate) {
      this.bottomSheet.open(AnytimeFollowupComponent, {
        data: {
          doctorProfileImageURL: this.followUp.get('allocatedDoctor').get('doctorDisplayImage')
            || 'https://cdn.cureskin.com/app/img/dr-charu-cropped.png',
          callback: (): void => {
            this.startFollowUp('ANYTIME_FOLLOWUP_POPUP_CLICKED');
          },
        },
      });
      // Update `LocalStorageService` with the current date
      this.localStorage.setValue('anytimeFollowupLastOpenedDate', currentDate);
      return;
    }
    if (this.bottomSheetPriority.includes('FollowUpChatSheetComponent')) {
      this.bottomSheet.open(FollowUpChatSheetComponent, {
        data: { user: this.user },
      });
      return;
    }
    if (this.bottomSheetPriority.includes('IncompleteChatSheetComponent')) {
      this.bottomSheet.open(IncompleteChatSheetComponent, {
        data: {
          regimenClass: this.consultationSession?.get('PrivateMainConcernClass'),
          callback: (): void => {
            this.openConsultation('bottom_sheet');
          },
        },
      });
    }
  }

  private async loadOrders(): Promise<void> {
    const where: any = { user: this.user };
    const select: Array<ParseKeys<Table.Order>> = ['orderNumber', 'type', 'stage', 'deliveredOn'];
    where.stage = [
      ...this.appConfig.Shared.Order.Stage.orderPackedStages,
      ...this.appConfig.Shared.Order.Stage.orderPlacedStages,
      ...this.appConfig.Shared.Order.Stage.orderDispatchedStages,
      ...this.appConfig.Shared.Order.Stage.orderOutForDeliveryStages,
      this.appConfig.Shared.Order.Stage.DELIVERED];

    try {
      let orders: any;
      if (!this.userStatus.PAID) {
        orders = await this.conn.fetchOrders(where, [...select, 'regimen']);
      } else {
        orders = await this.conn.fetchOrders(where, select);
      }
      const orderStages = await this.conn.fetchOrderStages();
      if (orders) {
        orders.forEach((order: any): void => {
          order.set('orderStage', orderStages.find((orderStage: any): boolean => orderStage.name === order?.get('stage')) || {});
        });
      }
      this.allActiveOrders = orders;
      if (this.allActiveOrders) {
        this.allActiveOrders.forEach((order: any): any => {
          if (order?.get('stage') === this.appConfig.Shared.Order.Stage.DELIVERED) {
            const deliveredDate = new Date(order?.get('deliveredOn'));
            const differenceBetweenCurrentDateAndDeliveryDate = this.timeService.differenceInDays(new Date(), deliveredDate);
            if (differenceBetweenCurrentDateAndDeliveryDate < 2) {
              order.set('showOrderDeliveredBanner', true);
            }
          }
        });
      }
    } catch (error) {
      this.eventLogger.logError(error);
      this.broadcast.broadcast('NOTIFY', { message: error.toString() });
    }
  }

  newRegimenBannerRenderLogic(): void {
    this.regimens.forEach((regimen: any): void => {
      if (!regimen.active) {
        if (regimen.class === this.appConfig.Shared.Regimen.Class.FACE) {
          if (this.hairBannerIndex === -1) this.skinBannerIndex = 0;
          else this.skinBannerIndex = 1;
        } else if (regimen.class === this.appConfig.Shared.Regimen.Class.HAIR) {
          if (this.skinBannerIndex === -1) this.hairBannerIndex = 0;
          else this.hairBannerIndex = 1;
        }
      }
    });
  }

  async fetchUserRegimenAndConsultation(): Promise<void> {
    const payload: RequestQueryPayload<Table.ConsultationSession> = {
      where: {
        user: this.user,
        PrivateMainConcern: { $exists: true },
        PrivateMainConcernClass: { $nin: this.regimensClass || [] },
        archive: false,
        regimen: { $exists: false },
      },
      descending: 'updatedAt',
    };
    this.latestOrder = await this.conn.findLastDeliveredOrder({
      stage: this.appConfig.Shared.Order.Stage.DELIVERED,
      type: this.appConfig.Shared.Order.Type.REGIMEN,
    });
    await this.checkLastDeliveredOrder();
    await this.checkFollowUpReportAvailable();
    const [consultationSession]: any = await this.conn.fetchConsultationSessions(payload);
    if (!consultationSession) return;
    this.consultationSession = consultationSession;
    this.concernClass = this.consultationSession?.get('PrivateMainConcernClass');
    this.checkConsultationPopup();
  }

  fetchCartProductAddedByScript(): any {
    let latestProduct = null;
    this.cartItems?.forEach((item: any): any => {
      if (item.isDoctorAdded && item.isAddedByScript && item.scriptAudioUrl) {
        if (!latestProduct || new Date(item.createdAt) > new Date(latestProduct.createdAt)) {
          latestProduct = item;
        }
      }
    });
    this.productAddedByScript = latestProduct;
    if (this.productAddedByScript) this.voiceNoteByScript = true;
  }

  async showWeeklyJourneyCard(): Promise<void> {
    this.isJourneyCardOne = false;
    this.isWeekOne = false;
    this.isJourneyCardTwo = false;
    this.isJourneyCardThree = false;
    this.isJourneyCardFour = false;
    this.isWeekThreeJourneyCard = false;
    this.isWeekFourJourneyCard = false;
    this.expectedDeliveryDate = { from: this.timeService.addDays(new Date(), 2),
      to: this.timeService.addDays(new Date(), 2 + this.deliveryIntervalInDays) };
    const concerns = this.user?.get('aiDetections');
    const latestDeliveredOrder = await this.conn.findLastDeliveredOrder({
      stage: this.appConfig.Shared.Order.Stage.DELIVERED,
      type: this.appConfig.Shared.Order.Type.REGIMEN,
    });
    const regimenType = latestDeliveredOrder?.get('regimen')?.get('class');
    if (regimenType === this.appConfig.Shared.Regimen.Class.HAIR) return;
    if (concerns?.length) {
      this.primaryConcern = concerns[0];
      this.otherConcerns = concerns.slice(1).join(', ');
    }
    this.latestPlacedRegimenOrder = await this.conn.findLastDeliveredOrder({
      type: this.appConfig.Shared.Order.Type.REGIMEN,
    });
    if (this.faceRegimenOrderPlaced && Object.keys(this.faceRegimenOrderPlaced).length && this.latestPlacedRegimenOrder) {
      this.isJourneyCardOne = true;
      return;
    }
    if (regimenType === this.appConfig.Shared.Regimen.Class.FACE) {
      this.latestFaceRegimenDelivered = latestDeliveredOrder;
    }
    this.pendingCheckInCall = await this.conn.findOnePendingCall({
      where: {
        user: this.user,
        type: this.appConfig.Shared.PendingCall.Type.CHECK_IN,
        status: this.appConfig.Shared.PendingCall.Status.Requested,
        createdAt: { $gte: this.timeService.removeDays(new Date(), 10) },
      },
    });
    const deliveredDate = new Date(this.faceRegimenOrderDelivered?.deliveredDate);
    const differenceBetweenCurrentDateAndDeliveryDate = this.timeService.differenceInDays(new Date(), deliveredDate);
    if (differenceBetweenCurrentDateAndDeliveryDate >= 0 && differenceBetweenCurrentDateAndDeliveryDate < 4) {
      this.isWeekOne = true;
    } else if (differenceBetweenCurrentDateAndDeliveryDate > 3 && differenceBetweenCurrentDateAndDeliveryDate < 7) {
      this.isJourneyCardTwo = true;
    } else if (differenceBetweenCurrentDateAndDeliveryDate > 6 && differenceBetweenCurrentDateAndDeliveryDate < 10) {
      this.isJourneyCardThree = true;
    } else if (differenceBetweenCurrentDateAndDeliveryDate > 9 && differenceBetweenCurrentDateAndDeliveryDate < 14) {
      this.isJourneyCardFour = true;
    } else if (differenceBetweenCurrentDateAndDeliveryDate > 13 && differenceBetweenCurrentDateAndDeliveryDate < 21) {
      this.isWeekThreeJourneyCard = true;
    } else if (differenceBetweenCurrentDateAndDeliveryDate > 20 && differenceBetweenCurrentDateAndDeliveryDate < 28) {
      this.isWeekFourJourneyCard = true;
    }
  }

  trackOrder(url: string): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'track-order-journey-card-1' }));
    if (this.isiOSDevice) this.conn.navigateToURL(url);
    else this.broadcast.broadcast('OPEN_IN_NEW_TAB', { url });
  }

  checkConsultationPopup(): void {
    if (this.conn.isInternalUser()) return;
    let showConsultationBanner$;
    this.store.select(fromSelectors.selectHomePageState).subscribe((data: any): void => {
      showConsultationBanner$ = data.showConsultationBanner;
    });
    this.showIncompleteChatPopup = showConsultationBanner$;
    if (this.showIncompleteChatPopup) {
      this.bottomSheetPriority.push('IncompleteChatSheetComponent');
    }
    if (this.consultationSession) {
      this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'incomplete-chat-popup-rebranded' }));
      this.store.dispatch(fromActions.HomePageUpdate({ showConsultationBanner: false }));
    }
  }

  openInstruction(regimenClass: string): void {
    this.eventLogger.trackEvent('INSTRUCTION_BANNER_CLICKED');
    this.router.navigate(['/user'], { queryParams: { tab: 'regimen', class: regimenClass } });
  }

  async checkLastDeliveredOrder(): Promise<void> {
    if (!this.latestOrder) return;
    const deliveredDate = new Date(this.latestOrder?.get('deliveredOn'));
    const differenceBetweenCurrentDateAndDeliveryDate = this.timeService.differenceInDays(new Date(), deliveredDate);
    if (differenceBetweenCurrentDateAndDeliveryDate > 7) {
      return;
    }
    this.showDeliveredBanner = true;
  }

  getKitImage(): string {
    const faceRegimen = this.appConfig.Shared.Regimen.Class.FACE;
    const classOfRegimen = this.latestOrder?.get('regimen')?.get('class') || faceRegimen;
    if (classOfRegimen === faceRegimen) return 'https://cdn.cureskin.com/app/img/Skin-Box.png';
    return 'https://cdn.cureskin.com/app/img/Hair-Box.png';
  }

  async checkFollowUpReportAvailable(): Promise<void> {
    [this.followUpReport] = await this.conn.fetchFollowUpReports({
      where: {
        user: this.user,
        createdAt: { $gte: new Date(new Date().getTime() - 604800000) }, // 7 * 24 * 60 * 60 * 1000
        read: { $ne: true },
      },
      descending: 'createdAt',
      limit: 1,
      include: ['doctor'],
    });
  }

  async fetchSupportTickets(): Promise<any> {
    const { user }: any = this;
    const payload = { where: { user, status: this.appConfig.Shared.Ticket.status.Pending } };
    const ticket: any = await this.conn.fetchLatestActiveSupportTickets(payload);
    const ticketObj: any = JSON.parse(JSON.stringify(ticket));
    if (ticketObj.cacheSupportChat
      && ticketObj.attended
      && new Date(ticketObj.lastVisited?.iso) < new Date(ticketObj?.latestResponseTimeFromInternalUser.iso)) {
      ticketObj.hasUnreadMessage = true;
    }
    this.supportTicket = ticketObj;
    if (ticketObj?.hasUnreadMessage) {
      const payloadOne = { where: { user, supportTicket: ticket } };
      const chat = await this.conn.fetchSupportChat(payloadOne);
      if (chat[chat.length - 2]?.get('Inputs')?.length) {
        this.requestDataInTicket = true;
      } else {
        this.replyInTicket = true;
      }
      this.userDoctor = await this.conn.findUserByObjectId(this.supportTicket.allocatedDoctor.objectId);
    }
  }

  openFollowUpReport(): void {
    this.eventLogger.trackEvent('FOLLOWUP_REPORT_BANNER_CLICKED', { id: this.followUpReport.id });
    this.router.navigate([`/user/report/${this.followUpReport.id}`]);
  }

  async openConsultation(origin?: string): Promise<void> {
    if (origin === 'bottom_sheet') {
      this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'incomplete-chat-banner' }));
    } else {
      this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'chat_incomplete_carousel_banner_clicked' }));
    }
    await this.conn.switchUserToState(
      `v4_${this.consultationSession?.get('PrivateMainConcernClass').toLowerCase()}:`,
      { consultationSessionId: this.consultationSession.id });
    await this.router.navigate([`/chatV2/${this.consultationSession.id}`], {
      queryParams: { type: 'consultationSession', back: 'home' },
    });
  }

  openDiet(): void {
    this.eventLogger.cleverTapEvent('diet_banner_rebranded');
    this.eventLogger.trackEvent('diet_banner_rebranded');
    this.router.navigate(['/notice']);
  }

  openGame(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'new-quiz-game' }));
    this.router.navigate(['/quiz-game']);
  }

  // Note: on logging something, it runs infinite. needs to check
  ngAfterViewChecked(): void {
    const params = this.route.snapshot.queryParams;
    const { section }: any = params;
    const windowRef = this.windowRef.nativeWindow;
    const doc = windowRef.document;
    const scrollDiv = doc.getElementById(section);
    if (scrollDiv) {
      scrollDiv.scrollIntoView({ behavior: 'smooth' });
    }
    this.updateSwiperAutoScroll();
    this.updateSwiperAutoScrollOne();
    this.updateSwiperAutoScrollTwo();
  }

  async fetchUserFollowUp(): Promise<void> {
    this.followUp = await this.conn.findRecentFollowUp({
      State: [
        this.appConfig.Shared.Followup.State.PENDING,
        this.appConfig.Shared.Followup.State.WAITING_FOR_IMAGE,
      ],
    });
    if (!this.followUp) return;
    this.selectedFollowUpBanner = this.decideFollowUpCard();
    if (!this.followUp?.get('allocatedDoctor')?.get('DoctorDisplayName')) {
      this.followUp?.get('allocatedDoctor')?.fetch();
    }
    let showAnytimeFollowupPopup$;
    this.store.select(fromSelectors.selectHomePageState).subscribe((data: any): void => {
      showAnytimeFollowupPopup$ = data.showAnytimeFollowupPopup;
    });
    if (['followUpScheduledInTwoDays', 'anyTimeFollowUpEnabled', 'lastFollowUpSkipped'].includes(this.selectedFollowUpBanner)
      && showAnytimeFollowupPopup$) {
      this.bottomSheetPriority.push('AnytimeFollowupComponent');
    }
    this.store.dispatch(fromActions.HomePageUpdate({ showAnytimeFollowupPopup: false }));
  }

  private decideFollowUpCard(): 'followUpScheduled' | 'followUpScheduledInTwoDays' | 'followUpReadyWaitingForReport'
    | 'followUpWaitingForImage' | 'anyTimeFollowUpEnabled' | 'lastFollowUpSkipped' | 'followUpReportIsReady' {
    switch (this.followUp?.get('State')) {
      case this.appConfig.Shared.Followup.State.WAITING_FOR_IMAGE: {
        return 'followUpWaitingForImage';
      }
      case this.appConfig.Shared.Followup.State.PENDING: {
        if (this.followUp?.get('ready')) {
          return 'followUpReadyWaitingForReport';
        }
        if (this.timeService.differenceInDays(this.followUp?.get('nextFollowUpDate'), new Date()) <= 2) {
          return 'followUpScheduledInTwoDays';
        }
        if (this.followUp?.get('isPreviousFollowUpSkipped')) {
          return 'lastFollowUpSkipped';
        }
        if (this.followUp?.get('isAnytimeFollowUpEnabled')) {
          return 'anyTimeFollowUpEnabled';
        }
        return 'followUpScheduled';
      }
      default: return 'followUpScheduled';
    }
  }

  async checkForUnreadNotifications(): Promise<void> {
    const unreadNotifications: number = await this.conn.findUnReadNotifications();
    if (unreadNotifications > 0) {
      this.unreadNotifications = true;
      this.toolbarIcons[0].notify = true;
    }
  }

  async fetchRegimens(): Promise<any> {
    this.regimens = (await this.conn.fetchRegimens(null, true)).filter((each: any): boolean => !each.expired);

    if (this.regimens && this.regimens.length) {
      this.latestUnBoughtRegimen = this.regimens.find((regimen: any): boolean => !(regimen.orderPlaced || regimen.delivered));
      this.latestUnDelivereRegimen = this.regimens.find((regimen: any): boolean => regimen.orderPlaced && !regimen.delivered);
      this.faceRegimenOrderPlaced = this.regimens.find((regimen: any): boolean => (regimen.orderPlaced && !regimen.active)
        && regimen.class === this.appConfig.Shared.Regimen.Class.FACE);
      this.faceRegimenOrderDelivered = this.regimens.find((regimen: any): boolean => regimen.delivered
        && regimen.class === this.appConfig.Shared.Regimen.Class.FACE);
      this.hairRegimenOrderDelivered = this.regimens.find((regimen: any): boolean => regimen.delivered
        && regimen.class === this.appConfig.Shared.Regimen.Class.HAIR);
    }
    this.regimensClass = this.regimens.map((each: any): string => each.class);
  }
  /**
   * Check for user status, whether he/she is PAID | GOT_REGIMEN | NO_PHOTO.
   * 1. PAID - if any regimen order is placed or delivered
   * 2. GOT_REGIMEN - if any regimen is allocated or in preparation time.
   * 3. NO_PHOTO - if none of the above.
   */
  findUserStatus(): any {
    this.userStatus = { PAID: false, GOT_REGIMEN: false, NO_PHOTO: false };
    const regimenOrderPlaced: boolean = (this.regimens?.findIndex((each: any): any => each.orderPlaced || each.delivered) >= 0);
    if (this.latestUnBoughtRegimen) {
      this.isRegimenBeingPrepared = this.commonUtil.isRegimenBeingPrepared(this.latestUnBoughtRegimen);
    }
    if (this.user?.get('orderState') === 'DELIVERED') this.userStatus.PAID = true;
    // commented for now, can be removed later
    // if (this.user.isPaid() || regimenOrderPlaced) this.userStatus.PAID = true;
    else if (this.latestUnBoughtRegimen && !this.isRegimenBeingPrepared) this.userStatus.GOT_REGIMEN = true;
    else this.userStatus.NO_PHOTO = true;
  }

  goToCart(): void {
    this.eventLogger.cleverTapEvent('cartClicked', JSON.stringify({ name: 'home' }));
    this.router.navigate(['cart']);
  }

  async getCart(): Promise<void> {
    this.cart = await this.conn.getCart();
    this.cartItems = this.cart?.get('lineItems');
    this.recommendedItems = this.cart?.get('recommendedProducts');
    if (this.recommendedItems?.length) {
      this.showSuggestedForYouBanner = true;
    }
    this.productsInCart = [];
    if (this.cartItems.length) {
      this.addBanner('cartItems');
      this.toolbarIcons[1].cart = true;
      let totalItems: number = 0;
      this.cartItems.forEach((element: any): void => {
        totalItems += element.quantity;
        this.productsInCart.push(element);
      });
      this.toolbarIcons[1].cartValue = totalItems || null;
    } else {
      this.toolbarIcons[1].cartValue = null;
    }
    this.fetchDiscount();
  }

  fetchDiscount(): any {
    this.totalMRP = 0;
    this.totalSP = 0;
    this.cartItems.forEach((element: any): any => {
      this.totalSP += element.quantity * element.price;
      this.totalMRP += element.quantity * element.mrp;
    });
    this.totalMRP = Math.round(this.totalMRP);
    this.totalSP = Math.round(this.totalSP);
    if (this.showCashBalance) {
      this.cashDiscount = Math.min(this.totalCashBalance, this.totalSP);
      this.totalSP -= this.cashDiscount;
    }
    let discount = 0;
    this.totalMRP = Math.floor(this.totalMRP);
    if (this.totalMRP > this.totalSP) {
      discount = Math.floor(((this.totalMRP - this.totalSP) * 100) / this.totalMRP);
    }
    this.cartDiscount = discount;
  }

  async fetchFaqs(): Promise<any> {
    let faqs = this.dataStore?.get('FAQS');
    if (!faqs.categories) faqs = await this.conn.fetchFAQ();
    this.faqs = faqs;
    this.dataStore.set('FAQS', faqs);
  }

  notificationClickAction(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'home-notification' }));
    this.openURL('/user/notifications');
  }

  referBannerClick(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'home-refer-and-earn-banner' }));
    this.navigateTo('/user/refer');
  }

  testimonialsClick(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'home-testimonials' }));
    this.navigateTo('/article/QvKJWYZyBB');
  }

  analysisOfTheDay(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'home-skin-analysis-of-the-day' }));
    this.navigateTo('/user/instantCheckup');
  }

  openBlog(blog: any): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'home-blog-article' }));
    this.navigateTo(blog.url);
  }

  optedForSupport(mode: any): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: `home-support-${mode}` }));
  }

  knowAboutDoctors(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'home-know-about-doctors' }));
    this.navigateTo('/doctors');
  }

  exploreSectionRedirect(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'home-explore' }));
    this.navigateTo('/user?tab=explore');
  }

  async fetchBlog(): Promise<any> {
    this.blogs$ = this.store.select(fromSelectors.selectHomePageBlogs).pipe(map((blogs: object[]): object[] => (
      blogs.map((each: any): any => ({ image: each.thumbnailURL, url: each.contentURL, createdAt: each.createdAt }))
    )));
    const payload = { where: { user: this.user }, descending: 'createdAt', limit: this.conn.isInternalUser() ? 100 : 5 };
    this.store.dispatch(fromActions.HomePageBlogsBegin({ payload }));
  }

  enableNgClassCondition(): boolean {
    return [this.appConfig.Shared.Languages.ML, this.appConfig.Shared.Languages.KN, this.appConfig.Shared.Languages.TE,
      this.appConfig.Shared.Languages.TA].includes(this.user.get('languagePreference'));
  }

  /**
   * Fetches more content based on user state.
   * 1. PAID - fetches addon products, reorder products, & checks for pending followup banner
   * 2. If any regimen is allocated and unbought, a banner shown to buy it
   * 3. Fetches faqs, Notifications, Cureskin cash calim banner if any order has unclaimed rewards.
   * Re-arranges cards based on user state.
   */
  async fetchOtherDataBasedOnUserState(): Promise<void> {
    setTimeout((): void => {
      this.loading = false;
      this.changeCardPositions();
    });
    await this.fetchFaqs();
    await this.checkForUnreadNotifications();
    if (this.latestUnBoughtRegimen && !this.isRegimenBeingPrepared) this.showUnBoughtRegimen = true;
  }

  async getOrderDetails(): Promise<void> {
    const where: any = { user: this.user };
    const select: Array<ParseKeys<Table.Order>> = ['orderNumber', 'type', 'amount', 'stage', 'regimen', 'productInfo', 'createdBy'];
    const stages = [
      this.appConfig.Shared.Order.Stage.ONLINE_PAYMENT_PENDING,
      this.appConfig.Shared.Order.Stage.INITIAL,
    ];
    where.stage = { $eq: stages };
    try {
      const orders = await this.conn.fetchOrders(where,
        select,
        ['products', 'services', 'regimen', 'regimen.products' as 'regimen', 'coupons', 'user', 'createdBy'],
      );
      const createdOrder = orders?.find((order: any): any => order?.get('createdBy')?.get('type'));
      this.doctorOrder = await this.conn.findOrderWithSignedURL(createdOrder.id);
      if (this.doctorOrder) {
        this.doctorOrder?.get('products').forEach((element: any): any => {
          if (element?.get('rebrandedImageWithoutBackground').length) {
            const rebrandedImage = element?.get('rebrandedImageWithoutBackground');
            this.addBannerTwo(rebrandedImage[element.get('rebrandedImageWithoutBackground').length - 1]);
          } else {
            const img = element?.get('productUnboxedImage');
            this.addBannerTwo(img[element.get('productUnboxedImage').length - 1]);
          }
        });
      }
      this.showDoctorBanner = true;
    } catch (error) {
      this.eventLogger.logError(error);
    }
  }

  async getInitialOrderDetails(): Promise<void> {
    const where: any = { user: this.user };
    const select: Array<ParseKeys<Table.Order>> = ['orderNumber', 'type', 'amount', 'stage', 'regimen', 'productInfo', 'createdBy'];
    const stages = [
      this.appConfig.Shared.Order.Stage.ONLINE_PAYMENT_PENDING,
      this.appConfig.Shared.Order.Stage.INITIAL,
    ];
    where.stage = { $eq: stages };
    try {
      const orders = await this.conn.fetchOrders(where,
        select,
        ['products', 'services', 'regimen', 'regimen.products' as 'regimen', 'coupons', 'user', 'createdBy'],
      );
      orders.forEach(async (element: any): Promise<any> => {
        this.order = await this.conn.findOrderWithSignedURL(element.id);
        if (this.drOrderBannerHome) {
          if (this.order?.get('amount')) {
            this.order.discount = Math.floor(((this.order.get('actualPrice') - this.order.get('amount')) * 100)
              / this.order.get('actualPrice'));
          }
        }
        const isDietOrder = this.order?.get('serviceInfo')?.some((service: any): any => service.type
          === this.appConfig.Shared.Order.Type.DIET_CONSULTATION);
        if (this.order && !isDietOrder && this.order?.get('type') !== this.appConfig.Shared.Order.Type.REGIMEN) {
          if (this.drOrderBannerHome) {
            if (this.order?.get('createdBy')?.get('type')) {
              this.addBannerOne(this.order);
            }
          } else {
            this.addBannerOne(this.order);
          }
        }
      });
    } catch (error) {
      this.eventLogger.logError(error);
    }
  }

  openBanner(banner: any = {}): void {
    this.eventLogger.trackEvent('home_banner_click', { url: banner.link });
    if (banner.link) this.navigateTo(banner.link);
  }

  openFaq(category: string): void {
    if (!this.faqs.categories) return;
    const faq = this.faqs.categories.find((each: any): boolean => each?.get('category') === category);
    if (faq) {
      this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: `home-faq-option-${faq.id}` }));
      this.navigateTo(`/faq/${faq.id}`);
    }
  }

  trackEventAndNavigateTo(eventName: string, url?: string): void {
    this.eventLogger.trackEvent(eventName);
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: `home-${eventName}` }));
    this.navigateTo(url);
  }

  navigateTo(url: string = ''): void {
    if (!url) return;
    const queryParams: Record<string, any> = {};
    const [URL, qParamString]: string[] = url.split('?');
    if (qParamString) {
      qParamString.split('&').forEach((each: string): void => {
        const [key, value]: string[] = each.split('=');
        queryParams[key] = value;
      });
    }
    this.eventLogger.trackEvent('home_page_url_open', { url });
    this.router.navigate([URL], { queryParams });
  }

  toggleMenu(status: boolean): void {
    this.zone.run((): void => {
      this.isMenuOpen = status;
    });
  }

  swipeCoordinates(data: any): void {
    const { direction, startXPos }: any = JSON.parse(data);
    const MAX_INITIAL_X_POSITION = 50;
    if (direction === 'RIGHT' && !this.isMenuOpen && Number(startXPos) <= MAX_INITIAL_X_POSITION) this.toggleMenu(true);
    if (direction === 'LEFT' && this.isMenuOpen) this.toggleMenu(false);
  }

  /**
   * Re-arranges cards based on user state
   * Takes the mentioned selctors from the array and pushes it to end inside 'nonStaticContent' element.
   * Note: 'nonStaticContent' element holds the cards which position can change based on user state.
   */
  changeCardPositions(): void {
    let positions = [];
    if (this.userStatus.PAID) positions = ['section.faq'];
    else positions = ['section.refer', 'section.faq'];
    setTimeout((): void => {
      const windowDocument = this.window.nativeWindow.document;
      const nonStaticContent = windowDocument.getElementById('nonStaticContent');
      if (!nonStaticContent) return;
      positions.forEach((each: any): void => {
        const dom = nonStaticContent.querySelector(each);
        if (dom) nonStaticContent.appendChild(dom);
      });
    });
  }

  buyRegimen(concernClass: string): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: `home-buy-${concernClass}` }));
    if (concernClass) {
      this.navigateTo(`/user?tab=regimen&class=${concernClass}`);
    } else {
      this.navigateTo('/user?tab=regimen');
    }
  }

  async refreshUserStatus(): Promise<any> {
    if (this.userStatus.PAID) return;
    await this.fetchRegimens();
    this.findUserStatus();
  }

  /**
   * If tab is inactive - i.e if we retain the tab in dom even if navigated to other tab. then we perform inactive state operation
   * (Note: we retained home tab in dom previously by translateX(-100%) when navigated to some other tab.
   *  Now its hidden. This code is not useful).
   * 1. Closes Navigation drawer menu if opened
   * 2. Scrolls home page to top
   * Sets the component to currentComponentService
   * Checks for change in user state and populates the data based on that.
   */
  async refreshComponent(): Promise<void> {
    if (!this.isTabActive) {
      if (this.isMenuOpen) this.toggleMenu(false);
      if (this.homePageContainer && this.homePageContainer?.nativeElement) this.homePageContainer.nativeElement.scrollTop = 0;
      return;
    }
    this.makeComponentActive(true);
    if (this.user) {
      await this.refreshUserStatus();
      this.fetchUserRegimenAndConsultation();
    }
  }

  shareTip(): void {
    this.broadcast.broadcast('SHARE', { type: 'IMAGE', url: this.tipOfDay?.get('image') });
  }

  /**
   * Handles back press locallay on folowing conditions
   * 1. if any popup is opened or Navigation drawer is opened
   * 2. else returns false and browser handles it
   */
  async handleBackPress(): Promise<boolean> {
    if (this.isMenuOpen) {
      this.toggleMenu(false);
      return true;
    }
    return false;
  }

  /**
   * Sets the component in currentComponentService based on status parameter.
   * If there is any multi layered popup and the any of popup needs to handle back press,
   * then we call this function to switch the status of component based on need.
   * @param {boolean} status -
   */
  makeComponentActive(status: boolean): void {
    if (status) this.currentComponentService.set(this);
    else this.currentComponentService.remove(this);
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  stopPropagation(event: any): void {
    event.stopPropagation();
  }

  viewAll(pageName: string): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: `view-all-${pageName}` }));
    this.conn.navigateToURL(`/user/order/reorder?from=${pageName}`);
  }

  openURL(url: string): void {
    this.conn.navigateToURL(url);
  }

  redirectToArticle(): void {
    this.navigateTo(`/article/${this.redirectArticleId}`);
  }

  startLiveSession(sessionId: string): void {
    if (this.appWebBridgeService.isAppWebBridgeLoaded()) {
      this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'Doctor-Live-Session-Open' }));
      this.appWebBridgeService.startLiveSession(sessionId);
    } else {
      this.router.navigate(['/user/live-session']);
    }
  }

  private addBanner(bannerName: string): void {
    if (!this.bannerContainer.includes(bannerName)) {
      this.bannerContainer.push(bannerName);
    }
    this.bannerContainer.sort((banner1: string, banner2: string): number => {
      if (UserHomeComponent.BannerOrder[banner1] === UserHomeComponent.BannerOrder[banner2]) {
        return 0;
      }
      if (UserHomeComponent.BannerOrder[banner1] > UserHomeComponent.BannerOrder[banner2]) {
        return 1;
      }
      return -1;
    });
    this.updateSwiperAutoScroll();
  }

  // Review for usage
  /*
  1. if kit ready banner is there in the home page
  2. kit is ready banner index
  */
  onSlideChange2(swiper: Swiper): void {
    if (swiper) {
      swiper.update();
    }
    // if (swiper && (swiper.activeIndex === this.skinBannerIndex || swiper.activeIndex === this.hairBannerIndex)) {
    //   this.resetKitReadyBannerHeight = false;
    // } else {
    //   this.resetKitReadyBannerHeight = true;
    // }
  }

  onSlideChange(event: any): void {
    this.activeBannerIndex = event[0].snapIndex - 1;
    if (this.activeBannerIndex >= this.bannerContainer.length || this.activeBannerIndex < 0) {
      this.activeBannerIndex = 0;
    }
    this.changeDetectorRef.detectChanges();
  }
  onSlideChangeOne(event: any): void {
    this.activeBannerIndexOne = event[0].snapIndex - 1;
    if (this.activeBannerIndexOne >= this.bannerContainerOne.length || this.activeBannerIndexOne < 0) {
      this.activeBannerIndexOne = 0;
    }
    this.changeDetectorRef.detectChanges();
  }
  onSlideChangeTwo(event: any): void {
    this.changeDetectorRef.detectChanges();
  }

  private updateSwiperAutoScroll(): void {
    if (this.bannerContainer.length) {
      this.store.select(fromSelectors.selectHomePageState).subscribe((data: any): void => {
        this.showStickyBars$ = data.isSessionOn;
      });
    }
    if (!this.swiper?.swiperRef) {
      return;
    }
    const swiper = this.swiper.swiperRef;
    if (this.bannerContainer.length <= 1) {
      swiper.updateAutoHeight();
      swiper?.autoplay?.stop();
      swiper.allowTouchMove = false;
      return;
    }
    swiper.updateAutoHeight();
    swiper.allowTouchMove = true;
    this.swiper?.swiperRef?.autoplay?.start();
  }

  private updateSwiperAutoScrollOne(): void {
    if (!this.swiperOne?.swiperRef) {
      return;
    }
    const swiper = this.swiperOne.swiperRef;
    if (this.bannerContainerOne.length <= 1) {
      swiper.updateAutoHeight();
      swiper?.autoplay?.stop();
      swiper.allowTouchMove = false;
      return;
    }
    swiper.updateAutoHeight();
    swiper.allowTouchMove = true;
    this.swiperOne.swiperRef?.autoplay?.start();
  }

  private updateSwiperAutoScrollTwo(): void {
    if (!this.swiperTwo?.swiperRef) {
      return;
    }
    const swiper = this.swiperTwo.swiperRef;
    if (this.bannerContainerTwo.length <= 1) {
      swiper.updateAutoHeight();
      swiper?.autoplay?.stop();
      swiper.allowTouchMove = false;
      return;
    }
    swiper.updateAutoHeight();
    swiper.allowTouchMove = true;
    this.swiperTwo.swiperRef?.autoplay?.start();
  }

  private addBannerTwo(banner: any): void {
    this.bannerContainerTwo.push(banner);
    this.updateSwiperAutoScrollTwo();
  }

  private addBannerOne(banner: any): void {
    if (!this.bannerContainerOne.includes(banner.id)) {
      this.bannerContainerOne.push(banner);
    }
    this.updateSwiperAutoScrollOne();
  }
  // End of review

  trackEvent(event: string): void {
    this.eventLogger.trackEvent(event, { id: this.followUp.id });
  }

  async trackVoiceEvent(swiperCardsBanner: any, action: any): Promise<void> {
    switch (action) {
      case 'play': {
        await this.onAudioPlay();
        swiperCardsBanner?.swiperRef?.autoplay?.stop();
        break;
      }
      case 'completed': {
        await this.onAudioPlayEnd();
        swiperCardsBanner?.swiperRef?.autoplay?.start();
        break;
      }
      default:
    }
  }

  async fetchOrderChat(): Promise<void> {
    const where = {
      user: this.user,
      Mode: 'AUDIO',
      Owner: 'DOCTOR',
      createdAt: { $gte: this.timeService.removeDays(new Date(), 5) },
      $or: [
        { trackingDetails: { $exists: false } },
        { 'trackingDetails.audioFinished': { $exists: false } },
        { 'trackingDetails.audioFinished': false },
      ],
    };
    this.notListenedVoiceMessage = await this.conn.findOneOrderChat({
      where,
      ascending: 'createdAt',
      option: { context: { translate: true } } as Parse.FullOptions,
    });
    if (this.notListenedVoiceMessage) {
      this.voiceNoteBannerDetails.voiceUrl = this.notListenedVoiceMessage?.get('audioUrl');
      this.voiceNoteBannerDetails.trackEventAudio = {
        event: 'AudioMessageSendDuringApprovalOpen',
        data: {
          orderChatId: this.notListenedVoiceMessage.id,
          orderId: this.notListenedVoiceMessage?.get('order').id,
        },
      };
      this.voiceNoteBannerDetails.doctorDisplayImageURL = this.userDrImage;
    }
  }
  async onAudioPlay(): Promise<void> {
    if (this.notListenedVoiceMessage?.get('trackingDetails')?.audioPlayed) return;
    this.eventLogger.cleverTapEvent('audioPlayed',
      JSON.stringify({ orderChatId: this.notListenedVoiceMessage.id,
        pageName: 'home',
        questionId: this.notListenedVoiceMessage.questionId || 'undefined' }));
    await this.conn.trackVoiceNotesAndScrollEvents({ objectId: this.notListenedVoiceMessage.id,
      eventName: 'audioPlayed',
      trackingTable: 'orderChat' });
  }

  async onAudioPlayEnd(): Promise<void> {
    if (this.notListenedVoiceMessage?.get('trackingDetails')) {
      this.notListenedVoiceMessage.get('trackingDetails').audioPlayed = true;
    }
    this.eventLogger.cleverTapEvent('audioFinished', JSON.stringify({ orderChatId: this.notListenedVoiceMessage.id, pageName: 'home' }));
    await this.conn.trackVoiceNotesAndScrollEvents({ objectId: this.notListenedVoiceMessage.id,
      eventName: 'audioFinished',
      trackingTable: 'orderChat' });
  }

  async startFollowUp(event?: string): Promise<void> {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ pageName: 'sticky-bar-followUp' }));
    if (event) {
      this.trackEvent(event);
    }
    try {
      if (this.followUp?.get('State') !== this.appConfig.Shared.Followup.State.WAITING_FOR_IMAGE) {
        await this.conn.createFollowUp();
      }
      await this.router.navigate([`/chatV2/${this.followUp.id}`], {
        queryParams: { type: 'followUp' },
      });
    } catch ({ message }) {
      this.broadcast.broadcast('NOTIFY', { message });
    }
    this.router.navigate([`/chatV2/${this.followUp.id}`], {
      queryParams: { type: 'followUp' },
    });
  }

  onCancelClick(event?: any): void {
    if (event) event.stopPropagation();
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ pageName: 'sticky-bar-cancel-click' }));
    this.store.dispatch(fromActions.HomePageUpdate({ isSessionOn: false }));
  }

  /*
  Block that can be compartmentalized
  */
  goToCartNav(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ pageName: 'sticky-bar-cart-click' }));
    this.router.navigate(['cart']);
  }
  openBogoBanner(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'bogo-banner-click' }));
    const pageName = 'bogoProduct';
    this.conn.navigateToURL(`/user/order/reorder?from=${pageName}`);
  }
  openAovBanner(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'aov-banner-click' }));
    this.router.navigate(['/user'], { queryParams: { tab: 'shop', back: 'home' } });
  }
  openSaleBanner(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'sale-banner-click' }));
    this.router.navigate(['/user'], { queryParams: { tab: 'shop', back: 'home' } });
  }
  openShopPage(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'home-cash-banner-click' }));
    this.router.navigate(['/user'], { queryParams: { tab: 'shop', back: 'home' } });
  }
  redirectToTestimonialsPage(): void {
    this.router.navigate(['/user/testimonialsNew']);
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'testimonials-new-more' }));
  }
  checkoutOrder(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ pageName: 'sticky-bar-checkout' }));
    this.router.navigate([`/user/order/${this.order?.id}/payment`]);
  }
  // End of compartment

  async viewTicket(): Promise<void> {
    await this.router.navigate([`/support/ticket/${this.supportTicket.objectId}`],
      { queryParams: { username: this.user?.get('username') } });
  }

  async fetchFeedback(): Promise<void> {
    [this.feedback] = await this.conn.fetchFeedback({
      where: {
        user: this.user,
        type: this.appConfig.Shared.Feedback.Type.NPS,
        stage: [this.appConfig.Shared.Feedback.Stage.Created, this.appConfig.Shared.Feedback.Stage.Partial],
      },
      descending: 'createdAt',
      limit: 1,
    });
  }

  async openFeedback(): Promise<void> {
    this.eventLogger.trackEvent('NPS_BANNER_CLICKED', { id: this.feedback?.id });
    await this.router.navigate(['/feedback'], { queryParams: { feedbackId: this.feedback?.id } });
  }

  addEvents(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'order-status-banner' }));
  }

  openInstructions(cardNumber: string): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: `post-purchase-see-instructions-${cardNumber}` }));
    if (this.latestPlacedRegimenOrder || this.faceRegimenOrderDelivered) {
      this.router.navigate(['/user'],
        { queryParams: { class: this.appConfig.Shared.Regimen.Class.FACE,
          username: this.user?.get('username'),
          tab: 'regimen',
          instructions: true } });
    }
  }

  openHelp(eventName?: string): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: eventName }));
    this.router.navigate(['/support']);
  }
  openCheckup(eventName?: string): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: eventName }));
    this.router.navigate(['/user/checkup']);
  }

  openStaticArticle(articleID: string, eventName?: string): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: eventName }));
    this.navigateTo(`/article/${articleID}`);
  }

  openDoctorList(): void {
    this.navigateTo('/doctors');
  }

  openPopUp(type:string):void {
    this.bottomSheet.open(HomePageSheetComponent, {
      data: {
        popUpType: type,
      },
    });
  }

  async loadIncompleteChatBannerData(): Promise<void> {
    const payload: RequestQueryPayload<Table.ConsultationSession> = {
      where: {
        user: this.user,
        PrivateMainConcern: { $exists: true },
        PrivateMainConcernClass: { $nin: this.regimensClass || [] },
        archive: false,
        regimen: { $exists: false },
      },
      descending: 'updatedAt',
    };

    const consultationSessions = await this.conn.fetchConsultationSessions(payload);
    if (!consultationSessions) return;

    const handleConcern = async (session: any, concernClass: any): Promise<void> => {
      const concern = session?.get('PrivateMainConcern');
      const serverConcerns = await this.conn.findMainWithSecondaryConcerns({
        concernClass,
        shouldTranslateConcernsList: true,
      });

      const matchedConcern = serverConcerns.find((data: any): boolean => data.value === concern);
      if (matchedConcern) {
        if (concernClass === 'HAIR') {
          this.hairDisplayConcern = matchedConcern.displayName;
          this.isHairChatPending = true;
        } else if (concernClass === 'FACE') {
          this.faceDisplayConcern = matchedConcern.displayName;
          this.isFaceChatPending = true;
        }
      }
    };

    // eslint-disable-next-line no-restricted-syntax
    for (const session of consultationSessions) {
      const concernClass = session?.get('PrivateMainConcernClass');
      if (concernClass === 'HAIR') {
        this.hairChatId = session.id;
        // eslint-disable-next-line no-await-in-loop
        await handleConcern(session, concernClass);
      }
      if (concernClass === 'FACE') {
        this.faceChatId = session.id;
        // eslint-disable-next-line no-await-in-loop
        await handleConcern(session, concernClass);
      }
    }
  }

  async openChatFromNewIncompleteChatBanner(event: any): Promise<void> {
    const targetElement: HTMLElement = event.currentTarget as HTMLElement;
    const { id }: { id: string } = targetElement;
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'chat_incomplete_carousel_banner_clicked' }));
    if (id.includes('face')) {
      await this.conn.switchUserToState(
        `v4_${this.appConfig.Shared.Regimen.Class.FACE.toLowerCase()}:`,
        { consultationSessionId: this.faceChatId });
      await this.router.navigate([`/chatV2/${this.faceChatId}`], {
        queryParams: { type: 'consultationSession', back: 'home' },
      });
    } else if (id.includes('hair')) {
      await this.conn.switchUserToState(
        `v4_${this.appConfig.Shared.Regimen.Class.HAIR.toLowerCase()}:`,
        { consultationSessionId: this.hairChatId });
      await this.router.navigate([`/chatV2/${this.hairChatId}`], {
        queryParams: { type: 'consultationSession', back: 'home' },
      });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
    this.currentComponentService.remove(this);
  }

  async trackEventOne(action: any): Promise<void> {
    switch (action) {
      case 'play': {
        await this.onAudioPlayCart();
        break;
      }
      case 'completed': {
        await this.onAudioPlayEndCart();
        break;
      }
      default:
    }
  }

  async onAudioPlayCart(): Promise<void> {
    this.eventLogger.cleverTapEvent('audioPlayed', JSON.stringify({ followupReportId: this.user.id, pageName: 'home' }));
    await this.conn.trackVoiceNotesAndScrollEvents({ objectId: this.cart.objectId,
      eventName: 'audioPlayed',
      trackingTable: 'cart' });
  }

  async onAudioPlayEndCart(): Promise<void> {
    this.eventLogger.cleverTapEvent('audioFinished', JSON.stringify({ userId: this.user.id, pageName: 'home' }));
    await this.conn.trackVoiceNotesAndScrollEvents({ objectId: this.cart.objectId,
      eventName: 'audioFinished',
      trackingTable: 'cart' });
  }

  async addProductToCart(product: any): Promise<void> {
    const isProductInCart = this.cartItems.some(
      (product: any): any => product.productId === this.recommendedItems[0].productId);
    if (!isProductInCart) {
      const params = {
        productId: product.productId,
        quantity: 1,
      };
      await this.conn.addProductToCart(params);
    }
    this.conn.navigateToURL('/cart');
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'add-to-cart-recommended-product' }));
  }

  regimenOfferProduct(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'free_travel_pouch_clicked_homepage' }));
    if (this.latestUnBoughtRegimen || this.latestUnDelivereRegimen) {
      this.buyRegimen(this.latestUnBoughtRegimen?.class || this.latestUnDelivereRegimen?.class);
    } else if (this.consultationSession) this.openConsultation();
  }
}
