import { Routes } from '@angular/router';
import { ChoosePlanInfoComponent } from '@shared/choose-plan-info/choose-plan-info.component';
import { EmptyComponent } from './empty/empty.component';
import { LoginDoctorComponent } from './login/doctor/login-doctor.component';
import { LogoutComponent } from './logout/logout.component';
import { MainConcernComponent } from './main-concern/main-concern.component';
import { HelpComponent } from './help/help.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { UserViewComponent } from './user/user-view/user-view.component';
import { ArticleComponent } from './article/article.component';
import { OnboardingLanguageComponent } from './onboarding-language/onboarding-language.component';
import { IframeComponent } from './iframe/iframe.component';
import { NoticeComponent } from './notice/notice.component';
import { VideoComponent } from './video/video.component';
import { ReminderFullPageComponent } from './reminder';
import { ProductDescriptionComponent } from './product-description/product-description.component';
import { ProductCartComponent } from './cart/cart.component';
import { QuizGameComponent } from './quiz-game/quiz-game.component';
import { ReportComponent } from './report/report.component';
import { authenticationGuard } from './guards/authentication.guard';
import { onboardingGuard } from './guards/onboarding.guard';
import { TestimonialsNewComponent } from './testimonial/testimonials-new/testimonials-new.component';
import { TestimonialsDescComponent } from './testimonial/testimonials-description/testimonials-description.component';
import { UserCheckoutComponent } from './user-checkout/user-checkout.component';
import { TestimonialCaseStudiesInfoComponent } from './testimonial/testimonial-case-studies-info/testimonial-case-studies-info.component';
import { UserNotificationComponent } from './user-notification/user-notification.component';
import { UserRegimenProductsViewComponent } from './user-regimen/user-regimen-products-view/user-regimen-products-view.component';
import { UserRegimenDoctorComponent } from './user-regimen/user-regimen-doctor/user-regimen-doctor.component';
import { UserRegimenServicesComponent } from './user-regimen/user-regimen-services/user-regimen-services.component';
import {
  CheckupCaptureComponent, CheckupListComponent,
  CheckupPhotoInstructionComponent, CheckupViewContainerComponent,
  SelectCheckupTypeComponent,

} from './instant-checkup/components';
import { UserAddressListComponent } from './user-address/user-address-list/user-address-list.component';
import { UserAddressEditComponent } from './user-address/user-address-edit/user-address-edit.component';
import { UserFollowupReportComponent } from './user-followup-report/user-followup-report.component';
import { UserOrderListComponent } from './user-order/user-order-list/user-order-list.component';
import { UserOrderReorderComponent } from './user-order/user-order-reorder/user-order-reorder.component';
import { UserOrderSuccessComponent } from './user-order/user-order-success/user-order-success.component';
import { UserOrderFailureComponent } from './user-order/user-order-failure/user-order-failure.component';
import { UserOrderAddonComponent } from './user-order/user-order-addon/user-order-addon.component';
import { UserOrderPaymentComponent } from './user-order/user-order-payment/user-order-payment.component';
import { UserOrderViewComponent } from './user-order/user-order-view/user-order-view.component';
import { UserPrescriptionViewComponent } from './user-prescription/user-prescription-view.component';
import { UserReferComponent } from './user-refer/user-refer.component';
import { UserWalletViewComponent } from './user-wallet/user-wallet-view/user-wallet-view.component';
import { UserWalletTransactionsComponent } from './user-wallet/user-wallet-transactions/user-wallet-transactions.component';
import { UserProfileComponent } from './user-profile/user-profile/user-profile.component';
import { UserProfileEditComponent } from './user-profile/user-profile-edit/user-profile-edit.component';
import { UserCheckupComponent } from './user-checkup/user-checkup.component';

const AppRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [authenticationGuard],
    redirectTo: '/user?tab=home&init=true',
  },
  {
    path: 'language',
    component: OnboardingLanguageComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'cart',
    canActivate: [authenticationGuard, onboardingGuard],
    component: ProductCartComponent,
  },
  {
    path: 'notice',
    canActivate: [authenticationGuard, onboardingGuard],
    component: NoticeComponent,
  },
  {
    path: 'video',
    canActivate: [authenticationGuard, onboardingGuard],
    component: VideoComponent,
  },
  {
    path: 'mainConcern',
    canActivate: [authenticationGuard],
    component: MainConcernComponent,
  },
  {
    path: 'progress/:id',
    canActivate: [authenticationGuard, onboardingGuard],
    redirectTo: '/user/report/:id',
  },
  {
    path: 'report',
    canActivate: [authenticationGuard, onboardingGuard],
    component: ReportComponent,
  },
  {
    path: 'help',
    canActivate: [authenticationGuard, onboardingGuard],
    component: HelpComponent,
  },
  {
    path: 'quiz-game',
    canActivate: [authenticationGuard, onboardingGuard],
    component: QuizGameComponent,
  },
  {
    path: 'feedback',
    canActivate: [authenticationGuard, onboardingGuard],
    component: FeedbackComponent,
  },
  {
    path: 'feedback/:category',
    canActivate: [authenticationGuard, onboardingGuard],
    component: FeedbackComponent,
  },
  {
    path: 'login/doctor',
    component: LoginDoctorComponent,
  },
  {
    path: 'empty',
    canActivate: [authenticationGuard, onboardingGuard],
    component: EmptyComponent,
  },
  {
    path: 'addonProduct/:id',
    canActivate: [authenticationGuard, onboardingGuard],
    component: ArticleComponent,
  },
  {
    path: 'article/:id',
    canActivate: [authenticationGuard, onboardingGuard],
    component: ArticleComponent,
  },
  {
    path: 'product/:id',
    canActivate: [authenticationGuard, onboardingGuard],
    component: ProductDescriptionComponent,
  },
  {
    path: 'iframe',
    component: IframeComponent,
  },
  {
    path: 'user',
    canActivate: [authenticationGuard, onboardingGuard],
    children: [
      {
        path: '',
        component: UserViewComponent,
        pathMatch: 'full',
      },
      { path: 'testimonialsNew', component: TestimonialsNewComponent },
      { path: 'testimonialsNew/:type/:testimonialId', component: TestimonialsDescComponent },
      { path: 'caseStudies/:caseStudyId', component: TestimonialCaseStudiesInfoComponent },
      { path: 'checkout', component: UserCheckoutComponent },
      { path: 'followups', component: UserCheckupComponent },
      { path: 'checkup', component: UserCheckupComponent },
      { path: 'notifications', component: UserNotificationComponent },
      { path: 'followup/reminder', component: UserCheckupComponent },
      {
        path: 'regimen',
        children: [
          { path: 'products', component: UserRegimenProductsViewComponent },
          { path: 'doctor', component: UserRegimenDoctorComponent },
          { path: 'services', component: UserRegimenServicesComponent },
          { path: 'plan-info', component: ChoosePlanInfoComponent },
        ],
      },
      {
        path: 'address',
        children: [
          { path: '', component: UserAddressListComponent },
          { path: ':id/edit', component: UserAddressEditComponent },
          { path: 'new', component: UserAddressEditComponent },
        ],
      },
      { path: 'addresses', component: UserAddressListComponent },
      { path: 'report/:id', component: UserFollowupReportComponent },
      {
        path: 'instantCheckups',
        children: [
          { path: '', component: CheckupListComponent },
          { path: 'preview', redirectTo: '/instant-checkup/preview', pathMatch: 'full' },
        ],
      },
      {
        path: 'instantCheckup',
        children: [
          { path: '', component: SelectCheckupTypeComponent },
          { path: 'menu', component: SelectCheckupTypeComponent },
          { path: 'preview', component: CheckupPhotoInstructionComponent },
          { path: 'capture', component: CheckupCaptureComponent },
          { path: 'list', component: CheckupListComponent },
          { path: ':id', component: CheckupViewContainerComponent },
        ],
      },
      {
        path: 'orders',
        children: [
          { path: '', component: UserOrderListComponent },
          { path: 'success', component: UserOrderSuccessComponent },
          { path: 'failure', component: UserOrderFailureComponent },
          { path: 'reorder', component: UserOrderReorderComponent },
        ],
      },
      {
        path: 'order',
        children: [
          { path: '', component: UserOrderListComponent },
          { path: 'list', component: UserOrderListComponent },
          { path: 'reorder', component: UserOrderReorderComponent },
          { path: 'success', component: UserOrderSuccessComponent },
          { path: 'failure', component: UserOrderFailureComponent },
          { path: 'addon', component: UserOrderAddonComponent },
          { path: ':id/payment', component: UserOrderPaymentComponent },
          { path: ':id', component: UserOrderViewComponent },
        ],
      },
      { path: 'prescription', component: UserPrescriptionViewComponent },
      { path: 'refer', component: UserReferComponent },
      {
        path: 'wallet',
        children: [
          { path: '', component: UserWalletViewComponent },
          { path: 'transactions', component: UserWalletTransactionsComponent },
        ],
      },
      {
        path: 'profile',
        children: [
          { path: '', component: UserProfileComponent },
          { path: 'edit', component: UserProfileEditComponent },
        ],
      },
    ],
  }, {
    path: 'reminder/:id',
    canActivate: [authenticationGuard, onboardingGuard],
    component: ReminderFullPageComponent,
    outlet: 'reminder',
  }, {
    path: '**',
    canActivate: [authenticationGuard, onboardingGuard],
    redirectTo: '/',
  },
];

const declarations: any = [];

export { AppRoutes, declarations };
