/* eslint-disable */
import { TestConstantType,PrescriptionPlaceholder } from './constant-type';


export const TestConstants: TestConstantType = {
  DEFAULT_TIMEOUT: 120000,
  MIN_TIMEOUT: 5000,
  MAX_RETRY: 2,
  TEST_MOBILE: '2000000000',
  TEST_OTP: '1111',
  DOCTOR_USERNAME: 'e2eUser',
  DOCTOR_PASSWORD: 'testing',
  OPERATOR_USERNAME: 'operator',
  OPERATOR_PASSWORD: 'password',
  APP_SITE: 'https://test-app.cureskin.com',
  API_SITE: 'https://test-api.cureskin.com',
  DOCTOR_SITE: 'https://test-doctor.cureskin.com',

  CONTACT_DETAILS: {
    contactName: 'CureSkin Tester',
    zipCode: 560034,
    alternateNumber: 7777444410,
    buildingDetails: 'XXX, YYY, India',
    buildingDetails2: 'XXX, YYY, India',
    landmark: 'XXX YYY',
    city: 'Bangalore',
    state: 'Karnataka',
    tag: 'Home',
  },

  CONCERN_CLASS: {
    FACE: 'FACE',
    BODY: 'BODY',
    HAIR: 'HAIR',
  },
};

export const testimonialWallObject = {
  Male: {
    hi: {
      HAIR: [
        '1663731062_584dff1fab0d4f6d90f6ce738b7b6afd',
        '1663731074_cf97576d78e44ce39f65f2feacaa4ac5',
        '1663731086_6a3cc3de340148cfa5f5d15075d26d0d',
      ],
      FACE: [
        '1663731122_0efaaa0e2baf43f99bb2e878c65c56b6',
        '1663731132_7e6ffb8f15ff4397b88d94f588cc1b4c',
      ],
    },
    en: {
      HAIR: [
        '1663731024_cdd5be8ad1144833995dda5e2362af83',
        '1663731038_4d768f7a72114dcc856e968de67122b0',
        '1663731051_9809332c3d094d9da57ab92e1f96a416',
      ],
      FACE: [
        '1663731100_3076d75f8bad4df1bc78a526c879aa61',
        '1663731112_60427e6b41f44998bfe63cec7b0fa332',
      ],
    },
  },
  Female: {
    hi: {
      HAIR: [
        '1663730891_0dfa499e53574b0780fbf78f469cfd4c',
        '1663730903_0ae7601fff6d41eb8953f8bbcc82ff7f',
      ],
      FACE: [
        '1663730947_6322134d11e041e2ba697f6127c714a3',
        '1663730962_f7182284a04045b2b107f298d072e8ee',
      ],
    },
    en: {
      HAIR: [
        '1663725895_1a86107d1908440c99f0f873472005a2',
        '1663725922_dc199b60d8b84fcd863ecfdbf54b1b6a',
      ],
      FACE: [
        '1663730921_c097343d205a46e5bc6848ff2d8bfa2e',
        '1663730935_83671486a46441c8af2461b5f2abccae',
      ],
    },
  },
};

export const regimenImagesWithoutLipBalmExperiment: { [key: string]: string } = {
  v4_102_alternate_18:
    'https://storage.googleapis.com/heallo-other-user-files/doctor_api_jisha_10252022_1666694934666.png',
  v4_208_alternate_4:
    'https://storage.googleapis.com/heallo-other-user-files/doctor_api_jisha_10252022_1666695480035.png',
  v4_203_alternate_13:
    'https://cdn.cureskin.com/product/1596534377_fd5d51c244e4485aa29bd24afbeb4100.png',
  v4_302_alternate_17:
    'https://storage.googleapis.com/heallo-other-user-files/doctor_api_jisha_11012022_1667278559327.png',
  v4_221_alternate_15:
    'https://storage.googleapis.com/heallo-other-user-files/doctor_api_jisha_10252022_1666696953910.png',
  v4_326_alternate_3:
    'https://storage.googleapis.com/heallo-other-user-files/doctor_api_jisha_11012022_1667278630091.png',
  v4_305_alternate_5:
    'https://storage.googleapis.com/heallo-other-user-files/doctor_api_jisha_10062022_1665052598685.png',
  v4_101_alternate_8:
    'https://cdn.cureskin.com/product/1591548452_d79cc5c1cd2a4b49b759add2319f4f14.png',
};

// Testimonials New page constants
export type TestimonialStory = {
  testimonial: string;
  story: string;
  name: string;
  location: string;
  img: string;
  tags?: string[],
};

type TestimonialStories = {
  en: TestimonialStory[];
  hi: TestimonialStory[];
  te: TestimonialStory[];
  kn: TestimonialStory[];
  ta: TestimonialStory[];
  ml: TestimonialStory[];
  mr: TestimonialStory[];
  bn: TestimonialStory[];
};

export type RebrandedTestimonialStory = {
  testimonial: string;
  name: string;
  location: string;
  occupation?: string,
  userImg: string,
  testimonialImg: string,
  tags: Array<String>,
};

type RebrandedTestimonialStories = {
  Male: {
    en: RebrandedTestimonialStory[];
    hi: RebrandedTestimonialStory[];
    te: RebrandedTestimonialStory[];
    kn: RebrandedTestimonialStory[];
    ta: RebrandedTestimonialStory[];
    mr: RebrandedTestimonialStory[];
    ml: RebrandedTestimonialStory[];
    bn: RebrandedTestimonialStory[];
  },
  Female: {
    en: RebrandedTestimonialStory[];
    hi: RebrandedTestimonialStory[];
    te: RebrandedTestimonialStory[];
    kn: RebrandedTestimonialStory[];
    ta: RebrandedTestimonialStory[];
    mr: RebrandedTestimonialStory[];
    ml: RebrandedTestimonialStory[];
    bn: RebrandedTestimonialStory[];
  }
};

export type TestimonialCaseStudy = {
  concern: string;
  name: string;
  imageName: string;
  age: number;
  place: string;
  testimonial: string;
  story: string;
  duration: number;
  noOfCheckups: number;
  diet: string;
  tabletsGiven: string;
};

type TestimonialCaseStudies = {
  en: TestimonialCaseStudy[];
  hi: TestimonialCaseStudy[];
  te: TestimonialCaseStudy[];
  kn: TestimonialCaseStudy[];
  ta: TestimonialCaseStudy[];
  ml: TestimonialCaseStudy[];
  mr: TestimonialCaseStudy[];
  bn: TestimonialCaseStudy[];
};

export type GenderFormErrorMessagesType = {
  numberInName: string;
  emptyName: string;
  maxNameLength: string;
  underAged: string;
  invalidAge: string;
  enterAge: string;
};

export const HairTestimonialObject: TestimonialStories = {
en: [
  {
    'testimonial': 'My hair is thicker than before!',
    'story': 'After becoming a mother, I had severe hairfall. So much that my scalp was showing. But my Cureskin doctor helped make my hair dense and thick. The products given by them are very good. Now I also suggest my friends to use this app.',
    'name': 'Komal Panchal',
    'location': 'Dungarpur',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_1.jpg',
  },
  {
    'testimonial': 'Scalp seems fuller and the hair healthier...',
    'story': 'Earlier I had used their skin treatment kit. Thats why I decided to treat my hair with Cureskin only.My hair was falling a lot and had become very thin. Bald patches had started appearing in some places. The condition was so bad that my scalp had dandruff, itching and small pimples. But after I started using my Cureskin doctors hair treatment kit, there is a lot of improvement.',
    'name': 'Komal',
    'location': 'Bikaner',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_2.jpg',
  },
  {
    'testimonial': 'Hair fall stopped completely!',
    'story': 'Seriously, I feel more confident now.I have been using my treatment kit continuously for the last 6 months. I also keep taking the free doctor checkups given to me in the app.',
    'name': 'Premjit',
    'location': 'Srinagar',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_3.jpg',
  },
  {
    'testimonial': 'I am seeing my hair grow in my 40s...',
    'story': 'When I installed the app, I never thought I would see such good results. I was clear that I didnt want any tablets, and my Cureskin doctor ensured I got a treatment kit without any supplements or tablets.Thanks to them, my hairfall has significantly reduced and my scalp is no longer visible.',
    'name': 'Madhumita',
    'location': 'Uttar Pradesh',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_4.jpg',
  },
  {
    'testimonial': 'Thanks to the doctor, my hair was saved!',
    'story': 'My father had suffered hair loss. So when I too started getting hair fall in my 20s I wanted to take it seriously. Thats why I downloaded this app. For the last 7 months, my Cureskin doctor has been guiding me through my hair treatment. They gave me the best products. Plus, the right advice at the right time. ',
    'name': 'Vishvajeet',
    'location': 'Madhya Pradesh',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_5.jpg',
  }
],
hi:[
  {
    'testimonial': 'मेरे बाल पहले से घने हैं !',
    'story': 'माँ बनने के बाद से मेरे बाल बहुत झड़ रहे थे। इतना की सिर पर बाल भी कम हो गए। लेकिन Cureskin के डॉक्टर ने मेरे बालों को घना बनाने में मदद की। उनके दिए प्रोडक्ट्स बहुत ही अच्छे हैं। अब तो मैं अपने सहेलियों को भी इस ऐप को यूज़ करने बोलती हूँ।',
    'name': 'कोमल पंचाल',
    'location': 'डूंगरपुर',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_1.jpg',
  },
  {
    'testimonial': 'अब मेरी मांग भरी-भरी दिखती है.. ',
    'story': 'पहले बाल ज्यादा झड़ते थे और बहुत पतले भी हो गए थे। कहीं-कहीं पे गंजापन दिखने लगा था। सिर पर डैंड्रफ, खुजली और छोटी-छोटी फुंसियां भी थी। लेकिन डॉक्टर का ट्रीटमेंट किट इस्तेमाल करने के बाद मुझे काफी फायदा हुआ। इससे पहले मैंने इनका दिया स्किन ट्रीटमेंट किट भी इस्तेमाल किया था। ',
    'name': 'कोमल ',
    'location': 'बीकानेर',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_2.jpg',
  },
  {
    'testimonial': 'बाल झड़ना पूरी तरह बंद हो गया!',
    'story': 'पसच में, मैं अब पहले से ज़्यादा कॉंफिडेंट महसूस करता हूँ। पिछले 6 महीने से मैं लगातार अपना ट्रीटमेंट किट इस्तेमाल कर रहा हूँ। मैं ऐप में दिए फ्री डॉक्टर चेकअप भी करवाता रहता हूँ।',
    'name': 'प्रेमजीत ',
    'location': 'श्रीनगर',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_3.jpg',
  },
  {
    'testimonial': '40 की उम्र में बाल फिर से उगते देख पा रही हूँ...',
    'story': 'ऐप इंस्टॉल करते समय मैंने नहीं सोचा था कि मुझे इतने अच्छे परिणाम मिलेंगे। मैंने अपनी Cureskin डॉक्टर को बताया था कि मुझे कोई टैबलेट नहीं चाहिए, और उन्होंने इस बात का पूरा ध्यान रखा।उनकी वजह से ही मेरे बालों का झड़ना बहुत कम हो गया है। सबसे बड़ी बात, मेरा स्कैल्प अब बालों में अलग से नहीं दिखता।',
    'name': 'मधुमिता ',
    'location': 'उत्तर प्रदेश ',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_4.jpg',
  },
  {
    'testimonial': 'मेरे बाल-बाल बच गए, सिर्फ डॉक्टर की वजह से!',
    'story': 'मेरे पिताजी को हेयर लॉस था। इसलिए जब 20 की उम्र में मेरे भी बाल झड़ने लगे, तो मैं उसे लेकर काफी सीरियस हो गया। तभी मैंने डॉक्टर्स की मदद लेने के लिए ये ऐप डाउनलोड की। पिछले 7 महीनों से मेरे Cureskin डॉक्टर मेरे बालों के ट्रीटमेंट में मेरी मदद कर रहे हैं। उन्होंने मुझे बेस्ट प्रोडक्ट्स सुझाये और सही समय पर सही सलाह भी दी।',
    'name': 'विश्वजीत',
    'location': 'मध्य प्रदेश',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_5.jpg'
  }
],
te: [
  {
    'testimonial': 'నా జుట్టు మునుపటి కంటే ఒత్తుగా ఉంది!',
    'story': 'అమ్మను అయిన తర్వాత నాకు జుట్టు బాగా రాలి దాంతోటి తలపై ఉన్న వెంట్రుకలు తగ్గి నెత్తి కనిపించడం ప్రారంభమైంది. కానీ నా Cureskin డాక్టర్ నా జుట్టును ఒత్తుగా మరియు మందంగా చేయడానికి సహాయం చేశారు. వారు ఇచ్చిన ప్రోడక్ట్స్ చాలా బాగున్నాయి. ఇప్పుడు నేను నా స్నేహితులను కూడా ఈ యాప్‌ని ఉపయోగించమని సూచిస్తున్నాను.',
    'name': 'కోమల్ పంచల్,',
    'location': 'దుంగార్పూర్',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_1.jpg',
  },
  {
    'testimonial': 'తలపై జుట్టు ఒత్తుగా మరియు ఆరోగ్యంగా కనిపిస్తుంది...',
    'story': 'ఇంతకు ముందు నేను Cureskin వారి స్కిన్ ట్రీట్మెంట్ కిట్‌ని ఉపయోగించాను. కాబట్టి, ఇప్పుడు నేను నా హెయిర్ కి కూడా వారితోనే ట్రీట్మెంట్ చేయించుకోవాలని నిర్ణయించుకున్నాను.నా జుట్టు బాగా రాలి సన్నగా మారింది. కొన్ని చోట్ల బట్టతల, మచ్చలు కనిపించడం ప్రారంభించింది. పరిస్థితి చాలా దారుణంగా మారి, నా తలలో చుండ్రు, దురద మరియు చిన్న కురుపులు కూడా ఉన్నాయి. కానీ నేను నా Cureskin డాక్టర్ యొక్క హెయిర్ ట్రీట్‌మెంట్ కిట్‌ని ఉపయోగించడం ప్రారంభించిన తర్వాత, చాలా మెరుగుదలని గమనిస్తున్నాను.',
    'name': 'కోమల్',
    'location': 'బికనీర్',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_2.jpg',
  },
  {
    'testimonial': 'జుట్టు రాలడం పూర్తిగా ఆగిపోయింది!',
    'story': 'నిజంగా, నేను ఇప్పుడు చాలా నమ్మకంగా ఉన్నాను.నేను గత 6 నెలలుగా నా ట్రీట్‌మెంట్ కిట్‌ని క్రమం తప్పకుండా ఉపయోగిస్తున్నాను. అంతేకాకుండా నేను యాప్‌లో నాకు ఇచ్చిన ఫ్రీ డాక్టర్ చెక్అప్‌లను కూడా తీసుకుంటాను.',
    'name': 'ప్రేమ్‌జిత్',
    'location': 'శ్రీనగర్',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_3.jpg',
  },
  {
    'testimonial': '40 ఏళ్లలో నా జుట్టు బాగా పెరగడాన్ని గమనించాను...',
    'story': 'నేను యాప్‌ని ఇన్‌స్టాల్ చేసుకున్నప్పుడు, ఇంత మంచి ఫలితాలను పొందుతానని ఎప్పుడూ అనుకోలేదు. అయితే నేను టాబ్లెట్‌లు వాడకూడదని కచ్చితంగా అనుకున్నాను అప్పుడు నా Cureskin డాక్టర్ నాకు ఎలాంటి సప్లిమెంట్‌లు లేదా టాబ్లెట్‌లు లేకుండా ట్రీట్మెంట్ కిట్‌ని నాకు ఇచ్చారు.వారికి ధన్యవాదాలు, నా జుట్టు రాలడం క్రమంగా తగ్గింది మరియు నా తలపై చర్మం కూడా కనిపించడం లేదు.',
    'name': 'మధుమిత',
    'location': 'ఉత్తరప్రదేశ్',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_4.jpg',
  },
  {
    'testimonial': 'డాక్టర్‌కి ధన్యవాదాలు, వారి వలనే నా జుట్టు రక్షించబడింది!',
    'story': 'మా నాన్నకు జుట్టు బాగా రాలిపోయింది. కాబట్టి నేను కూడా నా 20 ఏళ్ళలో జుట్టు రాలడం ప్రారంభించినప్పుడు దాని గురించి చాలా ఆందోళన చెందాను. అందుకే ఈ యాప్‌ని డౌన్‌లోడ్ చేసుకున్నాను. గత 7 నెలలుగా, నా Cureskin డాక్టర్ నా హెయిర్ ట్రీట్మెంట్ కిట్ ద్వారా నాకు మార్గనిర్దేశం చేస్తున్నారు. వారు నాకు చాలా మంచి ప్రోడక్ట్స్ ని, సరైన సమయంలో సరైన సలహాలతో అందించారు.',
    'name': 'విశ్వజీత్',
    'location': 'మధ్యప్రదేశ్',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_5.jpg',
  }
],
kn: [
  {
    'testimonial': 'ನನ್ನ ಕೂದಲು ಮೊದಲಿಗಿಂತ ದಟ್ಟವಾಗಿದೆ!',
    'story': 'ತಾಯಿಯಾದ ನಂತರ, ನನ್ನ ಕೂದಲು ತುಂಬಾ ಉದುರುತ್ತಿತ್ತು. ಎಷ್ಟು ಎಂದರೆ ನನ್ನ ನೆತ್ತಿಯು ಕಾಣುತ್ತಿತ್ತು. ಆದರೆ ನನ್ನ Cureskin ಡಾಕ್ಟರ್ ಅವರು ನನ್ನ ಕೂದಲು ದಟ್ಟ ಮತ್ತು ದಪ್ಪವಾಗಿಸಲು ಸಹಾಯ ಮಾಡಿದರು. ಅವರು ಕೊಟ್ಟ ಪ್ರಾಡಕ್ಟ್ ಗಳು ತುಂಬಾ ಚೆನ್ನಾಗಿವೆ. ಈಗ ನಾನು ನನ್ನ ಸ್ನೇಹಿತರಿಗೆ ಕೂಡಾ ಈ ಆಪ್ ಅನ್ನು ಬಳಸಲು ಸಲಹೆ ನೀಡುತ್ತೇನೆ.',
    'name': 'ಕೋಮಲ್ ಪಾಂಚಾಲ್',
    'location': 'దుంగార్పూర్',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_1.jpg',
  },
  {
    'testimonial': 'ನೆತ್ತಿಯು ಹೇರಳ ಮತ್ತು ಕೂದಲು ಆರೋಗ್ಯವಾಗಿ ಕಾಣುತ್ತವೆ...',
    'story': 'ಮೊದಲು ನಾನು ಅವರ ಚರ್ಮದ ಟ್ರೀಟ್ಮೆಂಟ್ ಕಿಟ್ ಬಳಸಿದ್ದೆ. ಹಾಗಾಗಿ ನಾನು ನನ್ನ ಕೂದಲನ್ನು Cureskin ನೊಂದಿಗೆ ಮಾತ್ರ ಟ್ರೀಟ್ ಮಾಡಲು ನಿರ್ಧರಿಸಿದೆ.ನನ್ನ ಕೂದಲು ತುಂಬಾ ಉದುರುತ್ತಿತ್ತು ಮತ್ತು ತುಂಬಾ ತೆಳುವಾಗಿತ್ತು. ಕೆಲವು ಸ್ಥಳಗಳಲ್ಲಿ ಬೊಕ್ಕತನದ ತೇಪೆಗಳು ಕಾಣಿಸಲು ಆರಂಭವಾಗಿದ್ದವು. ಪರಿಸ್ಥಿತಿ ಎಷ್ಟು ವಿಕಟವಾಗಿತ್ತು ಎಂದರೆ ನನ್ನ ನೆತ್ತಿಯ ಮೇಲೆ ತಲೆಹೊಟ್ಟು, ತುರಿಸುವಿಕೆ ಮತ್ತು ಚಿಕ್ಕ ಮೊಡವೆಗಳು ಆಗಿದ್ದವು. ಆದರೆ ನಾನು ನನ್ನ Cureskin ಡಾಕ್ಟರ್ ಅವರ ಕೂದಲು ಟ್ರೀಟ್ಮೆಂಟ್ ಕಿಟ್ ಬಳಸಲು ಆರಂಭಿಸಿದ ಮೇಲೆ, ತುಂಬಾ ಸುಧಾರಣೆ ಇದೆ.',
    'name': 'ಕೋಮಲ್',
    'location': 'ಬಿಕಾನೇರ್',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_2.jpg',
  },
  {
    'testimonial': 'ಕೂದಲು ಉದುರುವಿಕೆ ಪೂರ್ಣವಾಗಿ ನಿಂತಿದೆ!',
    'story': 'ನಿಜವಾಗಿಯೂ, ನನಗೆ ಈಗ ಇನ್ನೂ ಹೆಚ್ಚು ಆತ್ಮವಿಶ್ವಾಸ ಬಂದಿದೆ.ನಾನು ಕಳೆದ 6 ತಿಂಗಳಿನಿಂದ ಸತತವಾಗಿ ನನ್ನ ಟ್ರೀಟ್ಮೆಂಟ್ ಕಿಟ್ ಅನ್ನು ಬಳಸುತ್ತಿದ್ದೇನೆ. ನನಗೆ ಆಪ್ ನಲ್ಲಿ ನೀಡಿದ ಉಚಿತ ಡಾಕ್ಟರ್ ಚೆಕ್ ಅಪ್ ಗಳನ್ನು ಕೂಡಾ ನಾನು ತೆಗೆದುಕೊಳ್ಳುತ್ತಿರುತ್ತೇನೆ.',
    'name': 'ಪ್ರೇಮಜಿತ್',
    'location': 'ಶ್ರೀನಗರ',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_3.jpg',
  },
  {
    'testimonial': 'ನನ್ನ 40ರ ವಯಸ್ಸಿನಲ್ಲಿ ನನ್ನ ಕೂದಲು ಬೆಳೆಯುವುದನ್ನು ನಾನು ನೋಡುತ್ತಿದ್ದೇನೆ...',
    'story': 'ನಾನು ಆಪ್ ಅನ್ನು ಇನ್ಸ್ಟಾಲ್ ಮಾಡಿದಾಗ, ಇಷ್ಟು ಉತ್ತಮ ಪರಿಣಾಮಗಳನ್ನು ನೋಡುತ್ತೇನೆ ಎಂದು ನಾನು ಎಂದಿಗೂ ಯೋಚಿಸಿರಲಿಲ್ಲ. ನನಗೆ ಯಾವುದೇ ಟ್ಯಾಬ್ಲೆಟ್ ಗಳು ಬೇಡ ಎಂದು ನನಗೆ ಸ್ಪಷ್ಟವಿತ್ತು, ಮತ್ತು ಯಾವುದೇ ಪೋಷಕಗಳು ಅಥವಾ ಟ್ಯಾಬ್ಲೆಟ್ ಗಳು ಇಲ್ಲದೆ ಟ್ರೀಟ್ಮೆಂಟ್ ಕಿಟ್ ಪಡೆಯುವಂತೆ ನನ್ನ Cureskin ಡಾಕ್ಟರ್ ಅವರು ಖಚಿತಪಡಿಸಿದರು.ಅವರಿಗೆ ಧನ್ಯವಾದಗಳು, ನನ್ನ ಕೂದಲು ಉದುರುವಿಕೆ ಗಮನಾರ್ಹವಾಗಿ ಕಡಿಮೆಯಾಗಿದೆ ಮತ್ತು ನನ್ನ ನೆತ್ತಿಯು ಈಗ ಕಾಣುವುದಿಲ್ಲ.',
    'name': 'ಮಧುಮಿತಾ',
    'location': 'ಉತ್ತರ ಪ್ರದೇಶ',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_4.jpg',
  },
  {
    'testimonial': 'ಡಾಕ್ಟರ್ ಗೆ ಧನ್ಯವಾದಗಳು, ನನ್ನ ಕೂದಲು ಉಳಿಯಿತು!',
    'story': 'ನನ್ನ ತಂದೆಗೆ ಕೂದಲು ಉದುರುವಿಕೆಯಾಗಿತ್ತು. ಹಾಗಾಗಿ 20ರ ವಯಸ್ಸಿನಲ್ಲಿ ನನ್ನ ಕೂದಲು ಕೂಡಾ ಉದುರಲು ಆರಂಭವಾದಾಗ ನಾನು ಅದನ್ನು ಗಂಭೀರವಾಗಿ ಪರಿಗಣಿಸಿದೆ. ಆದ್ದರಿಂದ ನಾನು ಈ ಆಪ್ ಅನ್ನು ಡೌನ್ಲೋಡ್ ಮಾಡಿದೆ. ಕಳೆದ 7 ತಿಂಗಳಿನಿಂದ, ನನ್ನ Cureskin ಡಾಕ್ಟರ್ ಅವರು ನನ್ನ ಕೂದಲಿನ ಟ್ರೀಟ್ಮೆಂಟ್ ಮೂಲಕ ನನಗೆ ಮಾರ್ಗದರ್ಶನ ನೀಡುತ್ತಿದ್ದಾರೆ. ಅವರು ನನಗೆ ಉತ್ತಮ ಪ್ರಾಡಕ್ಟ್ ಗಳನ್ನು ಕೊಟ್ಟರು. ಜೊತೆಗೆ, ಸರಿಯಾದ ಸಮಯಕ್ಕೆ ಸರಿಯಾದ ಸಲಹೆ ಕೂಡಾ. ',
    'name': 'ವಿಶ್ವಜೀತ್',
    'location': 'ಮಧ್ಯ ಪ್ರದೇಶ',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_5.jpg',
  }
],
ta: [
  {
    'testimonial': 'என் தலைமுடி முன்பை விட அடர்த்தியாக இருக்கிறது!',
    'story': 'தாயான பிறகு என் உச்சந்தலை தெரியும் அளவுக்கு எனக்கு கடுமையான முடி உதிர்வு ஏற்பட்டது.எனது க்யூர்ஸ்கின் மருத்துவர் எனது தலைமுடியை அடர்த்தியாக மாற்ற உதவினார். அவர்கள் கொடுக்கும் பொருட்கள் மிகவும் நன்றாக இருக்கிறது.   இப்போது எனது நண்பர்களுக்கும் இந்த  ஆப்-ஐ பயன்படுத்த பரிந்துரைக்கிறேன்.',
    'name': 'கோமல் பஞ்சால்',
    'location': 'துங்கர்பூர்',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_1.jpg',
  },
  {
    'testimonial': 'உச்சந்தலை முழுமையாகவும்,  தலைமுடி ஆரோக்கியமாகவும் தெரிகிறது...',
    'story': 'உண்மையில் நான் இப்போது அதிக நம்பிக்கையுடன் உணர்கிறேன்.கடந்த 6 மாதங்களாக எனது சிகிச்சை தயாரிப்பை தொடர்ந்து பயன்படுத்தி வருகிறேன். ஆப்-இல் எனக்குக் கொடுக்கப்பட்ட இலவச மருத்துவர் பரிசோதனைகளையும் நான் தொடர்ந்து எடுத்து வருகிறேன்.',
    'name': 'பிரேம்ஜித்',
    'location': 'ஸ்ரீநகர்',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_2.jpg',
  },
  {
    'testimonial': 'முடி உதிர்வு முற்றிலுமாக நின்றுவிட்டது!',
    'story': 'உண்மையில் நான் இப்போது அதிக நம்பிக்கையுடன் உணர்கிறேன்.கடந்த 6 மாதங்களாக எனது சிகிச்சை தயாரிப்பை தொடர்ந்து பயன்படுத்தி வருகிறேன். ஆப்-இல் எனக்குக் கொடுக்கப்பட்ட இலவச மருத்துவர் பரிசோதனைகளையும் நான் தொடர்ந்து எடுத்து வருகிறேன்.',
    'name': 'பிரேம்ஜித்',
    'location': 'ஸ்ரீநகர்',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_3.jpg',
  },
  {
    'testimonial': '40 வயதில் என் கூந்தல் வளர்வதை நான் காண்கிறேன்.',
    'story': 'நான் இந்த ஆப்-ஐ நிறுவியபோது, இதுபோன்ற நல்ல விளைவுகளை நான் காண்பேன் என்று நான் நினைக்கவே இல்லை.எனக்கு மாத்திரைகள் எதுவும் தேவையில்லை என்பதில் நான் தெளிவாக இருந்தேன், மேலும் எனது க்யூர்ஸ்கின் மருத்துவர் எந்தவிதமான சப்ளிமெண்ட்ஸ் அல்லது மருந்துகள் இல்லாமல் ஒரு சிகிச்சைத் தயாரிப்பை நான்  பெறுவதை உறுதி செய்தார்.அவர்களுக்கு நன்றி, என் முடி உதிர்வு கணிசமாகக் குறைந்துவிட்டது, மேலும் என் உச்சந்தலை இப்போது தெரியவில்லை.',
    'name': 'மதுமிதா',
    'location': 'உத்தரபிரதேசம்',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_4.jpg',
  },
  {
    'testimonial': 'மருத்துவருக்கு நன்றி, என் தலைமுடி காப்பாற்றப்பட்டது!',
    'story': 'என் தந்தைக்கு முடி உதிர்வு ஏற்பட்டது. அதனால் எனக்கும் என் 20 வயதில் முடி கொட்ட ஆரம்பித்தபோது இந்த பிரச்சினையை தீவிரமாக எடுத்துக்கொள்ள நினைத்தேன். அதனால்தான் இந்த ஆப்-ஐ பதிவிறக்கம் செய்தேன். கடந்த 7 மாதங்களாக எனது க்யூரெஸ்கின் மருத்துவர் எனது தலைமுடிக்கான சிகிச்சையின் மூலம் எனக்கு வழிகாட்டி வருகிறார். அவர்கள் எனக்கு சிறந்த தயாரிப்புகளை வழங்கினர். மேலும், அவர்கள் சரியான நேரத்தில் சரியான ஆலோசனைகளை வழங்கினர்.',
    'name': 'விஸ்வஜீத்',
    'location': 'மத்திய பிரதேசம்',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_5.jpg',
  }
],
ml: [
  {
    'testimonial': 'എന്റെ മുടിക്ക് മുമ്പത്തേക്കാൾ കട്ടി!',
    'story': 'അമ്മ ആയ ശേഷം, എനിക്ക് തീവ്രമായ മുടികൊഴിച്ചിൽ ഉണ്ടായിരുന്നു. എന്റെ തലയോട്ടി കാണുന്ന തരത്തിൽ ആയിരുന്നു അത്. പക്ഷെ എന്റെ Cureskin ഡോക്ടർ എന്റെ മുടി ഇടതൂർന്നതും കട്ടിയുള്ളതും ആക്കാൻ സഹായിച്ചു. അവർ നൽകുന്ന ഉത്പന്നങ്ങൾ വളരെ നല്ലതാണ്. ഇപ്പോൾ ഞാൻ കൂട്ടുകാരോടും ഈ ആപ്പ് ഉപയോഗിക്കാൻ പറയുന്നു.',
    'name': 'കോമൾ പഞ്ചാൽ',
    'location': 'ദുൻഗാർപൂർ',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_1.jpg',
  },
  {
    'testimonial': 'തലയോട്ടി ഇടതൂർന്നതും മുടി ആരോഗ്യമുള്ളതും...',
    'story': 'മുൻപ് ഞാൻ അവരുടെ ചർമ്മ ചികിത്സ കിറ്റ് ഉപയോഗിച്ചിരുന്നു. അത് കൊണ്ടാണ് എന്റെ മുടിക്കും Cureskin ചികിത്സ തന്നെ ചെയ്യാൻ ഞാൻ തീരുമാനിച്ചത്.എന്റെ മുടി ഒരു പാട് കൊഴിയുന്നുണ്ടായിരുന്നു മാത്രമല്ല വളരെ കട്ടി കുറഞ്ഞു. ചില സ്ഥലത്ത് കഷണ്ടി ആയി തുടങ്ങി. എന്റെ തലയോട്ടിയിലെ താരൻ, ചൊറിച്ചിൽ, ചെറിയ കുരുക്കൾ എല്ലാം കൊണ്ട് സ്ഥിതി ആകെ മോശം ആയിരുന്നു. പക്ഷെ എന്റെ Cureskin ഡോക്ടറിന്റെ മുടി ചികിത്സ കിറ്റ് ഉപയോഗിക്കാൻ തുടങ്ങിയ ശേഷം, ഒരു പാട് മെച്ചപ്പെട്ടു. ',
    'name': 'കോമൾ',
    'location': 'ബിക്കാനെർ',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_2.jpg',
  },
  {
    'testimonial': 'മുടികൊഴിച്ചിൽ പൂർണമായി നിന്നു!',
    'story': 'ശരിക്കും, എനിക്ക് ഇപ്പോൾ കൂടുതൽ ആത്മവിശ്വാസം തോന്നുന്നു.കഴിഞ്ഞ 6 മാസമായി ഞാൻ എന്റെ ചികിത്സ കിറ്റ് തുടർച്ചയായി ഉപയോഗിക്കുന്നു. ആപ്പിൾ എനിക്ക് കിട്ടുന്ന ഫ്രീ ഡോക്ടർ ചെക്കപ്പുകളും ഞാൻ എടുക്കുന്നു. ',
    'name': 'പ്രേംജിത്',
    'location': 'ശ്രീനഗർ',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_3.jpg',
  },
  {
    'testimonial': 'എന്റെ മുടി നാൽപതുകളിൽ വളരുന്നത് കാണുന്നു...',
    'story': 'ആപ്പ് ഇൻസ്റ്റാൾ ചെയ്തപ്പോൾ, ഒരിക്കലും ഇത്ര നല്ല ഫലം കിട്ടുമെന്ന് ഞാൻ വിചാരിച്ചില്ല. ടാബ്‌ലെറ്റ് ഒന്നും വേണ്ടെന്ന് എനിക്ക് നിർബന്ധമായിരുന്നു, എന്റെ Cureskin ഡോക്ടർ സപ്പ്ളിമെന്റുകളോ ടാബ്ലെറ്റുകളോ ഇല്ലാത്ത ചികിത്സ കിറ്റ് നൽകുന്നു എന്ന് ഉറപ്പാക്കി. അവർക്ക് നന്ദി, എന്റെ മുടികൊഴിച്ചിൽ നന്നായി കുറഞ്ഞു മാത്രമല്ല തലയോട്ടി കാണുന്നുമില്ല..',
    'name': 'മധുമിത',
    'location': 'ഉത്തർ പ്രദേശ്',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_4.jpg',
  },
  {
    'testimonial': 'ഡോക്ടറിന് നന്ദി, എന്റെ മുടിയെ രക്ഷിച്ചു!',
    'story': 'എന്റെ അച്ഛന് മുടി കൊഴിച്ചിൽ ഉണ്ടായിരുന്നു. അത് കൊണ്ട് എനിക്ക് ഇരുപതുകളിൽ മുടി കൊഴിച്ചിൽ ഉണ്ടായപ്പോൾ, അത് ഗൗരവത്തോടെ കാണണം എന്ന് എനിക്ക് തോന്നി. അത് കൊണ്ടാണ് ഞാൻ ഈ ആപ്പ് ഡൌൺലോഡ് ചെയ്തത്. കഴിഞ്ഞ 7 മാസങ്ങളായി, എന്റെ Cureskin ഡോക്ടർ മുടി ചികിത്സയിൽ ഉടനീളം എനിക്ക് നിർദേശങ്ങൾ തരുന്നു. അവർ എനിക്ക് മികച്ച ഉത്പന്നങ്ങൾ തന്നു. കൂടാതെ, ശരിയായ സമയത്ത് ശരിയായ ഉപദേശവും.',
    'name': 'വിശ്വജീത്',
    'location': 'മധ്യ പ്രദേശ്',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_5.jpg',
  }
],
mr: [
  {
    'testimonial': 'माझे केस पहिल्या पेक्षा जास्त दाट झाले!',
    'story': 'आई झाल्यावर, मला खूप केसगळतीचा त्रास होऊ लागला आणि त्यामुळे माझी टाळू दिसू लागली.  पण माझ्या Cureskin डॉक्टरांनी माझे केस घनदाट होण्यास मदत केली.  त्यांनी दिलेली प्रॉडक्ट्स खूप चांगली आहेत. आता मी माझ्या मित्रांनाही हे ॲप वापरण्याचा सल्ला देते.',
    'name': 'कोमल पांचाळ',
    'location': 'डुंगरपूर',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_1.jpg',
  },
  {
    'testimonial': 'स्कॅल्प भरलेले दिसते आणि केस निरोगी...',
    'story': 'मी आधी स्किन ट्रीटमेंट किट वापरले होते. त्यामुळेच मी केसांचा उपचार देखील Cureskin कडूनच करण्याचे ठरवले. माझे केस खूप गळत होते आणि पातळ ही झाले होते. काही ठिकाणी टक्कल ही पडू लागले होते. माझ्या केसांची परिस्थिती इतकी वाईट होती की मला कोंडा, खाज व छोटे पिंपल हे त्रास होऊ लागले होते. पण मी जेव्हापासून Cureskin च्या डॉक्टरांनी दिलेले हेअर ट्रीटमेंट किट वापरायला लागले, मला खूप चांगला परिणाम जाणवला.',
    'name': 'कोमल',
    'location': 'बिकानेर',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_2.jpg',
  },
  {
    'testimonial': 'केसगळती लगेच थांबली! ',
    'story': 'खरंच मला आता अधिक आत्मविश्वास वाटतो. मी माझे ट्रीटमेंट किट गेल्या 6 महिन्यांपासून सतत वापरत आहे.  मी अ‍ॅप मध्ये दिलेले मोफत डॉक्टरांचे चेक-अप देखील घेतो. ',
    'name': 'प्रेमजीत',
    'location': 'श्रीनगर',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_3.jpg',
  },
  {
    'testimonial': 'चाळीशीमध्ये केसांची अधिक वाढ...',
    'story': 'जेव्हा मी ॲप इंस्टॉल केले, मला कधीच वाटले नव्हते की इतके चांगले परिणाम दिसतील. माझे नक्की होते की मी कसल्याही गोळ्या खाणार नाही आणि माझ्या Cureskin डॉक्टरांनी देखील ट्रीटमेंट किट मध्ये, गोळ्या खाव्या लागणार नाही याची हमी दिली. त्यांच्यामुळेच आज माझी केस गळती खूप कमी झाली व टक्कल ही दिसत नाही.',
    'name': 'मधुमिता',
    'location': 'उत्तर प्रदेश',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_4.jpg',
  },
  {
    'testimonial': 'डॉक्टरांचे आभार, माझे केस वाचले!',
    'story': 'माझ्या वडिलांना केसगळतीचा त्रास होता. त्यामुळे जेव्हा माझे 20 व्या वर्षी केस गळायला लागले तेव्हा मला ते गांभीर्याने घ्यायचे होते. म्हणून मी हे ॲप डाऊनलोड केले. मागच्या 7 महिन्यांपासून, माझे Cureskin चे डॉक्टर माझ्या केसांच्या उपचाराकरिता मला मार्गदर्शन करीत आहेत. त्यांनी मला उत्तम प्रॉडक्ट्स दिली आहेत. त्यासोबत योग्य वेळी योग्य सल्ले ही दिले आहेत. ',
    'name': 'विश्वजीत',
    'location': 'मध्य प्रदेश',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_5.jpg',
  }
],
bn: [
  {
    'testimonial': 'আমার চুল আগের থেকে ঘন হয়েছে !',
    'story': 'মা হওয়ার পরে আমার প্রচুর চুল পড়ছিল। আমার এতটাই চুল পড়ে গেছিলো যে টাক পর্যন্ত দেখা যাচ্ছিলো। কিন্তু Cureskin-এর ডাক্তার আমার চুলকে ঘন করতে আমাকে সাহায্য করেছেন। তাঁদের দেওয়া প্রোডাক্টস খুব ভালো। এখন আমি আমার বন্ধুদেরকেও এই অ্যাপটা ব্যবহার করতে বলবো।',
    'name': 'কোমল পাঞ্চাল',
    'location': 'ডুঙ্গারপুর',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_1.jpg',
  },
  {
    'testimonial': 'এখন আমার মাথায় চুল ভর্তি হয়ে গেছে.. ',
    'story': 'আগে আমি এদের স্কিন ট্রিটমেন্ট কিট ব্যবহার করেছিলাম। আর তাই আমি ঠিক করেছিলাম যে আমি Cureskin-এর থেকেই আমার চুলের ট্রিটমেন্ট করবো। আমার ভীষণ চুল পড়ছিল আর চুল পাতলা হয়ে গেছিলো। কিছু কিছু জায়গায় টাক দেখা যাচ্ছিলো। অবস্থা খুব খারাপ ছিলো, আমার মাথায় খুশকি, চুলকানি আর ছোটো ছোটো ফুসকুড়ি ছিলো। কিন্তু Cureskin-এর ডাক্তারের দেওয়া হেয়ার ট্রিটমেন্ট কিট ব্যবহার করতে শুরু করার পরে আমার অনেক উপকার হয়েছে। ',
    'name': 'কোমল',
    'location': 'বিকানের',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_2.jpg',
  },
  {
    'testimonial': 'চুল পড়া একদম বন্ধ হয়ে গেছে!',
    'story': 'সত্যিই, আমার এখন নিজেকে আগের থেকে বেশি আত্মবিশ্বাসী বলে মনে হয়।গত 6 মাস ধরে আমি নিয়মিতভাবে আমার ট্রিটমেন্ট কিট ব্যবহার করছি। আমি অ্যাপে দেওয়া ফ্রী ডক্টর চেকআপও করাতে থাকি।',
    'name': 'প্রেমজিৎ',
    'location': 'শ্রীনগর',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_3.jpg',
  },
  {
    'testimonial': '40 বছর বয়সে আমার চুল আবার গজাচ্ছে...',
    'story': 'অ্যাপ ইনস্টল করার সময় আমি ভাবতেও পারিনি যে আমি এত ভালো ফলাফল পাবো। আমি আমার Cureskin-এর ডাক্তারকে বলেছিলাম যে আমি কোনো ট্যাবলেট খেতে চাই না, আর তিনি এই ব্যাপারটা মাথায় রেখে কোনো সাপ্লিমেন্ট ও ট্যাবলেট ছাড়াই আমাকে ট্রিটমেন্ট কিট দিয়েছেন।তাঁদের টিমকে ধন্যবাদ জানাই, কারণ তাঁদের জন্য আমার চুল পড়া অনেক কমে গেছে। সবথেকে বড় কথা, এখন আর আমার মাথার ত্বক দেখা যায় না।',
    'name': 'মধুমিতা',
    'location': 'উত্তরপ্রদেশ',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_4.jpg',
  },
  {
    'testimonial': 'শুধুমাত্র ডাক্তারের জন্য আমার চুল বেঁচে গেছে!',
    'story': 'আমার বাবার চুল পড়ে যাচ্ছিলো। তাই যখন 20 বছর বয়সে আমার চুল পড়তে থাকলো, তখন আমি এই বিষয়টাকে খুব গুরুত্ব সহকারে নিয়েছিলাম। তখন আমি ডাক্তারের সাহায্য নেওয়ার জন্য এই অ্যাপটা ডাউনলোড করেছিলাম। গত 7 মাস ধরে, আমার Cureskin-এর ডাক্তার আমার চুলের ট্রিটমেন্টের ক্ষেত্রে আমাকে সাহায্য করছেন। তাঁরা আমাকে সেরা প্রোডাক্ট দিয়েছেন আর সঠিক সময় সঠিক পরামর্শও দিয়েছেন।',
    'name': 'বিশ্বজিৎ',
    'location': 'মধ্যপ্রদেশ',
    'img': 'https://cdn.cureskin.com/app/img/hairTestimonial_5.jpg',
  }
]
};
export const TestimonialStoriesObject: TestimonialStories = {
  en: [
    {
      'testimonial': 'Loved Cureskin! Adjusted my skincare routine for pregnancy.',
      'story': 'Cureskin\'s monthly skin check-ups are fantastic. When I mentioned my pregnancy, the doctor promptly adjusted my skincare products to ensure my well-being.',
      'name': 'Vibha',
      'location': 'Amritsar Punjab',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_1.png',
    },
    {
      'testimonial': 'Cureskin cleared my acne. No scars, happy skin!',
      'story': 'I battled with acne and the scars they left behind and I felt so self-conscious about my skin. Their online consultation was super easy, and the dermatologist actually listened to my concerns. They created a personalised routine with products that targeted my scars. It wasn\'t a miracle cure, but after a few months of consistent use, my scars faded noticeably, and my skin feels so much smoother.',
      'name': 'Jyoti',
      'location': 'Gujarat',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_2.png',
    },
    {
      'testimonial': 'Cureskin doctors cleared my inflammatory acne effectively and gently!',
      'story': 'I struggled with inflammatory acne for years, but after using Cureskin as recommended, I noticed a significant improvement. My skin feels clearer, smoother, and more confident. I can\'t thank them enough and would highly recommend it to anyone dealing with acne woes.',
      'name': 'Veena',
      'location': 'Maharashtra',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_3.png',
    },
    {
      'testimonial': 'Finally found relief from acne thanks to Cureskin\'s personalised routine',
      'story': 'Dealing with both inflammatory acne and melasma was a real challenge until I found Cureskin. Their personalized approach targeted my skin concerns effectively. Now, my skin feels balanced, with fewer breakouts and a noticeable reduction in melasma patches.',
      'name': 'Shahin',
      'location': 'Uttrakhand',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_4.png',
    },
    {
      'testimonial': 'I loved how the doctor created my routine to clear my acne & dark spots!',
      'story': 'Being a model meant tons of makeup, and, well, acne. Cureskin experts explained it\'s called \'acne cosmetica\'. With Cureskin\'s dermatologist skin treatment, I can now wear makeup while controlling breakouts',
      'name': 'Vaishnavi',
      'location': 'Uttar Pradesh',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_5.png',
    },
    {
      'testimonial': 'I could see visible improvement, great experience with my doctor! ',
      'story': 'I struggled with mild inflammatory acne and stubborn dark spots, but after starting Cureskin, I saw incredible results. My skin feels smoother, looks clearer, and those pesky dark spots have visibly faded. It\'s such a relief to finally find a solution that\'s easy to use and actually works.',
      'name': 'Ramya',
      'location': 'Vishakapatnam',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_6.png',
    }
  ],
  hi: [
    {
      'testimonial': 'Cureskin बहुत अच्छा लगा! इसने प्रेगनेंसी में मेरे स्किनकेयर रूटीन को एडजस्ट किया।',
      'story': 'Cureskin के मंथली स्किन चेक-अप बहुत ही बढ़िया हैं। जब मैंने अपनी प्रेगनेंसी के बारे में बताया, तो डॉक्टर ने तुरंत मेरे स्किनकेयर प्रोडक्ट्स को एडजस्ट किया, ताकि मेरी सेहत पर कोई बुरा असर ना पड़े।',
      'name': 'विभा',
      'location': 'अमृतसर पंजाबs',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_1.jpg',
    },
    {
      'testimonial': 'Cureskin ने मेरी एक्ने की समस्या को ठीक कर दिया। एक्ने के कोई निशान नहीं, बस अब है तो सिर्फ दमकती स्किन!',
      'story': 'मैं एक्ने और उनके निशान की समस्या से जूझ रही थी और मुझे अपनी स्किन को लेकर बहुत संकोच और शर्मिंदगी महसूस होती थी। उनका ऑनलाइन कंसल्टेशन बहुत आसान था, और डर्मेटोलॉजिस्ट ने पूरे ध्यान से मेरी समस्याओं को सुना। उन्होंने मेरे एक्ने के निशान को टारगेट करने वाले प्रोडक्ट्स को लेकर एक पर्सनलाइज़्ड रूटीन तैयार किया। यह कोई चमत्कारिक इलाज नहीं था, लेकिन कुछ महीनों के लगातार इस्तेमाल करने के बाद मेरे एक्ने के निशान काफी हल्के हो गए, और मेरी स्किन अब बहुत स्मूथ लगने लगी है।',
      'name': 'ज्योति',
      'location': 'गुजरात',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_2.png',
    },
    {
      'testimonial': 'Cureskin के डॉक्टरों ने मेरे इंफ्लेमेटरी एक्ने की समस्या को असरदार तरीके से ठीक कर दिया!',
      'story': 'मैं सालों तक इंफ्लेमेटरी एक्ने किस समस्या से जूझती रही, लेकिन जब मैंने Cureskin के बताए अनुसार कुछ प्रोडक्ट्स का इस्तेमाल किया तो मैंने एक अपनी इस समस्या में काफी सुधार देखा। मेरी स्किन साफ, स्मूथ और ज़्यादा कॉंफिडेंट महसूस होती है। मैं उन्हें जितना धन्यवाद दूं उतना कम है और मैं एक्ने की समस्या से जूझ रहे हर इंसान को Cureskin रेकमेंड करूंगी।',
      'name': 'वीणा',
      'location': 'महाराष्ट्र',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_3.png',
    },
    {
      'testimonial': 'Cureskin के पर्सनलाइज़्ड रूटीन की बदौलत आख़िरकार एक्ने की समस्या से राहत मिल गई',
      'story': 'जब तक मुझे Cureskin नहीं मिला था, तब तक इंफ्लेमेटरी एक्ने और मेलास्मा, इन दोनों समस्याओं से निपटना मेरे लिए बहुत बड़ी चुनौती थी। उनकी पर्सनलाइज़्ड एप्रोच ने मेरी स्किन से जुड़ी समस्याओं को काफी असरदार तरीके से टारगेट किया। अब, कम ब्रेकआउट और मेलास्मा पैच घटने के कारण मेरी स्किन बहुत बैलेंस्ड लगती है।',
      'name': 'शाहीन',
      'location': 'उत्तराखंड',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_4.png',
    },
    {
      'testimonial': 'जिस तरह से डॉक्टर ने मेरे एक्ने और डार्क स्पॉट्स को हटाने के लिए मेरा रूटीन तैयार किया, वह मुझे बहुत अच्छा लगा!',
      'story': 'एक मॉडल होने का मतलब है ढेर सारा मेकअप और इस वजह से एक्ने भी हो जाते हैं। Cureskin एक्सपर्ट ने मुझे समझाया कि इसे \'एक्ने कॉस्मेटिका\' कहा जाता है। Cureskin के डर्मेटोलॉजिस्ट स्किन ट्रीटमेंट से अब मैं ब्रेकआउट को कंट्रोल करते हुए मेकअप लगा सकती हूं।',
      'name': 'वैष्णवी',
      'location': 'उत्तर प्रदेश',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_5.png',
    },
    {
      'testimonial': 'मैंने काफी अच्छे सुधार देखे, डॉक्टर के साथ मेरा बहुत अच्छा अनुभव था!',
      'story': 'मैं इंफ्लेमेटरी एक्ने और ना जाने वाले डार्क स्पॉट्स की समस्या से जूझ रही थी , लेकिन Cureskin का इलाज शुरू करने के बाद मैंने शानदार नतीजे देखे। मेरी स्किन अब स्मूथ लगती है, क्लियर दिखती है, और वो डार्क स्पॉट्स भी बहुत कम हो गए हैं। ऐसा समाधान ढूंढ़ना बहुत राहत की बात है जो इस्तेमाल करने में भी आसान है और सच में काम करता है।',
      'name': 'राम्या',
      'location': 'विशाखापट्टनम',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_6.png',
    }
  ],
  te: [
    {
      'testimonial': 'Cureskin ని నేను బాగా ఇష్టపడుతున్నాను! ప్రెగ్నెన్సీ సమయంలో నేను నా స్కిన్ కేర్ రొటీన్ ని అడ్జస్ట్ చేసుకున్నాను.',
      'story': 'Cureskin మంత్లీ చెక్అప్ లు చాలా బాగున్నాయి. నేను నా ప్రెగ్నెన్సీ గురించి వారికి చెప్పినప్పుడు, డాక్టర్ వెంటనే నా స్కిన్ కేర్ ప్రోడక్ట్స్ ని అడ్జస్ట్ చేశారు.',
      'name': 'విభా',
      'location': 'అమృత్‌సర్ పంజాబ్',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_1.png',
    },
    {
      'testimonial': 'Cureskin నా మొటిమలను పూర్తిగా నయం చేసింది. ఎలాంటి మచ్చలు లేవు,  ఇప్పుడు నా చర్మం ప్రకాశవంతంగా మెరుస్తోంది!',
      'story': 'నేను నా మొటిమల సమస్యని ఇంకా దాని నుండి ఏర్పడిన మచ్చలతో పోరాడుతున్నాను. కాబట్టి, నా చర్మం గురించి నేను చాలా ఆందోళన చెందుతూ, ఇబ్బంది పడ్డాను. Cureskin వారిని ఆన్‌లైన్ లో సంప్రదించడం చాలా సులభం, ఇంకా అందులోని చర్మవ్యాధి నిపుణులు నా సమస్యలను పూర్తిగా శ్రద్ధతో విన్నారు. వారు నా మొటిమల మచ్చలను లక్ష్యంగా చేసుకుని ప్రోడక్ట్స్ ని ఉపయోగించడానికి పర్సనలైస్డ్ రొటీన్ ని సృష్టించారు. ఇది అద్భుతమైన నివారణ కాదు, కానీ నేను కొన్ని నెలలు క్రమం తప్పకుండా ఉపయోగించిన తర్వాత, నా మొటిమల మచ్చలు గమనించదగిన విధంగా నయమయ్యాయి మరియు నా చర్మం చాలా సున్నితంగా మారినట్లు అనిపించింది.',
      'name': 'జ్యోతి',
      'location': 'గుజరాత్',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_2.png',
    },
    {
      'testimonial': 'Cureskin డాక్టర్స్ నా ఇన్ఫ్లమేటరీ యాక్నే ని సమర్థవంతంగా నయం చేశారు!',
      'story': 'నేను ఇన్ఫ్లమేటరీ యాక్నే తో చాలా సంవత్సరాలు కష్టపడ్డాను, కానీ Cureskin సిఫార్సు చేసిన కొన్ని ప్రోడక్ట్స్ ని ఉపయోగించిన తర్వాత, నేను గణనీయమైన మెరుగుదలని గమనించాను. ఇప్పుడు నా చర్మం స్పష్టంగా, మృదువుగా మరియు మరింత నమ్మకంగా అనిపిస్తుంది. నేను వారికి తగినంత కృతజ్ఞతలు చెప్పలేను కానీ మొటిమలతో పోరాడుతున్న ఎవరికైనా నేను CureSkinని సిఫార్సు చేస్తాను..',
      'name': 'వీణ',
      'location': 'మహారాష్ట్ర',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_3.png',
    },
    {
      'testimonial': 'చివరికి Cureskin పర్సనలైస్డ్ రొటీన్ కి ధన్యవాదాలు, యాక్నే నుండి నాకు మంచి ఉపశమనం లభించింది',
      'story': 'నాకు Cureskin గురించి తెలిసేంత వరకు ఇన్ఫ్లమేటరీ యాక్నే మరియు మెలస్మా రెండింటినీ ఎదుర్కోవడం నాకు పెద్ద సవాలుగా అనిపించింది. వారి వ్యక్తిగతీకరించిన విధానం నా చర్మ సమస్యలను సమర్థవంతంగా నయం చేయగలిగింది. ఇప్పుడు, నా చర్మం తక్కువ యాక్నే తో మరియు తగ్గిన మెలస్మా మచ్చలతో మరింత సమతుల్యంగా కనిపిస్తోంది.',
      'name': 'షాహిన్',
      'location': 'ఉత్తరాఖండ్',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_4.png',
    },
    {
      'testimonial': 'నా మొటిమలు మరియు నల్ల మచ్చలను తొలగించడానికి డాక్టర్ నా రొటీన్ ని రూపొందించిన విధానం నాకు చాలా నచ్చింది!',
      'story': 'ఒక మోడల్‌గా ఉండటం అంటే ముఖానికి చాలా మేకప్ ని వేసుకోవడం ఇంకా ఇది మొటిమలకు తీవ్రంగా దారితీస్తుంది. Cureskin నిపుణులు దీనిని \'యాక్నే కాస్మెటికా\' అని పిలుస్తారని నాకు వివరించారు.Cureskin యొక్క చర్మవ్యాధి నిపుణులు చర్మ చికిత్సతో,బ్రేక్‌అవుట్‌లను నియంత్రించేటప్పుడు నేను ఇప్పుడు మేకప్ ని వేసుకోగలను',
      'name': 'వైష్ణవి',
      'location': 'ఉత్తరప్రదేశ్',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_5.png',
    },
    {
      'testimonial': 'నేను కనిపించే మెరుగుదలని చూడగలిగాను, నా డాక్టర్‌తో మంచి అనుభవం!',
      'story': 'నేను తేలికపాటి ఇన్ఫ్లమేటరీ యాక్నే ఇంకా మొండి నల్ల మచ్చలతో బాధపడ్డాను, కానీ Cureskin ఉపయోగించడం ప్రారంభించిన తర్వాత, నేను అద్భుతమైన ఫలితాలను చూశాను. ఇప్పుడు నా చర్మం మృదువుగా అనిపిస్తుంది, స్పష్టంగా కనిపిస్తుంది మరియు ఆ ఇబ్బందికరమైన నల్లటి మచ్చలు కనిపించకుండా నయమయ్యాయి. చివరకి ఉపయోగించడానికి సులభమైనదిగా మరియు వాస్తవానికి పని చేసే పరిష్కారాన్ని కనుగొనడం చాలా ఉపశమనం కలిగింది.',
      'name': 'రమ్య',
      'location': 'విశాఖపట్నం',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_6.png',
    }
  ],
  kn: [
    {
      'testimonial': 'Cureskin ಬಹಳ ಇಷ್ಟವಾಯಿತು! ಪ್ರೆಗ್ನೆನ್ಸಿಗಾಗಿ ನನ್ನ ಸ್ಕಿನ್ ಕೇರ್ ರೊಟಿನ್ ಅನ್ನು ಸರಿಹೊಂದಿಸಿದೆ.',
      'story': 'Cureskinನ ತಿಂಗಳ ಸ್ಕಿನ್ ಚೆಕಪ್ ಗಳು ಅದ್ಭುತವಾಗಿವೆ. ನಾನು ನನ್ನ ಪ್ರೆಗ್ನೆನ್ಸಿಯ ಬಗ್ಗೆ ತಿಳಿಸಿದಾಗ , ಡಾಕ್ಟರ್ ನನ್ನ ಯೋಗಕ್ಷೇಮವನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು ನನ್ನ ಸ್ಕಿನ್ ಕೇರ್ ಪ್ರಾಡಕ್ಟ್ ಗಳನ್ನು ತ್ವರಿತವಾಗಿ ಸರಿಹೊಂದಿಸಿದರು.',
      'name': 'ವಿಭಾ',
      'location': 'ಅಮೃತಸರ ಪಂಜಾಬ್',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_1.png',
    },
    {
      'testimonial': 'Cureskin ನನ್ನ ಮೊಡವೆಗಳನ್ನು ಹೋಗಳಾಡಿಸಿತು. ಯಾವುದೇ ಸ್ಕಾರ್ಸ್ ಗಳಿಲ್ಲ, ಹ್ಯಾಪಿ ಸ್ಕಿನ್!',
      'story': 'ನಾನು ಮೊಡವೆಗಳು ಮತ್ತು ಅವು ಬಿಟ್ಟುಹೋದ ಸ್ಕಾರ್ಸ್ ಗಳೊಂದಿಗೆ ಹೋರಾಡಿದೆ ಮತ್ತು ನನ್ನ ಚರ್ಮದ ಬಗ್ಗೆ ನಾನು ತುಂಬಾ ಸ್ವಯಂ ಪ್ರಜ್ಞೆ ಹೊಂದಿದ್ದೇನೆ. ಅವರ ಆನ್‌ಲೈನ್ ಕನ್ಸಲ್ಟೇಶನ್ ತುಂಬಾ ಸುಲಭ, ಮತ್ತು ಡರ್ಮಟಾಲಜಿಸ್ಟ್ ನನ್ನ ಸಮಸ್ಯೆಯನ್ನು ಕೇಳಿದಳು . ನನ್ನ ಸ್ಕಾರ್ಸ್ ಗಳನ್ನು ಗುರಿಯಾಗಿಸುವ ಪ್ರಾಡಕ್ಟ್ ಗಳೊಂದಿಗೆ ಅವರು ಪರ್ಸನಲೈಸ್ಡ್ ರೊಟಿನ್  ರಚಿಸಿದ್ದಾರೆ. ಇದು ಪವಾಡ ಚಿಕಿತ್ಸೆಯಾಗಿರಲಿಲ್ಲ, ಆದರೆ ಕೆಲವು ತಿಂಗಳ ನಿರಂತರ ಬಳಕೆಯ ನಂತರ, ನನ್ನ ಸ್ಕಾರ್ಸ್ ಗಮನಾರ್ಹವಾಗಿ ಮರೆಯಾಯಿತು ಮತ್ತು ನನ್ನ ಚರ್ಮವು ತುಂಬಾ ಮೃದುವಾಗಿರುತ್ತದೆ.',
      'name': 'ಜ್ಯೋತಿ',
      'location': 'ಗುಜರಾತ್',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_2.png',
    },
    {
      'testimonial': 'Cureskin ಡಾಕ್ಟರ್ ನನ್ನ ಇಂಪ್ಲಾಮೇಟರಿ ಏಕ್ನೆ ಗಳನ್ನು ಪರಿಣಾಮಕಾರಿಯಾಗಿ ಮತ್ತು ನಿಧಾನವಾಗಿ ವಾಸಿಮಾಡಿದರು!',
      'story': 'ನಾನು ವರ್ಷಗಳ ಕಾಲ ಇಂಪ್ಲಾಮೇಟರಿ ಏಕ್ನೆ ಗಳೊಂದಿಗೆ ಹೋರಾಡಿದೆ, ಆದರೆ Cureskin ಅನ್ನು ಶಿಫಾರಸು ಮಾಡಿದ ನಂತರ, ನಾನು ಗಮನಾರ್ಹ ಸುಧಾರಣೆಯನ್ನು ಗಮನಿಸಿದ್ದೇನೆ. ನನ್ನ ಚರ್ಮವು ಸ್ಪಷ್ಟ, ನಯವಾದ ಮತ್ತು ಹೆಚ್ಚು ಆತ್ಮವಿಶ್ವಾಸವನ್ನು ಅನುಭವಿಸುತ್ತದೆ. ನಾನು ಅವರಿಗೆ ಎಷ್ಟು ಧನ್ಯವಾದ ಹೇಳಿದರೂ ಸಾಕಾಗುವುದಿಲ್ಲ ಮತ್ತು ಮೊಡವೆ ಸಮಸ್ಯೆಗಳನ್ನು ಎದುರಿಸುತ್ತಿರುವ ಯಾರಿಗಾದರೂ ಇದನ್ನು ಶಿಫಾರಸು ಮಾಡುತ್ತೇನೆ.',
      'name': 'ವೀಣಾ',
      'location': 'ಮಹಾರಾಷ್ಟ್ರ',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_3.png',
    },
    {
      'testimonial': 'Cureskin ನ ಪರ್ಸನಲೈಸ್ಡ್ ರೊಟಿನ್ ನಿಂದಾಗಿ ಮೊಡವೆಗಳಿಂದ ಅಂತಿಮವಾಗಿ ಪರಿಹಾರ ಕಂಡುಕೊಂಡೆ',
      'story': 'ನಾನು Cureskin ನ ಬಗ್ಗೆ ತಿಳಿಯುವವರೆಗೂ ಇಂಪ್ಲಾಮೇಟರಿ ಏಕ್ನೆ ಮತ್ತು ಮೆಲಸ್ಮಾ ಎರಡನ್ನೂ ನಿಭಾಯಿಸುವುದು ನಿಜವಾದ ಸವಾಲಾಗಿತ್ತು. ಅವರ ಪರ್ಸನಲೈಸ್ಡ್ ವಿಧಾನವು ನನ್ನ ಚರ್ಮದ ಸಮಸ್ಯೆಯನ್ನು ಪರಿಣಾಮಕಾರಿಯಾಗಿ ಗುರಿಪಡಿಸಿದೆ. ಈಗ, ನನ್ನ ಚರ್ಮವು ಬಾಲನ್ಸ್ಡ್ ಆಗಿದೆ , ಕಡಿಮೆ ಬ್ರೇಕ್‌ಔಟ್‌ಗಳು ಮತ್ತು ಮೆಲಸ್ಮಾ ಪ್ಯಾಚ್‌ಗಳು ಗಮನಾರ್ಹವಾಗಿ ಕಡಿಮೆಯಾಗಿದೆ.',
      'name': 'ಶಾಹಿನ್',
      'location': 'ಉತ್ತರಖಂಡ',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_4.png',
    },
    {
      'testimonial': 'ನನ್ನ ಏಕ್ನೆ ಮತ್ತು ಡಾರ್ಕ್ ಸ್ಪಾಟ್ಸ್ ತೆರವುಗೊಳಿಸಲು ಡಾಕ್ಟರ್ ನನಗೆ ರಚಿಸಿದ ರೊಟೀನ್ ಇಷ್ಟವಾಯಿತು!',
      'story': 'ಮಾಡೆಲ್ ಆಗಿರುವುದು ಎಂದರೆ ಟನ್‌ಗಳಷ್ಟು ಮೇಕ್ಅಪ್ ಮತ್ತು ಏಕ್ನೆ. Cureskin ಎಕ್ಸ್ಪರ್ಟ್  ವಿವರಿಸಿದರು ಅದನ್ನು ಕರೆಯಲಾಗುತ್ತದೆ \'ಏಕ್ನೆ ಕಾಸ್ಮೆಟಿಕಾ\' Cureskin ಡರ್ಮಟಾಲಜಿಸ್ಟ್ ನ ಸ್ಕಿನ್ ಟ್ರೀಟ್ಮೆಂಟ್ ನೊಂದಿಗೆ, ಬ್ರೇಕ್‌ಔಟ್‌ಗಳನ್ನು ನಿಯಂತ್ರಿಸಿ ನಾನು ಈಗ ಮೇಕ್ಅಪ್ ಮಾಡಬಹುದು',
      'name': 'ವೈಷ್ಣವಿ',
      'location': 'ಉತ್ತರ ಪ್ರದೇಶ',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_5.png',
    },
    {
      'testimonial': 'ನಾನು ಕಾಣುವ ಸುಧಾರಣೆಯನ್ನು ನೋಡಬಹುದು, ನನ್ನ ಡಾಕ್ಟರ್ ನೊಂದಿಗೆ ಉತ್ತಮ ಅನುಭವ!',
      'story': 'ನಾನು ಮೈಲ್ಡ್ ಇಂಪ್ಲಾಮೇಟರಿ ಏಕ್ನೆ ಮತ್ತು ಸ್ಟಬ್ಬನ್ ಡಾರ್ಕ್ ಸ್ಪಾಟ್ಸ್ ಸಮಸ್ಯೆಯಿಂದ ಹೋರಾಡಿದೆ, ಆದರೆ Cureskin ಅನ್ನು ಪ್ರಾರಂಭಿಸಿದ ನಂತರ, ನಾನು ನಂಬಲಾಗದ ಫಲಿತಾಂಶಗಳನ್ನು ನೋಡಿದೆ. ನನ್ನ ಚರ್ಮವು ಮೃದುವಾಗಿ ಕಾಣುತ್ತದೆ, ಕ್ಲಿಯರ್ ಆಗಿದೆ, ಮತ್ತು ಆ ಕಾಡುವ  ಡಾರ್ಕ್ ಸ್ಪಾಟ್ಸ್ ಮರೆಯಾಗಿವೆ. ಅಂತಿಮವಾಗಿ ಬಳಸಲು ಸುಲಭವಾದ ಮತ್ತು ನಿಜವಾಗಿ ಕೆಲಸ ಮಾಡುವ ಪರಿಹಾರ ಕಂಡುಕೊಂಡೆ.',
      'name': 'ರಮ್ಯಾ',
      'location': 'ವಿಶಾಖಪಟ್ಟಣ',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_6.png',
    }
  ],
  ta: [
    {
      'testimonial': 'Cureskin -ஐ மிகவும் விரும்பினேன்! கர்ப்பத்திற்கான எனது சரும பராமரிப்பு வழக்கத்தை சரிசெய்தேன்.',
      'story': 'Cureskin-ன் மாதாந்திர சரும பரிசோதனைகள் அற்புதமானவை. நான் கர்ப்பம் தரித்ததைக் குறிப்பிட்டபோது, மருத்துவர் எனது நல்வாழ்வை உறுதிசெய்ய எனது சரும பராமரிப்புப் பொருட்களை உடனடியாகச் சரிசெய்தார்.',
      'name': 'விபா',
      'location': 'அமிர்தசரஸ் பஞ்சாப்',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_1.png',
    },
    {
      'testimonial': 'Cureskin என் முகப்பருவை நீக்கியது. வடுக்கள் இல்லை, மகிழ்ச்சியான சருமம் மட்டுமே!',
      'story': 'நான் முகப்பரு மற்றும் அவை விட்டுச்சென்ற தழும்புகளுடன் போராடினேன், என் சருமத்தைப் பற்றி நான் மிகவும் கவலைப்பட்டேன். அவர்களின் ஆன்லைன் ஆலோசனை மிகவும் எளிதாக இருந்தது, மருத்துவர் உண்மையில் எனது கவலைகளைக் கேட்டார். எனது வடுக்களை இலக்காகக் கொண்டு தனிப்பயனாக்கப்பட்ட தயாரிப்புகளை அவர்கள் உருவாக்கினர். இது ஒரு அதிசய சிகிச்சை அல்ல, ஆனால் சில மாதங்கள் தொடர்ந்து பயன்படுத்திய பிறகு, என் வடுக்கள் குறிப்பிடத்தக்க அளவில் மறைந்துவிட்டன, மேலும் என் சருமம் மிகவும் மென்மையாக உணர்கிறது.',
      'name': 'ஜோதி',
      'location': 'குஜராத்',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_2.png',
    },
    {
      'testimonial': 'Cureskin மருத்துவர்கள் என் முகப்பருவை திறம்பட மற்றும் மெதுவாக அகற்றியது!',
      'story': 'நான் பல ஆண்டுகளாக முகப்பருவுடன் போராடினேன், ஆனால் Cureskin ஐப் பயன்படுத்திய பிறகு, நான் ஒரு குறிப்பிடத்தக்க முன்னேற்றத்தைக் கண்டேன். என் சருமம் தெளிவாகவும், மென்மையாகவும், அதிக அழகாகவும் இருக்கிறது. நான் எவ்வளவு நன்றி கூறுவதும் போதுமானதாக இருக்காது மற்றும் முகப்பரு துயரங்களைக் கையாளும் எவருக்கும் இதை மிகவும் பரிந்துரைக்கிறேன்.',
      'name': 'வீணா',
      'location': 'மகாராஷ்டிரா',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_3.png',
    },
    {
      'testimonial': 'Cureskin-ன் தனிப்பயனாக்கப்பட்ட தயாரிப்புகளுக்கு நன்றி, முகப்பருவில் இருந்து இறுதியாக நிவாரணம் கிடைத்தது',
      'story': 'நான் Cureskin- -ஐ கண்டுபிடிக்கும் வரை முகப்பரு மற்றும் மெலஸ்மா இரண்டையும் கையாள்வது ஒரு உண்மையான சவாலாக இருந்தது. அவர்களின் தனிப்பயனாக்கப்பட்ட அணுகுமுறை எனது சரும பிரச்சினைகளை திறம்பட குறிவைத்தது. இப்போது, குறைவான பிரேக்அவுட்கள் மற்றும் மெலஸ்மா திட்டுகளில் குறிப்பிடத்தக்க குறைப்புடன், என் சருமம் சீரானதாக உணர்கிறது.',
      'name': 'ஷாஹின்',
      'location': 'உத்தரகாண்ட்',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_4.png',
    },
    {
      'testimonial': `எனது முகப்பரு மற்றும் கரும்புள்ளிகளை அழிக்க மருத்துவர்
       எனது சிகிச்சையை எப்படி உருவாக்கினார் என்பதை நான் விரும்பினேன்!`,
      'story': 'ஒரு மாடலாக இருப்பது என்பது நிறைய மேக்கப் மற்றும், முகப்பரு என்று பொருள் குர்ஸ்கின் நிபுணர்கள் இது \'அக்னே காஸ்மெடிகா\' என்று அழைக்கப்படுகிறது என்று விளக்கினர்.Cureskin மருத்துவர் சரும சிகிச்சையின் மூலம், பிரேக்அவுட்களைக் கட்டுப்படுத்தும் போது நான் இப்போது மேக்கப் அணிய முடியும்',
      'name': 'வைஷ்ணவி',
      'location': 'உத்தரபிரதேசம்',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_5.png',
    },
    {
      'testimonial': 'என்னால் பெரிய முன்னேற்றம் பார்க்க முடிந்தது, என் மருத்துவரிடம் ஒரு சிறந்த அனுபவம்!',
      'story': 'இன்ஃப்ளமேடரி அக்னே மற்றும் தொடர்ச்சியான கரும்புள்ளிகளுடன் நான் போராடினேன், ஆனால் Cureskin- ஐ தொடங்கிய பிறகு, நம்பமுடியாத பலன்களைக் கண்டேன். என் சருமம் மென்மையாகவும், தெளிவாகவும் தெரிகிறது, மேலும் அந்த தொல்லை தரும் கரும்புள்ளிகள் மங்கிவிட்டன. பயன்படுத்த எளிதான மற்றும் உண்மையில் வேலை செய்யும் ஒரு தீர்வை இறுதியாகக் கண்டுபிடித்தது மிகவும் நிம்மதி அளிக்கிறது.',
      'name': 'ரம்யா',
      'location': 'விசாகப்பட்டினம்',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_6.png',
    }
  ],
  ml: [
    {
      'testimonial': 'ഇഷ്ടപ്പെട്ടു Cureskin! ഗർഭകാലത്ത് എന്റെ ചർമ്മ സംരക്ഷണം പറ്റിയ രീതിയിൽ ക്രമീകരിച്ചു.',
      'story': 'Cureskin ന്റെ പ്രതിമാസ ചെക്കപ്പുകൾ അടിപൊളിയാണ്. ഗർഭിണിയാണെന്ന് പറഞ്ഞപ്പോൾ. എന്റെ ആരോഗ്യത്തിന് പറ്റിയ ചർമ്മ സംരക്ഷണ ഉത്പന്നങ്ങൾ ഡോക്ടർ ക്രമീകരിച്ചു.',
      'name': 'വിഭ',
      'location': 'അമൃത്സർ, പഞ്ചാബ്',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_1.png',
    },
    {
      'testimonial': 'Cureskin എന്റെ മുഖക്കുരു ഇല്ലാതാക്കി. പാടുകളില്ല, മികച്ച ചർമ്മം!',
      'story': 'മുഖക്കുരുവും അതുണ്ടാക്കുന്ന പാടുകളും കൊണ്ട് ഞാൻ ഒരു പാട് കഷ്ടപ്പെട്ടു അങ്ങനെ എനിക്ക് ചർമ്മത്തിന്റെ കാര്യത്തിൽ ആത്മവിശ്വാസം ഇല്ലായിരുന്നു. അവരുടെ ഓൺലൈൻ പരിശോധന വളരെ എളുപ്പമായിരുന്നു, ത്വക് രോഗ വിദഗ്ധൻ എന്റെ പ്രശ്നങ്ങൾ ശരിക്കും കേട്ടു മനസിലാക്കി. എന്റെ പാടുകൾ ഇല്ലാതാക്കാൻ വേണ്ട ഉത്പന്നങ്ങൾ അടങ്ങിയ ഒരു വ്യക്തിഗത ക്രമം അവർ തയ്യാറാക്കി. ഇത് ഒരു അത്ഭുതകരമായ രോഗശമനം ആയിരുന്നില്ല, പക്ഷെ കുറച്ച് മാസങ്ങൾ സ്ഥിരമായി ഉപയോഗിച്ചപ്പോൾ, എന്റെ പാടുകൾ കാണാതായി, മാത്രമല്ല ചർമ്മം കൂടുതൽ മിനുസവുമായി.',
      'name': 'ജ്യോതി',
      'location': 'ഗുജറാത്ത്',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_2.png',
    },
    {
      'testimonial': 'Cureskin ഡോക്ടർമാർ എന്റെ വീക്കമുള്ള മുഖക്കുരു ഫലപ്രദമായും ശാന്തമായും ചികിൽസിച്ചില്ലാതാക്കി!',
      'story': 'ഞാൻ ഒരു പാട് വർഷം വീക്കമുള്ള മുഖക്കുരു കാരണം കഷ്ടപ്പെട്ടു, പക്ഷെ നിർദേശിച്ച പോലെ Cureskin ഉപയോഗിച്ചപ്പോൾ, കാര്യമായ മാറ്റം കാണാൻ കഴിഞ്ഞു. എന്റെ ചർമ്മം തെളിഞ്ഞതും, മിനുസമുള്ളതും കൂടുതൽ ആത്മവിശ്വാസമുള്ളതുമായി. എനിക്ക് അവരോട് ഒരു പാട് നന്ദിയുണ്ട്, മാത്രമല്ല മുഖക്കുരു കാരണം കഷ്ടപ്പെടുന്ന ആർക്കും ഞാൻ ശക്തമായി ശുപാർശ ചെയ്യുന്നു.',
      'name': 'വീണ',
      'location': 'മഹാരാഷ്ട്ര',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_3.png',
    },
    {
      'testimonial': 'Cureskin ന്റെ പ്രത്യേക ചികിത്സാക്രമം കൊണ്ട് മുഖക്കുരുവിൽ നിന്ന് ശമനം അവസാനം നേടി',
      'story': 'Cureskin നെ കുറിച്ച് അറിയുന്നത് വരെ വീക്കമുള്ള മുഖക്കുരുവും മെലാസ്മയും എനിക്ക് വലിയൊരു വെല്ലുവിളി ആയിരുന്നു. അവരുടെ വ്യക്തിഗത സമീപനം എന്റെ ചർമ്മ പ്രശ്നങ്ങളെ ഇല്ലാതാക്കാൻ സഹായിച്ചു. ഇപ്പോൾ, എന്റെ ചർമ്മത്തിൽ പൊട്ടലും മെലാസ്മ പാടുകളും കുറഞ്ഞു സംതുലിതമായി തോന്നുന്നു.',
      'name': 'ഷാഹിൻ',
      'location': 'ഉത്തരാഖണ്ഡ്',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_4.png',
    },
    {
      'testimonial': 'എന്റെ മുഖക്കുരു & കറുത്ത പാടുകൾ ഇല്ലാതാക്കാൻ ഡോക്ടർ ചികിത്സ ക്രമം ഉണ്ടാക്കിയ രീതി എനിക്കിഷ്ടപ്പെട്ടു!',
      'story': 'മോഡൽ ആയത് കാരണം, ഒരു പാട് മേക്കപ്പ് ഉപയോഗിക്കും, മാത്രമല്ല മുഖക്കുരുവും. Cureskin വിദഗ്ധർ അത് \'ആക്നെ കോസ്മെറ്റിക\' ആണെന്ന് വിശദമാക്കി.Cureskin ന്റെ ത്വക് രോഗ വിദഗ്ധരുടെ ചർമ്മ ചികിത്സ കൊണ്ട്, പൊട്ടലുകൾ നിയന്ത്രിച്ച് കൊണ്ട് എനിക്ക് ഇപ്പോൾ മേക്കപ്പ് ഇടാം',
      'name': 'വൈഷ്ണവി',
      'location': 'ഉത്തർ പ്രദേശ്',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_5.png',
    },
    {
      'testimonial': 'എനിക്ക് നല്ല മാറ്റം കാണാൻ സാധിച്ചു, ഡോക്ടറുമായി നല്ല അനുഭവം ആയിരുന്നു!',
      'story': 'എനിക്ക് കുറച്ച് വീക്കമുള്ള മുഖക്കുരുവും വളരെയേറെ കറുത്ത പാടുകളും ഉണ്ടായിരുന്നു, പക്ഷെ Cureskin ചികിത്സ തുടങ്ങിയതിന് ശേഷം, എനിക്ക് മികച്ച ഫലങ്ങൾ ലഭിച്ചു. എന്റെ ചർമ്മം മിനുസമായി, തെളിഞ്ഞതായി കാണാം, കൂടാതെ എന്നെ ശല്യപ്പെടുത്തിയ ആ കറുത്ത പാടുകൾ മങ്ങിയിട്ടുണ്ട്. എളുപ്പത്തിൽ ഉപയോഗിക്കാവുന്നതും ഫലപ്രദവുമായ ഒരു പരിഹാരം അവസാനം കണ്ടെത്താൻ കഴിഞ്ഞത് വലിയ ഒരു ആശ്വാസമാണ്.',
      'name': 'രമ്യ',
      'location': 'വിശാഖപട്ടണം',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_6.png',
    }
  ],
  mr: [
    {
      'testimonial': 'Cureskin आवडले! प्रेगनन्सी मध्ये  माझे स्किनकेअर रूटीन ऍडजस्ट केले.',
      'story': 'Cureskinचे मंथली स्किन चेक अप विलक्षण आहे. जेव्हा मी माझ्या गर्भधारणेचा उल्लेख केला, तेव्हा माझे आरोग्य सुनिश्चित करण्यासाठी डॉक्टरांनी माझ्या स्किनकेअर प्रॉडक्ट्स त्वरित ऍडजस्ट केली.',
      'name': 'विभा',
      'location': 'अमृतसर पंजाब',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_1.png',
    },
    {
      'testimonial': 'Cureskinने माझी मुरुमांची समस्या बरी केली. मुरुमांची कोणतीही चिन्हे नाहीत, आता फक्त चमकदार त्वचा आहे!',
      'story': 'मी मुरुमांच्या समस्येशी आणि त्याच्या जखमांशी लढत होते, मला माझ्या त्वचेबद्दल खूप संकोच आणि लाज वाटली. त्यांचे ऑनलाइन कन्सल्टेशन खूप सोपे होते आणि डरमॅटोलॉजिस्ट माझ्या समस्या पूर्ण लक्ष देऊन ऐकत होते. त्यांनी माझ्या मुरुमांचे चट्टे लक्ष्यित करणारी प्रोडक्टस वापरून पर्सनल रुटीन तयार केले. तो चमत्कारिक उपचार नव्हता, परंतु काही महिन्यांच्या सतत वापरानंतर, माझ्या मुरुमांचे बरेचसे चिन्ह दिसेनासे झाले आहेत आणि माझी त्वचा आता खूप मुलायम वाटते.',
      'name': 'ज्योती',
      'location': 'गुजरात',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_2.png',
    },
    {
      'testimonial': 'Cureskinच्या डॉक्टरांनी माझ्या दाहक मुरुमांची समस्या प्रभावीपणे बरी केली!',
      'story': 'मी बऱ्याच वर्षांपासून दाहक मुरुमांशी झगडत होते, परंतु जेव्हा मी क्युरस्किनने शिफारस केलेली काही प्रोडक्टस वापरली तेव्हा मला माझ्या समस्येत बरीच सुधारणा दिसली. माझी त्वचा स्वच्छ, नितळ आणि अधिक आत्मविश्वासपूर्ण वाटते. मी त्यांचे पुरेसे आभार मानू शकत नाही आणि मी मुरुमांशी झगडत असलेल्या कोणालाही क्युरस्किनची शिफारस करेन.',
      'name': 'वीणा',
      'location': 'महाराष्ट्र',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_3.png',
    },
    {
      'testimonial': 'Cureskinच्या पर्सनल रुटीनमुळे शेवटी मुरुमांपासून आराम मिळाला.',
      'story': 'मला CureSkin सापडेपर्यंत दाहक मुरुम आणि मेलास्मा या दोन्हींचा सामना करणे हे माझ्यासाठी मोठे आव्हान होते. त्याचा पर्सनल दृष्टिकोन प्रभावीपणे माझ्या त्वचेच्या समस्यांना लक्ष्य करतो. आता, माझी त्वचा कमी ब्रेकआउट्स आणि कमी झालेल्या मेलास्मा पॅचसह अधिक संतुलित दिसते.',
      'name': 'शाहीन',
      'location': 'उत्तराखंड',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_4.png',
    },
    {
      'testimonial': 'माझे मुरुम आणि काळे डाग काढून टाकण्यासाठी डॉक्टरांनी माझी दिनचर्या ज्या प्रकारे तयार केली ते मला खूप आवडले!',
      'story': 'मॉडेल असणे म्हणजे खूप मेक-अप करणे आणि यामुळे मुरुमे देखील होतात. CureSkin तज्ञानी मला समजावून सांगितले की याला \'अक्ने कॉस्मेटिका\' म्हणतात.Cureskin च्या डर्माटोलॉजिस्ट स्किन ट्रीटमेंटसह, मी आता ब्रेकआउट्स नियंत्रित करताना मेकअप वापरू शकते.',
      'name': 'वैष्णवी',
      'location': 'उत्तर प्रदेश',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_5.png',
    },
    {
      'testimonial': 'मी उत्तम सुधारणा पाहिल्या, डॉक्टरांसोबत चांगला अनुभव होता!',
      'story': 'मी दाहक मुरुम आणि काळे गडद ठिपके यांच्याशी झुंजत होतो, परंतु Cureskin ट्रीटमेंट सुरू केल्यानंतर, मला आश्चर्यकारक परिणाम दिसले. माझी त्वचा आता नितळ वाटते, स्वच्छ दिसते आणि काळे डागही बऱ्यापैकी कमी झाले आहेत. वापरण्यास सोपा आणि प्रत्यक्षात कार्य करणारा असा उपाय शोधणे खूप आरामदायी आहे.',
      'name': 'रम्या',
      'location': 'विशाखापट्टणम',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_6.png',
    }
  ],
  bn: [
    {
      'testimonial': 'Cureskin ভীষণ ভালো লেগেছে! এটা প্রেগন্যান্সিতে আমার স্কিনকেয়ারের রুটিনকে অ্যাডজাস্ট করেছে।',
      'story': 'Cureskin-এর প্রতি মাসে ত্বকের চেক-আপ হলো দুর্দান্ত। যখন আমি আমার প্রেগন্যান্সির কথা বললাম, তখন ডাক্তার সঙ্গে সঙ্গে আমার স্বাস্থ্যের ভালোর জন্য আমার স্কিনকেয়ারের প্রোডাক্টগুলোকে অ্যাডজাস্ট করে দিয়েছেন।',
      'name': 'বিভা',
      'location': 'অমৃতসর, পাঞ্জাব',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_1.png',
    },
    {
      'testimonial': 'Cureskin আমার ব্রণকে দূর করে দিয়েছে। কোনো ক্ষত চিহ্নও নেই, আমার ত্বক এখন খুশি!',
      'story': 'আমি ব্রণ আর ব্রণের থেকে হওয়া ক্ষতচিহ্ন নিয়ে নাজেহাল হয়ে গিয়েছিলাম। আমার ত্বকের জন্য আমার ভীষণ সংকোচ ও লজ্জা বোধ হতো। এদের অনলাইনে কন্সালটেশন খুব সহজ ছিল, আর ডার্মাটোলজিস্ট আমার সমস্যার ব্যাপারে মনোযোগ সহকারে শুনেছিলেন। তাঁরা আমার প্রয়োজন অনুযায়ী প্রোডাক্ট দিয়ে একটা রুটিন তৈরি করেছিলেন যাতে আমার ক্ষতচিহ্নগুলো ঠিক হয়ে যায়। এটা সঙ্গে সঙ্গেই ঠিক হয়ে যায়নি, কিন্তু কিছু মাস ধারাবাহিকভাবে ব্যবহার করার পরে আমার ক্ষতচিহ্নগুলো আস্তে আস্তে মিলিয়ে যেতে শুরু করলো আর আমার ত্বক আরো মসৃণ হয়ে গেল।',
      'name': 'জ্যোতি',
      'location': 'গুজরাট',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_2.png',
    },
    {
      'testimonial': 'Cureskin-এর ডাক্তাররা আমার জ্বালাযুক্ত ব্রণকে খুব কার্যকরী আর কোমলভাবে নির্মূল করে দিয়েছেন!',
      'story': 'বহু বছর ধরে আমি জ্বালাযুক্ত ব্রণের জন্য ভুগছিলাম, কিন্তু পরামর্শ অনুযায়ী Cureskin ব্যবহার করার পরে, আমি উল্লেখযোগ্যভাবে উন্নতি লক্ষ্য করলাম। আমার ত্বক এখন আরো পরিষ্কার, মসৃণ হয়ে গেছে আর আমি আত্মবিশ্বাস ফিরে পেয়েছি। তাঁদেরকে ধন্যবাদ জানালে সেটা কম বলা হবে আর আমি সেই সমস্ত মানুষ যাঁরা ব্রণর সমস্যায় ভুগছেন তাঁদেরকে ভীষণভাবে Cureskin-এর ডাক্তারদেরকে দেখানোর পরামর্শ দেবো।',
      'name': 'বীনা',
      'location': 'মহারাষ্ট্র',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_3.png',
    },
    {
      'testimonial': 'শেষ পর্যন্ত ব্রণর থেকে মুক্তি পেয়েছি, Cureskin-এর পার্সোনালাইজড রুটিনকে ধন্যবাদ জানাই',
      'story': 'Cureskin-কে পাওয়ার আগে আমি জ্বালাযুক্ত ব্রণ আর মেলাসমা নিয়ে খুব সমস্যায় ছিলাম। আমার প্রয়োজন অনুযায়ী তাঁরা যেভাবে ট্রিটমেন্ট করেছেন সেটা আমার ত্বকের সমস্যাকে কার্যকরীভাবে সমাধান করেছে। এখন, আমার ত্বকের ব্যালেন্স ফিরে এসেছে, আর এখন খুব কম ব্রণ রয়েছে এবং আমার মেলাসমার ছোপগুলোও অনেক কমে গেছে।',
      'name': 'শাহিন',
      'location': 'উত্তরাখণ্ড',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_4.png',
    },
    {
      'testimonial': 'আমার ব্রণ আর কালো দাগকে কমানোর জন্য ডাক্তার যেভাবে আমার রুটিন তৈরি করেছেন সেটা আমার ভীষণ ভালো লেগেছে!',
      'story': 'মডেল হলে প্রচুর মেকআপ করতে হয়, আর প্রচুর ব্রণ হয়। Cureskin-এর বিশেষজ্ঞরা ব্যাখ্যা করেছেন যে এটাকে বলা হয় “অ্যাকনে কসমেটিকা”। Cureskin-এর ডার্মাটোলজিস্টদের স্কিন ট্রিটমেন্ট দিয়ে, আমি এখন ব্রণকে নিয়ন্ত্রণে রেখে মেকআপ করতে পারি',
      'name': 'বৈষ্ণবী',
      'location': 'উত্তর প্রদেশ',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_5.png',
    },
    {
      'testimonial': 'আমি খুব ভালো উন্নতি দেখতে পাচ্ছি, ডাক্তারের সাথে আমার দারুণ অভিজ্ঞতা হয়েছে!',
      'story': 'আমি কম জ্বালাযুক্ত ব্রণ এবং জেদি কালো দাগ নিয়ে ভুগছিলাম, কিন্তু Cureskin-এর ট্রিটমেন্ট শুরু করার পরে, আমি অবিশ্বাস্য ফলাফল দেখতে পেয়েছি। আমার ত্বক মসৃণ ও দেখতে পরিষ্কার হয়ে গেছে এবং সেই জেদি কালো দাগগুলি হালকা হয়ে গেছে। অবশেষে এমন একটি সমাধান, যেটা ব্যবহার করা সহজ এবং সেটা আসলেই কাজ করে, সেটাকে খুঁজে পেয়ে আমি খুব শান্তি পেয়েছি।',
      'name': 'রম্যা',
      'location': 'বিশাখাপত্তনম',
      'img': 'https://cdn.cureskin.com/app/img/testimonials/testimonialsv_6.png',
    }
  ]
};


export const TestimonialCaseStudiesInfo: TestimonialCaseStudies = {
  en:
  [
    {
      concern: 'Inflammatory Acne',
      name: 'Vibha',
      imageName: 'vibha',
      age: 22,
      place: 'Punjab',
      testimonial: 'My doctor have fixed my Inflammatory Acne during  pregnancy.',
      story: 'Monthly skin check-ups are fantastic. When I mentioned my pregnancy, the doctor promptly adjusted my skincare products to ensure my well-being. I really trust the team of doctors at Cureskin.',
      duration: 4,
      noOfCheckups: 5,
      diet: 'Given based on the skin type (oil tolerance) and was easy to follow.',
      tabletsGiven: 'No tablets/supplements included in the treatment',
    },
    {
      concern: 'Inflammatory Acne',
      name: 'Vaishnavi',
      imageName: 'vaishnavi',
      age: 31,
      place: 'Uttar Pradesh',
      testimonial: 'I can apply makeup without the fear of Acne',
      story: 'Being a model meant tons of makeup, and, well, acne. Cureskin experts explained it\ns called “acne cosmetica”.With Cureskin\ns dermatologist skin treatment, I can now wear makeup while controlling breakouts.',
      duration: 8,
      noOfCheckups: 5,
      diet: 'Given based on the skin type (oil tolerance) and was easy to follow.',
      tabletsGiven: 'No tablets given in the initial treatment.',
    },
    {
      concern: 'Pigmentation',
      name: 'Neelam Saini',
      imageName: 'neelam',
      age: 24,
      place: 'Punjab',
      testimonial: 'I can finally look at my face without any pigmentation.',
      story: 'Cureskin not only cleared my pigmentation but also enhanced my facial health while imparting valuable skin care lessons.',
      duration: 8,
      noOfCheckups: 7,
      diet: 'Yes, an easy-to-follow diet based on the skin type.',
      tabletsGiven: 'No tablets/supplements included in the treatment.',
    },
    {
      concern: 'Acne',
      name: 'Rajneesh',
      imageName: 'rajneesh',
      age: 24,
      place: 'Madhya Pradesh',
      testimonial: 'My severe acne is gone! Got my confidence back.',
      story: 'I struggled with severe acne for years. The online dermatologist listened to my woes and prescribed a whole routine. It took some time, but my skin finally cleared up. I finally feel confident again.',
      duration: 4,
      noOfCheckups: 6,
      diet: 'None',
      tabletsGiven: 'No tablets/supplements included in the treatment.',
    },
  ],
  hi: [
    {
      concern: 'इंफ्लेमेटरी एक्ने',
      name: 'विभा',
      imageName: 'vibha',
      age: 22,
      place: 'पंजाब',
      testimonial: 'मेरे डॉक्टर ने मेरी प्रेगनेंसी के दौरान हुए इंफ्लेमेटरी एक्ने ठीक कर दिए।',
      story: 'मंथली स्किन चेक-अप बहुत ही बढ़िया होते हैं। जब मैंने अपनी प्रेगनेंसी के बारे में बताया, तो डॉक्टर ने तुरंत मेरे स्किनकेयर प्रोडक्ट्स को एडजस्ट किया, ताकि मेरी सेहत पर कोई बुरा असर ना पड़े। मुझे Cureskin के डॉक्टरों की टीम पर सचमुच बहुत भरोसा है।',
      duration: 4,
      noOfCheckups: 5,
      diet:  'स्किन के प्रकार (ऑयल टोलरेंस) के आधार पर दिया गया और इसे फॉलो करना काफी आसान था।',
      tabletsGiven: 'ट्रीटमेंट में कोई टेबलेट/सप्लीमेंट शामिल नहीं किए गए।',
    },
    {
      concern: 'इंफ्लेमेटरी एक्ने',
      name: 'वैष्णवी',
      imageName: 'vaishnavi',
      age: 31,
      place: 'उत्तर प्रदेश',
      testimonial: 'मैं एक्ने के डर के बिना मेकअप लगा सकती हूँ',
      story: 'एक मॉडल होने का मतलब है ढेर सारा मेकअप और इस वजह से एक्ने भी हो जाते हैं। Cureskin एक्सपर्ट ने मुझे समझाया कि इसे "एक्ने कॉस्मेटिका" कहा जाता है। Cureskin के डर्मेटोलॉजिस्ट स्किन ट्रीटमेंट से अब मैं ब्रेकआउट को कंट्रोल करते हुए मेकअप लगा सकती हूं।',
      duration: 8,
      noOfCheckups: 5,
      diet: 'स्किन के प्रकार (ऑयल टोलरेंस) के आधार पर दिया गया और इसे फॉलो करना काफी आसान था।',
      tabletsGiven: 'शुरुआती इलाज में कोई टेबलेट नहीं दी गई।',
    },
    {
      concern: 'पिगमेंटेशन',
      name: 'नीलम सैनी',
      imageName: 'neelam',
      age: 24,
      place: 'पंजाब',
      testimonial: 'मैं आखिरकार अब बिना किसी पिगमेंटेशन के अपना चेहरा देख सकती हूं।',
      story: 'Cureskin ने न सिर्फ मेरी पिगमेंटेशन की समस्या तो ठीक किया बल्कि बहुत काम आने वाले स्किन केयर लेसन देकर मेरी फेशियल हेल्थ को भी बेहटा करने में मदद की।',
      duration: 8,
      noOfCheckups: 7,
      diet: 'हाँ, स्किन के प्रकार के आधार पर दिया गया और इसे फॉलो करना काफी आसान था।',
      tabletsGiven: 'ट्रीटमेंट में कोई टेबलेट/सप्लीमेंट शामिल नहीं है',
    },
    {
      concern: 'एक्ने',
      name: 'रजनीश',
      imageName: 'rajneesh',
      age: 24,
      place: 'मध्य प्रदेश',
      testimonial: 'मुझे एक्ने की गंभीर समस्या थी जो अब ठीक हो गई है! मेरा आत्मविश्वास वापस आ गया है।',
      story: 'मैं सालोंतक एक्ने की गंभीर समस्या से जूझता रहा। ऑनलाइन डर्मेटोलॉजिस्ट ने मेरी परेशानी ध्यान से सुनी और पूरा रूटीन दिया। इसमें कुछ समय लगा, लेकिन आख़िरकार मेरी स्किन अब क्लियर हो गई है। और में फिर से कॉंफिडेंट महसूस करने लगा हूँ।',
      duration: 4,
      noOfCheckups: 6,
      diet: 'कोई नहीं',
      tabletsGiven: 'ट्रीटमेंट में कोई टेबलेट/सप्लीमेंट शामिल नहीं किए गए।',
    },
  ],
  te: [
    {
      concern: 'ఇన్ఫ్లమేటరీ యాక్నే',
      name: 'విభా',
      imageName: 'vibha',
      age: 22,
      place: 'పంజాబ్',
      testimonial: 'నా డాక్టర్ ప్రెగ్నెన్సీ సమయంలో నా ఇన్ఫ్లమేటరీ యాక్నే ని పరిష్కరించారు.',
      story: 'మంత్లీ స్కిన్ చెక్అప్ లు చాలా బాగా ఉంటాయి. నేను నా ప్రెగ్నెన్సీ గురించి చెప్పినప్పుడు, డాక్టర్  నా స్కిన్ కేర్ ప్రోడక్ట్స్ ని వెంటనే అడ్జస్ట్ చేశారు. నేను Cureskin లోని డాక్టర్ ల బృందాన్ని నిజంగా బాగా నమ్ముతున్నాను.',
      duration: 4,
      noOfCheckups: 5,
      diet:  'చర్మం రకం (ఆయిల్ టాలరెన్స్) ఆధారంగా ఇవ్వబడింది మరియు అనుసరించడం సులభం.',
      tabletsGiven: 'చికిత్సలో ఏ మాత్రలు/సప్లిమెంట్లు చేర్చబడలేదు.',
    },
    {
      concern: 'ఇన్ఫ్లమేటరీ యాక్నే',
      name: 'వైష్ణవి',
      imageName: 'vaishnavi',
      age: 31,
      place: 'ఉత్తరప్రదేశ్',
      testimonial: 'నేను యాక్నే భయపడకుండా ఇప్పుడు నేను మేకప్ వేసుకోగలను',
      story: 'ఒక మోడల్‌గా ఉండటం అంటే ముఖానికి చాలా మేకప్ ని వేసుకోవడం ఇంకా ఇది మొటిమలకు తీవ్రంగా దారితీస్తుంది. Cureskin నిపుణులు దీనిని "యాక్నే కాస్మెటికా" అని పిలుస్తారని నాకు వివరించారు.',
      duration: 8,
      noOfCheckups: 5,
      diet: 'చర్మం రకం (ఆయిల్ టాలరెన్స్) ఆధారంగా ఇవ్వబడింది మరియు అనుసరించడం సులభం.',
      tabletsGiven: 'ప్రాథమిక చికిత్సలో మాత్రలు ఇవ్వబడలేదు.',
    },
    {
      concern: 'పిగ్మెంటేషన్',
      name: 'నీలం సైనీ',
      imageName: 'neelam',
      age: 24,
      place: 'పంజాబ్',
      testimonial: 'చివరకి నేను నా ముఖాన్ని ఎలాంటి పిగ్మెంటేషన్ లేకుండా చూడగలిగాను.',
      story: 'Cureskin నా పిగ్మెంటేషన్‌ను పూర్తిగా నఊయమ్ చేఉఆడమే కాకుండా ముఖ్యమైన స్కిన్ కేర్ విషయాలను తెలియజేస్తూ నా ముఖ ఆరోగ్యాన్ని మెరుగుపరిచింది.',
      duration: 8,
      noOfCheckups: 7,
      diet: 'అవును, చర్మం రకం ఆధారంగా సులభంగా అనుసరించగల ఆహారం.',
      tabletsGiven: 'చికిత్సలో ఏ మాత్రలు/సప్లిమెంట్లు చేర్చబడలేదు.',
    },
    {
      concern: 'యాక్నే',
      name: 'రజనీష్',
      imageName: 'rajneesh',
      age: 24,
      place: 'మధ్యప్రదేశ్',
      testimonial: 'నా మొటిమలు పోయాయి! నా నమ్మకాన్ని తిరిగి పొందాను.',
      story: 'నేను చాలా సంవత్సరాలుగా తీవ్రమైన మొటిమలతో పోరాడుతున్నాను.cureskin వారి ఆన్‌లైన్ చర్మవ్యాధి నిపుణులు నా ఆందోళలను విని, మొత్తం రొటీన్ ని సూచించారు. ఇది కొంత సమయం పట్టింది, కానీ నా చర్మం చివరకు స్పష్టంగా మారింది. చివరకు నేను మళ్ళీ చలా నమ్మకంగా ఉన్నాను.',
      duration: 4,
      noOfCheckups: 6,
      diet: 'ఏదీ లేదు',
      tabletsGiven: 'చికిత్సలో ఏ మాత్రలు/సప్లిమెంట్లు చేర్చబడలేదు.',
    },
  ],
  kn: [
    {
      concern: 'ಇಂಪ್ಲಾಮೇಟರಿ ಏಕ್ನೆ',
      name: 'ವಿಭಾ',
      imageName: 'vibha',
      age: 22,
      place: 'ಪಂಜಾಬ್',
      testimonial: 'ಪ್ರೆಗ್ನೆನ್ಸಿ ಸಮಯದಲ್ಲಿ ನನ್ನ ಡಾಕ್ಟರ್ ನನ್ನ ಇಂಪ್ಲಾಮೇಟರಿ ಏಕ್ನೆ ಯನ್ನು ಸರಿಪಡಿಸಿದ್ದಾರೆ.',
      story: 'ತಿಂಗಳ ಸ್ಕಿನ್ ಚೆಕಪ್ ಗಳು ಅದ್ಭುತವಾಗಿದೆ. ನಾನು ನನ್ನ ಪ್ರೆಗ್ನೆನ್ಸಿ ಬಗ್ಗೆ ತಿಳಿಸಿದಾಗ , ಡಾಕ್ಟರ್ ನನ್ನ ಯೋಗಕ್ಷೇಮವನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು ನನ್ನ ಸ್ಕಿನ್ ಕೇರ್ ಪ್ರಾಡಕ್ಟ್ ಗಳನ್ನು ತ್ವರಿತವಾಗಿ ಸರಿಹೊಂದಿಸಿದರು. Cureskin. ನಲ್ಲಿರುವ ಡಾಕ್ಟರ್ ತಂಡವನ್ನು ನಾನು ನಿಜವಾಗಿಯೂ ನಂಬುತ್ತೇನೆ.',
      duration: 4,
      noOfCheckups: 5,
      diet:  'ಚರ್ಮದ ಪ್ರಕಾರವನ್ನು (ಆಯಿಲ್ ಟಾಲರೆನ್ಸ್) ಆಧರಿಸಿ ನೀಡಲಾಗಿದೆ ಮತ್ತು ಅನುಸರಿಸಲು ಸುಲಭವಾಗಿದೆ.',
      tabletsGiven: 'ಟ್ರೀಟ್ಮೆಂಟ್ ನಲ್ಲಿ ಯಾವುದೇ ಮಾತ್ರೆಗಳು/ಸಪ್ಲಿಮೆಂಟ್ಸ್ ಅನ್ನು ಸೇರಿಸಲಾಗಿಲ್ಲ.',
    },
    {
      concern: 'ಇಂಪ್ಲಾಮೇಟರಿ ಏಕ್ನೆ',
      name: 'ವೈಷ್ಣವಿ',
      imageName: 'vaishnavi',
      age: 31,
      place: 'ಉತ್ತರ ಪ್ರದೇಶ',
      testimonial: 'ನಾನು ಮೊಡವೆಗಳ ಭಯವಿಲ್ಲದೆ ಮೇಕ್ಅಪ್ ಮಾಡಬಹುದು',
      story: 'ಮಾಡೆಲ್ ಆಗಿರುವುದು ಎಂದರೆ ಟನ್‌ಗಳಷ್ಟು ಮೇಕ್ಅಪ್ ಮತ್ತು ಅದರ ಜೊತೆಗೆ ಏಕ್ನೆ. Cureskin ಎಕ್ಸ್ಪರ್ಟ್ಸ್ ಇದನ್ನು "ಏಕ್ನೆ ಕಾಸ್ಮೆಟಿಕಾ" ಎಂದು ವಿವರಿಸಿದರು.Cureskin  ಡರ್ಮಟಾಲಜಿಸ್ಟ್ ನ ಸ್ಕಿನ್ ಟ್ರೀಟ್ಮೆಂಟ್ ನಿಂದ , ಬ್ರೇಕ್‌ಔಟ್‌ಗಳನ್ನು ನಿಯಂತ್ರಿಸುವುದರೊಂದಿಗೆ ನಾನು ಈಗ ಮೇಕ್ಅಪ್ ಮಾಡಬಹುದು.',
      duration: 8,
      noOfCheckups: 5,
      diet: 'ಚರ್ಮದ ಪ್ರಕಾರವನ್ನು (ಆಯಿಲ್ ಟಾಲರೆನ್ಸ್) ಆಧರಿಸಿ ನೀಡಲಾಗಿದೆ ಮತ್ತು ಅನುಸರಿಸಲು ಸುಲಭವಾಗಿದೆ.',
      tabletsGiven: 'ಆರಂಭಿಕ ಟ್ರೀಟ್ಮೆಂಟ್ ನಲ್ಲಿ ಯಾವುದೇ ಮಾತ್ರೆಗಳನ್ನು ನೀಡಲಾಗಿಲ್ಲ.',
    },
    {
      concern: 'ಪಿಗ್ಮೆಂಟೇಶನ್',
      name: 'ನೀಲಂ ಸೈನಿ',
      imageName: 'neelam',
      age: 24,
      place: 'ಪಂಜಾಬ್',
      testimonial: 'ನಾನು ಅಂತಿಮವಾಗಿ ನನ್ನ ಮುಖವನ್ನು ಯಾವುದೇ ಪಿಗ್ಮೆಂಟೇಶನ್ ಇಲ್ಲದೆ ನೋಡಬಹುದು.',
      story: 'Cureskin ನನ್ನ ಪಿಗ್ಮೆಂಟೇಶನ್ ಹೋಗಲಾಡಿಸಿತು ಅಲ್ಲದೆ , ಅಮೂಲ್ಯವಾದ ಸ್ಕಿನ್ ಕೇರ್ ಪಾಠಗಳನ್ನು ನೀಡುವಾಗ ನನ್ನ ಮುಖದ ಆರೋಗ್ಯವನ್ನು ಹೆಚ್ಚಿಸಿತು',
      duration: 8,
      noOfCheckups: 7,
      diet: 'ಹೌದು, ಚರ್ಮದ ಪ್ರಕಾರವನ್ನು ಆಧರಿಸಿ ಸುಲಭವಾಗಿ ಅನುಸರಿಸಬಹುದಾದ ಡಯೆಟ್.',
      tabletsGiven: 'ಟ್ರೀಟ್ಮೆಂಟ್ ನಲ್ಲಿ ಯಾವುದೇ ಮಾತ್ರೆಗಳು/ಸಪ್ಲಿಮೆಂಟ್ಸ್ ಸೇರಿಸಲಾಗಿಲ್ಲ.',
    },
    {
      concern: 'ಏಕ್ನೆ',
      name: 'ರಜನೀಶ್',
      imageName: 'rajneesh',
      age: 24,
      place: 'ಮಧ್ಯಪ್ರದೇಶ',
      testimonial: 'ನನ್ನ ಮೊಡವೆಗಳು ಹೋಗಿವೆ! ನನ್ನ ಆತ್ಮವಿಶ್ವಾಸವನ್ನು ಮರಳಿ ಪಡೆದಿದ್ದೇನೆ.',
      story: 'ನಾನು ವರ್ಷಗಳಿಂದ ತೀವ್ರವಾದ ಮೊಡವೆಗಳೊಂದಿಗೆ ಹೋರಾಡಿದೆ. ಆನ್‌ಲೈನ್ ಡರ್ಮಟಾಲಜಿಸ್ಟ್ ನನ್ನ ಕಷ್ಟಗಳನ್ನು ಆಲಿಸಿದರು ಮತ್ತು ಸಂಪೂರ್ಣ ರೊಟೀನ್ ಅನ್ನು ಸೂಚಿಸಿದರು. ಇದು ಸ್ವಲ್ಪ ಸಮಯ ತೆಗೆದುಕೊಂಡಿತು, ಆದರೆ ನನ್ನ ಚರ್ಮವು ಅಂತಿಮವಾಗಿ ಕ್ಲಿಯರ್ ಆಯಿತು. ನಾನು ಅಂತಿಮವಾಗಿ ಮತ್ತೆ ಆತ್ಮವಿಶ್ವಾಸವನ್ನು ಪಡೆದಿದ್ದೇನೆ.',
      duration: 4,
      noOfCheckups: 6,
      diet: 'ಯಾವುದೂ ಇಲ್ಲ',
      tabletsGiven: 'ಟ್ರೀಟ್ಮೆಂಟ್ ನಲ್ಲಿ ಯಾವುದೇ ಮಾತ್ರೆಗಳು/ಸಪ್ಲಿಮೆಂಟ್ಸ್  ಸೇರಿಸಲಾಗಿಲ್ಲ.',
    },
  ],
  ta: [
    {
      concern: 'இன்ஃப்ளமேடரி அக்னே',
      name: 'விபா',
      imageName: 'vibha',
      age: 22,
      place: 'பஞ்சாப்',
      testimonial: 'கர்ப்ப காலத்தில் என் மருத்துவர் என் இன்ஃப்ளமேடரி அக்னே-வை சரிசெய்தார்.',
      story: 'மாதாந்திர சரும பரிசோதனைகள் அற்புதமானவை. நான் கர்ப்பம் தரித்ததைக் குறிப்பிட்டபோது, மருத்துவர் எனது நல்வாழ்வை உறுதிசெய்ய எனது சரும பராமரிப்புப் பொருட்களை உடனடியாகச் சரிசெய்தார். Cureskin-ல் உள்ள மருத்துவர்கள் குழுவை நான் மிகவும் நம்புகிறேன்.',
      duration: 4,
      noOfCheckups: 5,
      diet:  'சரும வகை (எண்ணெய் சகிப்புத்தன்மை) அடிப்படையில் கொடுக்கப்பட்டது மற்றும் பின்பற்ற எளிதானது.',
      tabletsGiven: 'சிகிச்சையில் மாத்திரைகள்/சப்ளிமெண்ட்ஸ் சேர்க்கப்படவில்லை.',
    },
    {
      concern: 'இன்ஃப்ளமேடரி அக்னே',
      name: 'வைஷ்ணவி',
      imageName: 'vaishnavi',
      age: 31,
      place: 'உத்தரபிரதேசம்',
      testimonial: 'मैं एक्ने के डर के बिना मेकअप लगा सकती हूँ',
      story: 'முகப்பரு பயம் இல்லாமல் என்னால் மேக்கப் போட முடிகிறது நோயாளியின் கருத்துகள்: ஒரு மாடலாக இருப்பது நிறைய மேக்கப் மற்றும், முகப்பரு என்று பொருள். Cureskin நிபுணர்கள் இதை "அக்னே காஸ்மெடிகா" என்று விளக்கினர்."Cureskin மருத்துவர் சரும சிகிச்சையின் மூலம், பிரேக்அவுட்களைக் கட்டுப்படுத்தும் போது நான் இப்போது மேக்கப் அணிய முடியும்".',
      duration: 8,
      noOfCheckups: 5,
      diet: 'சரும வகை (எண்ணெய் சகிப்புத்தன்மை) அடிப்படையில் கொடுக்கப்பட்டது மற்றும் பின்பற்ற எளிதானது',
      tabletsGiven: 'ஆரம்ப சிகிச்சையில் மாத்திரைகள் வழங்கப்படவில்லை',
    },
    {
      concern: 'பிக்மென்டேஷன்',
      name: 'நீலம் சைனி',
      imageName: 'neelam',
      age: 24,
      place: 'பஞ்சாப்',
      testimonial: 'நான் இறுதியாக என் முகத்தை எந்த பிக்மென்டேஷனும்  இல்லாமல் பார்க்க முடிகிறது.',
      story: ' Cureskin என் பிக்மென்டேஷன்-ஐ  நீக்கியது மட்டுமல்லாமல், மதிப்புமிக்க சரும பராமரிப்பு பாடங்களை வழங்கும்போது எனது முக ஆரோக்கியத்தையும் மேம்படுத்தியது.',
      duration: 8,
      noOfCheckups: 7,
      diet: 'ஆம், சரும வகையின் அடிப்படையில் பின்பற்ற எளிதான உணவு.',
      tabletsGiven: 'சிகிச்சையில் மாத்திரைகள்/சப்ளிமெண்ட்ஸ் சேர்க்கப்படவில்லை.',
    },
    {
      concern: 'முகப்பரு ',
      name: 'ரஜ்னீஷ்',
      imageName: 'rajneesh',
      age: 24,
      place: 'மத்திய பிரதேசம்',
      testimonial: 'என் கடுமையான முகப்பரு போய்விட்டது! என் நம்பிக்கையை திரும்பப் பெற்றேன்.',
      story: 'நான் பல ஆண்டுகளாக கடுமையான முகப்பருவுடன் போராடினேன். ஆன்லைன் சரும மருத்துவர் எனது துயரங்களைக் கேட்டு, ஒரு முழு சிகிச்சையையும் பரிந்துரைத்தார். இது சிறிது நேரம் எடுத்தது, ஆனால் என் சருமம் இறுதியாக தெளிவாகியது. நான்  மீண்டும் நம்பிக்கையுடன் உணர்கிறேன்.',
      duration: 4,
      noOfCheckups: 6,
      diet: 'இல்லை',
      tabletsGiven: 'சிகிச்சையில் மாத்திரைகள்/சப்ளிமெண்ட்ஸ் சேர்க்கப்படவில்லை.',
    },
  ],
  ml: [
    {
      concern: 'വീക്കത്തോടെ ഉള്ള മുഖക്കുരു',
      name: 'വിഭ',
      imageName: 'vibha',
      age: 22,
      place: 'പഞ്ചാബ്',
      testimonial: 'എന്റെ ഗർഭകാലത്ത് ഡോക്ടർ വീക്കത്തോടെയുള്ള മുഖക്കുരു ചികിൽസിച്ച് മാറ്റി.',
      story: 'പ്രതിമാസ ചർമ്മ ചെക്കപ്പുകൾ വളരെ മികച്ചതാണ്. ഗർഭിണിയാണെന്ന് പറഞ്ഞപ്പോൾ. എന്റെ ആരോഗ്യത്തിന് പറ്റിയ ചർമ്മ സംരക്ഷണ ഉത്പന്നങ്ങൾ ഡോക്ടർ ക്രമീകരിച്ചു. Cureskin ലെ ഡോക്ടർമാരുടെ ടീമിനെ ഞാൻ ശരിക്കും വിശ്വസിക്കുന്നു.',
      duration: 4,
      noOfCheckups: 5,
      diet:  'ചർമ്മ തരത്തിന് അനുസരിച്ച് നൽകി (എണ്ണമയത്തിന്റെ തോത് പ്രകാരം) മാത്രമല്ല പാലിക്കാൻ എളുപ്പമായിരുന്നു.',
      tabletsGiven: 'ഗുളികകൾ/സപ്പ്ളിമെന്റുകൾ ഒന്നും ചികിത്സയിൽ ഉൾപ്പെട്ടിരുന്നില്ല.',
    },
    {
      concern: 'വീക്കത്തോടെ ഉള്ള മുഖക്കുരു',
      name: 'വൈഷ്ണവി',
      imageName: 'vaishnavi',
      age: 31,
      place: 'ഉത്തർ പ്രദേശ്',
      testimonial: 'എനിക്ക് മുഖക്കുരു പേടിക്കാതെ ഇപ്പോൾ മേക്കപ്പ് ഇടാം.',
      story: 'മോഡൽ ആയത് കാരണം, ഒരു പാട് മേക്കപ്പ് ഉപയോഗിക്കും, മാത്രമല്ല മുഖക്കുരുവും. Cureskin വിദഗ്ധർ അത് "ആക്നെ കോസ്മെറ്റിക" ആണെന്ന് വിശദമാക്കി. Cureskin ന്റെ ത്വക് രോഗ വിദഗ്ധരുടെ ചർമ്മ ചികിത്സ കൊണ്ട്, പൊട്ടലുകൾ നിയന്ത്രിച്ച് കൊണ്ട് എനിക്ക് ഇപ്പോൾ മേക്കപ്പ് ഇടാം.',
      duration: 8,
      noOfCheckups: 5,
      diet: 'ചർമ്മ തരത്തിന് അനുസരിച്ച് നൽകി (എണ്ണമയത്തിന്റെ തോത് പ്രകാരം) മാത്രമല്ല പാലിക്കാൻ എളുപ്പമായിരുന്നു.',
      tabletsGiven: 'ഗുളികകൾ ഒന്നും ആദ്യഘട്ട ചികിത്സയിൽ നൽകിയിരുന്നില്ല',
    },
    {
      concern: 'വീക്കത്തോടെ ഉള്ള മുഖക്കുരു',
      name: 'നീലം സെയ്നി',
      imageName: 'neelam',
      age: 24,
      place: 'പഞ്ചാബ്',
      testimonial: 'നിറവ്യത്യാസമില്ലാത്ത ചർമ്മം എനിക്ക് ഇനി കാണാം.',
      story: 'Cureskin എന്റെ നിറവ്യത്യാസം ഇല്ലാതാക്കുക മാത്രമല്ല വിലപ്പെട്ട ചർമ്മ പാഠങ്ങൾ നൽകിക്കൊണ്ട് എന്റെ മുഖത്തിന്റെ ആരോഗ്യം മെച്ചപ്പെടുത്തുകയും ചെയ്തു.',
      duration: 8,
      noOfCheckups: 7,
      diet: 'ചർമ്മ തരത്തിന് അനുസരിച്ച് എളുപ്പത്തിൽ പാലിക്കാൻ പറ്റിയ ഡയറ്റ്.',
      tabletsGiven: 'ഗുളികകൾ/സപ്പ്ളിമെന്റുകൾ ഒന്നും ചികിത്സയിൽ ഉൾപ്പെട്ടിരുന്നില്ല',
    },
    {
      concern: 'മുഖക്കുരു',
      name: 'രജനീഷ്',
      imageName: 'rajneesh',
      age: 24,
      place: 'മധ്യ പ്രദേശ്',
      testimonial: 'എന്റെ ഗുരുതരമായ മുഖക്കുരു ഇല്ലാതായി! എന്റെ ആത്മവിശ്വാസം തിരികെ കിട്ടി.',
      story: 'ഞാൻ വര്ഷങ്ങളായി ഗുരുതര മുഖക്കുരുവിനാൽ കഷ്ടപ്പെട്ടിരുന്നു. ഓൺലൈൻ ത്വക് രോഗ വിദഗ്ധൻ എന്റെ പ്രശ്നങ്ങൾ കേട്ട് മുഴുവൻ ചികിത്സാക്രമം നിർദേശിച്ചു. കുറച്ച് സമയം എടുത്തു, പക്ഷെ എന്റെ ചർമ്മം മുഴുവൻ തെളിഞ്ഞു. അവസാനം എനിക്ക് ആത്മവിശ്വാസം തോന്നുന്നു.',
      duration: 4,
      noOfCheckups: 6,
      diet: 'ഇല്ല',
      tabletsGiven: 'ഗുളികകൾ/സപ്പ്ളിമെന്റുകൾ ഒന്നും ചികിത്സയിൽ ഉൾപ്പെട്ടിരുന്നില്ല.',
    },
  ],
  mr: [
    {
      concern: 'दाहक पुरळ',
      name: 'विभा',
      imageName: 'vibha',
      age: 22,
      place: 'पंजाब',
      testimonial: 'माझ्या डॉक्टरांनी गर्भधारणेदरम्यान माझ्या दाहक मुरुमांना बरे केले.',
      story: 'मासिक स्कीन चेक अप उत्तम आहे. जेव्हा मी तिला माझ्या गरोदरपणाबद्दल सांगितले तेव्हा डॉक्टरांनी माझ्या आरोग्यावर कोणताही विपरीत परिणाम होऊ नये म्हणून माझी त्वचा निगा राखण्याची प्रोडक्टस त्वरित एडजस्ट  केली. Cureskin येथील डॉक्टरांच्या टीमवर मला खरोखरच मोठा विश्वास आहे.',
      duration: 4,
      noOfCheckups: 5,
      diet:  'ते त्वचेच्या प्रकारावर आधारित (ऑइल टॉलेरन्स) दिले गेले होते आणि ते अनुसरण करणे सोपे होते.',
      tabletsGiven: 'उपचारांमध्ये कोणत्याही गोळ्या/सप्लीमेंट्स चा समावेश करण्यात आला नाही.',
    },
    {
      concern: 'दाहक पुरळ',
      name: 'वैष्णवी',
      imageName: 'vaishnavi',
      age: 31,
      place: 'उत्तर प्रदेश',
      testimonial: 'मी मुरुमांच्या भीतीशिवाय मेकअप करू शकते.',
      story: 'मॉडेल असणे म्हणजे खूप मेकअप करणे आणि यामुळे मुरुमे देखील होतात. CureSkin एक्स्पर्टने मला समजावून सांगितले की याला "Acne Cosmetica" म्हणतात.CureSkin च्या Dermatologist त्वचेच्या उपचाराने, मी आता ब्रेकआउट्स नियंत्रित करताना मेकअप लागू करू शकतो.',
      duration: 8,
      noOfCheckups: 5,
      diet: 'त्वचेच्या प्रकारावर आधारित (तेल सहनशीलता) आणि अनुसरण करणे सोपे होते.',
      tabletsGiven: 'उपचारांमध्ये कोणत्याही गोळ्या/सप्लीमेंट्स चा समावेश करण्यात आला नाही.',
    },
    {
      concern: 'रंगद्रव्य',
      name: 'नीलम सैनी',
      imageName: 'neelam',
      age: 24,
      place: 'पंजाब',
      testimonial: 'मी आता कोणत्याही रंगद्रव्याशिवाय माझा चेहरा पाहू शकतो.',
      story: 'Cureskin ने केवळ माझी पिगमेंटेशनची समस्याच बरी केली नाही तर त्वचेची काळजी घेण्याचे अतिशय उपयुक्त धडे देऊन मला माझ्या चेहऱ्याचे आरोग्य सुधारण्यास मदत केली.',
      duration: 8,
      noOfCheckups: 7,
      diet: 'होय, त्वचेच्या प्रकारावर आधारित आणि अनुसरण करणे सोपे होते.',
      tabletsGiven: 'उपचारांमध्ये कोणत्याही गोळ्या/सप्लीमेंट्स चा समावेश करण्यात आला नाही.',
    },
    {
      concern: 'पुरळ',
      name: 'रजनीश',
      imageName: 'rajneesh',
      age: 24,
      place: 'मध्य प्रदेश',
      testimonial: 'मला मुरुमांची गंभीर समस्या होती जी आता बरी झाली आहे! माझा आत्मविश्वास परत आला आहे.',
      story: 'मी अनेक वर्षांपासून तीव्र मुरुमांशी झगडत होतो. ऑनलाइन डरमटोलोजीस्टनी माझी समस्या काळजीपूर्वक ऐकली आणि संपूर्ण रुटीन सांगितले. यास थोडा वेळ लागला, परंतु माझी त्वचा आता साफ झाली आहे आणि मला पुन्हा आत्मविश्वास वाटू लागला आहे.',
      duration: 4,
      noOfCheckups: 6,
      diet: 'काहीही नाही',
      tabletsGiven: 'उपचारांमध्ये कोणत्याही गोळ्या/सप्लीमेंट्स चा समावेश करण्यात आला नाही.',
    },
  ],
  bn: [
    {
      concern: 'জ্বালাযুক্ত ব্রণ',
      name: 'বিভা',
      imageName: 'vibha',
      age: 22,
      place: 'পাঞ্জাব',
      testimonial: 'আমার ডাক্তার আমার প্রেগন্যান্সির সময়ে জ্বালাযুক্ত ব্রণকে ঠিক করেছেন।',
      story: 'প্রতি মাসে যে স্কিন চেক-আপ করা হয় সেটা দুর্দান্ত। যখন আমি আমার প্রেগন্যান্সির কথা বললাম, তখন ডাক্তার সঙ্গে সঙ্গে আমার স্বাস্থ্যের ভালোর জন্য আমার স্কিনকেয়ারের প্রোডাক্টগুলোকে অ্যাডজাস্ট করে দিয়েছেন। Cureskin-এর ডাক্তারদের টিমের উপর আমার ভীষণ বিশ্বাস আছে।',
      duration: 4,
      noOfCheckups: 5,
      diet:  'ত্বকের ধরণের (অয়েল টলারেন্স) উপর ভিত্তি করে দেওয়া হয়েছে আর মেনে চলা খুব সহজ।',
      tabletsGiven: 'ট্রিটমেন্টে কোনো ট্যাবলেট/সাপ্লিমেন্ট দেওয়া হয়নি।',
    },
    {
      concern: 'জ্বালাযুক্ত ব্রণ',
      name: 'বৈষ্ণবী',
      imageName: 'vaishnavi',
      age: 31,
      place: 'উত্তরপ্রদেশ',
      testimonial: 'আমি ব্রণকে ভয় না পেয়ে মেকআপ লাগাতে পারি।',
      story: 'মডেল হলে প্রচুর মেকআপ করতে হয়, আর প্রচুর ব্রণ হয়। Cureskin-এর বিশেষজ্ঞরা ব্যাখ্যা করেছেন যে এটাকে বলা হয় “অ্যাকনে কসমেটিকা”।Cureskin-এর ডার্মাটোলজিস্টদের স্কিন ট্রিটমেন্ট দিয়ে, আমি এখন ব্রণকে নিয়ন্ত্রণে রেখে মেকআপ করতে পারি।',
      duration: 8,
      noOfCheckups: 5,
      diet: 'ত্বকের ধরণের (অয়েল টলারেন্স) উপর ভিত্তি করে দেওয়া হয়েছে আর মেনে চলা খুব সহজ।',
      tabletsGiven: 'প্রাথমিক ট্রিটমেন্টের সময় কোনো ট্যাবলেট দেওয়া হয়নি।',
    },
    {
      concern: 'পিগমেন্টেশন',
      name: 'নীলম সাইনি',
      imageName: 'neelam',
      age: 24,
      place: 'পাঞ্জাব',
      testimonial: 'শেষ পর্যন্ত আমি কোনো পিগমেন্টেশন ছাড়া আমার মুখের দিকে তাকাতে পারি।',
      story: 'Cureskin শুধুমাত্র আমার পিগমেন্টেশনকে দূর করেনি, এটা ত্বকের ব্যাপারে গুরুত্বপূর্ণ শিক্ষা দেওয়ার সাথে সাথে আমার মুখের ত্বককেও উন্নত করেছে।',
      duration: 8,
      noOfCheckups: 7,
      diet: 'হ্যাঁ, ত্বকের ধরণের উপর ভিত্তি করে সহজেই মেনে চলা যায় এমন একটা ডায়েট।',
      tabletsGiven: 'ট্রিটমেন্টে কোনো ট্যাবলেট/সাপ্লিমেন্ট দেওয়া হয়নি।',
    },
    {
      concern: 'ব্রণ',
      name: 'রজনীশ',
      imageName: 'rajneesh',
      age: 24,
      place: 'মধ্যপ্রদেশ',
      testimonial: 'আমার মারাত্মক ব্রণ ঠিক হয়ে গেছে! আমি আমার আত্মবিশ্বাস ফিরে পেয়েছি।',
      story: 'বহু বছর ধরে আমি মারাত্মক ব্রণতে ভুগছিলাম। অনলাইন ডার্মাটোলজিস্ট আমার সমস্যার কথা শুনলেন আর আমার জন্য একটা সম্পূর্ণ রুটিন তৈরি করে দিলেন। এটা কিছুটা সময় নিয়েছে, কিন্তু আস্তে আস্তে আমার ত্বক পরিষ্কার হয়ে গেছে। আমি আবার আমার আত্মবিশ্বাস ফিরে পেয়েছি।',
      duration: 4,
      noOfCheckups: 6,
      diet: 'কিছুই না',
      tabletsGiven: 'ট্রিটমেন্টে কোনো ট্যাবলেট/সাপ্লিমেন্ট দেওয়া হয়নি।',
    },
  ]
};

export const TestimonialVideoTitles: any = {
  en: [
    'Got wedding-ready skin in 3 months',
    'Pimples disappeared in 60 days',
    'For my spotless face, thank you Cureskin',
    'Cureskin got me my confidence back',
    'My journey from Sensitive to Stunning skin.',
    'Cureskin cleared my pregnancy breakouts!',
  ],
  hi: [
    '3 महीने में ही ग्लोइंग स्किन मिली',
    '60 दिनों में पिम्पल्स गायब',
    'मेरी बेदाग़ त्वचा के लिए थैंक यू Cureskin',
    'Cureskin ने दिलाया कॉन्फिडेंस वापस',
    'सेंसिटिव से लेकर खूबसूरत स्किन तक का मेरा सफर।',
    'Cureskin ने मेरी प्रेगनेंसी ब्रेकआउट्स की समस्या को ठीक कर दिया!',
  ],
  te: [
    '3 నెలల్లో పెళ్లికి సిద్ధమైన చర్మం వచ్చింది',
    '60 రోజుల్లో మొటిమలు మాయమయ్యాయి',
    'నా మచ్చలేని ముఖం కోసం, Cureskinకి ధన్యవాదాలు',
    'Cureskin నా విశ్వాసాన్ని తిరిగి పొందాడు',
    'సున్నితమైన చర్మం నుంచి అందమైన చర్మం వైపు నా ప్రయాణం.',
    'Cureskin నా ప్రెగ్నెన్సీ బ్రేక్‌అవుట్‌లను నయం చేసింది!'
  ],
  kn: [
    '3 ತಿಂಗಳಲ್ಲಿ ಮದುವೆಗೆ ಸಿದ್ಧವಾದ ಚರ್ಮ ಸಿಕ್ಕಿತು',
    'ಮೊಡವೆಗಳು 60 ದಿನಗಳಲ್ಲಿ ಕಣ್ಮರೆಯಾಗುತ್ತವೆ',
    'ನನ್ನ ನಿರ್ಮಲ ಮುಖಕ್ಕಾಗಿ, ಧನ್ಯವಾದಗಳು ಕ್ಯೂರೆಸ್ಕಿನ್',
    'ಕರ್ಸ್ಕಿನ್ ನನ್ನ ಆತ್ಮವಿಶ್ವಾಸವನ್ನು ಮರಳಿ ಪಡೆದರು',
    'ಸೆನ್ಸಿಟಿವ್‌ನಿಂದ ಸ್ಟನ್ನಿಂಗ್ ಸ್ಕಿನ್‌ಗೆ ನನ್ನ ಪಯಣ.',
    'Cureskin ನನ್ನ ಪ್ರೆಗ್ನೆನ್ಸಿಯ ಬ್ರೇಕ್‌ಔಟ್‌ಗಳನ್ನು ಗುಣಪಡಿಸಿದೆ!'
  ],
  ta: [
    '3 மாதங்களில் திருமணத்திற்கு தயாராகும் சருமம் கிடைத்தது',
    '60 நாட்களில் பருக்கள் மறைந்துவிடும்',
    'என் களங்கமற்ற முகத்திற்கு, நன்றி Cureskin',
    'Cureskin என் நம்பிக்கையை திரும்பப் பெறச் செய்தது',
    'சென்சிடிவ் சருமத்திலிருந்து அற்புதமான சருமத்திற்க்கான  எனது பயணம்.',
    'Cureskin என் கர்ப்ப கால பிரேக்அவுட்களை நீக்கியது!'
  ],
  mr: [
    '३ महिन्यात लग्नासाठी रेडी स्किन मिळाली',
    'पिंपल्स 60 दिवसात नाहीसे झाले',
    'माझ्या स्पॉटलेस चेहऱ्यासाठी, Cureskin धन्यवाद',
    'Cureskin ने माझा आत्मविश्वास परत मिळवला',
    'संवेदनशील ते सुंदर त्वचेपर्यंतचा माझा प्रवास.',
    'Cureskin ने माझ्या प्रेगनन्सी ब्रेकआऊटस् च्या समस्या बऱ्या केल्या !'
  ]
};

export const PaymentMethodsTranslations: any = {
  'Credit/Debit Card': {
    hi: 'क्रेडिट कार्ड / डेबिट कार्ड ',
    te: 'క్రెడిట్ / డెబిట్ కార్డ్',
    kn: 'ಕ್ರೆಡಿಟ್ / ಡೆಬಿಟ್ ಕಾರ್ಡ್',
    ta: 'கிரெடிட் / டெபிட் கார்டு',
    ml: 'ക്രെഡിറ്റ് / ഡെബിറ്റ് കാർഡ്',
    mr: 'क्रेडिट/डेबिट कार्ड',
    bn: 'ক্রেডিট / ডেবিট কার্ড',
  },
  Netbanking: {
    hi: 'नेटबैंकिंग',
    te: 'నెట్‌బ్యాంకింగ్',
    kn: 'ನೆಟ್ ಬ್ಯಾಂಕಿಂಗ್',
    ta: 'நெட்பேங்கிங்',
    ml: 'നെറ്റ്ബാങ്കിങ്',
    mr: 'नेटबँकिंग',
    bn: 'নেটব্যাংকিং',
  },
  'Mobile Wallets': {
    hi: 'मोबाइल वॉलेट',
    te: 'మొబైల్ వాలెట్‌లు',
    kn: 'ಮೊಬೈಲ್ ವ್ಯಾಲೆಟ್‌ಗಳು',
    ta: 'மொபைல் வாலட்டுகள்',
    ml: 'മൊബൈൽ വാലറ്റ്',
    mr: 'मोबाइल वॉलेट्स',
    bn: 'মোবাইল ওয়ালেট',
  },
  'Cash On  Delivery': {
    hi: 'कैश ऑन डिलीवरी',
    te: 'క్యాష్ ఆన్ డెలివరీ',
    kn: 'ಕ್ಯಾಶ್ ಆನ್ ಡೆಲಿವರಿ',
    ta: 'கேஷ் ஆன் டெலிவரி ',
    ml: 'ക്യാഷ് ഓൺ ഡെലിവറി',
    mr: 'कॅश ऑन डिलिव्हरी',
    bn: 'ক্যাশ অন ডেলিভারী',
  },

};

export const prescriptionVar: PrescriptionPlaceholder = {
  name : 'Dr Charu',
  signature : 'https://cdn.cureskin.com/app/img/dr-charu-sign.png'
};

export const RebrandedTestimonialStoriesObject: RebrandedTestimonialStories = {
  Male: {
    en: [
      {
        'name': 'Nath',
        'location': 'Hyderabad',
        'testimonial': "I had dark spots and acne that wouldn't go away. Thanks to Cureskin, my skin looks so much better now! The treatment really worked, and my confidence is back!",
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_M1.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_M1.png',
        'tags': ['Acne', 'Dark Spots', 'Pigmentation', 'Uneven Skin Texture'],
      },
      {
        'name': 'Karan',
        'location': 'Jharkhand',
        'testimonial': 'My acne & inflammation were getting worse, but when I started my treatment with Cureskin dermatologist, it started improving! Their products are great!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_M2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_M2.png',
        'tags': ['Inflammatory Acne', 'Acne Scars', 'Oily Skin', 'Dark Spots'],
      },
      {
        'name': 'Rajneesh',
        'location': 'MP',
        'occupation': '(Student)',
        'testimonial': 'I struggled with acne for years. The online dermatologist listened to my woes and prescribed a skin routine. It took some time, but my skin finally cleared up.',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_M3.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_M3.png',
        'tags': ['Inflammatory Acne', 'Acne Scars', 'Uneven Skin Texture'],
      },
      {
        'name': 'Manju',
        'location': 'MP',
        'occupation': '(Anganwadi Worker)',
        'testimonial': 'As an Anganwadi worker, I had to work in the sun and do field surveys, which caused spots on my skin. Thanks to Cureskin, my skin is now better!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_5.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_5.png',
        'tags': ['Pigmentation', 'Dull Skin', 'Uneven Skin Texture'],
      },
      {
        'name': 'Pratyusha',
        'location': 'Orissa',
        'occupation': '(Student)',
        'testimonial': 'Comedones on my skin really affected my self-confidence & I was embarrassed to socialise. But Cureskin treatment kit improved my skin concerns drastically!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_2.png',
        'tags': ['Comedones', 'Darkspots', 'Inflammatory Acne', 'Acne Marks',],
      },
    ],
    hi: [
      {
        'name': 'नाथ',
        'location': 'हैदराबाद',
        'testimonial': 'मेरे चेहरे पर डार्क स्पॉट्स और एक्ने थे। Cureskin की वजह से मेरी स्किन अब और भी बेहतर दिखती है! ट्रीटमेंट ने अपना काम किया और मेरा आत्मविश्वास वापस आ गया है!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_M1.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_M1.png',
        'tags': ['एक्ने', 'डार्क स्पॉट्स', 'पिगमेंटेशन', 'असमान स्किन टेक्सचर'],
      },
      {
        'name': 'करण',
        'location': 'झारखंड',
        'testimonial': 'मेरे एक्ने और इंफ्लेमेशन बदतर होते जा रहे थे, लेकिन जब मैंने Cureskin डर्मेटोलॉजिस्ट से अपना इलाज करवाया तो इसमें सुधार होने लगा! उनके प्रोडक्ट्स शानदार हैं!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_M2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_M2.png',
        'tags': ['इंफ्लेमेटरी एक्ने ', 'मुंहासों के निशान', 'ऑयली स्किन', 'डार्क स्पॉट्स'],
      },
      {
        'name': 'रजनीश',
        'location': 'मध्य प्रदेश',
        'occupation': '(स्टूडेंट)',
        'testimonial': 'मैं सालों तक एक्ने की समस्या से जूझता रहा। ऑनलाइन डर्मेटोलॉजिस्ट ने मेरी परेशानी सुनी और स्किन रूटीन दिया। कुछ समय लगा, लेकिन अब मेरी स्किन क्लियर हो गई है।',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_M3.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_M3.png',
        'tags': ['इंफ्लेमेटरी एक्ने ', 'मुंहासों के निशान', 'असमान स्किन टेक्सचर'],
      },
      {
        'name': 'मंजू',
        'location': 'मध्य प्रदेश',
        'occupation': '(आंगनवाड़ी वर्कर)',
        'testimonial': 'मैं एक आंगनवाड़ी वर्कर हूँ इसलिए धूप में काम करना और फील्ड सर्वे करना पड़ता था, जिससे स्किन पर स्पॉट्स हो जाते थे। Cureskin की वजह से मेरी स्किन बेहतर हो गई है!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_5.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_5.png',
        'tags': ['पिगमेंटेशन', 'डल स्किन', 'असमान स्किन टेक्सचर'],
      },
      {
        'name': 'प्रत्युषा',
        'location': 'उड़ीसा',
        'occupation': '(स्टूडेंट)',
        'testimonial': 'मेरी स्किन पर कॉमेडोन थे जिससे मेरा आत्मविश्वास कम हो गया और मुझे लोगों से मिलने में शर्म आती थी। Cureskin ट्रीटमेंट किट ने मेरी इस समस्या में काफी सुधार किया!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_2.png',
        'tags': ['कॉमेडोन', 'डार्क स्पॉट्स', 'इंफ्लेमेटरी एक्ने', 'मुहासों के निशान'],
      },
    ],
    te: [
      {
        'name': 'నాథ్',
        'location': 'హైదరాబాద్',
        'testimonial': 'నాకు డార్క్ స్పాట్స్, యాక్నే తగ్గలేదు.Cureskinకి ధన్యవాదాలు, నా స్కిన్ చాలా మెరుగ్గా మారింది!ట్రీట్మెంట్ బాగా పనిచేసింది ఇంకా నాకు ఆత్మవిశ్వాసం తిరిగి వచ్చింది!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_M1.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_M1.png',
        'tags': ['యాక్నే', 'డార్క్ స్పాట్స్', 'పిగ్మెంటేషన్', 'అసమాన చర్మ ఆకృతి'],
      },
      {
        'name': 'కరణ్',
        'location': 'జార్ఖండ్',
        'testimonial': 'నా యాక్నే, ఇన్ఫ్లమేషన్ తీవ్రమవుతున్నాయి, కానీ Cureskin డెర్మటాలజిస్ట్‌తో ట్రీట్మెంట్ ప్రారంభించినప్పుడు,అది మెరుగుపడటం ప్రారంభించింది! వారి ప్రోడక్ట్స్ అద్భుతం!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_M2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_M2.png',
        'tags': ['ఇన్ఫ్లమేటరీ యాక్నే', 'మొటిమల గుర్తులు', 'జిడ్డుగల చర్మం', 'డార్క్ స్పాట్స్'],
      },
      {
        'name': 'రజనీష్',
        'location': 'మధ్యప్రదేశ్',
        'occupation': '(విద్యార్థి)',
        'testimonial': 'కొన్నాళ్లుగా యాక్నేతో ఇబ్బంది పడ్డాను.ఆన్‌లైన్ డెర్మటాలజిస్ట్‌ నా బాధలు విని  స్కిన్ రొటీన్ ని సూచించారు.కొంత సమయం పట్టింది,కానీ నా చర్మం చివరకు క్లియర్ అయింది.',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_M3.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_M3.png',
        'tags': ['Iఇన్ఫ్లమేటరీ యాక్నే', 'మొటిమల గుర్తులు', 'అసమాన చర్మ ఆకృతి'],
      },
      {
        'name': 'మంజు',
        'location': 'మధ్యప్రదేశ్',
        'occupation': '(అంగన్‌వాడీ వర్కర్‌)',
        'testimonial': 'నేను అంగన్‌వాడీ వర్కర్‌ని కాబట్టి ఎండలో పనిచేసి పొలంలో సర్వేలు చేయాల్సి వచ్చి చర్మంపై మచ్చలు ఏర్పడింది. Cureskinకి ధన్యవాదాలు,నా చర్మం ఇప్పుడు మెరుగ్గా మారింది!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_5.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_5.png',
        'tags': ['పిగ్మెంటేషన్', 'డల్ స్కిన్', 'అసమాన చర్మ ఆకృతి'],
      },
      {
        'name': 'ప్రత్యూష',
        'location': 'ఒరిస్సా',
        'occupation': '(విద్యార్థి)',
        'testimonial': 'నా చర్మంపై ఉండే కామెడోన్‌లు నా ఆత్మవిశ్వాసాన్ని తగ్గించింది.నేను ప్రజలను కలవడానికి ఇబ్బంది పడ్డాను.కానీCureskinట్రీట్మెంట్ కిట్ నా చర్మ సమస్యలను మెరుగుపరిచింది!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_2.png',
        'tags': ['కామెడోన్స్', 'డార్క్ స్పాట్స్', 'ఇన్ఫ్లమేటరీ యాక్నే', 'మొటిమల గుర్తులు'],
      },
    ],
    kn: [
      {
        'name': 'ನಾಥ್',
        'location': 'ಹೈದರಾಬಾದ್',
        'testimonial': 'ನನ್ನ ಡಾರ್ಕ್ ಸ್ಪಾಟ್ಸ್ ಮತ್ತು ಏಕ್ನೆ ಹೋಗುತ್ತಿರಲಿಲ್ಲ. Cureskinಗೆ ಧನ್ಯವಾದಗಳು,  ಚರ್ಮವು ತುಂಬಾ ಉತ್ತಮವಾಗಿ ಕಾಣುತ್ತದೆ! ಟ್ರೀಟ್ಮೆಂಟ್ ಕೆಲಸ ಮಾಡಿದೆ ಆತ್ಮವಿಶ್ವಾಸ ಮತ್ತೆ ಬಂದಿದೆ!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_M1.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_M1.png',
        'tags': ['ಮೊಡವೆ', 'ಡಾರ್ಕ್ಸ್ ಸ್ಪಾಟ್ಸ್', 'ಪಿಗ್ಮೆಂಟೇಶನ್', 'ಅನ್ ಈವನ್ ಸ್ಕಿನ್ ಟೋನ್'],
      },
      {
        'name': 'ಕರಣ್',
        'location': 'ಜಾರ್ಖಂಡ್',
        'testimonial': 'ನನ್ನ ಏಕ್ನೆ ಮತ್ತು ಇಂಪ್ಲಾಮೇಶನ್ ಹೆಚ್ಚಿತ್ತು, ಆದರೆ ನಾನು Cureskin  ಡರ್ಮಟಾಲಜಿಸ್ಟ್ ನೊಂದಿಗೆ ಟ್ರೀಟ್ಮೆಂಟ್  ಪ್ರಾರಂಭಿಸಿದಾಗ,  ಸುಧಾರಿಸಲು ಪ್ರಾರಂಭಿಸಿತು! ಪ್ರಾಡಕ್ಟ್ಸ್ ಉತ್ತಮವಾಗಿವೆ!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_M2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_M2.png',
        'tags': ['ಇಂಪ್ಲಾಮೇಟರಿ ಏಕ್ನೆ', 'ಏಕ್ನೆ ಮಾರ್ಕ್ಸ್', 'ಆಯಿಲಿ ಸ್ಕಿನ್', 'ಡಾರ್ಕ್ಸ್ ಸ್ಪಾಟ್ಸ್'],
      },
      {
        'name': 'ರಜನೀಶ್',
        'location': 'ಎಂಪಿ',
        'occupation': '(ವಿದ್ಯಾರ್ಥಿ)',
        'testimonial': 'ನಾನು ವರ್ಷ ಮೊಡವೆಗಳೊಂದಿಗೆ ಹೋರಾಡಿದೆ. ಆನ್‌ಲೈನ್ ಡರ್ಮಟಾಲಜಿಸ್ಟ್  ಕಷ್ಟ ಕೇಳಿದರು  ಸ್ಕಿನ್ ರೊಟೀನ್ ಸೂಚಿಸಿದರು. ಇದು ಸ್ವಲ್ಪ ಸಮಯ ತೆಗೆದುಕೊಂಡಿತು,  ನನ್ನ ಚರ್ಮವು ಕ್ಲಿಯರ್ ಆಯಿತು.',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_M3.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_M3.png',
        'tags': ['ಇಂಪ್ಲಾಮೇಟರಿ ಏಕ್ನೆ', 'ಏಕ್ನೆ ಮಾರ್ಕ್ಸ್', 'ಅನ್ ಈವನ್ ಸ್ಕಿನ್ ಟೋನ್'],
      },
      {
        'name': 'ಮಂಜು',
        'location': 'ಎಂಪಿ',
        'occupation': '(ಅಂಗನವಾಡಿ ಕಾರ್ಯಕರ್ತ)',
        'testimonial': 'ಅಂಗನವಾಡಿ ಕೆಲಸ ಮಾಡುವುದರಿಂದ,  ಬಿಸಿಲಿನಲ್ಲಿ ಕೆಲಸ  ಮತ್ತು ಫೀಲ್ಡ್ ಸರ್ವೆ ಮಾಡಬೇಕಾಗಿತ್ತು, ಇದು ನನ್ನ ಚರ್ಮದ ಮೇಲೆ ಕಲೆ ಉಂಟುಮಾಡಿತು. Cureskin ಗೆ  ಧನ್ಯವಾದ, ಚರ್ಮವು ಈಗ ಉತ್ತಮವಾಗಿದೆ!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_5.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_5.png',
        'tags': ['ಪಿಗ್ಮೆಂಟೇಶನ್', 'ಡಲ್ ಸ್ಕಿನ್', 'ಅನ್ ಈವನ್ ಸ್ಕಿನ್ ಟೋನ್'],
      },
      {
        'name': 'ಪ್ರತ್ಯೂಷಾ',
        'location': 'ಒರಿಸ್ಸಾ',
        'occupation': '(ವಿದ್ಯಾರ್ಥಿ)',
        'testimonial': 'ನನ್ನ ಚರ್ಮದ ಮೇಲಿನ ಕಾಮೆಡೋನ್‌  ಆತ್ಮವಿಶ್ವಾಸವನ್ನು ಪ್ರಭಾವಿಸುತ್ತವೆ ಮತ್ತು ನಾನು ಬೆರೆಯಲು ಆಗುತ್ತಿರಲಿಲ್ಲ. Cureskin ಟ್ರೀಟ್ಮೆಂಟ್ ಕಿಟ್ ಚರ್ಮದ ಸಮಸ್ಯೆಯನ್ನು ತೀವ್ರವಾಗಿ ಸುಧಾರಿಸಿದೆ!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_2.png',
        'tags': ['ಕಾಮೆಡೋನ್ಸ್', 'ಡಾರ್ಕ್ಸ್ ಸ್ಪಾಟ್ಸ್', 'ಇಂಪ್ಲಾಮೇಟರಿ ಏಕ್ನೆ', 'ಏಕ್ನೆ ಮಾರ್ಕ್ಸ್'],
      },
    ],
    ta: [
      {
        'name': 'நாத்',
        'location': 'ஹைதராபாத்',
        'testimonial': 'என் கரும்புள்ளிககளும்  முகப்பருக்களும் மறையவில்லை.நன்றி Cureskin, சருமம் இப்போது மிகவும் நன்றாக இருக்கிறது! சிகிச்சை வேலை செய்தது, நம்பிக்கை மீண்டும் வந்தது!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_M1.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_M1.png',
        'tags': ['முகப்பரு', 'கரும்புள்ளிகள்', 'பிக்மென்டேஷன்', 'சீரற்ற தோல் அமைப்பு'],
      },
      {
        'name': 'கரன்',
        'location': 'ஜார்கண்ட்',
        'testimonial': 'என் முகப்பருவும் வீக்கமும் மோசமடைந்தது,ஆனால் நான் Cureskin மருத்துவரிடம் சிகிச்சையை தொடங்கியபோது, ​​​​அது மேம்படத் தொடங்கியது! அவர்களின் தயாரிப்புகள் சிறந்தவை!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_M2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_M2.png',
        'tags': ['இன்ஃப்ளமேட்டரி அக்னே', 'முகப்பரு வடுக்கள்', 'எண்ணெய் பசை சருமம்', 'கரும்புள்ளிகள்'],
      },
      {
        'name': 'ரஜ்னீஷ்',
        'location': 'மத்திய பிரதேசம்',
        'occupation': '(மாணவர்)',
        'testimonial': 'பல ஆண்டுகளாக முகப்பருவுடன் போராடினேன். ஆன்லைன் மருத்துவர் எனக்கு சிகிச்சையை பரிந்துரைத்தார். இது சிறிது நேரம் எடுத்தாலும் சருமம் இறுதியாக தெளிந்தது.',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_M3.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_M3.png',
        'tags': ['இன்ஃப்ளமேட்டரி அக்னே', 'முகப்பரு வடுக்கள்', 'சீரற்ற தோல் அமைப்பு'],
      },
      {
        'name': 'மஞ்சு',
        'location': 'மத்திய பிரதேசம்',
        'occupation': '(அங்கன்வாடி பணியாளர்)',
        'testimonial': 'அங்கன்வாடி ஊழியரான நான் வெயிலில் வேலை செய்து கள ஆய்வு செய்ததால் என் தோலில் புள்ளிகள் ஏற்பட்டன. Cureskin மூலம் என் சருமம் இப்போது நன்றாக இருக்கிறது!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_5.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_5.png',
        'tags': ['பிக்மென்டேஷன்', 'மந்தமான சருமம்', 'சீரற்ற தோல் அமைப்பு'],
      },
      {
        'name': 'பிரத்யுஷா',
        'location': 'ஒரிசா ',
        'occupation': '(மாணவி)',
        'testimonial': 'சருமத்தில்  காமெடோன்கள் என் தன்னம்பிக்கையை பாதித்தது.நான் பழகுவதற்கு வெட்கப்பட்டேன். ஆனால் Cureskin சிகிச்சை கிட் என் சரும பிரச்சனைகளை வெகுவாக தீர்த்து வைத்தது!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_2.png',
        'tags': ['கொமெடோன்கள்', 'கரும்புள்ளிகள்', 'இன்ஃப்ளமேட்டரி அக்னே', 'முகப்பரு வடுக்கள்'],
      },
    ],
    ml: [
      {
        'name': 'Nath',
        'location': 'Hyderabad',
        'testimonial': "I had dark spots and acne that wouldn't go away. Thanks to Cureskin, my skin looks so much better now! The treatment really worked, and my confidence is back!",
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_M1.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_M1.png',
        'tags': ['Acne', 'Dark Spots', 'Pigmentation', 'Uneven Skin Texture'],
      },
      {
        'name': 'Karan',
        'location': 'Jharkhand',
        'testimonial': 'My acne & inflammation were getting worse, but when I started my treatment with Cureskin dermatologist, it started improving! Their products are great!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_M2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_M2.png',
        'tags': ['Inflammatory Acne', 'Acne Scars', 'Oily Skin', 'Dark Spots'],
      },
      {
        'name': 'Rajneesh',
        'location': 'MP',
        'occupation': '(Student)',
        'testimonial': 'I struggled with acne for years. The online dermatologist listened to my woes and prescribed a skin routine. It took some time, but my skin finally cleared up.',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_M3.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_M3.png',
        'tags': ['Inflammatory Acne', 'Acne Scars', 'Uneven Skin Texture'],
      },
      {
        'name': 'Manju',
        'location': 'MP',
        'occupation': '(Anganwadi Worker)',
        'testimonial': 'As an Anganwadi worker, I had to work in the sun and do field surveys, which caused spots on my skin. Thanks to Cureskin, my skin is now better!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_5.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_5.png',
        'tags': ['Pigmentation', 'Dull Skin', 'Uneven Skin Texture'],
      },
      {
        'name': 'Pratyusha',
        'location': 'Orissa',
        'occupation': '(Student)',
        'testimonial': 'Comedones on my skin really affected my self-confidence & I was embarrassed to socialise. But Cureskin treatment kit improved my skin concerns drastically!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_2.png',
        'tags': ['Comedones', 'Darkspots', 'Inflammatory Acne', 'Acne Marks',],
      },
    ],
    mr: [
      {
        'name': 'नाथ',
        'location': 'हैदराबाद',
        'testimonial': 'माझ्या चेहऱ्यावर काळे डाग आणि मुरूम होते वाटले कधीच जाणार नाहीत. Cureskin चे आभार, ट्रीटमेंटने माझी स्किन आता खूप चांगली दिसते! माझा आत्मविश्वास परत आला!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_M1.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_M1.png',
        'tags': ['एक्ने', 'डार्क स्पॉट्स', 'पिगमेंटेशन', 'असमान स्किन टेक्सचर'],
      },
      {
        'name': 'करण',
        'location': 'झारखंड',
        'testimonial': 'माझे मुरुम आणि जळजळ आणखी वाढत चालली होती, पण जेव्हा मी Cureskin डर्मोलॉजिस्टचे उपचार सुरू केले, तेव्हा स्किन सुधारू लागली! ही प्रॉडक्ट  छान आहेत!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_M2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_M2.png',
        'tags': ['इंफ्लेमेटरी एक्ने', 'मुरुमांच्या खुणा', 'ऑयली स्किन', 'डार्क स्पॉट्स'],
      },
      {
        'name': 'रजनीश',
        'location': 'मध्य प्रदेश',
        'occupation': '(विद्यार्थी)',
        'testimonial': 'मी अनेक वर्षांपासून मुरुमांसोबत स्ट्रगल करत होते. ऑनलाइन डर्मोटॉलिजिस्टने समस्या ऐकून त्वचेची दिनचर्या लिहून दिली. थोड्या दिवसातच माझी स्किन शेवटी क्लीन झाली.',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_M3.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_M3.png',
        'tags': ['इंफ्लेमेटरी एक्ने', 'मुरुमांच्या खुणा', 'असमान स्किन टेक्सचर'],
      },
      {
        'name': 'मंजू',
        'location': 'खासदार',
        'occupation': '(अंगणवाडी सेविका)',
        'testimonial': 'अंगणवाडी सेविका म्हणून मी उन्हात शेताचे सर्वेक्षणावर काम करते , त्यामुळे माझ्या स्किनवर डाग पडले. Cureskin चे आभार, माझी स्किन आता चांगली आहे!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_5.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_5.png',
        'tags': ['पिगमेंटेशन', 'डल स्किन', 'असमान स्किन टेक्सचर'],
      },
      {
        'name': 'प्रत्युषा',
        'location': 'ओरिसा',
        'occupation': '(विद्यार्थी)',
        'testimonial': 'माझ्या स्किनवरील कॉमेडोनमुळे आत्मविश्वास कमी झाला त्यामुळे मला लोकांना भेटताना लाज वाटायची. Cureskin ट्रीटमेंट किटने माझ्या समस्येत खूप सुधारणा केली आहे!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_2.png',
        'tags': ['कॉमेडोन', 'डार्क स्पॉट्स', 'इंफ्लेमेटरी एक्ने ', 'मुरुमांच्या खुणा'],
      },
    ],
    bn: [
      {
        'name': 'Nath',
        'location': 'Hyderabad',
        'testimonial': "I had dark spots and acne that wouldn't go away. Thanks to Cureskin, my skin looks so much better now! The treatment really worked, and my confidence is back!",
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_M1.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_M1.png',
        'tags': ['Acne', 'Dark Spots', 'Pigmentation', 'Uneven Skin Texture'],
      },
      {
        'name': 'Karan',
        'location': 'Jharkhand',
        'testimonial': 'My acne & inflammation were getting worse, but when I started my treatment with Cureskin dermatologist, it started improving! Their products are great!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_M2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_M2.png',
        'tags': ['Inflammatory Acne', 'Acne Scars', 'Oily Skin', 'Dark Spots'],
      },
      {
        'name': 'Rajneesh',
        'location': 'MP',
        'occupation': '(Student)',
        'testimonial': 'I struggled with acne for years. The online dermatologist listened to my woes and prescribed a skin routine. It took some time, but my skin finally cleared up.',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_M3.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_M3.png',
        'tags': ['Inflammatory Acne', 'Acne Scars', 'Uneven Skin Texture'],
      },
      {
        'name': 'Manju',
        'location': 'MP',
        'occupation': '(Anganwadi Worker)',
        'testimonial': 'As an Anganwadi worker, I had to work in the sun and do field surveys, which caused spots on my skin. Thanks to Cureskin, my skin is now better!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_5.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_5.png',
        'tags': ['Pigmentation', 'Dull Skin', 'Uneven Skin Texture'],
      },
      {
        'name': 'Pratyusha',
        'location': 'Orissa',
        'occupation': '(Student)',
        'testimonial': 'Comedones on my skin really affected my self-confidence & I was embarrassed to socialise. But Cureskin treatment kit improved my skin concerns drastically!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_2.png',
        'tags': ['Comedones', 'Darkspots', 'Inflammatory Acne', 'Acne Marks',],
      },
    ],
  },
  Female: {
    en: [
      {
        'name': 'Vibha',
        'location': 'Amritsar',
        'testimonial': "Cureskin's monthly skin check-ups are fantastic. When I mentioned my pregnancy, the doctor promptly adjusted my skincare products to ensure my well-being",
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_1.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_1.png',
        'tags': ['Inflammatory Acne', 'Acne Marks', 'Oily Skin', 'Uneven Skin Texture'],
      },
      {
        'name': 'Pratyusha',
        'location': 'Orissa',
        'occupation': '(Student)',
        'testimonial': 'Comedones on my skin really affected my self-confidence & I was embarrassed to socialise. But Cureskin treatment kit improved my skin concerns drastically!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_2.png',
        'tags': ['Comedones', 'Darkspots', 'Inflammatory Acne', 'Acne Marks',],
      },
      {
        'name': 'Neelam',
        'location': 'Punjab',
        'occupation': '(Yoga Instructor)',
        'testimonial': 'Cureskin not only helped improve my melasma but also enhanced my facial health while educating me about sustainable skin care methods. Their products are great!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_3.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_3.png',
        'tags': ['Melasma', 'Uneven Skin Tone', 'Wrinkles'],
      },
      {
        'name': 'Vaishnavi',
        'location': 'Uttar Pradesh',
        'occupation': '(Model)',
        'testimonial': "Being a model meant tons of makeup causing acne. Cureskin doctor explained it's “acne cosmetica”. I love how she created my routine to fix my acne & dark spots.",
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_4.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_4.png',
        'tags': ['Darkspots', 'Pimples', 'Uneven Skin Texture', 'Acne Scars'],
      },
      {
        'name': 'Manju',
        'location': 'MP',
        'occupation': '(Anganwadi Worker)',
        'testimonial': 'As an Anganwadi worker, I had to work in the sun and do field surveys, which caused spots on my skin. Thanks to Cureskin, my skin is now better!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_5.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_5.png',
        'tags': ['Pigmentation', 'Dull Skin', 'Uneven Skin Texture'],
      },
      {
        'name': 'Shahin',
        'location': 'Uttarakhand',
        'testimonial': "Dealing with both inflammatory acne and melasma was a real challenge and I couldn't go out to see a doctor. Cureskin's treatment fixed my issues from home.",
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_6.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_6.png',
        'tags': ['Inflammatory Acne', 'Oily Skin', 'Pigmentation', 'Open Pores'],
      },
    ],
    hi: [
      {
        'name': 'विभा',
        'location': 'अमृतसर',
        'testimonial': 'Cureskin के मंथली स्किन चेक-अप बहुत बढ़िया हैं। मैंने डॉक्टर को प्रेगनेंसी के बारे में बताया और इसे ध्यान में रखते हुए स्किनकेयर प्रोडक्ट्स को एडजस्ट किया गया।',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_1.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_1.png',
        'tags': ['इंफ्लेमेटरी एक्ने', 'मुहासों के निशान', 'ऑयली स्किन', 'असमान स्किन टेक्सचर'],
      },
      {
        'name': 'प्रत्युषा',
        'location': 'उड़ीसा',
        'occupation': '(स्टूडेंट)',
        'testimonial': 'मेरी स्किन पर कॉमेडोन थे जिससे मेरा आत्मविश्वास कम हो गया और मुझे लोगों से मिलने में शर्म आती थी। Cureskin ट्रीटमेंट किट ने मेरी इस समस्या में काफी सुधार किया!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_2.png',
        'tags': ['कॉमेडोन', 'डार्क स्पॉट्स', 'इंफ्लेमेटरी एक्ने', 'मुहासों के निशान',],
      },
      {
        'name': 'नीलम',
        'location': 'पंजाब',
        'occupation': '(योग प्रशिक्षक)',
        'testimonial': 'Cureskin ने न सिर्फ मेरी पिगमेंटेशन की समस्या तो ठीक किया बल्कि स्किन केयर लेसन देकर मेरी फेशियल हेल्थ को भी बेहतर करने में मदद की। उनके प्रोडक्ट्स शानदार हैं!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_3.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_3.png',
        'tags': ['मेलास्मा', 'असमान स्किन टोन', 'झुर्रियाँ'],
      },
      {
        'name': 'वैष्णवी ',
        'location': 'उत्तर प्रदेश',
        'occupation': '(मॉडल)',
        'testimonial': 'एक मॉडल होने का मतलब है ढेर सारा मेकअप, इस वजह से एक्ने भी हो जाते हैं और इसे "एक्ने कॉस्मेटिका" कहते हैं। Cureskin डॉक्टर ने बहुत अच्छा रूटीन तैयार किया!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_4.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_4.png',
        'tags': ['डार्क स्पॉट्स', 'मुहांसे', 'असमान स्किन टेक्सचर', 'मुंहासों के निशान'],
      },
      {
        'name': 'मंजू',
        'location': 'मध्य प्रदेश',
        'occupation': '(आंगनवाड़ी वर्कर)',
        'testimonial': 'मैं एक आंगनवाड़ी वर्कर हूँ इसलिए धूप में काम करना और फील्ड सर्वे करना पड़ता था, जिससे स्किन पर स्पॉट्स हो जाते थे। Cureskin की वजह से मेरी स्किन बेहतर हो गई है!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_5.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_5.png',
        'tags': ['पिगमेंटेशन', 'डल स्किन', 'असमान स्किन टेक्सचर'],
      },
      {
        'name': 'शाहीन',
        'location': 'उत्तराखंड',
        'testimonial': 'इंफ्लेमेटरी एक्ने और मेलास्मा से निपटना बड़ी चुनौती थी और मैं डॉक्टर के पास नहीं जा सकती थी। Cureskin के ट्रीटमेंट ने घर बैठे ही मेरी इन समस्याओं को ठीक कर दिया।',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_6.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_6.png',
        'tags': ['इंफ्लेमेटरी एक्ने', 'ऑयली स्किन', 'पिगमेंटेशन', 'ओपन पोर्स'],
      },
    ],
    te: [
      {
        'name': 'విభా',
        'location': 'అమృత్‌సర్',
        'testimonial': 'Cureskin మంత్లీ చెక్అప్ లు చాలా బాగున్నాయి. నా ప్రెగ్నెన్సీ గురించి డాక్టర్ కి చెప్పినప్పుడు, దీన్ని దృష్టిలో ఉంచుకుని స్కిన్ కేర్  ప్రోడక్ట్స్ సర్దుబాటు చేశారు',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_1.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_1.png',
        'tags': ['ఇన్ఫ్లమేటరీ యాక్నే', 'మొటిమల గుర్తులు', 'జిడ్డుగల చర్మం', 'అసమాన చర్మ ఆకృతి'],
      },
      {
        'name': 'ప్రత్యూష',
        'location': 'ఒరిస్సా',
        'occupation': '(విద్యార్థి)',
        'testimonial': 'నా చర్మంపై ఉండే కామెడోన్‌లు నా ఆత్మవిశ్వాసాన్ని తగ్గించింది.నేను ప్రజలను కలవడానికి ఇబ్బంది పడ్డాను.కానీCureskinట్రీట్మెంట్ కిట్ నా చర్మ సమస్యలను మెరుగుపరిచింది!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_2.png',
        'tags': ['కామెడోన్స్', 'డార్క్ స్పాట్స్', 'ఇన్ఫ్లమేటరీ యాక్నే', 'మొటిమల గుర్తులు'],
      },
      {
        'name': 'నీలం',
        'location': 'పంజాబ్',
        'occupation': '(యోగా శిక్షకుడు)',
        'testimonial': 'Cureskin నా మెలస్మాను నయం చేయడమే కాకుండా చర్మ సంరక్షణ పద్ధతుల గురించి నాకు అవగాహన కల్పిస్తూ నా ముఖ ఆరోగ్యాన్ని మెరుగుపరిచింది. వారి ప్రోడక్ట్స్ అద్భుతమైనవి!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_3.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_3.png',
        'tags': ['మెలస్మా', 'అసమాన స్కిన్ టోన్', 'ముడతలు'],
      },
      {
        'name': 'వైష్ణవి',
        'location': 'ఉత్తర ప్రదేశ్',
        'occupation': '(మోడల్)',
        'testimonial': 'మోడల్‌ అంటేనే మేకప్‌ ఇది మొటిమలను కలిగిస్తాయి. దీనిని "యాక్నే కాస్మెటిక" అని Cureskin డాక్టర్ వివరించారు.ఇది సరి చేయడానికి డాక్టర్ నాకు ఒక రొటీన్ ని తయారు చేశారు!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_4.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_4.png',
        'tags': ['డార్క్ స్పాట్స్', 'మొటిమలు', 'అసమాన చర్మ ఆకృతి', 'మొటిమల గుర్తులు'],
      },
      {
        'name': 'మంజు',
        'location': 'మధ్యప్రదేశ్',
        'occupation': '(అంగన్‌వాడీ వర్కర్‌)',
        'testimonial': 'నేను అంగన్‌వాడీ వర్కర్‌ని కాబట్టి ఎండలో పనిచేసి పొలంలో సర్వేలు చేయాల్సి వచ్చి చర్మంపై మచ్చలు ఏర్పడింది. Cureskinకి ధన్యవాదాలు,నా చర్మం ఇప్పుడు మెరుగ్గా మారింది!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_5.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_5.png',
        'tags': ['పిగ్మెంటేషన్', 'డల్ స్కిన్', 'అసమాన చర్మ ఆకృతి'],
      },
      {
        'name': 'షాహిన్',
        'location': 'ఉత్తరాఖండ్',
        'testimonial': 'ఇన్ఫ్లమేటరీ యాక్నే, మెలాస్మా రెండింటినీ ఎదుర్కోవడం పెద్ద సవాలు. నేను డాక్టర్‌ వద్దకు వెళ్లలేకపోయాను. Cureskin ట్రీట్మెంట్ ఇంటి నుండే నా సమస్యలను పరిష్కరించింది',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_6.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_6.png',
        'tags': ['ఇన్ఫ్లమేటరీ యాక్నే', 'జిడ్డుగల చర్మం', 'పిగ్మెంటేషన్', 'ఓపెన్ పోర్స్'],
      },
    ],
    kn: [
      {
        'name': 'ವಿಭಾ',
        'location': 'ಅಮೃತಸರ್',
        'testimonial': "Cureskin' ತಿಂಗಳ ಸ್ಕಿನ್ ಚೆಕಪ್ ಅದ್ಭುತವಾಗಿವೆ. ನನ್ನ ಪ್ರೆಗ್ನೆನ್ಸಿ ಬಗ್ಗೆ ಹೇಳಿದಾಗ , ಡಾಕ್ಟರ್ ನನ್ನ ಕ್ಷೇಮವನ್ನು ಖಚಿತಪಡಿಸಲು ನನ್ನ ಸ್ಕಿನ್ ಕೇರ್ ಪ್ರಾಡಕ್ಟ್ಸ್ ಸರಿಹೊಂದಿಸಿದರು",
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_1.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_1.png',
        'tags': ['ಇಂಪ್ಲಾಮೇಟರಿ ಏಕ್ನೆ', 'ಏಕ್ನೆ ಮಾರ್ಕ್ಸ್', 'ಆಯಿಲಿ ಸ್ಕಿನ್', 'ಅನ್ ಈವನ್ ಸ್ಕಿನ್ ಟೋನ್'],
      },
      {
        'name': 'ಪ್ರತ್ಯೂಷಾ',
        'location': 'ಒರಿಸ್ಸಾ',
        'occupation': '(ವಿದ್ಯಾರ್ಥಿ)',
        'testimonial': 'ನನ್ನ ಚರ್ಮದ ಮೇಲಿನ ಕಾಮೆಡೋನ್‌  ಆತ್ಮವಿಶ್ವಾಸವನ್ನು ಪ್ರಭಾವಿಸುತ್ತವೆ ಮತ್ತು ನಾನು ಬೆರೆಯಲು ಆಗುತ್ತಿರಲಿಲ್ಲ. Cureskin ಟ್ರೀಟ್ಮೆಂಟ್ ಕಿಟ್ ಚರ್ಮದ ಸಮಸ್ಯೆಯನ್ನು ತೀವ್ರವಾಗಿ ಸುಧಾರಿಸಿದೆ!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_2.png',
        'tags': ['ಕಾಮೆಡೋನ್ಸ್', 'ಡಾರ್ಕ್ಸ್ ಸ್ಪಾಟ್ಸ್', 'ಇಂಪ್ಲಾಮೇಟರಿ ಏಕ್ನೆ', 'ಏಕ್ನೆ ಮಾರ್ಕ್ಸ್',],
      },
      {
        'name': 'ನೀಲಂ',
        'location': 'ಪಂಜಾಬ್',
        'occupation': '(ಯೋಗ ಶಿಕ್ಷಕಿ)',
        'testimonial': 'Cureskin ನನ್ನ ಮೆಲಸ್ಮಾವನ್ನು ಸುಧಾರಿಸಲು ಸಹಾಯ ಮಾಡಿತು ಆದರೆ ಸುಸ್ಥಿರ ಸ್ಕಿನ್ ಕೇರ್ ಬಗ್ಗೆ ನನಗೆ ಶಿಕ್ಷಣ ನೀಡುವಾಗ ಮುಖದ ಆರೋಗ್ಯವನ್ನು ಹೆಚ್ಚಿಸಿತು. ಅವರ ಪ್ರಾಡಕ್ಟ್ಸ್ ಉತ್ತಮವಾಗಿವೆ!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_3.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_3.png',
        'tags': ['ಮೆಲಸ್ಮಾ', 'ಅನ್ ಈವನ್ ಸ್ಕಿನ್ ಟೋನ್', 'ರಿಂಕಲ್ಸ್'],
      },
      {
        'name': 'ವೈಷ್ಣವಿ',
        'location': 'ಉತ್ತರ ಪ್ರದೇಶ',
        'occupation': '(ಮಾಡೆಲ್)',
        'testimonial': 'ಮಾಡೆಲ್ ಆಗಿರುವುದು ಎಂದರೆ ತುಂಬಾ ಮೇಕಪ್ ಮಾಡುವುದು, ಇದು ಮೊಡವೆಗಳಿಗೆ ಕಾರಣವಾಗುತ್ತದೆ. ಸರಿಪಡಿಸಲು  Cureskin ಡಾಕ್ಟರ್ ಉತ್ತಮ ರೊಟೀನ್ ರಚಿಸಿದ್ದಾರೆ!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_4.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_4.png',
        'tags': ['ಡಾರ್ಕ್ಸ್ ಸ್ಪಾಟ್ಸ್', 'ಮೊಡವೆಗಳು', 'ಅನ್ ಈವನ್ ಸ್ಕಿನ್ ಟೋನ್', 'ಏಕ್ನೆ ಮಾರ್ಕ್ಸ್'],
      },
      {
        'name': 'ಮಂಜು',
        'location': 'ಎಂಪಿ',
        'occupation': '(ಅಂಗನವಾಡಿ ಕಾರ್ಯಕರ್ತ)',
        'testimonial': 'ಅಂಗನವಾಡಿ ಕೆಲಸ ಮಾಡುವುದರಿಂದ,  ಬಿಸಿಲಿನಲ್ಲಿ ಕೆಲಸ  ಮತ್ತು ಫೀಲ್ಡ್ ಸರ್ವೆ ಮಾಡಬೇಕಾಗಿತ್ತು, ಇದು ನನ್ನ ಚರ್ಮದ ಮೇಲೆ ಕಲೆ ಉಂಟುಮಾಡಿತು. Cureskin ಗೆ  ಧನ್ಯವಾದ, ಚರ್ಮವು ಈಗ ಉತ್ತಮವಾಗಿದೆ!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_5.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_5.png',
        'tags': ['ಪಿಗ್ಮೆಂಟೇಶನ್', 'ಡಲ್ ಸ್ಕಿನ್', 'ಅನ್ ಈವನ್ ಸ್ಕಿನ್ ಟೋನ್'],
      },
      {
        'name': 'ಶಾಹಿನ್',
        'location': 'ಉತ್ತರಾಖಂಡ್',
        'testimonial': "ಇಂಪ್ಲಾಮೇಶನ್ ಏಕ್ನೆ ಮತ್ತು ಮೆಲಸ್ಮಾ ಎರಡನ್ನೂ ನಿಭಾಯಿಸುವುದು ಸವಾಲಾಗಿತ್ತು ನನಗೆ  ಡಾಕ್ಟರ್ ನೋಡಲು ಹೋಗಲು ಸಾಧ್ಯವಾಗಲಿಲ್ಲ. Cureskin's ಟ್ರೀಟ್ಮೆಂಟ್  ಮನೆಯಿಂದಲೇ ಸಮಸ್ಯೆ ಬಗೆಹರಿಸಿದೆ.",
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_6.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_6.png',
        'tags': ['ಇಂಪ್ಲಾಮೇಟರಿ ಏಕ್ನೆ', 'ಆಯಿಲಿ ಸ್ಕಿನ್', 'ಪಿಗ್ಮೆಂಟೇಶನ್', 'ಓಪನ್ ಪೋರ್ಸ್'],
      },
    ],
    ta: [
      {
        'name': 'விபா',
        'location': 'அமிர்தசரஸ்',
        'testimonial': 'Cureskin-ன் மாதாந்திர செக் அப்கள் அற்புதமானவை. நான் கர்ப்பமானபோது, ​​மருத்துவர் எனது நல்வாழ்வுக்காக எனது சரும பராமரிப்புப் பொருட்களை உடனடியாகச் சரிசெய்தார்.',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_1.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_1.png',
        'tags': ['இன்ஃப்ளமேட்டரி அக்னே ', 'முகப்பரு வடுக்கள்', 'எண்ணெய் பசை சருமம்', 'சீரற்ற தோல் அமைப்பு'],
      },
      {
        'name': 'பிரத்யுஷா',
        'location': 'ஒரிசா',
        'occupation': '(மாணவி)',
        'testimonial': 'சருமத்தில்  காமெடோன்கள் என் தன்னம்பிக்கையை பாதித்தது.நான் பழகுவதற்கு வெட்கப்பட்டேன். ஆனால் Cureskin சிகிச்சை கிட் என் சரும பிரச்சனைகளை வெகுவாக தீர்த்து வைத்தது!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_2.png',
        'tags': ['கொமெடோன்கள்', 'கரும்புள்ளிகள்', 'இன்ஃப்ளமேட்டரி அக்னே', 'முகப்பரு வடுக்கள்'],
      },
      {
        'name': 'நீலம்',
        'location': 'பஞ்சாப்',
        'occupation': '(யோகா ஆசிரியர்)',
        'testimonial': 'Cureskin எனது மெலஸ்மாவை மேம்படுத்த உதவி, நிலையான சரும பராமரிப்பு முறைகளை கற்பித்து எனது முக ஆரோக்கியத்தையும் மேம்படுத்தியது. அவர்களின் தயாரிப்புகள் சிறந்தவை!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_3.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_3.png',
        'tags': ['மெலஸ்மா', 'சீரற்ற தோல் நிறம்', 'சுருக்கங்கள்'],
      },
      {
        'name': 'வைஷ்ணவி',
        'location': 'உத்தரப் பிரதேசம்',
        'occupation': '(மாடல்)',
        'testimonial': 'மாடல் என்றாலே மேக்அப் மற்றும் முகப்பரு. Cureskin மருத்துவர் இது "அக்னே காஸ்மெடிகா" என விளக்கினார்.  இதை சரிசெய்ய அவர் உருவாக்கிய சிகிச்சை மிகவும் பிடிக்கும்',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_4.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_4.png',
        'tags': ['கரும்புள்ளிகள்', 'பருக்கள்', 'சீரற்ற தோல் அமைப்பு', 'முகப்பரு வடுக்கள்'],
      },
      {
        'name': 'மஞ்சு',
        'location': 'மத்திய பிரதேசம்',
        'occupation': '(அங்கன்வாடி பணியாளர்)',
        'testimonial': 'அங்கன்வாடி ஊழியரான நான் வெயிலில் வேலை செய்து கள ஆய்வு செய்ததால் என் தோலில் புள்ளிகள் ஏற்பட்டன. Cureskin மூலம் என் சருமம் இப்போது நன்றாக இருக்கிறது!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_5.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_5.png',
        'tags': ['பிக்மென்டேஷன்', 'மந்தமான சருமம்', 'சீரற்ற தோல் அமைப்பு'],
      },
      {
        'name': 'ஷாஹின்',
        'location': 'உத்தரகாண்ட்',
        'testimonial': 'இன்ஃப்ளமேடரி அக்னே, மெலஸ்மாவை சமாளிப்பது சவாலாக இருந்தது, மருத்துவரை பார்க்க வெளியே செல்ல முடியவில்லை. Cureskin சிகிச்சை பிரச்னைகளை வீட்டிலிருந்தே சரிசெய்தது',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_6.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_6.png',
        'tags': ['இன்ஃப்ளமேட்டரி அக்னே', 'எண்ணெய் பசை சருமம்', 'பிக்மென்டேஷன்', 'திறந்த சரும துளைகள்'],
      },
    ],
    ml: [
      {
        'name': 'Vibha',
        'location': 'Amritsar',
        'testimonial': "Cureskin's monthly skin check-ups are fantastic. When I mentioned my pregnancy, the doctor promptly adjusted my skincare products to ensure my well-being",
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_1.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_1.png',
        'tags': ['Inflammatory Acne', 'Acne Marks', 'Oily Skin', 'Uneven Skin Texture'],
      },
      {
        'name': 'Pratyusha',
        'location': 'Orissa',
        'occupation': '(Student)',
        'testimonial': 'Comedones on my skin really affected my self-confidence & I was embarrassed to socialise. But Cureskin treatment kit improved my skin concerns drastically!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_2.png',
        'tags': ['Comedones', 'Darkspots', 'Inflammatory Acne', 'Acne Marks',],
      },
      {
        'name': 'Neelam',
        'location': 'Punjab',
        'occupation': '(Yoga Instructor)',
        'testimonial': 'Cureskin not only helped improve my melasma but also enhanced my facial health while educating me about sustainable skin care methods. Their products are great!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_3.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_3.png',
        'tags': ['Melasma', 'Uneven Skin Tone', 'Wrinkles'],
      },
      {
        'name': 'Vaishnavi',
        'location': 'Uttar Pradesh',
        'occupation': '(Model)',
        'testimonial': "Being a model meant tons of makeup causing acne. Cureskin doctor explained it's “acne cosmetica”. I love how she created my routine to fix my acne & dark spots.",
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_4.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_4.png',
        'tags': ['Darkspots', 'Pimples', 'Uneven Skin Texture', 'Acne Scars'],
      },
      {
        'name': 'Manju',
        'location': 'MP',
        'occupation': '(Anganwadi Worker)',
        'testimonial': 'As an Anganwadi worker, I had to work in the sun and do field surveys, which caused spots on my skin. Thanks to Cureskin, my skin is now better!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_5.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_5.png',
        'tags': ['Pigmentation', 'Dull Skin', 'Uneven Skin Texture'],
      },
      {
        'name': 'Shahin',
        'location': 'Uttarakhand',
        'testimonial': "Dealing with both inflammatory acne and melasma was a real challenge and I couldn't go out to see a doctor. Cureskin's treatment fixed my issues from home.",
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_6.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_6.png',
        'tags': ['Inflammatory Acne', 'Oily Skin', 'Pigmentation', 'Open Pores'],
      },
    ],
    mr: [
      {
        'name': 'विभा',
        'location': 'अमृतसर',
        'testimonial': 'Cureskin ची मंथली स्किन चेक-अप खूपच छान आहे. मी डॉक्टरांना माझ्या गर्भधारणेबद्दल सांगितले आणि हे लक्षात घेऊन स्किनकेअर त्यांनी प्रॉडक्ट ऍडजेस्ट केली गेली.',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_1.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_1.png',
        'tags': ['इंफ्लेमेटरी एक्ने', 'मुरुमांच्या खुणा', 'ऑयली स्किन', 'असमान स्किन टेक्सचर'],
      },
      {
        'name': 'प्रत्युषा',
        'location': 'ओरिसा',
        'occupation': '(विद्यार्थी)',
        'testimonial': 'माझ्या स्किनवरील कॉमेडोनमुळे आत्मविश्वास कमी झाला त्यामुळे मला लोकांना भेटताना लाज वाटायची. Cureskin ट्रीटमेंट किटने माझ्या समस्येत खूप सुधारणा केली आहे!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_2.png',
        'tags': ['कॉमेडोन', 'डार्क स्पॉट्स', 'इंफ्लेमेटरी एक्ने', 'मुरुमांच्या खुणा'],
      },
      {
        'name': 'नीलम',
        'location': 'पंजाब',
        'occupation': '(योग प्रशिक्षक)',
        'testimonial': 'Cureskin माझी मेलास्मा सुधारण्यास मदत केलीच तसेच शाश्वत स्किन केअर पद्धतींबद्दल मला शिक्षित केले माझ्या चेहऱ्याचे आरोग्य देखील सुधारले. हे  प्रॉडक्ट छान आहेत!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_3.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_3.png',
        'tags': ['ಮೆಲಸ್ಮಾ', 'असमान स्किन टोन', 'सुरकुत्या'],
      },
      {
        'name': 'वैष्णवी',
        'location': 'उत्तर प्रदेश',
        'occupation': '(मॉडेल)',
        'testimonial': 'मॉडेल असणं म्हणजे पुष्कळ मेकअपमुळे मुरुमे होतात त्याला "ऍक्ने कॉस्मेटिका" म्हणतात. माझे मुरुम घालवण्यासाठी  Cureskin डॉक्टरांनी रुटीन सेट केले हे मला आवडले! ',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_4.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_4.png',
        'tags': ['डार्क स्पॉट्स', 'मुरुम', 'असमान स्किन टेक्सचर', 'मुरुमांच्या खुणा'],
      },
      {
        'name': 'मंजू',
        'location': 'खासदार',
        'occupation': '(अंगणवाडी सेविका)',
        'testimonial': 'अंगणवाडी सेविका म्हणून मी उन्हात शेताचे सर्वेक्षणावर काम करते , त्यामुळे माझ्या स्किनवर डाग पडले. Cureskin चे आभार, माझी स्किन आता चांगली आहे!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_5.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_5.png',
        'tags': ['पिगमेंटेशन', 'डल स्किन', 'असमान स्किन टेक्सचर'],
      },
      {
        'name': 'शाहीन',
        'location': 'उत्तराखंड',
        'testimonial': 'इंफ्लेमेटरी एक्ने आणि मेलास्माचा सामना करणे हे एक मोठे आव्हान होते आणि मी डॉक्टरकडे जाऊ शकले नाही. Cureskin च्या ट्रीटमेंटने या समस्या घरी सोडवल्या.',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_6.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_6.png',
        'tags': ['इंफ्लेमेटरी एक्ने', 'ऑयली स्किन', 'पिगमेंटेशन', 'ओपन पोर्स'],
      },
    ],
    bn: [
      {
        'name': 'Vibha',
        'location': 'Amritsar',
        'testimonial': "Cureskin's monthly skin check-ups are fantastic. When I mentioned my pregnancy, the doctor promptly adjusted my skincare products to ensure my well-being",
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_1.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_1.png',
        'tags': ['Inflammatory Acne', 'Acne Marks', 'Oily Skin', 'Uneven Skin Texture'],
      },
      {
        'name': 'Pratyusha',
        'location': 'Orissa',
        'occupation': '(Student)',
        'testimonial': 'Comedones on my skin really affected my self-confidence & I was embarrassed to socialise. But Cureskin treatment kit improved my skin concerns drastically!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_2.png',
        'tags': ['Comedones', 'Darkspots', 'Inflammatory Acne', 'Acne Marks',],
      },
      {
        'name': 'Neelam',
        'location': 'Punjab',
        'occupation': '(Yoga Instructor)',
        'testimonial': 'Cureskin not only helped improve my melasma but also enhanced my facial health while educating me about sustainable skin care methods. Their products are great!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_3.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_3.png',
        'tags': ['Melasma', 'Uneven Skin Tone', 'Wrinkles'],
      },
      {
        'name': 'Vaishnavi',
        'location': 'Uttar Pradesh',
        'occupation': '(Model)',
        'testimonial': "Being a model meant tons of makeup causing acne. Cureskin doctor explained it's “acne cosmetica”. I love how she created my routine to fix my acne & dark spots.",
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_4.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_4.png',
        'tags': ['Darkspots', 'Pimples', 'Uneven Skin Texture', 'Acne Scars'],
      },
      {
        'name': 'Manju',
        'location': 'MP',
        'occupation': '(Anganwadi Worker)',
        'testimonial': 'As an Anganwadi worker, I had to work in the sun and do field surveys, which caused spots on my skin. Thanks to Cureskin, my skin is now better!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_5.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_5.png',
        'tags': ['Pigmentation', 'Dull Skin', 'Uneven Skin Texture'],
      },
      {
        'name': 'Shahin',
        'location': 'Uttarakhand',
        'testimonial': "Dealing with both inflammatory acne and melasma was a real challenge and I couldn't go out to see a doctor. Cureskin's treatment fixed my issues from home.",
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_6.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_6.png',
        'tags': ['Inflammatory Acne', 'Oily Skin', 'Pigmentation', 'Open Pores'],
      },
    ],
  }
};

export const RebrandedHairTestimonialObject: RebrandedTestimonialStories = {
  Male: {
    en: [
      {
        'name': 'Rohit',
        'location': 'Chhattisgarh',
        'occupation': '(Farmer)',
        'testimonial': "Due to advanced sun exposure, I started having hair fall. That's when I approached Cureskin & was put on a treatment plan. Their products are great!",
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H1.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H1.png',
        'tags': ['Scalp Coverage', 'Hair Fall', 'Hair Thickness'],
      },
      {
        'name': 'Lakshman',
        'location': 'Maharashtra',
        'testimonial': 'Losing hair at a young age was worrying. My scalp looked bald, and my hair density was poor. Now, I have got thicker hair all because of Cureskin!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H2.png',
        'tags': ['Hair Fall', 'Hair Density', 'Hair Texture'],
      },
      {
        'name': 'Madhumita',
        'location': 'Uttar Pradesh',
        'testimonial': 'I was sick of persistent hair fall and a friend suggested Cureskin. They recommended a treatment plan, and my hair fall has reduced. Their products are great!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H3.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H3.png',
        'tags': ['Hair Fall', 'Hair Volume', 'Hair Strength', 'Hair Texture'],
      },
      {
        'name': 'Rahil',
        'location': 'Uttar Pradesh',
        'testimonial': "I was having an itchy scalp that resulted in hair loss immensely. But Cureskin's treatment kit worked really well to improve my hair fall. Thanks to my doctor!",
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H4.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H4.png',
        'tags': ['Scalp Coverage', 'Hair Loss', 'Frizzy Hair', 'Dandruff'],
      },
      {
        'name': 'Bom Saroh',
        'location': 'Arunachal Pradesh',
        'testimonial': "Cureskin's dermatologist and treatment plan actually worked to improve my hair thinning! I'm experiencing less hair fall, and my scalp is less visible now.",
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H5.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H5.png',
        'tags': ['Hairfall', 'Hair Density', 'Scalp Coverage'],
      },
    ],
    hi: [
      {
        'name': 'रोहित',
        'location': 'छत्तीसगढ़',
        'occupation': '(किसान)',
        'testimonial': 'बहुत ज़्यादा धूप में रहने के कारण मेरे बाल झड़ने लगे थे। तभी मैंने Cureskin से संपर्क किया और उन्होंने मुझे ट्रीटमेंट प्लान दिया गया। उनके प्रोडक्ट्स शानदार हैं!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H1.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H1.png',
        'tags': ['स्कैल्प कवरेज', 'बाल गिरना', 'बालों की थिकनेस'],
      },
      {
        'name': 'लक्ष्मण',
        'location': 'महाराष्ट्र',
        'testimonial': 'मैं कम उम्र में बाल झड़ने से परेशान था। गंजापन दिखाई देता था और मेरे बाल घने भी नहीं था। अब मेरे बाल घने हो गए हैं और यह सब Cureskin की वजह से हुआ है!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H2.png',
        'tags': ['बाल गिरना', 'बालों की डेंसिटी', 'बालों का टेक्सचर'],
      },
      {
        'name': 'मधुमिता',
        'location': 'उत्तर प्रदेश',
        'testimonial': 'मैं झड़ते बालों से परेशान थी और किसी दोस्त ने Cureskin के बारे में बताया। उन्होंने ट्रीटमेंट प्लान दिया और मेरे बाल झड़ना कम हो गए। उनके प्रोडक्ट्स शानदार हैं!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H3.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H3.png',
        'tags': ['बाल गिरना', 'बालों का वॉल्यूम', 'बालों की मजबूती', 'बालों का टेक्सचर'],
      },
      {
        'name': 'राहिल',
        'location': 'उत्तर प्रदेश',
        'testimonial': 'मेरे सिर में खुजली होती थी जिसके कारण मेरे बाल बहुत झड़ रहे थे। Cureskin ट्रीटमेंट किट ने इस समस्या को ठीक करने में मदद की। इसके लिए मेरे डॉक्टर को धन्यवाद!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H4.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H4.png',
        'tags': ['स्कैल्प कवरेज', 'बालों का झड़ना', 'उलझे हुए बाल', 'डैंड्रफ'],
      },
      {
        'name': 'बोम सारोह',
        'location': 'अरुणाचल प्रदेश',
        'testimonial': 'Cureskin के डर्मेटोलॉजिस्ट और ट्रीटमेंट प्लान ने मेरे बालों के पतले होने की समस्या को ठीक करने में मदद की! मेरे बाल अब कम झड़ते हैं और गंजापन कम दिखाई देता है।',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H5.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H5.png',
        'tags': ['बाल गिरना', 'बालों की डेंसिटी', 'स्कैल्प कवरेज'],
      },
    ],
    te: [
      {
        'name': 'రోహిత్',
        'location': 'ఛత్తీస్‌గఢ్',
        'occupation': '(రైతు)',
        'testimonial': 'నేను జుట్టు రాలడంతో ఇబ్బంది పడ్డాను.నా ఫ్రెండ్ Cureskinని సూచించారు. వారి ట్రీట్మెంట్ ప్లాన్ ని ఇచ్చారు ఇంకా నా జుట్టు రాలడం తగ్గింది. వారి ప్రోడక్ట్స్ మంచివి!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H1.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H1.png',
        'tags': ['స్కాల్ప్ కవరేజ్', 'జుట్టు రాలడం', 'జుట్టు మందం'],
      },
      {
        'name': 'లక్ష్మణ్',
        'location': 'మహారాష్ట్ర',
        'testimonial': 'చిన్న వయసులోనే జుట్టు రాలడం వల్ల ఇబ్బంది కలిగింది. నా నెత్తిపై బట్టతల కనిపించింది, నా జుట్టు మందం తగ్గింది. ఇప్పుడు, నేను cureskin వల్ల ఒత్తైన జుట్టును పొందాను!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H2.png',
        'tags': ['జుట్టు రాలడం', 'జుట్టు సాంద్రత', 'జుట్టు ఆకృతి'],
      },
      {
        'name': 'మధుమిత',
        'location': 'ఉత్తరప్రదేశ్',
        'testimonial': 'నేను నిరంతరం జుట్టు రాలడం వల్ల అనారోగ్యంతో ఉన్నాను మరియు ఒక స్నేహితుడు కురెస్‌కిన్‌ని సూచించాడు. వారు చికిత్స ప్రణాళికను సిఫార్సు చేసారు మరియు నా జుట్టు రాలడం తగ్గింది. వారి ఉత్పత్తులు గొప్పవి!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H3.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H3.png',
        'tags': ['జుట్టు రాలడం', 'జుట్టు పరిమాణం', 'జుట్టు బలం', 'జుట్టు ఆకృతి'],
      },
      {
        'name': 'రాహిల్',
        'location': 'ఉత్తరప్రదేశ్',
        'testimonial': 'నా తల దురదగా ఉండేది, దాని కారణంగా నా జుట్టు చాలా రాలుతోంది. Cureskin ట్రీట్‌మెంట్ కిట్ ఈ సమస్యను పరిష్కరించడంలో సహాయపడింది. దీనికి నా వైద్యుడికి ధన్యవాదాలు!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H4.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H4.png',
        'tags': ['స్కాల్ప్ కవరేజ్', 'జుట్టు ఊడటం', 'పొడిబారిన జుట్టు', 'చుండ్రు'],
      },
      {
        'name': 'బోమ్ సరోహ్',
        'location': 'అరుణాచల్ ప్రదేశ్',
        'testimonial': 'Cureskinడెర్మటాలజిస్ట్ ట్రీట్మెంట్ ప్లాన్ నా సన్నబడిన జుట్టుని రివర్స్ చేయడంలో సహాయపడింది!నా జుట్టు ఇప్పుడు తక్కువగా రాలుతుంది ఇంక బట్టతల తక్కువగా కనిపిస్తుంది.',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H5.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H5.png',
        'tags': ['జుట్టు రాలడం', 'జుట్టు సాంద్రత', 'స్కాల్ప్ కవరేజ్'],
      },
    ],
    kn: [
      {
        'name': 'ರೋಹಿತ್',
        'location': 'ಛತ್ತೀಸ್‌ಗಢ',
        'occupation': '(ರೈತ)',
        'testimonial': 'ಬಹಳ ಬಿಸಿಲಿಗೆ ಹೋಗಿದ್ದರಿಂದ ನನಗೆ ಕೂದಲು ಉದುರಲು ಪ್ರಾರಂಭಿಸಿದೆ. ಆಗ ನಾನು Cureskin ಸಂಪರ್ಕಿಸಿದೆ  ಟ್ರೀಟ್ಮೆಂಟ್ ಪ್ಲಾನ್  ನೀಡಿದರು. ಅವರ ಪ್ರಾಡಕ್ಟ್ಸ್ ಉತ್ತಮವಾಗಿವೆ!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H1.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H1.png',
        'tags': ['ಸ್ಕಾಲ್ಪ್ ಕವರೇಜ್', 'ಕೂದಲು ಉದುರುವಿಕೆ', 'ಕೂದಲಿನ ದಪ್ಪ'],
      },
      {
        'name': 'ಲಕ್ಷ್ಮಣ್',
        'location': 'ಮಹಾರಾಷ್ಟ್ರ',
        'testimonial': 'ಚಿಕ್ಕ ವಯಸ್ಸಿನಲ್ಲೇ ಕೂದಲು ಉದುರುವುದು  ಚಿಂತೆಯಾಗಿತ್ತು. ನೆತ್ತಿ ಬೋಳಾಗಿ ಕಾಣುತ್ತದೆ, ಮತ್ತು ನನ್ನ ಕೂದಲಿನ ದಟ್ಟತೆ  ಕಳಪೆಯಾಗಿತ್ತು. ಈಗ, Cureskinನಿಂದಾಗಿ ದಪ್ಪ ಕೂದಲು ಪಡೆದಿದ್ದೇನೆ!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H2.png',
        'tags': ['ಕೂದಲು ಉದುರುವಿಕೆ', 'ಕೂದಲಿನ ಸಾಂದ್ರತೆ', 'ಕೂದಲಿನ ಟೆಕ್ಸ್ಚರ್'],
      },
      {
        'name': 'ಮಧುಮಿತಾ',
        'location': 'ಉತ್ತರ ಪ್ರದೇಶ',
        'testimonial': 'ನಾನು ಕೂದಲುಉದುರುತಿತ್ತು ಸ್ನೇಹಿತರೊಬ್ಬರು Cureskin  ಸೂಚಿಸಿದರು. ಅವರು ಟ್ರೀಟ್ಮೆಂಟ್ ಪ್ಲಾನ್ ಶಿಫಾರಸು ಮಾಡಿದರು   ಕೂದಲು ಉದುರುರುವುದು ಕಡಿಮೆಯಾಗಿದೆ. ಪ್ರಾಡಕ್ಟ್ಸ್ ಉತ್ತಮವಾಗಿವೆ!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H3.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H3.png',
        'tags': ['ಕೂದಲು ಉದುರುವಿಕೆ', 'ಕೂದಲಿನ ಪರಿಮಾಣ', 'ಕೂದಲಿನ ಶಕ್ತಿ', 'ಕೂದಲಿನ ಟೆಕ್ಸ್ಚರ್'],
      },
      {
        'name': 'ರಾಹಿಲ್',
        'location': 'ಉತ್ತರ ಪ್ರದೇಶ',
        'testimonial': 'ನನಗೆ ಇಚಿ ಸ್ಕಾಲ್ಪ್ ಇತ್ತು, ಇದರಿಂದ ಕೂದಲು ಉದುರುತ್ತಿತ್ತು . ನನ್ನ ಕೂದಲು ಉದುರುವುದನ್ನು  ಸುಧಾರಿಸಲು Cureskin ನ ಟ್ರೀಟ್ಮೆಂಟ್ ಕಿಟ್ ಚೆನ್ನಾಗಿ ಕೆಲಸ ಮಾಡಿದೆ. ಡಾಕ್ಟರ್ ಗೆ ಧನ್ಯವಾದ!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H4.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H4.png',
        'tags': ['ಸ್ಕಾಲ್ಪ್ ಕವರೇಜ್', 'ಕೂದಲು ನಷ್ಟ', 'ಸುಕ್ಕುಗಟ್ಟಿದ ಕೂದಲು', 'ಡ್ಯಾಂಡ್ರಫ್'],
      },
      {
        'name': 'ಬೊಮ್ ಸರೋಹ್',
        'location': 'ಅರುಣಾಚಲ ಪ್ರದೇಶ',
        'testimonial': 'ನನ್ನ ಕೂದಲು ತೆಳುವಾಗುವುದನ್ನು ಸುಧಾರಿಸಲು Cureskinನ ಡರ್ಮಟಾಲಜಿಸ್ಟ್ ನ ಟ್ರೀಟ್ಮೆಂಟ್ ಪ್ಲಾನ್ ಕೆಲಸ ಮಾಡಿದೆ! ಕಡಿಮೆ ಕೂದಲು ಉದುರುರುವುದನ್ನು ಗಮನಿಸಿದ್ದೇನೆ ನೆತ್ತಿಯು ಕಡಿಮೆ ಕಾಣುತ್ತದೆ .',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H5.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H5.png',
        'tags': ['ಕೂದಲು ಉದುರುವಿಕೆ', 'ಕೂದಲಿನ ಸಾಂದ್ರತೆ', 'ಸ್ಕಾಲ್ಪ್ ಕವರೇಜ್'],
      },
    ],
    ta: [
      {
        'name': 'ரோஹித்',
        'location': 'சத்தீஸ்கர்',
        'occupation': '(விவசாயி)',
        'testimonial': 'மேம்பட்ட சூரிய ஒளியினால், எனக்கு முடி கொட்ட ஆரம்பித்தது. அப்போது Cureskinஐ  அணுகி சிகிச்சை திட்டத்தில் சேர்த்தேன்.அவர்களின் தயாரிப்புகள் சிறந்தவை!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H1.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H1.png',
        'tags': ['ஸ்கால்ப் கவரேஜ்', 'முடி உதிர்தல்', 'முடி தடிமன்'],
      },
      {
        'name': 'லக்ஷ்மன்',
        'location': 'மகாராஷ்டிரா',
        'testimonial': 'இளவயது முடி உதிர்வு கவலையளித்தது. என் உச்சந்தலை வழுக்கையாகத் தெரிந்தது,முடி அடர்த்தியும் இல்லை.இப்போது, Cureskin-ஆல் அடர்த்தியான முடி கிடைத்துள்ளது!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H2.png',
        'tags': ['முடி உதிர்தல்', 'முடி அடர்த்தி', 'முடி அமைப்பு'],
      },
      {
        'name': 'மதுமிதா',
        'location': 'உத்தரபிரதேசம்',
        'testimonial': 'தொடர்ந்த முடி உதிர்வால் வெறுப்பாக இருந்தேன்,ஒரு நண்பர் Cureskinஐ பரிந்துரைத்தார்.அவர்கள் சிகிச்சையால்  முடி உதிர்வு குறைந்தது. அவர்களின் தயாரிப்புகள் சிறந்தவை!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H3.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H3.png',
        'tags': ['முடி உதிர்தல்', 'முடி அளவு', 'முடி வலிமை', 'முடி அமைப்பு'],
      },
      {
        'name': 'ரஹில்',
        'location': 'உத்தரப் பிரதேசம்',
        'testimonial': 'என் உச்சந்தலை அரிப்பால் பெருமளவில் முடிஉதிர்ந்தது. ஆனால் Cureskin சிகிச்சை கிட் என் முடி உதிர்வை மேம்படுத்த நன்றாக வேலை செய்தது. என் மருத்துவருக்கு நன்றி!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H4.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H4.png',
        'tags': ['ஸ்கால்ப் கவரேஜ்', 'முடி கொட்டுதல்', 'வறண்ட முடி', 'பொடுகு'],
      },
      {
        'name': 'போம் சரோ',
        'location': 'அருணாச்சல பிரதேசம்',
        'testimonial': 'Cureskin மருத்துவரும் சிகிச்சையும் என் முடி மெலிவதை மேம்படுத்தியது! முடி உதிர்வு குறைவாக உள்ளது, மேலும் என் உச்சந்தலை இப்போது குறைவாகவே தெரிகிறது.',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H5.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H5.png',
        'tags': ['முடி உதிர்தல்', 'முடி அடர்த்தி', 'ஸ்கால்ப் கவரேஜ்'],
      },
    ],
    ml: [
      {
        'name': 'Rohit',
        'location': 'Chhattisgarh',
        'occupation': '(Farmer)',
        'testimonial': "Due to advanced sun exposure, I started having hair fall. That's when I approached Cureskin & was put on a treatment plan. Their products are great!",
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H1.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H1.png',
        'tags': ['Scalp Coverage', 'Hair Fall', 'Hair Thickness'],
      },
      {
        'name': 'Lakshman',
        'location': 'Maharashtra',
        'testimonial': 'Losing hair at a young age was worrying. My scalp looked bald, and my hair density was poor. Now, I have got thicker hair all because of Cureskin!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H2.png',
        'tags': ['Hair Fall', 'Hair Density', 'Hair Texture'],
      },
      {
        'name': 'Madhumita',
        'location': 'Uttar Pradesh',
        'testimonial': 'I was sick of persistent hair fall and a friend suggested Cureskin. They recommended a treatment plan, and my hair fall has reduced. Their products are great!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H3.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H3.png',
        'tags': ['Hair Fall', 'Hair Volume', 'Hair Strength', 'Hair Texture'],
      },
      {
        'name': 'Rahil',
        'location': 'Uttar Pradesh',
        'testimonial': "I was having an itchy scalp that resulted in hair loss immensely. But Cureskin's treatment kit worked really well to improve my hair fall. Thanks to my doctor!",
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H4.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H4.png',
        'tags': ['Scalp Coverage', 'Hair Loss', 'Frizzy Hair', 'Dandruff'],
      },
      {
        'name': 'Bom Saroh',
        'location': 'Arunachal Pradesh',
        'testimonial': "Cureskin's dermatologist and treatment plan actually worked to improve my hair thinning! I’m experiencing less hair fall, and my scalp is less visible now.",
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H5.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H5.png',
        'tags': ['Hairfall', 'Hair Density', 'Scalp Coverage'],
      },
    ],
    mr: [
      {
        'name': 'रोहित',
        'location': 'छत्तीसगड',
        'occupation': '(शेतकरी)',
        'testimonial': 'प्रखर सूर्यप्रकाशामुळे माझे केस गळायला लागले. तेव्हाच मी Cureskin शी संपर्क साधला आणि ट्रीटमेंट प्लॅनवर ठेवले. त्यांची उत्पादने छान आहेत!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H1.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H1.png',
        'tags': ['स्कॅल्प कव्हरेज', 'केस गळणे', 'केसांची जाडी'],
      },
      {
        'name': 'लक्ष्मण',
        'location': 'महाराष्ट्र',
        'testimonial': 'लहान वयात केस गळणे चिंताजनक होते. माझी स्कॅल्पवर टक्कल दिसत होती आणि माझ्या केसांची घनता कमी होती. आता, Cureskin मुळे माझे केस दाट झाले आहेत!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H2.png',
        'tags': ['केस गळणे', 'केसांची घनता', 'केसांचा पोत'],
      },
      {
        'name': 'मधुमिता',
        'location': 'उत्तर प्रदेश',
        'testimonial': 'मी सतत केसगळतीमुळे आजारी होतो आणि एका मित्राने Cureskin सुचवले. त्यांनी ट्रीटमेंट प्लॅनची शिफारस केली आणि माझे केस गळणे कमी झाले. त्यांची उत्पादने छान आहेत!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H3.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H3.png',
        'tags': ['केस गळणे', 'केसांचे व्हॉल्युम', 'केसांची मजबूती', 'केसांचा पोत'],
      },
      {
        'name': 'राहिल',
        'location': 'उत्तर प्रदेश',
        'testimonial': "मला स्कॅल्पमुळे खाज सुटली व केस गळत होते परंतु माझे केस गळणे सुधारण्यासाठी Cureskin' च्या ट्रीटमेंट किटने खरोखर चांगले काम केले. माझ्या डॉक्टरांना धन्यवाद!",
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H4.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H4.png',
        'tags': ['स्कॅल्प कव्हरेज', 'केस गळणे', 'विचकटलेले केस', 'डँड्रफ '],
      },
      {
        'name': 'बोम सारोह',
        'location': 'अरुणाचल प्रदेश',
        'testimonial': 'Cureskin च्या डर्मोटॉलॉजिस्ट ट्रीटमेंट प्लॅनने माझे केस गळणे सुधारण्यासाठी प्रत्यक्षात काम केले! माझे केस गळणे कमी झाले आणि माझी स्कॅल्प आता कमी दिसत आहे.',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H5.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H5.png',
        'tags': ['केस गळणे', 'केसांची घनता', 'स्कॅल्प कव्हरेज'],
      },
    ],
    bn: [
      {
        'name': 'Rohit',
        'location': 'Chhattisgarh',
        'occupation': '(Farmer)',
        'testimonial': "Due to advanced sun exposure, I started having hair fall. That's when I approached Cureskin & was put on a treatment plan. Their products are great!",
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H1.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H1.png',
        'tags': ['Scalp Coverage', 'Hair Fall', 'Hair Thickness'],
      },
      {
        'name': 'Lakshman',
        'location': 'Maharashtra',
        'testimonial': 'Losing hair at a young age was worrying. My scalp looked bald, and my hair density was poor. Now, I have got thicker hair all because of Cureskin!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H2.png',
        'tags': ['Hair Fall', 'Hair Density', 'Hair Texture'],
      },
      {
        'name': 'Madhumita',
        'location': 'Uttar Pradesh',
        'testimonial': 'I was sick of persistent hair fall and a friend suggested Cureskin. They recommended a treatment plan, and my hair fall has reduced. Their products are great!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H3.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H3.png',
        'tags': ['Hair Fall', 'Hair Volume', 'Hair Strength', 'Hair Texture'],
      },
      {
        'name': 'Rahil',
        'location': 'Uttar Pradesh',
        'testimonial': "I was having an itchy scalp that resulted in hair loss immensely. But Cureskin's treatment kit worked really well to improve my hair fall. Thanks to my doctor!",
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H4.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H4.png',
        'tags': ['Scalp Coverage', 'Hair Loss', 'Frizzy Hair', 'Dandruff'],
      },
      {
        'name': 'Bom Saroh',
        'location': 'Arunachal Pradesh',
        'testimonial': "Cureskin's dermatologist and treatment plan actually worked to improve my hair thinning! I’m experiencing less hair fall, and my scalp is less visible now.",
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H5.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H5.png',
        'tags': ['Hairfall', 'Hair Density', 'Scalp Coverage'],
      },
    ],
  },
  Female: {
    en: [
      {
        'name': 'Madhumita',
        'location': 'Uttar Pradesh',
        'testimonial': 'I was sick of persistent hair fall and a friend suggested Cureskin. They recommended a treatment plan, and my hair fall has reduced. Their products are great!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H3.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H3.png',
        'tags': ['Hair Fall', 'Hair Volume', 'Hair Strength', 'Hair Texture'],
      },
      {
        'name': 'Rohit',
        'location': 'Chhattisgarh',
        'occupation': '(Farmer)',
        'testimonial': "Due to advanced sun exposure, I started having hair fall. That's when I approached Cureskin & was put on a treatment plan. Their products are great!",
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H1.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H1.png',
        'tags': ['Scalp Coverage', 'Hair Fall', 'Hair Thickness'],
      },
      {
        'name': 'Lakshman',
        'location': 'Maharashtra',
        'testimonial': 'Losing hair at a young age was worrying. My scalp looked bald, and my hair density was poor. Now, I have got thicker hair all because of Cureskin!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H2.png',
        'tags': ['Hair Fall', 'Hair Density', 'Hair Texture'],
      },
      {
        'name': 'Rahil',
        'location': 'Uttar Pradesh',
        'testimonial': "I was having an itchy scalp that resulted in hair loss immensely. But Cureskin's treatment kit worked really well to improve my hair fall. Thanks to my doctor!",
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H4.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H4.png',
        'tags': ['Scalp Coverage', 'Hair Loss', 'Frizzy Hair', 'Dandruff'],
      },
      {
        'name': 'Bom Saroh',
        'location': 'Arunachal Pradesh',
        'testimonial': "Cureskin's dermatologist and treatment plan actually worked to improve my hair thinning! I’m experiencing less hair fall, and my scalp is less visible now.",
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H5.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H5.png',
        'tags': ['Hairfall', 'Hair Density', 'Scalp Coverage'],
      },
    ],
    hi: [
      {
        'name': 'मधुमिता',
        'location': 'उत्तर प्रदेश',
        'testimonial': 'मैं झड़ते बालों से परेशान थी और किसी दोस्त ने Cureskin के बारे में बताया। उन्होंने ट्रीटमेंट प्लान दिया और मेरे बाल झड़ना कम हो गए। उनके प्रोडक्ट्स शानदार हैं!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H3.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H3.png',
        'tags': ['बाल गिरना', 'बालों का वॉल्यूम', 'बालों की मजबूती', 'बालों का टेक्सचर'],
      },
      {
        'name': 'रोहित',
        'location': 'छत्तीसगढ़',
        'occupation': '(किसान)',
        'testimonial': 'बहुत ज़्यादा धूप में रहने के कारण मेरे बाल झड़ने लगे थे। तभी मैंने Cureskin से संपर्क किया और उन्होंने मुझे ट्रीटमेंट प्लान दिया गया। उनके प्रोडक्ट्स शानदार हैं!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H1.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H1.png',
        'tags': ['स्कैल्प कवरेज', 'बाल गिरना', 'बालों की थिकनेस'],
      },
      {
        'name': 'लक्ष्मण',
        'location': 'महाराष्ट्र',
        'testimonial': 'मैं कम उम्र में बाल झड़ने से परेशान था। गंजापन दिखाई देता था और मेरे बाल घने भी नहीं था। अब मेरे बाल घने हो गए हैं और यह सब Cureskin की वजह से हुआ है!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H2.png',
        'tags': ['बाल गिरना', 'बालों की डेंसिटी', 'बालों का टेक्सचर'],
      },
      {
        'name': 'राहिल',
        'location': 'उत्तर प्रदेश',
        'testimonial': 'मेरे सिर में खुजली होती थी जिसके कारण मेरे बाल बहुत झड़ रहे थे। Cureskin ट्रीटमेंट किट ने इस समस्या को ठीक करने में मदद की। इसके लिए मेरे डॉक्टर को धन्यवाद!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H4.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H4.png',
        'tags': ['स्कैल्प कवरेज', 'बालों का झड़ना', 'उलझे हुए बाल', 'डैंड्रफ'],
      },
      {
        'name': 'बोम सारोह',
        'location': 'अरुणाचल प्रदेश',
        'testimonial': 'Cureskin के डर्मेटोलॉजिस्ट और ट्रीटमेंट प्लान ने मेरे बालों के पतले होने की समस्या को ठीक करने में मदद की! मेरे बाल अब कम झड़ते हैं और गंजापन कम दिखाई देता है।',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H5.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H5.png',
        'tags': ['बाल गिरना', 'बालों की डेंसिटी', 'स्कैल्प कवरेज'],
      },
    ],
    te: [
      {
        'name': 'మధుమిత',
        'location': 'ఉత్తరప్రదేశ్',
        'testimonial': 'నేను నిరంతరం జుట్టు రాలడం వల్ల అనారోగ్యంతో ఉన్నాను మరియు ఒక స్నేహితుడు కురెస్‌కిన్‌ని సూచించాడు. వారు చికిత్స ప్రణాళికను సిఫార్సు చేసారు మరియు నా జుట్టు రాలడం తగ్గింది. వారి ఉత్పత్తులు గొప్పవి!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H3.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H3.png',
        'tags': ['జుట్టు రాలడం', 'జుట్టు పరిమాణం', 'జుట్టు బలం', 'జుట్టు ఆకృతి'],
      },
      {
        'name': 'రోహిత్',
        'location': 'ఛత్తీస్‌గఢ్',
        'occupation': '(రైతు)',
        'testimonial': 'నేను జుట్టు రాలడంతో ఇబ్బంది పడ్డాను.నా ఫ్రెండ్ Cureskinని సూచించారు. వారి ట్రీట్మెంట్ ప్లాన్ ని ఇచ్చారు ఇంకా నా జుట్టు రాలడం తగ్గింది. వారి ప్రోడక్ట్స్ మంచివి!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H1.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H1.png',
        'tags': ['స్కాల్ప్ కవరేజ్', 'జుట్టు రాలడం', 'జుట్టు మందం'],
      },
      {
        'name': 'లక్ష్మణ్',
        'location': 'మహారాష్ట్ర',
        'testimonial': 'చిన్న వయసులోనే జుట్టు రాలడం వల్ల ఇబ్బంది కలిగింది. నా నెత్తిపై బట్టతల కనిపించింది, నా జుట్టు మందం తగ్గింది. ఇప్పుడు, నేను cureskin వల్ల ఒత్తైన జుట్టును పొందాను!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H2.png',
        'tags': ['జుట్టు రాలడం', 'జుట్టు సాంద్రత', 'జుట్టు ఆకృతి'],
      },
      {
        'name': 'రాహిల్',
        'location': 'ఉత్తరప్రదేశ్',
        'testimonial': 'నా తల దురదగా ఉండేది, దాని కారణంగా నా జుట్టు చాలా రాలుతోంది. Cureskin ట్రీట్‌మెంట్ కిట్ ఈ సమస్యను పరిష్కరించడంలో సహాయపడింది. దీనికి నా వైద్యుడికి ధన్యవాదాలు!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H4.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H4.png',
        'tags': ['స్కాల్ప్ కవరేజ్', 'జుట్టు ఊడటం', 'పొడిబారిన జుట్టు', 'చుండ్రు'],
      },
      {
        'name': 'బోమ్ సరోహ్',
        'location': 'అరుణాచల్ ప్రదేశ్',
        'testimonial': 'Cureskinడెర్మటాలజిస్ట్ ట్రీట్మెంట్ ప్లాన్ నా సన్నబడిన జుట్టుని రివర్స్ చేయడంలో సహాయపడింది!నా జుట్టు ఇప్పుడు తక్కువగా రాలుతుంది ఇంక బట్టతల తక్కువగా కనిపిస్తుంది.',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H5.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H5.png',
        'tags': ['జుట్టు రాలడం', 'జుట్టు సాంద్రత', 'స్కాల్ప్ కవరేజ్'],
      },
    ],
    kn: [
      {
        'name': 'ಮಧುಮಿತಾ',
        'location': 'ಉತ್ತರ ಪ್ರದೇಶ',
        'testimonial': 'ನಾನು ಕೂದಲುಉದುರುತಿತ್ತು ಸ್ನೇಹಿತರೊಬ್ಬರು Cureskin  ಸೂಚಿಸಿದರು. ಅವರು ಟ್ರೀಟ್ಮೆಂಟ್ ಪ್ಲಾನ್ ಶಿಫಾರಸು ಮಾಡಿದರು   ಕೂದಲು ಉದುರುರುವುದು ಕಡಿಮೆಯಾಗಿದೆ. ಪ್ರಾಡಕ್ಟ್ಸ್ ಉತ್ತಮವಾಗಿವೆ!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H3.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H3.png',
        'tags': ['ಕೂದಲು ಉದುರುವಿಕೆ', 'ಕೂದಲಿನ ಪರಿಮಾಣ', 'ಕೂದಲಿನ ಶಕ್ತಿ', 'ಕೂದಲಿನ ಟೆಕ್ಸ್ಚರ್'],
      },
      {
        'name': 'ರೋಹಿತ್',
        'location': 'ಛತ್ತೀಸ್‌ಗಢ',
        'occupation': '(ರೈತ)',
        'testimonial': 'ಬಹಳ ಬಿಸಿಲಿಗೆ ಹೋಗಿದ್ದರಿಂದ ನನಗೆ ಕೂದಲು ಉದುರಲು ಪ್ರಾರಂಭಿಸಿದೆ. ಆಗ ನಾನು Cureskin ಸಂಪರ್ಕಿಸಿದೆ  ಟ್ರೀಟ್ಮೆಂಟ್ ಪ್ಲಾನ್  ನೀಡಿದರು. ಅವರ ಪ್ರಾಡಕ್ಟ್ಸ್ ಉತ್ತಮವಾಗಿವೆ!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H1.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H1.png',
        'tags': ['ಸ್ಕಾಲ್ಪ್ ಕವರೇಜ್', 'ಕೂದಲು ಉದುರುವಿಕೆ', 'ಕೂದಲಿನ ದಪ್ಪ'],
      },
      {
        'name': 'ಲಕ್ಷ್ಮಣ್',
        'location': 'ಮಹಾರಾಷ್ಟ್ರ',
        'testimonial': 'ಚಿಕ್ಕ ವಯಸ್ಸಿನಲ್ಲೇ ಕೂದಲು ಉದುರುವುದು  ಚಿಂತೆಯಾಗಿತ್ತು. ನೆತ್ತಿ ಬೋಳಾಗಿ ಕಾಣುತ್ತದೆ, ಮತ್ತು ನನ್ನ ಕೂದಲಿನ ದಟ್ಟತೆ  ಕಳಪೆಯಾಗಿತ್ತು. ಈಗ, Cureskinನಿಂದಾಗಿ ದಪ್ಪ ಕೂದಲು ಪಡೆದಿದ್ದೇನೆ!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H2.png',
        'tags': ['ಕೂದಲು ಉದುರುವಿಕೆ', 'ಕೂದಲಿನ ಸಾಂದ್ರತೆ', 'ಕೂದಲಿನ ಟೆಕ್ಸ್ಚರ್'],
      },
      {
        'name': 'ರಾಹಿಲ್',
        'location': 'ಉತ್ತರ ಪ್ರದೇಶ',
        'testimonial': 'ನನಗೆ ಇಚಿ ಸ್ಕಾಲ್ಪ್ ಇತ್ತು, ಇದರಿಂದ ಕೂದಲು ಉದುರುತ್ತಿತ್ತು . ನನ್ನ ಕೂದಲು ಉದುರುವುದನ್ನು  ಸುಧಾರಿಸಲು Cureskin ನ ಟ್ರೀಟ್ಮೆಂಟ್ ಕಿಟ್ ಚೆನ್ನಾಗಿ ಕೆಲಸ ಮಾಡಿದೆ. ಡಾಕ್ಟರ್ ಗೆ ಧನ್ಯವಾದ!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H4.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H4.png',
        'tags': ['ಸ್ಕಾಲ್ಪ್ ಕವರೇಜ್', 'ಕೂದಲು ನಷ್ಟ', 'ಸುಕ್ಕುಗಟ್ಟಿದ ಕೂದಲು', 'ಡ್ಯಾಂಡ್ರಫ್'],
      },
      {
        'name': 'ಬೊಮ್ ಸರೋಹ್',
        'location': 'ಅರುಣಾಚಲ ಪ್ರದೇಶ',
        'testimonial': 'ನನ್ನ ಕೂದಲು ತೆಳುವಾಗುವುದನ್ನು ಸುಧಾರಿಸಲು Cureskinನ ಡರ್ಮಟಾಲಜಿಸ್ಟ್ ನ ಟ್ರೀಟ್ಮೆಂಟ್ ಪ್ಲಾನ್ ಕೆಲಸ ಮಾಡಿದೆ! ಕಡಿಮೆ ಕೂದಲು ಉದುರುರುವುದನ್ನು ಗಮನಿಸಿದ್ದೇನೆ ನೆತ್ತಿಯು ಕಡಿಮೆ ಕಾಣುತ್ತದೆ .',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H5.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H5.png',
        'tags': ['ಕೂದಲು ಉದುರುವಿಕೆ', 'ಕೂದಲಿನ ಸಾಂದ್ರತೆ', 'ಸ್ಕಾಲ್ಪ್ ಕವರೇಜ್'],
      },
    ],
    ta: [
      {
        'name': 'மதுமிதா',
        'location': 'உத்தரபிரதேசம்',
        'testimonial': 'தொடர்ந்த முடி உதிர்வால் வெறுப்பாக இருந்தேன்,ஒரு நண்பர் Cureskinஐ பரிந்துரைத்தார்.அவர்கள் சிகிச்சையால்  முடி உதிர்வு குறைந்தது. அவர்களின் தயாரிப்புகள் சிறந்தவை!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H3.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H3.png',
        'tags': ['முடி உதிர்தல்', 'முடி அளவு', 'முடி வலிமை', 'முடி அமைப்பு'],
      },
      {
        'name': 'ரோஹித்',
        'location': 'சத்தீஸ்கர்',
        'occupation': '(விவசாயி)',
        'testimonial': 'மேம்பட்ட சூரிய ஒளியினால், எனக்கு முடி கொட்ட ஆரம்பித்தது. அப்போது Cureskinஐ  அணுகி சிகிச்சை திட்டத்தில் சேர்த்தேன்.அவர்களின் தயாரிப்புகள் சிறந்தவை!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H1.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H1.png',
        'tags': ['ஸ்கால்ப் கவரேஜ்', 'முடி உதிர்தல்', 'முடி தடிமன்'],
      },
      {
        'name': 'லக்ஷ்மன்',
        'location': 'மகாராஷ்டிரா',
        'testimonial': 'இளவயது முடி உதிர்வு கவலையளித்தது. என் உச்சந்தலை வழுக்கையாகத் தெரிந்தது,முடி அடர்த்தியும் இல்லை.இப்போது, Cureskin-ஆல் அடர்த்தியான முடி கிடைத்துள்ளது!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H2.png',
        'tags': ['முடி உதிர்தல்', 'முடி அடர்த்தி', 'முடி அமைப்பு'],
      },
      {
        'name': 'ரஹில்',
        'location': 'உத்தரப் பிரதேசம்',
        'testimonial': 'என் உச்சந்தலை அரிப்பால் பெருமளவில் முடிஉதிர்ந்தது. ஆனால் Cureskin சிகிச்சை கிட் என் முடி உதிர்வை மேம்படுத்த நன்றாக வேலை செய்தது. என் மருத்துவருக்கு நன்றி!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H4.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H4.png',
        'tags': ['ஸ்கால்ப் கவரேஜ்', 'முடி கொட்டுதல்', 'வறண்ட முடி', 'பொடுகு'],
      },
      {
        'name': 'போம் சரோ',
        'location': 'அருணாச்சல பிரதேசம்',
        'testimonial': 'Cureskin மருத்துவரும் சிகிச்சையும் என் முடி மெலிவதை மேம்படுத்தியது! முடி உதிர்வு குறைவாக உள்ளது, மேலும் என் உச்சந்தலை இப்போது குறைவாகவே தெரிகிறது.',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H5.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H5.png',
        'tags': ['முடி உதிர்தல்', 'முடி அடர்த்தி', 'ஸ்கால்ப் கவரேஜ்'],
      },
    ],
    ml: [
      {
        'name': 'Madhumita',
        'location': 'Uttar Pradesh',
        'testimonial': 'I was sick of persistent hair fall and a friend suggested Cureskin. They recommended a treatment plan, and my hair fall has reduced. Their products are great!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H3.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H3.png',
        'tags': ['Hair Fall', 'Hair Volume', 'Hair Strength', 'Hair Texture'],
      },
      {
        'name': 'Rohit',
        'location': 'Chhattisgarh',
        'occupation': '(Farmer)',
        'testimonial': "Due to advanced sun exposure, I started having hair fall. That's when I approached Cureskin & was put on a treatment plan. Their products are great!",
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H1.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H1.png',
        'tags': ['Scalp Coverage', 'Hair Fall', 'Hair Thickness'],
      },
      {
        'name': 'Lakshman',
        'location': 'Maharashtra',
        'testimonial': 'Losing hair at a young age was worrying. My scalp looked bald, and my hair density was poor. Now, I have got thicker hair all because of Cureskin!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H2.png',
        'tags': ['Hair Fall', 'Hair Density', 'Hair Texture'],
      },
      {
        'name': 'Rahil',
        'location': 'Uttar Pradesh',
        'testimonial': "I was having an itchy scalp that resulted in hair loss immensely. But Cureskin's treatment kit worked really well to improve my hair fall. Thanks to my doctor!",
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H4.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H4.png',
        'tags': ['Scalp Coverage', 'Hair Loss', 'Frizzy Hair', 'Dandruff'],
      },
      {
        'name': 'Bom Saroh',
        'location': 'Arunachal Pradesh',
        'testimonial': "Cureskin's dermatologist and treatment plan actually worked to improve my hair thinning! I’m experiencing less hair fall, and my scalp is less visible now.",
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H5.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H5.png',
        'tags': ['Hairfall', 'Hair Density', 'Scalp Coverage'],
      },
    ],
    mr: [
      {
        'name': 'मधुमिता',
        'location': 'उत्तर प्रदेश',
        'testimonial': 'मी सतत केसगळतीमुळे आजारी होतो आणि एका मित्राने Cureskin सुचवले. त्यांनी ट्रीटमेंट प्लॅनची शिफारस केली आणि माझे केस गळणे कमी झाले. त्यांची उत्पादने छान आहेत!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H3.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H3.png',
        'tags': ['केस गळणे', 'केसांचे व्हॉल्युम', 'केसांची मजबूती', 'केसांचा पोत'],
      },
      {
        'name': 'रोहित',
        'location': 'छत्तीसगड',
        'occupation': '(शेतकरी)',
        'testimonial': 'प्रखर सूर्यप्रकाशामुळे माझे केस गळायला लागले. तेव्हाच मी Cureskin शी संपर्क साधला आणि ट्रीटमेंट प्लॅनवर ठेवले. त्यांची उत्पादने छान आहेत!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H1.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H1.png',
        'tags': ['स्कॅल्प कव्हरेज', 'केस गळणे', 'केसांची जाडी'],
      },
      {
        'name': 'लक्ष्मण',
        'location': 'महाराष्ट्र',
        'testimonial': 'लहान वयात केस गळणे चिंताजनक होते. माझी स्कॅल्पवर टक्कल दिसत होती आणि माझ्या केसांची घनता कमी होती. आता, Cureskin मुळे माझे केस दाट झाले आहेत!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H2.png',
        'tags': ['केस गळणे', 'केसांची घनता', 'केसांचा पोत'],
      },
      {
        'name': 'राहिल',
        'location': 'उत्तर प्रदेश',
        'testimonial': "मला स्कॅल्पमुळे खाज सुटली व केस गळत होते परंतु माझे केस गळणे सुधारण्यासाठी Cureskin's च्या ट्रीटमेंट किटने खरोखर चांगले काम केले. माझ्या डॉक्टरांना धन्यवाद!",
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H4.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H4.png',
        'tags': ['स्कॅल्प कव्हरेज', 'केस गळणे', 'विचकटलेले केस', 'डँड्रफ '],
      },
      {
        'name': 'बोम सारोह',
        'location': 'अरुणाचल प्रदेश',
        'testimonial': 'Cureskin च्या डर्मोटॉलॉजिस्ट ट्रीटमेंट प्लॅनने माझे केस गळणे सुधारण्यासाठी प्रत्यक्षात काम केले! माझे केस गळणे कमी झाले आणि माझी स्कॅल्प आता कमी दिसत आहे.',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H5.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H5.png',
        'tags': ['केस गळणे', 'केसांची घनता', 'स्कॅल्प कव्हरेज'],
      },
    ],
    bn: [
      {
        'name': 'Madhumita',
        'location': 'Uttar Pradesh',
        'testimonial': 'I was sick of persistent hair fall and a friend suggested Cureskin. They recommended a treatment plan, and my hair fall has reduced. Their products are great!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H3.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H3.png',
        'tags': ['Hair Fall', 'Hair Volume', 'Hair Strength', 'Hair Texture'],
      },
      {
        'name': 'Rohit',
        'location': 'Chhattisgarh',
        'occupation': '(Farmer)',
        'testimonial': "Due to advanced sun exposure, I started having hair fall. That's when I approached Cureskin & was put on a treatment plan. Their products are great!",
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H1.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H1.png',
        'tags': ['Scalp Coverage', 'Hair Fall', 'Hair Thickness'],
      },
      {
        'name': 'Lakshman',
        'location': 'Maharashtra',
        'testimonial': 'Losing hair at a young age was worrying. My scalp looked bald, and my hair density was poor. Now, I have got thicker hair all because of Cureskin!',
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H2.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H2.png',
        'tags': ['Hair Fall', 'Hair Density', 'Hair Texture'],
      },
      {
        'name': 'Rahil',
        'location': 'Uttar Pradesh',
        'testimonial': "I was having an itchy scalp that resulted in hair loss immensely. But Cureskin's treatment kit worked really well to improve my hair fall. Thanks to my doctor!",
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H4.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H4.png',
        'tags': ['Scalp Coverage', 'Hair Loss', 'Frizzy Hair', 'Dandruff'],
      },
      {
        'name': 'Bom Saroh',
        'location': 'Arunachal Pradesh',
        'testimonial': "Cureskin's dermatologist and treatment plan actually worked to improve my hair thinning! I’m experiencing less hair fall, and my scalp is less visible now.",
        'testimonialImg': 'https://cdn.cureskin.com/app/img/testimonials/rebranded_testimonialsv_H5.png',
        'userImg': 'https://cdn.cureskin.com/app/img/testimonials/user_thumbnailv_H5.png',
        'tags': ['Hairfall', 'Hair Density', 'Scalp Coverage'],
      },
    ],
  }
};

export const GenderFormErrorMessages: GenderFormErrorMessagesType = {
  numberInName: 'Numbers or special characters are not allowed in Name',
  emptyName: 'Empty names are not allowed',
  maxNameLength: 'Only three words are allowed in Name',
  underAged: 'As you are under-age, presence and consent of your parent is required. Sorry, you will not be able to proceed further.',
  invalidAge: 'Please enter a valid age.',
  enterAge: 'Correct age required',
};

export const MIN_SECONDARY_CONCERNS_LENGTH = 1;

export const MAX_SECONDARY_CONCERNS_LENGTH = 3;

export const SKIP_HAIR_TREE_REGIMEN = 'v5_GEN10';

export enum ConcernType {
  ACNE = 'Acne',
  COMEDONES = 'Comedones',
  OPEN_PORES = 'Open pores',
  DARK_SPOTS = 'Dark spots',
  ACNE_SCARS = 'Acne Scars',
  MELASMA = 'Melasma',
  DARK_CIRCLES = 'Dark circles',
}

export interface FallbackSummaryMap {
  [ConcernType.ACNE]: string;
  [ConcernType.COMEDONES]: string;
  [ConcernType.OPEN_PORES]: string;
  [ConcernType.DARK_SPOTS]: string;
  [ConcernType.ACNE_SCARS]: string;
  [ConcernType.MELASMA]: string;
  [ConcernType.DARK_CIRCLES]: string;
}

export const FallbackSummaries = {
  ACNE: 'You’re dealing with acne, which may be due to excess oil, clogged pores, or hormonal changes. A dermatologist given skincare routine can help manage breakouts, clear your skin, and prevent future acne, helping you achieve a healthier complexion.',
  ACNE_SCARS: 'Your skin has acne scars, likely from past breakouts. These scars can be stubborn, but a personalized regimen from a dermatologist can help improve skin texture and reduce their appearance, giving you smoother, clearer skin.',
  DARK_SPOT: 'You have dark spots, likely due to sun exposure, aging, or past acne. These can worsen with hormonal changes or inflammation. A customized dermatologist-led routine can help fade these spots, giving you a more even and radiant complexion.',
  DULL_SKIN: 'Your skin appears dull, possibly due to a buildup of dead skin cells or dehydration. A personalized, dermatologist given skincare routine focusing on exfoliation and hydration can restore brightness and give your skin a refreshed glow.',
  INFLAMMATORY_ACNE: 'Your skin shows signs of inflammatory acne, which can cause redness and swelling. This may be linked to stress, diet, or hormonal changes. A tailored, dermatologist-led skincare plan can help calm inflammation and clear your skin effectively.',
  MELESMA: 'You’re experiencing melasma, which may be due to sun exposure or hormonal changes. These brown or gray patches can be lightened with a specialized, dermatologist given skincare plan to help even out your skin tone.',
  OPEN_PORES: 'Your skin has open pores, likely from excess oil or aging. These can make your complexion look uneven. A personalized skincare routine, recommended by a dermatologist, can help minimize their appearance, leaving your skin looking smoother.',
  UNDER_EYE_DARK_CIRCLES: 'You have noticeable dark circles under your eyes, which may result from lack of sleep, dehydration, or genetics. A dermatologist given regimen can help reduce their appearance, giving you a more refreshed and youthful look.',
  GENERAL: 'Achieve healthier skin by following a dermatologist-prescribed regimen tailored to your skin’s specific needs. Consistency in using the recommended products and treatments will enhance your skin’s overall health and appearance.',
  COMEDONES: 'Comedones, or clogged pores, form due to excess oil, dead skin cells, and trapped bacteria. Following a dermatologist-prescribed regimen can help prevent and treat comedones by controlling oil production and maintaining clear pores.',
  SENSITIVE_SKIN: 'Skin sensitivity can be caused by factors like genetics, environmental irritants or harsh skincare products. Following a skin care routine prescribed by a dermatologist can help soothe your skin and strengthen the skin barrier.',
};
