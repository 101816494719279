<loading class="tw-absolute tw-top-1/2 tw-left-1/2 tw-translate-x-[-50%] tw-translate-y-[-50%]" *ngIf="loading"></loading>
<div class="tw-fixed tw-left-6 tw-top-6 tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
    <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'arrow_back'"></cs-icon>
</div>
<div class="tw-fixed tw-right-6 tw-top-6 tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10"
     (click)="goToCart()">
    <cs-icon class="tw-h-6 tw-w-6 tw-text-white"
             [iconName]="'shopping_cart'"></cs-icon><span
          class="tw-absolute tw-top-[2px] tw-right-[18%] tw-bg-black tw-font-body tw-font-bold tw-h-[14px] tw-text-200 tw-text-red-400">{{toolbarIcons[0]?.cartValue}}</span>
</div>
<div class="tw-block tw-border-spacing-0 tw-overflow-hidden tw-w-full tw-h-full tw-font-body tw-relative" id="reorderRegimenPage" *ngIf="!loading">
    <div class="tw-flex tw-flex-col tw-h-full">
        <div class="tw-overflow-y-scroll tw-pt-24">
            <div class="tw-p-4 tw-relative tw-flex tw-flex-col tw-items-start tw-justify-end tw-w-[70%]" id="sectionTitle" style="padding-top: unset;" *ngIf="!searchKey?.length">
                <div class="tw-text-[20px] tw-font-bold tw-float-left tw-text-[#242424]" *ngIf="toolbarTitle === 'Recommended Products'" i18n="@@recommProductsForYou">
                    Recommended products for you
                </div>
                <div class="tw-text-[20px] tw-font-bold tw-float-left tw-text-[#242424]" *ngIf="toolbarTitle === 'Reorder Products'" i18n="@@reorderProducts">
                    Reorder products
                </div>
                <div class="tw-text-[20px] tw-font-bold tw-float-left tw-text-[#242424]" *ngIf="toolbarTitle === 'Select Products'" i18n="@@selectProducts">
                    Select products
                </div>
                <div class="tw-text-[20px] tw-font-bold tw-float-left tw-text-[#242424]" *ngIf="toolbarTitle === 'Lips Products'">
                    Products for your lips
                </div>
                <div class="tw-text-[20px] tw-font-bold tw-float-left tw-text-[#242424]" *ngIf="toolbarTitle === 'Body Products'">
                    Products for your body
                </div>
                <div class="tw-text-[20px] tw-font-bold tw-float-left tw-text-[#242424]" *ngIf="toolbarTitle === 'Scalp Products'">
                    Products for your scalp
                </div>
                <div class="tw-text-[20px] tw-font-bold tw-float-left tw-text-[#242424]" *ngIf="toolbarTitle === 'Sale Products'">
                    {{saleVariants?.saleTitle}}
                </div>
                <div class="tw-text-[20px] tw-font-bold tw-float-left tw-text-[#242424]" *ngIf="toolbarTitle === 'Holi Products'">
                    {{holiSaleVariants?.saleTitle}}
                </div>
                <div class="tw-text-[20px] tw-font-bold tw-float-left tw-text-[#242424]" *ngIf="toolbarTitle === 'BOGO Sale Products'">
                    {{personalizedBogoTitle?.saleTitle}}
                </div>
                <div class="tw-text-[20px] tw-font-bold tw-float-left tw-text-[#242424]" *ngIf="toolbarTitle === 'Kits Products'">
                    Super saver kits
                </div>
                <div class="tw-text-[20px] tw-font-bold tw-float-left tw-text-[#242424]" *ngIf="toolbarTitle === 'Suggested by your doctor'" i18n="@@DrSuggested">
                    Suggested by your doctor
                </div>
                <div class="tw-text-[14px] tw-font-medium tw-float-left" i18n="@@byOurDermats" *ngIf="toolbarTitle !== 'Suggested by your doctor'">
                    by our dermatologists
                </div>
            </div>
            <div class="tw-p-4 tw-relative tw-flex tw-items-start tw-justify-end tw-w-[70%]" style="padding-top: unset;" *ngIf="searchKey?.length">
                <div class="tw-text-[20px] tw-font-bold tw-float-left tw-text-[#242424]">"{{searchKey}}"</div>
            </div>
            <div class="tw-w-[85%] tw-pt-[75px] tw-px-5 tw-text-center tw-leading-[1.8rem] tw-font-normal tw-text-[1.1rem] tw-bg-no-repeat tw-bg-center tw-bg-top tw-bg-60 tw-absolute tw-top-2/4 tw-left-2/4 tw-translate-x-[-50%] tw-translate-y-[-50%]" style="background-image: url('/assets/images/not-found.png'); background-size: 60px;" id="noAvailableProducts" *ngIf="!loading && !productsAvailable.length" i18n="@@noProductsAvailable">
                No products available
            </div>
            <div class="tw-grid tw-grid-cols-2 tw-gap-2 tw-px-4" [ngClass]='{ "tw-pb-[100px]": aovTask }'>
                <ng-container *ngFor="let item of productsAvailable; let i = index">
                    <div class="tw-animate-anim_fadeIn" style="box-shadow: unset;" *ngIf='item.price !== 0 && (item.productUnboxedImage?.length || item.rebrandedImageWithBackground?.length)' (click)="viewProduct(item)">
                        <div class="tw-items-center tw-flex tw-flex-col tw-justify-between tw-h-full" [id]="item?.objectId">
                            <img class="tw-mb-[14px] tw-w-40 tw-h-50 tw-rounded-[20px] tw-object-contain" #img="" [src]='item?.rebrandedImageWithBackground && item?.rebrandedImageWithBackground.length ? item.rebrandedImageWithBackground[item.rebrandedImageWithBackground.length - 1] : false || item.productUnboxedImage[item.productUnboxedImage.length - 1] || item.images[item.images.length - 1]' />
                            <div class="tw-m-5 tw-text-center tw-text-[14px] tw-mt-0" style="margin-bottom: unset;">
                                <div class="tw-text-[12px] font12" id="productTitle">{{item.title}}</div>
                            </div>
                            <div class="tw-text-[1.2rem] tw-leading-[1.8rem] tw-tracking-[1px] tw-font-bold tw-text-center tw-mx-5 tw-mt-1 tw-mb-2">
                                <del class="tw-pr-1">₹{{item.mrp}}</del><span class="tw-text-orange-500">₹{{item.price}}</span>
                            </div>
                            <div class="tw-max-w-36 tw-min-w-36 tw-border tw-border-solid tw-border-green-400 tw-flex tw-justify-evenly tw-m-[10px] tw-rounded-[50px] tw-h-[36px]" *ngIf="productsInCart[item.objectId]">
                                <em class="cs-icon-minus green" (click)='addOrRemoveFromCart(i, "REMOVE", $event, img)'></em>
                                <div class="tw-mt-[5px] tw-text-green-400">
                                    {{ productsInCart[item.objectId] || 0 }}
                                </div>
                                <em class="cs-icon-plus tw-text-green-400" (click)='addOrRemoveFromCart(i, "ADD", $event, img)'></em>
                            </div>
                            <div class="tw-mx-[5px] tw-my-[10px] tw-rounded-[14px] " *ngIf="!productsInCart[item.objectId]" (click)='addOrRemoveFromCart(i, "ADD", $event, img)'>
                                <div></div>
                                <div class="tw-flex tw-flex-row tw-items-center tw-py-[10px] tw-px-4 tw-gap-2 tw-text-center tw-text-gray-75 tw-font-bold tw-h-9 tw-text-3 tw-bg-green-400 tw-rounded-3xl">
                                    <span i18n="@@addToCart">Add to Cart</span><em class="cs-icon-arrow"></em>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <aov-sale class="tw-w-full" *ngIf="aovTask && !loading && !newCartUI" [numberOfProductsInCart]="toolbarIcons[0].cartValue" [maxPrice]="maxPrice" [showConfettiAnimation]="showConfettiAnimation" [barStyle]="barStyle" [cart]="cart" [prodImage]="prodImage" [amountRemaining]="amountRemaining" [fromListingPage]="true" [bottomBar]="totalSP && !loading"  (onCheckout)="checkoutAOV()"></aov-sale>
      <div *ngIf="aovTask && !loading && newCartUI" class="tw-absolute tw-w-full tw-h-32 tw-bottom-8">
        <app-aov-banner
          [amountRemaining]="amountRemaining"
          [freeProductBannerImage]="prodImage"
          [percentageDone]="percentageDone"
          [isEnglishLanguage]="user?.get('languagePreference') === appConfig.Shared.Languages.EN"
          [displayWhenAmountRemainingIsZero] = true
        ></app-aov-banner>
      </div>
        <div class="tw-relative tw-shadow-[0_-1px_7px_rgba(0,0,0,0.13)] tw-w-full tw-flex-[0_1_0px] tw-mt-auto" *ngIf="totalSP && !loading ">
            <div class="tw-flex tw-item-center tw-justify-center tw-py-2 tw-bg-yellow-100" *ngIf="+discount >= 5 && !aovTask">
                <p class="tw-font-body tw-text-200 tw-text-black tw-flex tw-items-center tw-gap-1">
                    <cs-icon class="tw-h-5 tw-w-5 tw-text-green-400"
                             [iconName]="'sale'"></cs-icon><span i18n="@@discountAppliedForProducts">
                        {{discount}}% discount applied</span>
                </p>
            </div>
            <div class="tw-flex tw-items-center tw-p-5 tw-pl-3">
                <div class="tw-flex tw-items-center tw-mr-1">
                    <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-500"
                             [iconName]="'shopping_cart'"></cs-icon>
                    <p class="tw-font-body tw-text-gray-500 tw-text-200"
                       *ngIf="+toolbarIcons[0].cartValue === 1"
                       i18n="@@singleProduct">
                        1 product -
                    </p>
                    <p class="tw-font-body tw-text-gray-500 tw-text-200"
                       *ngIf="+toolbarIcons[0].cartValue !== 1"
                       i18n="@@multipleProducts">
                        {{ toolbarIcons[0].cartValue }} products -
                    </p>
                </div>
                <div class="tw-flex-none tw-mr-4">
                    <span class="tw-font-body tw-font-bold tw-text-500 tw-text-green-400">₹{{totalSP%1 != 0 ?
                        totalSP.toFixed(2) : totalSP}}</span>
                </div>
                <div class="tw-flex-auto">
                    <cs-button [class]="'primary w100 shine tw-gap-2 tw-items-center tw-leading-none'" (callback)="checkout()"><cs-button-text class="tw-font-body tw-font-bold tw-text-300" i18n="@@buyNow">Buy Now</cs-button-text><cs-icon class="tw-h-7 tw-w-7 tw-text-white" [iconName]="'chevron_right'"></cs-icon></cs-button>
                </div>
            </div>
        </div>
<pop-up [show]="selfReorderWarning" (close)="closeDialog()"><self-reorder-warning (close)="back()"></self-reorder-warning></pop-up>
<pop-up *ngIf="isDiscontinued" [class]="'bdr-rad-10 p15 popup-ht-unset'" [show]="isDiscontinued" [triggerDismiss]="true" (close)="closePopup()">
    <discontinued-popup [oldProductData]="oldProduct" [newProductData]="newProduct" (changeProduct)="changeProduct()" (articleOpen)="openArticle()"></discontinued-popup>
</pop-up>
<div class="tw-absolute tw-p-5 tw-border tw-border-gray-500 tw-bg-white tw-flex tw-gap-4 animate-fly_product" *ngIf="animationProduct" style="height: 200px; top: calc(50% - 100px); right: 16px; left: 16px">
    <img class="tw-flex-none tw-w-auto tw-object-contain" style="height: 158px" [src]='animationProduct?.rebrandedImageWithBackground && animationProduct?.rebrandedImageWithBackground.length ? animationProduct.rebrandedImageWithBackground[animationProduct.rebrandedImageWithBackground.length - 1] : false || animationProduct.productUnboxedImage[animationProduct.productUnboxedImage.length - 1] || animationProduct.images[animationProduct.images.length - 1]' />
    <div class="tw-flex-1 tw-text-[12px]">{{animationProduct.title}}</div>
</div>
